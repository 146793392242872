import React, { useEffect, useState } from 'react';
import { Keyboard, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

import { changeUserName } from 'services/userService';

import SponsoredBy from 'components/Treeinfo/SponsoredBy';
import Avatar from 'components/reusable/Avatar';
import CustomButton from 'components/reusable/CustomButton';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import Icon from 'components/reusable/Icon';
import ScreenWithRightButton from 'components/reusable/ScreenWithRightButton';
import SquircleContainer from 'components/reusable/SquircleContainer';
import Text from 'components/reusable/Text';
import Toast from 'components/reusable/Toast';
import UnderlinedInput from 'components/reusable/UnderlinedInput';
import UploadImage from 'components/reusable/UploadImage';

import ScreenContainer from 'reusable/ScreenContainer';

import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const EditProfile = ({ navigation }) => {
 const store = useSnapshot(authStore);

 const [userData, setUserData] = useState({
  firstName: store.profile.firstName || '',
  lastName: store.profile.lastName || '',
 });

 const onChangePassword = () => {
  navigation.navigate('ChangePasswordModal');
 };

 const onChangeEmail = () => {
  navigation.navigate('BeforeChangeEmailModal');
 };

 const handleChange = (name, value) => {
  setUserData({ ...userData, [name]: value });
 };

 const saveUserData = async () => {
  const data = {
   userId: store.profile._id,
   firstName: userData.firstName,
   lastName: userData.lastName,
  };

  const response = await changeUserName(data);

  authStore.profile = {
   ...authStore.profile,
   firstName: userData.firstName,
   lastName: userData.lastName,
  };

  Keyboard.dismiss();

  navigation.setOptions({
   headerRight: () => null,
  });

  if (response.type === 'success') {
   Defaults.toast.show(<Toast message={response.message} />);
  } else {
   Defaults.toast.show(<Toast withError={true} message={response.message} />);
  }
 };

 useEffect(() => {
  navigation.addListener('beforeRemove', () => {
   if (Defaults.toast) {
    Defaults.toast.hide();
   }
  });
 }, []);

 const notChanged =
  store.profile.firstName === userData.firstName &&
  store.profile.lastName === userData.lastName;

 return (
  <ScreenWithRightButton
   text={notChanged ? '' : 'Done'}
   disabled={notChanged ? true : false}
   isShow={true}
   userData={userData}
   navigation={navigation}
   onPress={saveUserData}>
   <FocusAwareStatusBar barStyle='dark-content' />

   <ScreenContainer>
    <View style={styles.avatarContainer}>
     <UploadImage
      uploadedImageWidth={300}
      images={store.profile.avatar || []}
      withAvatar={true}
      render={(pickImage) => (
       <TouchableOpacity onPress={pickImage}>
        <Avatar mode='edit' image={authStore.profile.avatar} />
       </TouchableOpacity>
      )}
     />
    </View>

    <View style={styles.formContainer}>
     <View style={{ marginBottom: 10 }}>
      <UnderlinedInput
       value={userData.firstName}
       placeholder='First Name'
       onChangeText={(val) => handleChange('firstName', val)}
       error={false}
       showClearIcon={true}
       onClear={() => handleChange('firstName', '')}
      />
     </View>

     <View style={{ marginBottom: 10 }}>
      <UnderlinedInput
       value={userData.lastName}
       placeholder='Last Name'
       onChangeText={(val) => handleChange('lastName', val)}
       error={false}
       showClearIcon={true}
       onClear={() => handleChange('lastName', '')}
      />
     </View>

     <CustomButton
      height={40}
      onPress={onChangeEmail}
      customStyles={{ paddingLeft: 0, paddingRight: 4 }}>
      <Text
       fontType='MontserratSemiBold'
       fontSize={lineHeight.subTitle3}
       color='NEUTRAL800'
       style={styles.textStyle}>
       {store.profile.email}
      </Text>

      <Icon name='arrowLeft' style={styles.arrowRight} />
     </CustomButton>
    </View>

    {store.profile?.enterpriseData && (
     <View style={styles.buttonMargin}>
      <CustomButton height={80}>
       <SponsoredBy
        image={store.profile?.enterpriseData?.avatar}
        text={store.profile?.enterpriseData?.enterpriseName}
        status={store.profile?.enterpriseData?.typeOfEnterprise}
       />
      </CustomButton>
     </View>
    )}

    <CustomButton onPress={onChangePassword}>
     <SquircleContainer style={styles.iconContainer} bgColor='#fff'>
      <Icon
       name='lock3'
       style={{ color: getColor('NORMAL_ORANGE'), fontSize: 27 }}
      />
     </SquircleContainer>

     <Text
      fontType='MontserratSemiBold'
      fontSize={14}
      color='NEUTRAL800'
      style={styles.textStyle}>
      Change password
     </Text>

     <Icon name='arrowLeft' style={styles.arrowRight} />
    </CustomButton>
   </ScreenContainer>
  </ScreenWithRightButton>
 );
};

export default EditProfile;

const styles = StyleSheet.create({
 container: {
  borderRadius: 16,
  background: getColor('NEUTRAL200'),
 },
 buttonMargin: {
  marginBottom: 16,
 },
 avatarContainer: {
  alignItems: 'center',
  width: '100%',
  marginTop: 30,
 },
 formContainer: {
  borderRadius: 16,
  marginTop: 40,
  marginBottom: 16,
  paddingHorizontal: 20,
  paddingVertical: 10,
  backgroundColor: getColor('NEUTRAL200'),
 },

 iconContainer: {
  width: 44,
  height: 44,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 12,
  marginRight: 12,
  backgroundColor: '#fff',
 },
 textStyle: { marginRight: 'auto' },
 arrowRight: { color: '#454A5B', transform: [{ rotate: '180deg' }] },
});
