import axios from 'axios';

import { storageGetString } from 'utils/mmkvStorage';

class Ajax {
 headers(isPhoto) {
  return {
   'Content-Type': isPhoto ? 'multipart/form-data' : 'application/json',
   Authorization: 'Bearer ' + storageGetString('AccessToken'),
  };
 }

 get(uri, payload) {
  return this._fetch(uri, payload, 'get');
 }

 post(uri, payload, isPhoto) {
  return this._fetch(uri, payload, 'post', isPhoto);
 }

 delete(uri, payload) {
  return this._fetch(uri, payload, 'delete');
 }

 patch(uri, payload) {
  return this._fetch(uri, payload, 'patch');
 }

 put(uri, payload) {
  return this._fetch(uri, payload, 'put');
 }

 _fetch(uri, payload, method, isPhoto) {
  const promise = new Promise((resolve, reject) => {
   const params = payload;
   const headers = this.headers(isPhoto);
   const url = uri;

   axios({
    method,
    url,
    headers,
    data: params,
    params,
   })
    .then((response) => {
     const errorWithStatusCode = {
      message: null,
      status: 'Success',
     };

     this.logResponse(method, url, headers, payload, errorWithStatusCode);

     resolve(response.data);
    })
    .catch((error) => {
     const err = JSON.parse(
      error.response.request._response || error.response.request.response
     );

     const errorWithStatusCode = {
      ...err,
      status: 'Fail',
     };

     this.logResponse(method, url, headers, payload, errorWithStatusCode);

     reject(errorWithStatusCode);
    });
  });
  return promise;
 }

 static getParams(payload, request) {
  return payload
   ? '\n' +
      (request ? '>>>>>' : '<<<<<') +
      ' Body Param: ' +
      JSON.stringify(payload)
   : '';
 }

 logRequest(method, url, headers, payload = '') {
  console.log(
   '>>>>>>>>>>>>>>>>\n' +
    '>>>>> Headers: ' +
    JSON.stringify(headers) +
    '\n' +
    '>>>>> ' +
    method +
    ' ' +
    url +
    Ajax.getParams(payload, true) +
    '\n' +
    '>>>>>>>>>>>>>>>>'
  );
 }

 logResponse(method, url, headers, payload = '', response) {
  console.log(
   '<<<<<<<<<<<<<<<<\n' +
    // '<<<<< Headers: ' +
    // JSON.stringify(headers) +
    // '\n' +
    '<<<<< ' +
    'Method: ' +
    method +
    '\n' +
    '<<<<< ' +
    'Url: ' +
    url +
    Ajax.getParams(payload, false) +
    '\n' +
    '<<<<< Status: ' +
    response?.status +
    '\n' +
    '<<<<< Errors: ' +
    response?.message +
    '\n' +
    '<<<<<<<<<<<<<<<<'
  );
 }
}

export default new Ajax();
