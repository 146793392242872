// import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import axios from 'axios';
import debounce from 'lodash/debounce';
import 'mapbox-gl/dist/mapbox-gl.css';
// import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Map, { Source, Layer, GeolocateControl } from 'react-map-gl';
import { useSnapshot } from 'valtio';

import geoFence from 'store/geoFence';

import { getGeofence } from 'utils/calls';
import { MAP_ACCESSTOKEN, API_MAP } from 'utils/uris';

const mapStyle = {
 Satellite: 'mapbox://styles/mapbox/satellite-v9',
 SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
};

const WebMapOld = () => {
 const mapRef = React.useRef();
 const [clusters, setclusters] = useState([]);
 const [styleURL, setStyleURL] = useState(mapStyle.SatelliteStreet);
 const { geoFences } = useSnapshot(geoFence);

 const zoomRef = useRef(2);
 const initialRequestPerformed = useRef(false);

 const fetchInitialData = () => {
  if (geoFences && mapRef.current && !initialRequestPerformed.current) {
   getClusters();
   getGeofence();
   initialRequestPerformed.current = true;
  }
 };

 useEffect(() => {
  fetchInitialData();
 }, []);

 const getClusters = async () => {
  const map = mapRef.current.getMap();
  const bounds = map.getBounds().toArray();
  const baseUrl = API_MAP;
  const queries = `?zoom=${Math.round(zoomRef.current)}&westLng=${
   bounds[1][0]
  }&southLat=${bounds[0][1]}&eastLng=${bounds[0][0]}&northLat=${
   bounds[1][1]
  }&userId=&private=${null}`;

  axios.get(`${baseUrl}${queries}`).then((res) => {
   const { clusters } = res.data;
   setclusters(clusters);
  });
 };

 const debouncedChangeHandler = useCallback(
  debounce(() => getClusters(), 200),
  []
 );

 const onMapActionPerformed = () => {
  debouncedChangeHandler();
 };

 const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'trees',
  filter: ['!', ['has', 'point_count']],
  paint: {
   'circle-color': '#ed7d32',
   'circle-radius': 6,
   'circle-stroke-width': 1,
   'circle-stroke-color': '#fff',
  },
 };

 return (
  <>
   <Map
    ref={(ref) => {
     mapRef.current = ref;
     fetchInitialData();
    }}
    initialViewState={{
     longitude: -80,
     latitude: 37.8,
     zoom: zoomRef.current,
    }}
    dragRotate={false}
    keyboard={false}
    touchZoomRotate={false}
    boxZoom={false}
    doubleClickZoom={false}
    attributionControl={false}
    onMoveEnd={() => {
     onMapActionPerformed();
    }}
    onZoomEnd={({ viewState: { zoom } }) => {
     zoomRef.current = zoom;
     onMapActionPerformed();
    }}
    style={{
     flex: 1,
     borderRadius: 12,
    }}
    mapboxAccessToken={MAP_ACCESSTOKEN}
    renderWorldCopies={false}
    mapStyle={styleURL}
    interactiveLayerIds={['data', 'unclustered-point']}>
    <Source
     id='trees'
     type='geojson'
     data={{
      type: 'FeatureCollection',
      crs: {
       type: 'name',
       properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
      },

      features: clusters,
     }}
     clusterMaxZoom={14}
     clusterRadius={50}>
     <Layer {...clusterLayer} />
     <Layer {...clusterCountLayer} />
     <Layer {...unclusteredPointLayer} />
    </Source>
   </Map>
  </>
 );
};

export default WebMapOld;

const clusterLayer = {
 id: 'clusters',
 type: 'circle',
 source: 'trees',
 filter: ['has', 'point_count'],
 paint: {
  'circle-color': [
   'step',
   ['get', 'point_count'],
   '#ed7d32',
   100,
   '#ed7d32',
   750,
   '#ed7d32',
  ],
  'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
 },
};

const clusterCountLayer = {
 id: 'cluster-count',
 type: 'symbol',
 source: 'trees',
 filter: ['has', 'point_count'],
 layout: {
  'text-field': '{point_count_abbreviated}',
  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
  'text-size': 12,
 },
};
