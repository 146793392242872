import { useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
 Alert,
 Pressable,
 ScrollView,
 StyleSheet,
 TouchableOpacity,
 View,
} from 'react-native';
import uuid from 'react-native-uuid';
import { useSnapshot } from 'valtio';

import CommonNames from './CommonNames';

import authStore from 'store/authStore';

import { addTree } from 'services';
import {
 deleteTreeImage,
 getNetworkSpeed,
 getTreeFullInfo,
} from 'services/treeServices';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import SmallMap from 'components/map/SmallMap';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import Hr from 'components/reusable/Hr';
import Icon from 'components/reusable/Icon';
import Radio from 'components/reusable/Radio';
import ScreenWithRightButton from 'components/reusable/ScreenWithRightButton';
import Select from 'components/reusable/Select';
import SquircleContainer from 'components/reusable/SquircleContainer';
import Toast from 'components/reusable/Toast';
import UploadImage from 'components/reusable/UploadImage';
import { AddTreeInfoSchema } from 'components/validations/schema';

import ScreenContainer from 'reusable/ScreenContainer';
import Text from 'reusable/Text';

import { getLocationPermission } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from 'utils/dimensions';
import { storageGetString, storageSet } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';
import { colors, fonts, fontSize, lineHeight } from 'utils/theme';

const AddTreeInfo = ({ navigation, route }) => {
 const [networkSpeed, setNetworkSpeed] = useState();

 const store = useSnapshot(authStore);

 const isDirtyRef = useRef(false);
 const currentCommonNameValue = useRef();

 const genusArr = storageGetString('genusData');

 const [isOpenCommonNames, setIsOpenCommonNames] = useState(false);
 const [commonNamesArray, setCommonNamesArray] = useState([]);

 const resolver = useYupValidationResolver(AddTreeInfoSchema);

 const {
  watch,
  control,
  setValue,
  getValues,
  reset,
  handleSubmit,
  formState: { errors, isDirty },
 } = useForm({
  resolver,
  defaultValues: {
   commonName: '',
   genus: {},
   species: '',
   privateValue: false,
   imageUrl: [],
   coordinates: '',
   properties: {
    family: '',
   },
   customFields: {},
  },
 });
 //  const watchFields = watch('commonName');

 //  const getCommonNamesData = async () => {
 //   const response = await getCommonNames(watchFields);

 //   if (response.commons.length > 0) {
 //    setCommonNamesArray(response.commons);

 //    if (!isOpenCommonNames.visible) {
 //     setIsOpenCommonNames(true);

 //     navigation.setOptions({ headerShown: false });
 //    }
 //   }
 //  };

 //  const handler = useCallback(
 //   debounce(() => getCommonNamesData(), 1000),
 //   []
 //  );

 useFocusEffect(
  React.useCallback(() => {
   getNetworkSpeed().then((speed) => {
    console.log(speed);
    setNetworkSpeed(speed);
   });
  }, [])
 );

 const onSubmit = async () => {
  const {
   coordinates,
   imageUrl,
   commonName,
   privateValue,
   genus,
   customFields,
   species,
   properties,
  } = getValues();

  let obj = {
   lng: coordinates[0],
   lat: coordinates[1],
   userId: storageGetString('userId'),
   organizationId: store.isOffline
    ? store.profile?.enterprise
    : !store.isOffline
    ? store.profile?.enterpriseData?._id
    : '',
   imgUrls: imageUrl,
   private: privateValue,
   properties: {
    family: properties.family,
    age: properties.age,
    biomass: properties.biomass,
    common: commonName,
    crown: properties.crown,
    dbh: properties.dbh,
    genus: genus.genus,
    mapScreenshoot: properties.mapScreenshoot,
    health: properties.health,
    height: properties.height,
    location: properties.location,
    planted: properties.planted,
    refId: properties.refId,
    resilience: properties.resilience,
    species: species,
    structure: properties.structure,
    updated: properties.updated,
    variety: properties.variety,
    ...customFields,
   },
  };

  if (store.isOffline || networkSpeed > Defaults.maxWaitTime) {
   let b = {
    _id: '',
    createdAt: new Date().toISOString(),
    custom: true,
    geometry: {
     coordinates: [coordinates[0], coordinates[1]],
     type: 'Point',
    },
    imgUrls: imageUrl,
    organizationId: store.profile?.enterpriseData?._id,
    private: privateValue,
    properties: {
     ...obj.properties,
     offlineTreeId: uuid.v4(),
     color: privateValue ? '#717993' : '#559158',
     imgUrls: imageUrl,
    },
    type: 'Feature',
    userId: store.profile._id,
   };

   authStore.trees = [...authStore.trees, b];

   if (storageGetString('OfflineAddedTrees')) {
    storageSet(
     'OfflineAddedTrees',
     JSON.stringify([...JSON.parse(storageGetString('OfflineAddedTrees')), b])
    );
   } else {
    storageSet('OfflineAddedTrees', JSON.stringify([b]));
   }

   reset();

   navigation.navigate('HomeScreen');
  } else {
   const response = await addTree(obj);

   if (response.type === 'success') {
    reset();

    const b = {
     _id: response.tree._id,
     createdAt: new Date().toISOString(),
     custom: true,
     geometry: {
      coordinates: [coordinates[0], coordinates[1]],
      type: 'Point',
     },
     imgUrls: imageUrl,
     organizationId: store.profile?.enterpriseData?._id,
     private: privateValue,
     properties: {
      color: privateValue ? '#717993' : '#559158',
     },
     type: 'Feature',
     userId: storageGetString('userId'),
    };

    authStore.trees = [...authStore.trees, b];

    navigation.navigate('HomeScreen');

    // if (isOpenCommonNames) {
    //  setIsOpenCommonNames(false);
    // }
   } else {
    Defaults.toast.show(<Toast message={response.message} withError={true} />);
   }
  }
 };

 const deletePhoto = async (img) => {
  let fileId = img.uri.split('fileId=')[1];

  await deleteTreeImage(fileId);
 };

 const onDeletePhoto = (img) => {
  Alert.alert(
   '',
   'This action will delete this photo from the gallery. Would you like to continue?',
   [
    {
     text: 'Cancel',
     style: 'cancel',
     onPress: () => console.log('Cancel Pressed'),
    },

    {
     text: 'Continue',
     onPress: () => {
      const filterImages = getValues().imageUrl.filter((g) => g.id !== img.id);

      setValue('imageUrl', filterImages);

      deletePhoto(img);
     },
    },
   ]
  );
 };

 useEffect(() => {
  navigation.setOptions({
   headerLeft: () => (
    <TouchableOpacity
     hitSlop={Defaults.hitSlop}
     onPress={() => {
      if (isDirtyRef.current) {
       reset();
      }
      navigation.navigate('HomeScreen', { haveToRenderTabs: true });
     }}
     style={{ marginLeft: 18 }}>
     <Icon style={{ color: '#0F1118', ...lineHeight.h3 }} name='arrowLeft' />
    </TouchableOpacity>
   ),
   headerRight: () => (
    <TouchableOpacity
     hitSlop={Defaults.hitSlop}
     onPress={handleSubmit(onSubmit)}>
     <Text style={styles.buttonStyle}>Done</Text>
    </TouchableOpacity>
   ),
  });
 }, [navigation]);

 //  useEffect(() => {
 //   if (
 //    watchFields &&
 //    watchFields !== currentCommonNameValue.current &&
 //    !store.isOffline
 //   ) {
 //    handler();
 //   }
 //  }, [watchFields]);

 const onselect = (title, name, withSearch = false) => {
  navigation.navigate('SelectableModal', {
   name,
   title,
   genusValue: getValues().genus,
   array:
    Object.keys(getValues().genus).length > 0 && name === 'species'
     ? getValues().genus.species
     : Object.keys(getValues().genus).length === 0 && name === 'species'
     ? []
     : JSON.parse(genusArr),
   withSearch,
   setValue: (val) => {
    if (val.genus && getValues().species) {
     setValue(name, val, { shouldDirty: true });
     setValue('species', '', { shouldDirty: true });
    } else {
     setValue(name, val, { shouldDirty: true });
    }
   },
  });
 };

 const setData = async (data) => {
  navigation.setOptions({ headerShown: true });

  setIsOpenCommonNames(false);
  setCommonNamesArray([]);

  const response = await getTreeFullInfo(data.treeId);

  const { properties } = response.tree[0];

  currentCommonNameValue.current = properties.common;

  const genusObj = JSON.parse(genusArr).find(
   (g) => g.genus === properties.genus
  );

  setValue('commonName', properties.common);
  setValue('genus', genusObj);
  setValue('species', properties.species);
 };

 const setAdditionalInfo = (val) => {
  setValue('properties', val, { shouldDirty: true });

  let customFieldsObj = {
   customField1: val.customField1,
   customField2: val.customField2,
   customField3: val.customField3,
  };
  setValue('customFields', customFieldsObj, { shouldDirty: true });
 };

 const navigateAddPinScreen = (coords) => {
  navigation.navigate('AddPin', {
   coords,
   setCoordinates: (data) => {
    setValue('coordinates', data.coordinates, { shouldDirty: true });
   },
  });
 };

 const addTreePosition = async (val) => {
  const status = await getLocationPermission();

  let obj;

  if (val) {
   obj = {
    coords: {
     longitude: val[0],
     latitude: val[1],
    },
   };
  }

  if (status !== 'denied') {
   navigateAddPinScreen(obj || status);
  }
 };

 isDirtyRef.current = isDirty;

 return (
  <>
   <FocusAwareStatusBar barStyle='dark-content' />
   <ScrollView>
    <ScreenWithRightButton
     isShow
     text='Done'
     navigation={navigation}
     onPress={handleSubmit(onSubmit)}>
     <ScreenContainer style={{ marginBottom: 30 }}>
      <View
       style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
       }}>
       <Text
        fontType='MontserratBold'
        fontSize={lineHeight.body2}
        style={styles.desc}>
        Location
       </Text>

       <Controller
        control={control}
        name='coordinates'
        render={({ field: { value } }) => {
         if (value) {
          return (
           <Text
            fontType='MontserratBold'
            fontSize={lineHeight.body2}
            style={styles.desc}
            onPress={() => addTreePosition(value)}>
            Edit
           </Text>
          );
         }

         return null;
        }}
       />
      </View>

      <Controller
       control={control}
       name='coordinates'
       render={({ field: { value } }) => {
        if (value) {
         return <SmallMap coords={value} />;
        } else {
         return (
          <Button
           title='Position on the map'
           bgColor={colors.NORMAL_GREEN}
           onPress={() => addTreePosition(null)}
          />
         );
        }
       }}
      />

      <View style={{ marginTop: 16 }}>
       <Hr />
      </View>

      <View>
       <Text
        fontType='MontserratBold'
        fontSize={lineHeight.body2}
        style={[styles.desc, { marginTop: 30 }]}>
        Label Info
       </Text>

       <Field label='Common Name'>
        <ControllerInput
         withClearIcon
         withIcon={false}
         name='commonName'
         control={control}
         placeholder='Enter common name'
         error={errors.commonName?.message}
         inputStyles={{ marginBottom: 10 }}
        />
       </Field>

       <Field label='Genus'>
        <Controller
         control={control}
         name='genus'
         render={({ field: { value } }) => {
          return (
           <Select
            error={errors['genus.genus']?.message}
            value={Object.keys(value).length > 0 ? value.genus : ''}
            placeholder='Select genus'
            onPress={() => onselect('Genus', 'genus', true)}
           />
          );
         }}
        />
       </Field>

       <Field label='Species'>
        <Controller
         control={control}
         name='species'
         render={({ field: { value } }) => {
          return (
           <Select
            error={errors['genus.genus']?.message}
            value={Object.keys(value).length > 0 ? value : ''}
            placeholder='Select species'
            onPress={() => onselect('Species', 'species', true)}
           />
          );
         }}
        />
       </Field>
      </View>

      <View style={{ marginTop: 18, marginBottom: 20 }}>
       <Hr />
      </View>

      <Text
       fontType='MontserratBold'
       fontSize={lineHeight.body1}
       style={styles.desc}>
       Photo(s)
      </Text>

      <View
       style={{
        flexDirection: 'row',
       }}>
       <Controller
        control={control}
        name='imageUrl'
        render={({ field: { value } }) => {
         return (
          <UploadImage
           images={value}
           networkSeed={networkSpeed}
           onDeletePhoto={(img) => onDeletePhoto(img)}
           setImages={(img) =>
            setValue('imageUrl', [img, ...value], { shouldDirty: true })
           }
           withAvatar={false}
          />
         );
        }}
       />
      </View>

      <View style={{ marginTop: 16 }}>
       <Hr />
      </View>

      <Text
       fontType='MontserratBold'
       fontSize={lineHeight.body2}
       style={[styles.desc, { marginTop: 16 }]}>
       Private
      </Text>
      <View style={{ flexDirection: 'row' }}>
       <Controller
        control={control}
        name='privateValue'
        render={({ field: { value } }) => {
         return (
          <View style={{ marginRight: 36 }}>
           <Radio
            onPress={() =>
             setValue('privateValue', true, { shouldDirty: true })
            }
            selected={value || false}>
            Yes
           </Radio>
          </View>
         );
        }}
       />

       <Controller
        control={control}
        name='privateValue'
        render={({ field: { value } }) => {
         return (
          <View style={{ marginRight: 36 }}>
           <Radio
            onPress={() =>
             setValue('privateValue', false, { shouldDirty: true })
            }
            selected={value === false ? true : false}>
            No
           </Radio>
          </View>
         );
        }}
       />
      </View>

      <View style={{ marginBottom: 16, marginTop: 20 }}>
       <Hr />
      </View>

      <Pressable
       onPress={() => {
        navigation.navigate('AdditionalInfoModal', {
         setValue: (val) => setAdditionalInfo(val),
         values: getValues().properties,
        });
       }}
       style={{ flexDirection: 'row', alignItems: 'center' }}>
       <SquircleContainer
        bgColor={getColor('LIGHT_GREEN_HOVER')}
        style={styles.infoButton}
        cornerRadius={12}>
        <Icon
         name='plus2'
         style={{ fontSize: 15, color: getColor('NORMAL_GREEN') }}
        />
       </SquircleContainer>
       <Text
        fontType='MontserratSemiBold'
        fontSize={fontSize.body2}
        color='NEUTRAL800'
        style={{ marginLeft: 12 }}>
        Additional Info
       </Text>

       <Icon
        name='arrowLeft'
        style={{ marginLeft: 'auto', transform: [{ rotate: '180deg' }] }}
       />
      </Pressable>
     </ScreenContainer>
    </ScreenWithRightButton>

    {isOpenCommonNames && (
     <View style={styles.commonNametyles}>
      <CommonNames
       isAddScreen={true}
       onClear={() => setValue('commonName', '')}
       inputVal={getValues().commonName}
       onPressItem={(data) => setData(data)}
       commonNames={commonNamesArray}
       onDone={(val) => {
        navigation.setOptions({ headerShown: true });
        setValue('commonName', val);
        setIsOpenCommonNames(false);
       }}
       onCancelPress={() => {
        navigation.setOptions({ headerShown: true });
        setCommonNamesArray([]);
        setIsOpenCommonNames(false);
       }}
      />
     </View>
    )}
   </ScrollView>
  </>
 );
};

export default AddTreeInfo;

const Field = ({ label, marginBottom = 16, children }) => (
 <View style={{ marginBottom: marginBottom }}>
  <Text
   color='NEUTRAL600'
   fontSize={fontSize.body3}
   fontType='MontserratSemiBold'
   style={{ marginBottom: 8 }}>
   {label}
  </Text>
  {children}
 </View>
);

const styles = StyleSheet.create({
 desc: {
  marginBottom: 16,
 },
 addPhotoButton: {
  width: 100,
  height: 100,
  borderRadius: 12,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: getColor('LIGHT_GREEN_HOVER'),
 },
 buttonStyle: {
  color: getColor('NORMAL_GREEN'),
  fontFamily: fonts.MontserratBold,
  letterSpacing: 0.5,
  marginRight: 12,
 },
 labelStyle: {
  color: getColor('NEUTRAL600'),
 },
 closeIcon: {
  position: 'absolute',
  right: -8,
  top: -9,
  color: getColor('NEUTRAL500'),
  fontSize: fontSize.button1.fontSize,
 },
 fieldContainer: {
  marginBottom: 16,
 },
 infoButton: {
  width: 36,
  height: 36,
  justifyContent: 'center',
  alignItems: 'center',
 },
 commonNametyles: {
  position: 'absolute',
  top: 0,
  bottom: 0,
  paddingBottom: 30,
  width: SCREEN_WIDTH,
  height: SCREEN_HEIGHT,
  backgroundColor: '#fff',
  paddingTop: 50,
 },
});
