import * as FileSystem from 'expo-file-system';
import ajax from 'helpers/ajax';

import { Defaults } from 'utils/defaults';
import { storageGetString } from 'utils/mmkvStorage';
import {
 ADDTREE,
 COMMENTS,
 DELETETREE,
 DELETETREEIMAGE,
 DELETE_SELECTED_TREES,
 EDITTREE,
 FILTER_SEARCHED_TREES,
 GETCOLLECTION,
 GETCSV,
 GETENTERPRISETREES,
 GETFULLTREEINFO,
 SEARCHTREE,
 SUGGESTIONS,
 TREECOUNT,
 TREEINFO,
 UPDATE_TREE_VISIBILITY,
} from 'utils/uris';

const getGenus = async (type) => {
 try {
  const response = await ajax.get(`${SUGGESTIONS}?type=${type}`);

  return response;
 } catch (error) {
  return error;
 }
};

const getCommonNames = async (value = '', enterpriseId) => {
 const userId = storageGetString('userId');
 try {
  const response = await ajax.get(
   enterpriseId
    ? `${COMMENTS}?searchStr=${value}&organizationId=${enterpriseId}&page=1&limit=100`
    : `${COMMENTS}?searchStr=${value}&userId=${userId}&page=1&limit=100`
  );

  return response;
 } catch (error) {
  return error;
 }
};

const deleteTree = async (id, userId) => {
 try {
  const response = await ajax.delete(
   `${DELETETREE}?treeId=${id}&userId=${userId}`
  );

  return response;
 } catch (error) {
  return error;
 }
};

const getTreeInfo = async (longitude, latitude) => {
 try {
  const response = await ajax.get(
   `${TREEINFO}?coordinates=[${longitude}, ${latitude}]`
  );

  return response;
 } catch (error) {
  return error;
 }
};

const getTreeFullInfo = async (id) => {
 try {
  const response = await ajax.get(`${GETFULLTREEINFO}?treeId=${id}`);

  return response;
 } catch (error) {
  return error;
 }
};

const editTreeInfo = async (data) => {
 try {
  const response = await ajax.patch(EDITTREE, data);

  return response;
 } catch (error) {
  return error;
 }
};

const addTree = async (data) => {
 try {
  const response = await ajax.post(ADDTREE, data);

  return response;
 } catch (error) {
  return error;
 }
};

const getSpecies = async (genus) => {
 try {
  const response = await ajax.get(`${SUGGESTIONS}?type=species&genus=${genus}`);

  return response;
 } catch (error) {
  return error;
 }
};

const deleteTreeImage = async (imageId) => {
 try {
  const response = await ajax.delete(`${DELETETREEIMAGE}${imageId}`);

  return response;
 } catch (error) {
  return error;
 }
};

const searchTree = async (searchStr, coords) => {
 try {
  const response = await ajax.get(
   `${SEARCHTREE}?searchStr=${searchStr}&radius=2&lng=${coords[0]}&lat=${coords[1]}&page=1&limit=100`
  );

  return response;
 } catch (error) {
  return error;
 }
};

const getAllTrees = async () => {
 try {
  const response = await ajax.get(`${TREECOUNT}`);

  return response;
 } catch (error) {
  return error;
 }
};

const getEnterpriseTrees = async (enterpriseId, userId) => {
 try {
  const response = await ajax.get(
   enterpriseId
    ? `${GETENTERPRISETREES}organizationId=${enterpriseId}`
    : `${GETENTERPRISETREES}userId=${userId}`
  );
  return response;
 } catch (error) {
  return error;
 }
};

const getCollection = async (
 page,
 limit,
 enterpriseId,
 userId,
 species,
 genus,
 common
) => {
 try {
  const response = await ajax.get(
   enterpriseId
    ? `${GETCOLLECTION}page=${page}&limit=${limit}&enterpriseId=${enterpriseId}&species=${species}&genus=${genus}&common=${common}`
    : `${GETCOLLECTION}page=${page}&limit=${limit}&userId=${userId}&species=${species}&genus=${genus}&common=${common}`
  );
  return response;
 } catch (error) {
  return error;
 }
};

const downloadCsv = async (enterpriseId, userId) => {
 try {
  const response = await ajax.get(
   enterpriseId
    ? `${GETCSV}organizationId=${enterpriseId}`
    : `${GETENTERPRISETREES}userId=${userId}`
  );
  return response;
 } catch (error) {
  return error;
 }
};

const filterSearchedTrees = async (
 organizationIds,
 names,
 genuses,
 userIds,
 species,
 userId,
 lng,
 lat,
 radius
) => {
 try {
  const response = await ajax.post(FILTER_SEARCHED_TREES, {
   organizationIds,
   names,
   genuses,
   userIds,
   species,
   userId,
   lng,
   lat,
   radius,
  });
  return response;
 } catch (error) {
  return error;
 }
};

const updateTreeVisibility = async (
 enterpriseId,
 selectedTreeArray,
 isPrivate,
 userId
) => {
 try {
  const response = await ajax.patch(UPDATE_TREE_VISIBILITY, {
   treeIds: selectedTreeArray,
   private: isPrivate,
   organizationId: enterpriseId ? enterpriseId : null,
   userId: enterpriseId ? null : userId,
  });
  return response;
 } catch (error) {
  return error;
 }
};

const deleteSelectedTrees = async (enterpriseId, selectedTreeArray, userId) => {
 try {
  const response = await ajax.patch(DELETE_SELECTED_TREES, {
   treeIds: selectedTreeArray,
   organizationId: enterpriseId && userId ? enterpriseId : null,
   userId: userId && !enterpriseId ? userId : null,
  });
  return response;
 } catch (error) {
  return error;
 }
};

const getNetworkSpeed = async () => {
 const startTime = Date.now();

 const downloadResumable = FileSystem.createDownloadResumable(
  'https://clusters.tremap.com/assets/small.mp4',
  FileSystem.documentDirectory + 'small.mp4',
  {},
  () => {}
 );

 try {
  const { uri } = await Promise.race([
   downloadResumable.downloadAsync(),
   new Promise((_, reject) =>
    setTimeout(() => {
     reject(10000000);

     return 100000000;
    }, Defaults.maxWaitTime)
   ),
  ]);

  const endTime = Date.now();
  const timeDiff = endTime - startTime;

  return timeDiff;
 } catch (error) {
  downloadResumable.pauseAsync();

  return error;
 }
};

export {
 getSpecies,
 editTreeInfo,
 getGenus,
 getTreeInfo,
 deleteTree,
 addTree,
 getTreeFullInfo,
 getCommonNames,
 deleteTreeImage,
 searchTree,
 getAllTrees,
 getEnterpriseTrees,
 getCollection,
 filterSearchedTrees,
 downloadCsv,
 updateTreeVisibility,
 deleteSelectedTrees,
 getNetworkSpeed,
};
