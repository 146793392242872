import axios from 'axios';
import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Map, { Source, Layer, GeolocateControl, Marker } from 'react-map-gl';
import { Image, StyleSheet, View } from 'react-native';
import { useSnapshot } from 'valtio';

import { WebStyles } from '../WebStyles';
import {
 unclusteredPointLayer,
 geofenceLayer,
 geofenceLayerLine,
 clusterLayer,
 clusterCountLayer,
 mapStyle,
} from './map-style';

import geoFence from 'store/geoFence';
import treeFilter from 'store/treeFilter';

import { filterSearchedTrees } from 'services/treeServices';

import GenerateMapEmbedLink from 'components/modals/GenerateMapEmbedLink';
import ModalWrapper from 'components/modals/ModalWrapper';
import AddGeoFence from 'components/webComps/webcommon/AddGeoFence';
import AddTreeButton from 'components/webComps/webcommon/AddTreeButton';
import AddTreeWeb from 'components/webComps/webcommon/AddTreeWeb';
import GeofenceInfo from 'components/webComps/webcommon/GeofenceInfo';
import MapButton from 'components/webComps/webcommon/MapButton';
import MapFeatures from 'components/webComps/webcommon/MapFeatures';
import MapZoomButton from 'components/webComps/webcommon/MapZoomButton';
import SelectTrees from 'components/webComps/webcommon/SelectTrees';
import WebSearch from 'components/webComps/webcommon/WebSearch';
import WebTreeInfoMini from 'components/webComps/webcommon/WebTreeInfoMini';

import { getGeofence } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { IMAGES } from 'utils/images';
import { MAP_ACCESSTOKEN, API_MAP } from 'utils/uris';

const WebMap = ({ isEmbededMap, embededMapParams }) => {
 const mapRef = React.useRef();
 const GeoLocateRef = React.useRef();
 const [center, setCenter] = useState();
 const [geofenceHoverInfo, setGeofenceHoverInfo] = useState(null);
 const [treeHoverInfo, setTreeHoverInfo] = useState(null);
 const [showFeatures, setShowFeatures] = useState(false);
 const [geofenceVisible, setGeofenceVisible] = useState(true);
 const [streetsVisible, setStreetsVisible] = useState(true);
 const [clusters, setClusters] = useState([]);
 const [styleURL, setStyleURL] = useState(mapStyle.SatelliteStreet);
 const [isCtrlPressed, setIsCtrlPressed] = useState(false);
 const [searchedTreeValues, setSearchedTreeValues] = useState([]);
 const [filterValues, setFilterValues] = useState([]);
 const [selectedTreeIds, setSelectedTreeIds] = useState([]);
 const { geoFences } = useSnapshot(geoFence);
 const { profile } = useProfile();
 const { permissions } = usePermission();
 const zoomRef = useRef(2);
 const initialRequestPerformed = useRef(false);
 useSnapshot(treeFilter);

 const { filteredTrees, enterpriseTreeCount, pin, selectedTrees } = treeFilter;

 const filteredTreesBoolean =
  filteredTrees === 'public'
   ? false
   : filteredTrees === 'private'
   ? true
   : null;

 const enterpriseTotalTrees =
  enterpriseTreeCount?.privateTreesCount + enterpriseTreeCount?.treesCount;

 const userId = profile?._id || embededMapParams.userId;
 const radius = 2;

 if (!isEmbededMap) {
  var boughtTrees = profile?.enterpriseData
   ? profile.enterpriseData.boughtTrees
   : profile.boughtTrees;
 }

 const getCenter = () => {
  setCenter(mapRef?.current?.getCenter());
 };

 const fetchInitialData = () => {
  if (mapRef.current && !initialRequestPerformed.current) {
   getClusters();
   getGeofence();
   initialRequestPerformed.current = true;
   getCenter();
  }
 };

 const handleKeyDown = (event) => {
  if (event.key === 'Control' || event.key === 'Meta') {
   setIsCtrlPressed(true);
  }
 };

 const handleKeyUp = (event) => {
  if (event.key === 'Control' || event.key === 'Meta') {
   setIsCtrlPressed(false);
  }
 };

 const getTreeIdFromClusters = (array, pinArray) => {
  const filteredItem = clusters.filter((item) => {
   return (
    item.properties.coordinates[0] === array[0] &&
    item.properties.coordinates[1] === array[1]
   );
  });

  if (
   selectedTrees.some((item) =>
    selectedTrees.some((item) => item.treeId === filteredItem[0]._id)
   )
  ) {
   treeFilter.selectedTrees = selectedTrees.filter(
    (i) => i.treeId !== filteredItem[0]._id
   );
  } else {
   treeFilter.selectedTrees = [
    ...selectedTrees,
    { pinArray, treeId: filteredItem[0]._id },
   ];
  }
 };

 const onClick = useCallback(
  (event) => {
   const {
    features,
    point: { x, y },
   } = event;
   const hoveredFeature = features && features[0];

   if (isCtrlPressed && filteredTrees) {
    if (hoveredFeature?.layer?.id === 'unclustered-point') {
     getTreeIdFromClusters(
      JSON.parse(hoveredFeature.properties.coordinates),
      hoveredFeature.geometry.coordinates
     );
    }
   } else {
    if (hoveredFeature?.layer?.id === 'unclustered-point') {
     setTreeHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
     treeFilter.pin = hoveredFeature.geometry.coordinates;
     if (geofenceHoverInfo) setGeofenceHoverInfo(null);
    }
    if (hoveredFeature?.layer?.id === 'data') {
     setGeofenceHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
     if (pin.length === 2) treeFilter.pin = [];
     if (treeHoverInfo) setTreeHoverInfo(null);
    }
    if (hoveredFeature === undefined && geofenceHoverInfo) {
     setGeofenceHoverInfo();
    }
    if (hoveredFeature === undefined && treeHoverInfo) {
     setTreeHoverInfo();
    }
    if (hoveredFeature === undefined && pin.length === 2) {
     treeFilter.pin = [];
    }
   }
  },
  [
   geoFences,
   geofenceHoverInfo,
   treeHoverInfo,
   pin,
   selectedTrees,
   isCtrlPressed,
  ]
 );

 const getClusters = async () => {
  const map = mapRef?.current?.getMap();
  const bounds = map.getBounds().toArray();
  const baseUrl = API_MAP;

  const queries = `?zoom=${Math.round(zoomRef.current)}&westLng=${
   bounds[1][0]
  }&southLat=${bounds[0][1]}&eastLng=${bounds[0][0]}&northLat=${
   bounds[1][1]
  }&userId=${embededMapParams?.userId || Defaults.userId}&private=${
   isEmbededMap ? false : filteredTreesBoolean
  }`;

  axios.get(`${baseUrl}${queries}`).then((res) => {
   const { clusters } = res.data;
   clusters.forEach((c) => {
    if (c.geometry.type === 'Point') {
     if (!c.properties) c.properties = {};
     c.properties.color = getColorForTree(c);
    }
   });
   setClusters(clusters);
  });
 };

 const getFilteredTrees = async () => {
  const organizationIds = [];
  const names = [];
  const genuses = [];
  const species = [];
  const userIds = [];

  for (const item of filterValues) {
   if (item.company !== undefined) {
    organizationIds.push(item.company);
   } else if (item.name !== undefined) {
    names.push(item.name);
   } else if (item.genus !== undefined) {
    genuses.push(item.genus);
   } else if (item.species !== undefined) {
    species.push(item.species);
   } else if (item.userId !== undefined) {
    userIds.push(item.userId);
   }
  }

  const res = await filterSearchedTrees(
   organizationIds,
   names,
   genuses,
   userIds,
   species,
   userId,
   center.lng,
   center.lat,
   radius
  );
  if (res.message === 'Success') {
   const { trees } = res;
   trees.forEach((c) => {
    if (c.geometry.type === 'Point') {
     if (!c.properties) c.properties = {};
     c.properties.color = getColorForTree(c);
    }
   });
   setClusters(trees);
  }
 };

 const debouncedChangeHandler = useCallback(
  debounce(() => getClusters(), 200),
  [filteredTreesBoolean, filterValues]
 );

 const toggleMap = () => {
  if (styleURL === mapStyle.SatelliteStreet) {
   setStyleURL(mapStyle.Satellite);
  } else {
   setStyleURL(mapStyle.SatelliteStreet);
  }
  setStreetsVisible((prev) => !prev);
 };

 const onMapActionPerformed = () => {
  if (filterValues.length === 0) {
   debouncedChangeHandler();
  }

  if (pin) {
   treeFilter.pin = [];
  }
 };

 const getCurrentLocation = () => {
  GeoLocateRef.current.trigger();
 };

 const toggleGeofence = () => {
  setGeofenceVisible((prev) => !prev);
 };

 const onMove = () => {
  if (geofenceHoverInfo) {
   setGeofenceHoverInfo();
  }
  if (treeHoverInfo) {
   setTreeHoverInfo();
  }
 };

 const getColorForTree = (tree) => {
  if (isEmbededMap) {
   return '#559158';
  } else {
   if (tree?.custom === true)
    if (tree?.private === true) {
     return '#717993';
    } else if (
     tree?.organizationId === profile?.enterprise ||
     tree?.userId === profile?._id
    ) {
     return '#559158';
    }
  }
  return '#ed7d32';
 };

 const zoomOut = () => {
  mapRef.current.zoomOut();
 };

 const zoomIn = () => {
  mapRef.current.zoomIn();
 };

 const addTree = () => {
  Defaults.Modal.show(
   <ModalWrapper fullScreenModal customStyles={WebStyles.infoModalWrapperStyle}>
    <AddTreeWeb getClusters={getClusters} centerCoordinates={center} />
   </ModalWrapper>
  );
 };

 const addGeoFence = () => {
  Defaults.Modal.show(
   <ModalWrapper fullScreenModal customStyles={WebStyles.infoModalWrapperStyle}>
    <AddGeoFence centerCoordinates={center} />
   </ModalWrapper>
  );
 };

 const onMoveEnd = () => {
  onMapActionPerformed();
  getCenter();
 };

 const generateEmbedLink = () => {
  setShowFeatures(false);

  let url = `${location.origin}/embedmap?zoom=${zoomRef.current}&lat=${center.lat}&lng=${center.lng}&userId=${profile?._id}`;
  let embedLink = `<iframe width='500' height='500' src='${url}' allow="geolocation *"></iframe>`;

  Defaults.Modal.show(
   <ModalWrapper customStyles={WebStyles.embedLinkModal}>
    <GenerateMapEmbedLink inputValue={embedLink} />
   </ModalWrapper>
  );
 };

 useEffect(() => {
  const arr = [];
  searchedTreeValues.map((item) => {
   clusterCountLayer;
   if (item?.properties?.common) {
    arr.push({ name: item.properties.common });
   } else if (item?.properties?.genus) {
    arr.push({ genus: item.properties.genus });
   } else if (item?.enterpriseName) {
    arr.push({ company: item._id });
   } else if (item?.email) {
    arr.push({ userId: item._id });
   } else if (item?.properties?.species) {
    arr.push({ species: item?.properties?.species });
   }
  });
  setFilterValues(arr);
 }, [searchedTreeValues.length]);

 useEffect(() => {
  fetchInitialData();
 }, []);

 useEffect(() => {
  if (
   (filteredTreesBoolean === true ||
    filteredTreesBoolean === false ||
    filteredTreesBoolean === null) &&
   initialRequestPerformed.current === true
  ) {
   getClusters();
  }
 }, [filteredTreesBoolean]);

 useEffect(() => {
  window.addEventListener('keydown', handleKeyDown);
  window.addEventListener('keyup', handleKeyUp);

  return () => {
   window.removeEventListener('keydown', handleKeyDown);
   window.removeEventListener('keyup', handleKeyUp);
  };
 }, [handleKeyDown, handleKeyUp]);

 useEffect(() => {
  setSelectedTreeIds(selectedTrees.map((item) => item.treeId));
 }, [selectedTrees]);

 useEffect(() => {
  if (filterValues.length > 0) {
   getFilteredTrees();
  } else {
   debouncedChangeHandler();
  }
 }, [filterValues.length, filteredTreesBoolean]);

 return (
  <>
   <Map
    ref={(ref) => {
     mapRef.current = ref;
     fetchInitialData();
    }}
    initialViewState={{
     longitude: embededMapParams?.lng || -80,
     latitude: embededMapParams?.lat || 37.8,
     zoom: embededMapParams?.zoom - 1 || zoomRef.current,
    }}
    dragRotate={false}
    keyboard={false}
    touchZoomRotate={false}
    boxZoom={false}
    doubleClickZoom={false}
    attributionControl={false}
    onMoveEnd={onMoveEnd}
    onZoomEnd={({ viewState: { zoom } }) => {
     onMapActionPerformed();
     zoomRef.current = zoom;
    }}
    style={{
     flex: 1,
     borderRadius: 12,
    }}
    mapboxAccessToken={MAP_ACCESSTOKEN}
    renderWorldCopies={false}
    mapStyle={styleURL}
    interactiveLayerIds={['data', 'unclustered-point']}
    onClick={onClick}
    onLoad={() => {
     if (isEmbededMap) {
      zoomIn();
     } else {
      getCurrentLocation();
     }
    }}
    onMove={onMove}>
    <View
     style={{
      top: 24,
      left: 24,
      width: '21%',
      position: 'absolute',
      zIndex: 10,
     }}>
     {!isEmbededMap && (
      <WebSearch
       geofenceHoverInfo={geofenceHoverInfo}
       setGeofenceHoverInfo={setGeofenceHoverInfo}
       setTreeHoverInfo={setTreeHoverInfo}
       centerCoordinates={center}
       setSearchedTreeValues={setSearchedTreeValues}
       searchedTreeValues={searchedTreeValues}
       getClusters={getClusters}
      />
     )}
    </View>

    <GeolocateControl
     showUserHeading
     trackUserLocation
     showAccuracyCircle={false}
     ref={GeoLocateRef}
     style={{ display: 'none' }}
     onError={(err) => console.log('errrr....', err)}
    />

    {!isEmbededMap &&
    (permissions === 'Admin' || permissions === 'Editor') &&
    enterpriseTotalTrees < boughtTrees ? (
     <AddTreeButton onPress={addTree} />
    ) : null}

    <MapZoomButton zoomIn={zoomIn} zoomOut={zoomOut} />

    <MapButton
     iconName='location'
     customStyles={{ top: 24, left: !isEmbededMap ? 411 : 24 }}
     onPress={getCurrentLocation}
    />

    <MapButton
     iconName='more'
     customStyles={{ top: 24, left: !isEmbededMap ? 467 : 79 }}
     onPress={() => setShowFeatures((prev) => !prev)}
    />

    {selectedTrees.length > 0 && (
     <SelectTrees
      privateOrPublic={filteredTrees}
      selectedTreeArray={selectedTreeIds}
      getClusters={getClusters}
     />
    )}
    {showFeatures && (
     <MapFeatures
      profile={profile}
      permissions={permissions}
      isEmbededMap={isEmbededMap}
      streetsValue={streetsVisible}
      geofenceValue={geofenceVisible}
      toggleMap={toggleMap}
      addGeoFence={addGeoFence}
      toggleGeofence={toggleGeofence}
      setShowFeatures={setShowFeatures}
      handleEmbedLink={generateEmbedLink}
     />
    )}

    {pin.length === 2 && (
     <Marker longitude={pin[0]} latitude={pin[1]}>
      <Image style={styles.pinImg} source={{ uri: IMAGES.treepoint }} />
     </Marker>
    )}
    {selectedTrees.length > 0 &&
     selectedTrees.map((item, index) => {
      return (
       <Marker
        key={index}
        longitude={item.pinArray[0]}
        latitude={item.pinArray[1]}>
        <Image
         style={styles.pinImg}
         source={{
          uri: IMAGES.treepoint,
         }}
        />
       </Marker>
      );
     })}

    {geofenceVisible && (
     <Source type='geojson' data={geoFences}>
      <Layer {...geofenceLayerLine} />
      <Layer {...geofenceLayer} />
     </Source>
    )}

    <Source
     id='trees'
     type='geojson'
     data={{
      type: 'FeatureCollection',
      crs: {
       type: 'name',
       properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
      },

      features: clusters,
     }}
     clusterMaxZoom={14}
     clusterRadius={50}>
     <Layer {...clusterLayer} />
     <Layer {...clusterCountLayer} />
     <Layer {...unclusteredPointLayer} />
    </Source>

    {geofenceHoverInfo && (
     <GeofenceInfo
      data={geofenceHoverInfo}
      setHoverInfo={setGeofenceHoverInfo}
     />
    )}

    {treeHoverInfo && (
     <View style={{ zIndex: 1 }}>
      <WebTreeInfoMini
       isEmbededMap={isEmbededMap}
       embededMapParams={embededMapParams}
       data={treeHoverInfo}
       setHoverInfo={setTreeHoverInfo}
       getClusters={getClusters}
      />
     </View>
    )}
   </Map>
  </>
 );
};

const styles = StyleSheet.create({
 pinImg: { width: 60, height: 60, marginBottom: 50, marginRight: 30 },
});

WebMap.defaultProps = {
 isEmbededMap: false,
};

export default WebMap;
