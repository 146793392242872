import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSnapshot } from 'valtio';

import PrivacyAndTerms from './PrivacyAndTerms';

import authStore from 'store/authStore';

import DeleteAccount from 'components/modals/DeleteAccount';
import CustomButton from 'components/reusable/CustomButton';
import Icon from 'components/reusable/Icon';

import Avatar from 'reusable/Avatar';
import Button from 'reusable/Button';
import SquircleContainer from 'reusable/SquircleContainer';
import Text from 'reusable/Text';

import { Defaults } from 'utils/defaults';
import { storageDel } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';
import { colors, lineHeight } from 'utils/theme';

const SettingsScreen = ({ navigation }) => {
 const { profile } = useSnapshot(authStore);

 const logOut = () => {
  storageDel('AccessToken');
  storageDel('RefreshToken');
  storageDel('userId');
  authStore.profile = null;
  authStore.isLoggedIn = false;
  authStore.permissions = null;
  navigation.push('Home');
 };

 const deleteAccount = () => {
  Defaults.BottomSheetModal.show(<DeleteAccount navigation={navigation} />);
 };

 return (
  <>
   <SquircleContainer
    cornerRadius={20}
    style={styles.formContainer}
    bgColor={getColor('NEUTRAL200')}>
    <Avatar mode='view' image={profile?.avatar} />

    <Text
     fontType='MontserratSemiBold'
     fontSize={lineHeight.subTitle2}
     color='NEUTRAL000'
     style={{ marginTop: 16, marginBottom: 8 }}>
     {profile?.email}
    </Text>

    {profile?.enterpriseData && (
     <>
      <Text
       fontType='MontserratSemiBold'
       fontSize={lineHeight.subTitle3}
       color='NEUTRAL600'
       style={{ marginTop: 16, textAlign: 'center' }}>
       This account is connected to:
      </Text>
      <Text color='NEUTRAL900' fontType='MontserratBold'>
       {profile?.enterpriseData?.enterpriseName}
      </Text>
     </>
    )}

    {profile && (
     <Button
      title='Edit profile'
      bgColor={getColor('NORMAL_GREEN')}
      customStyles={{ width: 144, marginTop: 10 }}
      onPress={() => navigation.navigate('Settings', { screen: 'EditProfile' })}
     />
    )}
   </SquircleContainer>

   <PrivacyAndTerms />

   <View style={{ marginTop: 16 }}>
    <CustomButton onPress={logOut}>
     <SquircleContainer style={styles.iconContainer} bgColor='#fff'>
      <Icon
       name='logOut'
       style={{ color: getColor('NORMAL_ORANGE'), fontSize: 25 }}
      />
     </SquircleContainer>

     <Text
      fontType='MontserratSemiBold'
      fontSize={14}
      color='NEUTRAL800'
      style={[styles.textStyle, { color: colors.error }]}>
      Log out
     </Text>
    </CustomButton>

    <CustomButton onPress={deleteAccount} customStyles={{ marginTop: 16 }}>
     <SquircleContainer style={styles.iconContainer} bgColor='#fff'>
      <Icon
       name='trash'
       style={{
        ...lineHeight.h2,
        fontSize: 25,
        color: getColor('NORMAL_ORANGE'),
       }}
      />
     </SquircleContainer>

     <Text
      fontType='MontserratSemiBold'
      fontSize={14}
      color='NEUTRAL800'
      style={[styles.textStyle, { color: colors.error }]}>
      Delete account
     </Text>
    </CustomButton>
   </View>
  </>
 );
};

export default SettingsScreen;

const styles = StyleSheet.create({
 formContainer: {
  marginTop: 30,
  marginBottom: 16,
  padding: 24,
  justifyContent: 'center',
  alignItems: 'center',
 },
 iconContainer: {
  width: 44,
  height: 44,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 12,
  marginRight: 12,
 },
 textStyle: { marginRight: 'auto' },
 arrowRight: {
  color: '#454A5B',
  width: 30,
  transform: [{ rotate: '180deg' }],
 },
});
