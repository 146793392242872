import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import PrivacyAndTerms from './PrivacyAndTerms';

import Button from 'reusable/Button';
import Text from 'reusable/Text';

import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const SettingsScreen = ({ navigation }) => {
 const onNavigateSignup = () => {
  navigation.navigate('Settings', { screen: 'Signup' });
 };

 const onNavigateSignin = () => {
  navigation.navigate('Settings', { screen: 'Signin' });
 };

 return (
  <>
   <View style={styles.containerStyle}>
    <Image source={IMAGES.sun} style={styles.imageSize} />

    <Text
     fontType='MontserratBold'
     fontSize={lineHeight.h2}
     color='NEUTRAL000'
     style={{ marginTop: 16, marginBottom: 12 }}>
     Welcome!
    </Text>

    <Text
     fontType='MontserratSemiBold'
     fontSize={lineHeight.subTitle3}
     color='NEUTRAL500'
     style={{ marginBottom: 24 }}>
     Want to know more about trees?
    </Text>

    <Button
     title='Log In'
     bgColor={getColor('NORMAL_GREEN')}
     onPress={onNavigateSignin}
    />

    <Text
     fontType='MontserratSemiBold'
     fontSize={lineHeight.body3}
     color='NEUTRAL500'
     style={{ marginTop: 16 }}>
     New here?{' '}
     <Text
      onPress={onNavigateSignup}
      fontType='MontserratBold'
      fontSize={lineHeight.body3}
      color='error'>
      Create Account
     </Text>
    </Text>
   </View>

   <PrivacyAndTerms />
  </>
 );
};

export default SettingsScreen;

const styles = StyleSheet.create({
 containerStyle: {
  alignItems: 'center',
  marginTop: 20,
  marginBottom: 48,
 },
 imageSize: {
  width: 144,
  height: 144,
 },
});
