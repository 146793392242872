import React from 'react';
import { Linking, StyleSheet, View } from 'react-native';

import CustomButton from 'components/reusable/CustomButton';
import Icon from 'components/reusable/Icon';

import SquircleContainer from 'reusable/SquircleContainer';
import Text from 'reusable/Text';

import { getColor } from 'utils/settings';
import { colors } from 'utils/theme';

const PrivacyAndTerms = () => {
 const onNavigateTermsAndCondition = async () => {
  await Linking.openURL(
   'https://app.websitepolicies.com/policies/view/q43ixxbe'
  );
 };

 const onNavigatePrivacyPolicy = async () => {
  await Linking.openURL(
   'https://app.websitepolicies.com/policies/view/e4267gf9'
  );
 };

 return (
  <SquircleContainer bgColor={colors.NEUTRAL200}>
   <CustomButton onPress={onNavigateTermsAndCondition}>
    <SquircleContainer style={styles.iconContainer} bgColor='#fff'>
     <Icon
      name='document'
      style={{ color: getColor('NORMAL_ORANGE'), fontSize: 27 }}
     />
    </SquircleContainer>

    <View style={styles.viewStyle}>
     <Text
      fontType='MontserratSemiBold'
      fontSize={14}
      color='NEUTRAL800'
      style={styles.textStyle}>
      Terms & conditions
     </Text>

     <Icon name='arrowLeft' style={styles.arrowRight} />
     <View style={styles.iconSibling} />
    </View>
   </CustomButton>

   <CustomButton onPress={onNavigatePrivacyPolicy}>
    <SquircleContainer style={styles.iconContainer} bgColor='#fff'>
     <Icon
      name='lock'
      style={{ color: getColor('NORMAL_ORANGE'), fontSize: 27 }}
     />
    </SquircleContainer>

    <Text
     fontType='MontserratSemiBold'
     fontSize={14}
     color='NEUTRAL800'
     style={styles.textStyle}>
     Privacy policy
    </Text>

    <Icon name='arrowLeft' style={styles.arrowRight} />
   </CustomButton>
  </SquircleContainer>
 );
};

export default PrivacyAndTerms;

const styles = StyleSheet.create({
 iconContainer: {
  width: 44,
  height: 44,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 12,
  marginRight: 12,
  backgroundColor: '#fff',
 },
 textStyle: { marginRight: 'auto' },
 arrowRight: {
  color: '#454A5B',
  width: 30,
  transform: [{ rotate: '180deg' }],
 },
 viewStyle: {
  flexGrow: 1,
  height: 50,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
 },
 iconSibling: {
  height: 1,
  width: '100%',
  position: 'absolute',
  bottom: -13,
  borderRadius: 10,
  right: 0,
  backgroundColor: '#DFE2E8',
  zIndex: 5,
  marginLeft: 'auto',
 },
});
