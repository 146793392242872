import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import Button from 'components/reusable/Button';
import ScreenContainer from 'components/reusable/ScreenContainer';
import Text from 'components/reusable/Text';

import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const SuccessScreen = ({ navigation }) => {
 return (
  <ScreenContainer>
   <View style={styles.container}>
    <Image style={styles.imageStyle} source={IMAGES.selebration} />

    <Text
     fontType='MontserratBold'
     fontSize={{ fontSize: 25, lineHeight: 36, textAlign: 'center' }}>
     New password confirmed successfully!
    </Text>

    <Text
     fontType='MontserratRegular'
     fontSize={lineHeight.subTitle3}
     color='NEUTRAL500'
     style={styles.desc}>
     You have successfully confirmed your password. Please use your new password
     when logging in.
    </Text>

    <Button
     title='Continue'
     customStyles={{ marginTop: 40 }}
     bgColor={getColor('NORMAL_GREEN')}
     onPress={() => navigation.navigate('Signin')}
    />
   </View>
  </ScreenContainer>
 );
};

export default SuccessScreen;

const styles = StyleSheet.create({
 imageStyle: {
  width: 124,
  height: 124,
  marginBottom: 40,
 },
 container: {
  alignItems: 'center',
 },
 desc: {
  textAlign: 'center',
  lineHeight: 22,
  marginBottom: 24,
  marginTop: 25,
 },
});
