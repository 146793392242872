import React, { useEffect, useState } from 'react';
import { Alert, Image, StyleSheet, View } from 'react-native';

import authStore from 'store/authStore';

import { addTree } from 'services';
import { deleteTree, editTreeInfo } from 'services/treeServices';
import { uploadImage } from 'services/userService';

import Button from 'components/reusable/Button';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import Text from 'components/reusable/Text';

import { IMAGES } from 'utils/images';
import { storageDel, storageGetString, storageSet } from 'utils/mmkvStorage';
import { isAndroid } from 'utils/platform';
import { getColor } from 'utils/settings';

const OfflineModal = ({ navigation, route }) => {
 const OfflineDeletTrees = storageGetString('OfflineDeletTrees');
 const OfflineAddedTrees = storageGetString('OfflineAddedTrees');
 const OfflineEditedTrees = storageGetString('OfflineEditedTrees');
 const clustersArr = storageGetString('clustersData');

 const [uploadedTreeCount, setUploadedTreeCount] = useState(0);

 const updatedTrees = [];

 const uploadOfflineTree = async (data, i) => {
  const currentData = data.imgUrls;
  for (let k = 0; k < currentData.length; k++) {
   let localUri = data.imgUrls[k].uri;

   let filename = localUri.split('/').pop();

   // Infer the type of the image
   let match = /\.(\w+)$/.exec(filename);
   let type = match ? `image/${match[1]}` : `image`;

   const uploadedAvatarRes = await uploadImage(
    { uri: localUri, name: filename, type },
    'users',
    true
   );
   const currentId = currentData[k].id;

   data.imgUrls.splice(k, 1);
   data.imgUrls.splice(k, 0, {
    id: currentId,
    uri: uploadedAvatarRes.imageUrl,
   });
  }

  updatedTrees.push(data);

  if (updatedTrees.length == JSON.parse(OfflineAddedTrees).length) {
   updatedTrees.map((w) => {
    console.log('wwww...', w);
    uploadTree(w);
   });
  }
 };

 const deleteOfflineTree = async (data) => {
  const response = await deleteTree(data._id);

  if (response.type === 'success') {
   const parsed = JSON.parse(clustersArr);

   const newArr = parsed.filter((w) => w._id !== data._id);

   if (newArr.length === 0) {
    storageDel('clustersData');
   } else {
    storageSet('clustersData', JSON.stringify(newArr));
   }

   setUploadedTreeCount(uploadedTreeCount + 1);
  } else {
   Alert.alert('', JSON.stringify(response.message));
  }
 };

 const editTreeData = async (obj) => {
  const response = await editTreeInfo(obj);

  if (response.type === 'success') {
   setUploadedTreeCount(uploadedTreeCount + 1);
  } else {
   Alert.alert('', response.message);
  }
 };

 const uploadTree = async (data) => {
  delete data.properties.offlineTreeId;

  let obj = {
   lng: data.geometry.coordinates[0],
   lat: data.geometry.coordinates[1],
   userId: data.userId,
   organizationId: data.organizationId,
   imgUrls: data.imgUrls,
   private: data.private,
   properties: data.properties,
  };

  const response = await addTree(obj);

  if (response.type === 'success') {
   setUploadedTreeCount(uploadedTreeCount + 1);

   const filtered = authStore.trees.map((w) => {
    if (data.offlineTreeId === w.properties.offlineTreeId) {
     return response.tree;
    }

    return w;
   });

   authStore.trees = filtered;
  } else {
   Alert.alert('', JSON.stringify(response.message));
  }
 };

 useEffect(() => {
  if (route.params.type === 'ADD_TREE') {
   for (let index = 0; index < JSON.parse(OfflineAddedTrees).length; index++) {
    uploadOfflineTree(JSON.parse(OfflineAddedTrees)[index], index);
   }
  }

  if (route.params.type === 'DELETE_TREE') {
   for (let index = 0; index < JSON.parse(OfflineDeletTrees).length; index++) {
    deleteOfflineTree(JSON.parse(OfflineDeletTrees)[index]);
   }
  }

  if (route.params.type === 'EDIT_TREE') {
   for (let index = 0; index < JSON.parse(OfflineEditedTrees).length; index++) {
    editTreeData(JSON.parse(OfflineEditedTrees)[index]);
   }
  }
 }, []);

 useEffect(() => {
  if (route.params.type === 'ADD_TREE') {
   storageDel('OfflineAddedTrees');
  }

  if (route.params.type === 'DELETE_TREE') {
   storageDel('OfflineDeletTrees');
  }

  if (route.params.type === 'EDIT_TREE') {
   storageDel('OfflineEditedTrees');
  }
 }, [uploadedTreeCount]);

 const closeModal = () => {
  navigation.goBack();
 };

 return (
  <>
   <FocusAwareStatusBar
    barStyle={isAndroid ? 'dark-content' : 'light-content'}
   />
   <View
    style={{ paddingHorizontal: 24, alignItems: 'center', marginTop: '20%' }}>
    <Image style={{ width: 124, height: 124 }} source={IMAGES.outboxTray} />
    <Text
     fontSize={14}
     fontType='MontserratSemiBold'
     color='NEUTRAL500'
     style={{ textAlign: 'center', marginTop: 34, marginBottom: 48 }}>
     {`${uploadedTreeCount} out of ${route.params.count} trees are uploaded in your list. Do you want to continue?`}
    </Text>

    {route.params.count === uploadedTreeCount && (
     <Button
      title='Close'
      bgColor={getColor('NORMAL_GREEN')}
      onPress={closeModal}
     />
    )}
   </View>
  </>
 );
};

export default OfflineModal;

const styles = StyleSheet.create({});
