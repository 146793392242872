import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import React, { useState } from 'react';
import {
 Linking,
 ScrollView,
 Text,
 TouchableOpacity,
 View,
 StyleSheet,
} from 'react-native';

import AddGeoFence from './AddGeoFence';
import Alert from './Alert';
import RemoveModal from './RemoveModal';
import UserImageContainer from './UserImageContainer';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { deleteGeofence } from 'services/mapServices';

import ModalWrapper from 'components/modals/ModalWrapper';
import Icon from 'components/reusable/Icon';

import { getGeofence } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';
import { InfoPolygon, WebClose, WebTrash } from 'utils/svgs';

const GeofenceInfo = ({ data, setHoverInfo }) => {
 const [show, setShow] = useState(false);
 const { profile } = useProfile();
 const { permissions } = usePermission();
 const properties = data.feature.properties;
 const geofenceId = properties.id;
 const geofenceEnterpiseId = data?.feature?.properties?.enterpriseId;
 const enterpriseId = profile?.enterprise;
 const isCompanysGeofence = geofenceEnterpiseId === enterpriseId;

 const avatar = data?.feature?.properties?.avatar
  ? JSON.parse(data?.feature?.properties?.avatar)[0]
  : null;

 const removeGeofence = async () => {
  const res = await deleteGeofence(geofenceId);
  if (res.status === 'success') {
   getGeofence();
   setHoverInfo(null);
   Defaults.Modal.hide(<ModalWrapper />);
   setTimeout(() => {
    Defaults.Modal.show(<Alert alert='Geofence deleted successfully' />);
   }, 500);
  }
 };

 const editGeoFence = () => {
  Defaults.Modal.show(
   <ModalWrapper
    add
    fullScreenModal
    customStyles={WebStyles.infoModalWrapperStyle}>
    <AddGeoFence editMode data={data} fullScreenModal />
   </ModalWrapper>
  );
 };

 const removeGeofenceModal = async () => {
  Defaults.Modal.show(
   <ModalWrapper customStyles={WebStyles.removeTreeWrapper}>
    <RemoveModal remove={removeGeofence} />
   </ModalWrapper>
  );
 };

 return (
  <View
   style={{
    flexDirection: 'column',
    left: data.x - 225,
    top: data.y + 25,
    width: 452,
   }}>
   <View style={[styles.cardCont, show ? { maxHeight: 284 } : { height: 145 }]}>
    <TouchableOpacity
     style={styles.buttonStyle}
     onPress={() => setHoverInfo(null)}>
     <WebClose />
    </TouchableOpacity>
    <UserImageContainer image={avatar} otherStyles={styles.imgSize} />
    <View style={{ marginLeft: 12, marginTop: 8, flex: 1 }}>
     <View style={properties?.website ? { height: 50 } : { height: 25 }}>
      <Text style={WebStyles.userNameText}>{properties.geofenceName}</Text>
      {properties?.website ? (
       <TouchableOpacity
        style={{ marginTop: 8 }}
        onPress={() => {
         Linking.openURL(properties.website);
        }}>
        <Text style={WebStyles.url}>
         {properties?.website?.substring(0, 30) + '...'}
        </Text>
       </TouchableOpacity>
      ) : null}
     </View>
     <ScrollView style={{ flex: 1 }}>
      <Text numberOfLines={show ? 100 : 3} style={WebStyles.geofenceInfoText}>
       {properties?.info}
      </Text>
     </ScrollView>

     {properties?.info.length > 180 ? (
      <TouchableOpacity
       onPress={() => setShow((prev) => !prev)}
       style={{ alignSelf: 'flex-end' }}>
       <Text style={[WebStyles.geofenceInfoText, { alignSelf: 'flex-end' }]}>
        {show ? 'Show less' : 'Show more'}
       </Text>
      </TouchableOpacity>
     ) : null}
    </View>
   </View>
   {isCompanysGeofence && permissions === 'Admin' ? (
    <>
     <View style={styles.buttonsContainer}>
      <TouchableOpacity onPress={editGeoFence}>
       <Icon
        name='edit'
        style={{ fontSize: 24, color: getColor('NEUTRAL500') }}
       />
      </TouchableOpacity>
      <TouchableOpacity onPress={removeGeofenceModal}>
       <WebTrash color={getColor('NEUTRAL500')} />
      </TouchableOpacity>
     </View>
     <View style={{ alignSelf: 'center' }}>
      <InfoPolygon />
     </View>
    </>
   ) : null}
  </View>
 );
};

const styles = StyleSheet.create({
 buttonsContainer: {
  width: 100,
  height: 52,
  backgroundColor: getColor('WHITE'),
  marginTop: 8,
  borderRadius: 12,
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  flexDirection: 'row',
 },
 imgSize: { width: 116, height: 126 },
 buttonStyle: { position: 'absolute', right: 10, top: 10, zIndex: 1 },
 cardCont: {
  position: 'relative',
  backgroundColor: getColor('WHITE'),
  borderRadius: 12,
  width: 452,
  padding: 8,
  flexDirection: 'row',
 },
});

export default GeofenceInfo;
