import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSnapshot } from 'valtio';

import WebCompanySignup from './WebSignUpScreen/WebCompanySignup';
import { WebStyles } from './WebStyles';

import treeFilter from 'store/treeFilter';

import ModalWrapper from 'components/modals/ModalWrapper';
import PrivacyPolicy from 'components/webComps/webcommon/PrivacyPolicy';
import TermsAndConditions from 'components/webComps/webcommon/TermsAndConditions';
import TremapLogo from 'components/webComps/webcommon/TremapLogo';
import UserContainer from 'components/webComps/webcommon/UserContainer';
import WebNavButton from 'components/webComps/webcommon/WebNavButton';

import { getEnterpriseTree, getTrees } from 'utils/calls';
import { Defaults } from 'utils/defaults';

const NavContainer = ({ state, navigation, descriptors }) => {
 const { profile } = useProfile();
 const { permissions } = usePermission();
 const { selectedTrees } = useSnapshot(treeFilter);
 const treeCount = useSnapshot(treeFilter);
 const usersInEnterprise = profile?.enterpriseData?.usersInEnterprise?.length;
 const hasEnterprise = !!profile?.enterpriseData;
 const enterpriseId = profile?.enterprise;
 const userId = profile._id;

 useEffect(() => {
  getTrees();
  if (enterpriseId || userId) {
   getEnterpriseTree(enterpriseId, userId);
  }
 }, [profile]);

 const companyRegistration = () => {
  Defaults.Modal.show(
   <ModalWrapper
    companyReg
    customStyles={WebStyles.companyRegModalWrapperStyle}>
    <WebCompanySignup />
   </ModalWrapper>
  );
 };

 return (
  <>
   <View style={styles.container}>
    <View style={styles.navContainer}>
     <View style={{ height: 40 }}>
      {profile?.enterpriseData ? <UserContainer company /> : null}
      {!profile?.enterpriseData ? (
       <WebNavButton
        organisationButton
        title='Create an organisation account'
        iconName='profileAdd'
        color='#E6EFE6'
        textColor='#4D834F'
        companyRegistration={companyRegistration}
       />
      ) : null}
     </View>
     <View style={{ marginTop: 16 }}>
      {state.routes.map((route, index) => {
       const { options } = descriptors[route.key];
       const isFocused = state.index === index;

       const onPress = () => {
        const event = navigation.emit({
         type: 'tabPress',
         target: route.key,
        });

        if (route.name === 'MapScreen' && selectedTrees.length > 0) {
         treeFilter.selectedTrees = [];
        }

        if (!isFocused && !event.defaultPrevented) {
         navigation.navigate(route.name);
        }
       };

       if (hasEnterprise === false && route.name === 'Users') {
       } else {
        return (
         <WebNavButton
          key={index}
          usersInEnterprise={usersInEnterprise}
          routeName={route.name}
          index={index}
          role='button'
          accessibilityState={isFocused ? { selected: true } : {}}
          accessibilityLabel={options.tabBarAccessibilityLabel}
          testID={options.tabBarTestID}
          onPress={onPress}
          isFocused={isFocused}
          treeCount={treeCount.treeCount.toLocaleString('en-US')}
          title={
           route.name === 'MapScreen'
            ? 'Map'
            : route.name === 'Collection'
            ? 'Collection Management'
            : route.name
          }
          iconName={
           route.name === 'MapScreen'
            ? 'map'
            : route.name === 'Collection'
            ? 'category'
            : route.name === 'Users'
            ? 'people'
            : route.name === 'Billing'
            ? 'walletmoney'
            : ''
          }
          expandedOptions={
           index === 0
            ? [
               {
                title: profile?.enterpriseData ? 'Organisation' : 'User',
                trees: `Public trees:  ${
                 treeCount.enterpriseTreeCount?.treesCount
                  ? treeCount.enterpriseTreeCount?.treesCount
                  : '0'
                }`,
                color: '#559158',
               },

               {
                title: profile?.enterpriseData ? 'Organisation' : 'User',
                trees: `Private trees:  ${
                 treeCount.enterpriseTreeCount?.privateTreesCount
                  ? treeCount.enterpriseTreeCount?.privateTreesCount
                  : '0'
                }`,
                color: '#717993',
               },
              ]
            : null
          }
         />
        );
       }
      })}
     </View>
     <View style={styles.navBottomContainer}>
      {profile && <UserContainer profile={profile} permissions={permissions} />}
     </View>
    </View>
   </View>
   <TremapLogo />
   <PrivacyPolicy />
   <TermsAndConditions />
  </>
 );
};

const styles = StyleSheet.create({
 container: {
  position: 'absolute',
  width: 355,
  height: '100%',
  alignItems: 'flex-end',
  backgroundColor: 'white',
 },
 navContainer: {
  flex: 1,
  width: 282,
  marginTop: 84,
  marginBottom: 63,
 },
 navBottomContainer: {
  flex: 1,
  justifyContent: 'flex-end',
 },
 childContainer: {
  flex: 1,
  paddingBottom: 63,
  paddingLeft: 72,
  paddingTop: 84,
  paddingRight: 72,
  flexDirection: 'row',
 },
});

export default NavContainer;
