import React from 'react';

const ThirdMap = () => {
 return (
  <iframe
   height={500}
   width={500}
   src='https://dev.portal.tremap.com/embedmap?zoom=17.95373412237268&lat=41.731124587956145&lng=44.742345476214865&userId=639c40515a1a521c203f5231'></iframe>
 );
};

export default ThirdMap;
