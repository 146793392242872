const clusterStyle = {
 circleStrokeColor: '#D96C31',
 circleStrokeWidth: 2,
 circleColor: [
  'step',
  ['get', 'point_count'],
  'rgba(255, 255, 255, 0.5)',
  100,
  'rgba(255, 255, 255, 0.5)',
  750,
  'rgba(255, 255, 255, 0.5)',
 ],
 circleRadius: ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
};

const singlePointStyle = {
 circleColor: ['get', 'color'],
 circleStrokeWidth: 2,
 circleStrokeColor: '#fff',
 circleRadius: ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
};

const layerStyles = {
 singlePoint: {
  circleColor: 'green',
  circleOpacity: 0.84,
  circleStrokeWidth: 2,
  circleStrokeColor: 'white',
  circleRadius: 5,
  circlePitchAlignment: 'Point',
  'fill-color': {
   property: 'color',
   stops: [[0, '#ffffbf']],
  },
 },

 clusteredPoints: {},
 clusterCount: {
  textField: '{point_count_abbreviated}',
  textSize: 12,
  textPitchAlignment: 'map',
 },
};

export { clusterStyle, singlePointStyle, layerStyles };
