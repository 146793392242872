import { StyleSheet } from 'react-native';

import { getColor } from 'utils/settings';
import { fonts, fontSize } from 'utils/theme';

export const WebStyles = StyleSheet.create({
 webButtonText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 500,
  fontSize: 12,
  color: '#FFFFFF',
  marginHorizontal: 8,
  lineHeight: 20,
 },
 pricingCardText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 500,
  fontSize: 12,
  color: getColor('NEUTRAL800'),
  marginHorizontal: 8,
  lineHeight: 24,
  letterSpacing: 0.5,
 },
 currentPlan: {
  fontFamily: fonts.MontserratBold,
  fontWeight: 700,
  fontSize: 28,
  lineHeight: 34,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL800'),
 },
 currentPlan700: {
  fontFamily: fonts.MontserratBold,
  fontWeight: 700,
  fontSize: 12,
  lineHeight: 16,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL800'),
 },
 currentPlanTitle: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  fontSize: 12,
  lineHeight: 16,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL600'),
 },
 currentPlanInfo: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 500,
  fontSize: 12,
  lineHeight: 24,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL800'),
 },
 pricePerMonth: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 500,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL500'),
 },
 webDefaultText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  color: getColor('NEUTRAL600'),
  lineHeight: 15,
  ...fontSize.body3,
 },
 WebHeadText: {
  fontSize: 28,
  letterSpacing: 0.5,
  fontWeight: 700,
  marginTop: 20,
  marginBottom: 24,
  fontFamily: fonts.MontserratBold,
  color: getColor('NEUTRAL800'),
 },
 text: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL600'),
  fontSize: 12,
  lineHeight: 24,
 },
 stepText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 700,
  color: getColor('NEUTRAL800'),
  ...fontSize.body3,
  lineHeight: 14,
 },
 dropDownText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL700'),
  ...fontSize.subTitle3,
  lineHeight: 26,
 },
 fileSize: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL600'),
  lineHeight: 12,
  ...fontSize.h6,
 },
 verificationText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL800'),
  ...fontSize.subTitle2,
 },
 webInputTextStyle: {
  ...fontSize.body2,
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  letterSpacing: 0.5,
 },
 imagePickerBotText: {
  fontSize: 10,
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  lineHeight: 16,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL600'),
 },
 usersCount: {
  fontSize: 14,
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL500'),
 },
 userNameText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 700,
  fontSize: 14,
  lineHeight: 20,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL900'),
 },
 userEmail: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 500,
  fontSize: 10,
  lineHeight: 16,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL500'),
 },

 modalWrapperStyles: {
  width: 394,
  height: 415,
  borderRadius: 12,
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
 },

 removeTreeWrapper: {
  width: 486,
  height: 358,
  borderRadius: 12,
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
 },

 userErrorModalWrapperStyles: {
  width: 471,
  height: 500,
  borderRadius: 12,
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
 },

 contactModalWrapperStyles: {
  width: 450,
  height: 350,
  borderRadius: 12,
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
 },

 enterpriseInfoWrapperStyles: {
  width: 650,
  height: 560,
  borderRadius: 12,
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
 },

 infoModalWrapperStyle: {
  borderRadius: 12,
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  paddingBottom: 20,
 },
 embedLinkModal: {
  borderRadius: 12,
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: 500,
  height: 300,
  margin: 'auto',
  paddingBottom: 20,
 },

 companyRegModalWrapperStyle: {
  height: '100%',
  width: '33%',
  borderRadius: 0,
  zIndex: 5,
  alignItems: 'center',
  justifyContent: 'center',
 },

 addtTreeText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  fontSize: 12,
  lineHeight: 22,
  letterSpacing: 0.5,
  color: getColor('NORMAL_GREEN_HOVER'),
 },
 addGeofenceTitle: {
  fontFamily: fonts.MontserratBold,
  fontSize: 16,
  lineHeight: 22,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL900'),
 },

 url: {
  fontFamily: fonts.MontserratItalic,
  fontWeight: 500,
  fontSize: 12,
  lineHeight: 16,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL700'),
 },
 geofenceInfoText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 400,
  fontSize: 10,
  lineHeight: 16,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL600'),
 },
 alertText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 500,
  fontSize: 16,
  color: '#FFFFFF',
 },
 treeGenus: {
  fontFamily: fonts.MontserratBold,
  fontWeight: 600,
  fontSize: 24,
  lineHeight: 24,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL800'),
 },
 treeSpecies: {
  fontFamily: fonts.MontserratItalic,
  fontWeight: 500,
  fontSize: 16,
  lineHeight: 22,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL700'),
 },
 editGeofence: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  fontSize: 12,
  lineHeight: 16,
  letterSpacing: 0.5,
  color: getColor('NORMAL_GREEN'),
 },
 filterText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 700,
  fontSize: 12,
  lineHeight: 24,
  letterSpacing: 0.5,
  color: '#292D32',
 },
 genusFilterText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 20,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL800'),
 },
 treeCollectionTextBold: {
  fontFamily: fonts.MontserratBold,
  fontWeight: 700,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL800'),
 },
 treeCollectionTextBold1: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL800'),
 },
 treeCollectionTextNormal: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 500,
  fontSize: 12,
  lineHeight: 16,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL600'),
 },
 additionalFeatures: {
  fontFamily: fonts.MontserratBold,
  fontWeight: 700,
  fontSize: 14,
  lineHeight: 20,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL900'),
 },
 searchFilterNames: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 20,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL900'),
 },
 treeSelectText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 20,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL800'),
 },
 permissionsTitle: {
  fontFamily: fonts.MontserratSemiBold,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: 20,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL900'),
  marginVertical: 5,
 },
 permissionsText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  fontSize: 12,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL700'),
  marginBottom: 8,
 },
 contactText: {
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  fontSize: 12,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL600'),
  marginLeft: 8,
 },
});
