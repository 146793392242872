import React from 'react';
import { StyleSheet, ActivityIndicator, View } from 'react-native';

const Loading = () => {
 return (
  <View style={[styles.container, styles.horizontal]}>
   <ActivityIndicator size='large' color='#559158' />
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  flex: 1,
  justifyContent: 'center',
  backgroundColor: 'white',
 },
 horizontal: {
  flexDirection: 'row',
  justifyContent: 'space-around',
  padding: 10,
 },
});

export default Loading;
