import React from 'react';
import { useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { resetPasswordForMobile } from 'services/authService';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';
import ScreenContainer from 'components/reusable/ScreenContainer';
import { changeEmailSchema } from 'components/validations/schema';

import Text from 'reusable/Text';

import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const ResetPassword = ({ navigation }) => {
 const resolver = useYupValidationResolver(changeEmailSchema);

 const {
  control,
  setError,
  handleSubmit,
  formState: { errors, isSubmitting },
 } = useForm({
  resolver,
  defaultValues: {
   email: '',
  },
 });

 const onSubmit = async (values) => {
  const response = await resetPasswordForMobile(values.email);

  if (response.type === 'success') {
   navigation.navigate('ConfirmPassword', { email: values.email });
  } else {
   setError('email', { message: response.message });
  }
 };

 return (
  <KeyboardAwareScrollViewComponent>
   <ScreenContainer>
    <Text
     fontType='MontserratRegular'
     fontSize={lineHeight.subTitle3}
     color='NEUTRAL500'
     style={styles.topText}>
     Enter the E-mail address associated with your account and we’ll send
     instructions to reset your password
    </Text>

    <View style={{ marginBottom: 16 }}>
     <ControllerInput
      name='email'
      control={control}
      iconName='envelope'
      keyboardType='email'
      placeholder='Email Address'
      error={errors.email?.message}
      inputStyles={{ marginBottom: 10 }}
     />
    </View>
    <Text
     fontType='MontserratSemiBold'
     fontSize={lineHeight.subTitle3}
     color='NEUTRAL600'
     style={{
      lineHeight: 20,
     }}>
     Didn't receive the email? Check your spam filter, or try{' '}
     <Text
      fontType='MontserratSemiBold'
      fontSize={lineHeight.subTitle3}
      color='NORMAL_GREEN'
      style={{
       lineHeight: 20,
      }}>
      another E-mail address
     </Text>
    </Text>

    <Button
     title='Send Instructions'
     disabled={isSubmitting}
     customStyles={{ marginTop: 'auto', marginBottom: 40 }}
     bgColor={getColor('NORMAL_GREEN')}
     onPress={handleSubmit(onSubmit)}
    />
    <KeyboardPaddingView />
   </ScreenContainer>
  </KeyboardAwareScrollViewComponent>
 );
};

export default ResetPassword;

const styles = StyleSheet.create({
 topText: {
  textAlign: 'center',
  lineHeight: 22,
  marginBottom: 24,
  marginTop: 25,
 },
});
