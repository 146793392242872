import MapboxGL from '@rnmapbox/maps';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { getColor } from 'utils/settings';
import { MAP_ACCESSTOKEN } from 'utils/uris';

MapboxGL.setAccessToken(MAP_ACCESSTOKEN);

const mapStyle = {
 Satellite: 'mapbox://styles/mapbox/satellite-v9',
 SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
};

const SmallMap = ({ coords, withPoligons, poligonsData }) => {
 const [styleURL, setStyleURL] = useState(mapStyle.SatelliteStreet);

 return (
  <MapboxGL.MapView
   pitchEnabled={false}
   zoomEnabled={false}
   scrollEnabled={false}
   localizeLabels={false}
   scaleBarEnabled={false}
   attributionEnabled={false}
   surfaceView={false}
   rotateEnabled={false}
   compassFadeWhenNorth={false}
   style={styles.container}
   styleURL={styleURL}
   logoEnabled={false}
   compassEnabled={false}
   animated={false}>
   <MapboxGL.Camera
    animationMode='moveTo'
    animationDuration={500}
    defaultSettings={{
     zoomLevel: withPoligons ? 15 : 17,
     centerCoordinate: coords,
    }}
    centerCoordinate={coords}
   />

   {!withPoligons && (
    <MapboxGL.MarkerView coordinate={coords} isSelected={true}>
     <View style={styles.markerStyle} />
    </MapboxGL.MarkerView>
   )}

   {/* Polygons */}
   {withPoligons && (
    <MapboxGL.ShapeSource id='source' shape={poligonsData}>
     <MapboxGL.FillLayer id='fill' style={{ fillColor: '#D96C313D' }} />
     <MapboxGL.LineLayer
      id='line'
      style={{ lineColor: '#D96C31', lineWidth: 3 }}
     />
    </MapboxGL.ShapeSource>
   )}
   {/* Polygons */}
  </MapboxGL.MapView>
 );
};

export default SmallMap;

SmallMap.defaultProps = {
 withPoligons: false,
};

const styles = StyleSheet.create({
 container: {
  flex: 1,
  width: '100%',
  height: 120,
  borderRadius: 16,
  overflow: 'hidden',
 },
 treePoint: {
  width: 26,
  height: 31,
 },
 markerStyle: {
  width: 13,
  height: 13,
  backgroundColor: getColor('NORMAL_GREEN_HOVER'),
  borderColor: '#fff',
  borderWidth: 2,
  borderRadius: 15,
 },
});
