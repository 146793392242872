import React, { useMemo } from 'react';
import { Text as RNText } from 'react-native';

import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

const Text = ({
 fontType = 'MontserratRegular',
 color = 'NEUTRAL800',
 fontSize,
 style,
 ...rest
}) => {
 const memoizedStyle = useMemo(() => getColor(color), [color]);

 const _style = {
  fontFamily: fonts[fontType],
  color: memoizedStyle,
  ...fontSize,
 };
 return <RNText style={[_style, style]} {...rest} />;
};

export default Text;
