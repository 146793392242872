import { useIsFocused } from '@react-navigation/native';
import * as React from 'react';
import { StatusBar } from 'react-native';

const FocusAwareStatusBar = (props) => {
 const isFocused = useIsFocused();

 return isFocused ? (
  <StatusBar backgroundColor='transparent' {...props} />
 ) : null;
};

export default FocusAwareStatusBar;
