import React from 'react';
import { StyleSheet } from 'react-native';

import Icon from './Icon';
import SquircleContainer from './SquircleContainer';

import Text from 'reusable/Text';

const Barge = ({ title, onDelete }) => {
 return (
  <SquircleContainer
   bgColor='#DFE2E8'
   cornerRadius={8}
   style={styles.bargeContainer}>
   <Text fontType='MontserratSemiBold' fontSize={12} color='NEUTRAL800'>
    {title}
   </Text>

   <Icon name='plus2' style={styles.iconStyle} onPress={onDelete} />
  </SquircleContainer>
 );
};

export default Barge;

const styles = StyleSheet.create({
 bargeContainer: {
  height: 32,
  paddingLeft: 12,
  paddingRight: 16,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
 },
 iconStyle: {
  fontSize: 20,
  marginLeft: 10,
  transform: [{ rotate: '45deg' }],
 },
});
