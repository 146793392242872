import React from 'react';
import { StyleSheet, Text } from 'react-native';
import Animated from 'react-native-reanimated';

import { icons } from 'utils/icons';
import { getColor } from 'utils/settings';

const Icon = ({ name, animated, onPress, style }) => {
 if (animated) {
  return (
   <Animated.Text style={[styles.iconStyle, style || {}]} onPress={onPress}>
    {icons[name]}
   </Animated.Text>
  );
 } else {
  return (
   <Text style={[styles.iconStyle, style || {}]} onPress={onPress}>
    {icons[name]}
   </Text>
  );
 }
};

export default Icon;

Icon.defaultProps = {
 animated: false,
};

const styles = StyleSheet.create({
 iconStyle: {
  fontFamily: 'MyCustomIconSet',
  color: getColor('NEUTRAL700'),
 },
});
