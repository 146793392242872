import React, { useEffect, useRef, useState } from 'react';
import {
 Animated,
 StyleSheet,
 Pressable,
 View,
 TouchableOpacity,
} from 'react-native';
import { useSnapshot } from 'valtio';

import treeFilter from 'store/treeFilter';

import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';
import { WebClose } from 'utils/svgs';

function useWindowDimensions() {
 const [dimensions, setDimensions] = useState({
  width: window.innerWidth,
  height: window.innerHeight,
 });

 useEffect(() => {
  function handleResize() {
   setDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
   });
  }

  window.addEventListener('resize', handleResize);
  return () => {
   window.removeEventListener('resize', handleResize);
  };
 }, []);

 return dimensions;
}

const ModalWrapper = ({
 children,
 isEmbededMap,
 customStyles,
 fullScreenModal,
}) => {
 const { pin } = useSnapshot(treeFilter);
 const { width, height } = useWindowDimensions();

 const fadeAnim = useRef(new Animated.Value(0)).current;

 const fadeIn = () => {
  Animated.timing(fadeAnim, {
   toValue: 1,
   duration: 400,
   useNativeDriver: true,
  }).start();
 };

 useEffect(() => {
  fadeIn();
 }, []);

 const hideModal = () => {
  Defaults.Modal.hide();
  if (pin.length > 0) {
   treeFilter.pin = [];
  }
 };

 return (
  <>
   <Animated.View
    style={[
     styles.modalContainer,
     { ...customStyles, opacity: fadeAnim },
     fullScreenModal && {
      width:
       window.innerWidth <= 1000 && isEmbededMap ? width - 50 : width - 200,
      height:
       window.innerHeight <= 700 && isEmbededMap ? height - 50 : height - 100,
     },
    ]}>
    <View style={styles.childrenContainer}>{children}</View>

    <TouchableOpacity style={styles.closeButton} onPress={() => hideModal()}>
     <WebClose />
    </TouchableOpacity>
   </Animated.View>

   <Animated.View style={[styles.background, { opacity: fadeAnim }]}>
    <Pressable style={{ flex: 1 }} onPress={() => hideModal()} />
   </Animated.View>
  </>
 );
};

const styles = StyleSheet.create({
 modalContainer: {
  position: 'absolute',
  backgroundColor: getColor('WHITE'),
 },
 background: {
  ...StyleSheet.absoluteFill,
  justifyContent: 'center',
  backgroundColor: 'rgba(33, 37, 54, 0.6)',
  cursor: 'default',
 },
 closeButton: {
  position: 'absolute',
  top: 24,
  right: 24,
 },
 childrenContainer: { flex: 1, paddingTop: 50 },
});

export default ModalWrapper;
