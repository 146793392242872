import { StyleSheet } from 'react-native';

import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

export const styles = StyleSheet.create({
 mainText: {
  fontSize: 32,
  letterSpacing: 0.5,
  marginTop: 20,
  marginBottom: 24,
  fontFamily: fonts.MontserratBold,
 },
 TermsText: {
  fontSize: 12,
  lineHeight: 16,
  marginBottom: 16,
  textAlign: 'justify',
  letterSpacing: 0.5,
  fontFamily: fonts.MontserratRegular,
 },
 greenColorStyle: {
  color: getColor('NORMAL_GREEN'),
  fontFamily: fonts.MontserratSemiBold,
 },
 bottomText: {
  fontSize: 12,
  color: getColor('NEUTRAL600'),
  textAlign: 'center',
  letterSpacing: 0.5,
  fontFamily: fonts.MontserratSemiBold,
 },
 signUpWith: {
  marginTop: 20,
  marginBottom: 16,
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
 },
 signUpWithText: {
  fontSize: 13,
  marginHorizontal: 16,
  color: getColor('NEUTRAL500'),
  fontFamily: fonts.MontserratSemiBold,
 },
 hr: {
  height: 1,
  width: '29%',
  borderRadius: 4,
  backgroundColor: '#DFE2E8',
 },
 forgorPassStyle: {
  color: '#D96C31',
  fontSize: 12,
  lineHeight: 16,
  fontFamily: 'MontserratSemiBold',
  marginLeft: 'auto',
  marginTop: 4,
 },
});
