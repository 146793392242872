import { MMKV } from 'react-native-mmkv';

const storage = new MMKV();

const storageSet = (key, value) => {
 return storage.set(key, value);
};

const storageDel = (key) => {
 return storage.delete(key);
};

const storageGetString = (key) => {
 return storage.getString(key);
};

const storageGetBoolean = (key) => {
 return storage.getBoolean(key);
};

export { storageSet, storageGetString, storageGetBoolean, storageDel, storage };
