import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';

const CollectionTree = ({ data }) => {
 const genus = data?.properties?.genus;
 const species =
  data?.properties?.species.charAt(0).toUpperCase() +
  data?.properties?.species.slice(1);
 const upload = data?.createdAt?.slice(0, 10);
 const datePlanted = data?.planted;
 const commonName = data?.properties?.common;
 const noInfo = 'No info';

 return (
  <View style={styles.container}>
   <View
    style={[
     styles.imgContainer,
     !data?.imgUrls[0]?.uri && {
      alignItems: 'center',
      justifyContent: 'center',
     },
    ]}>
    {data?.imgUrls[0]?.uri ? (
     <Image
      style={styles.treeDefaultImg}
      source={{
       uri: data.imgUrls[0].uri,
      }}
     />
    ) : null}
    {!data?.imgUrls[0]?.uri ? (
     <Image
      style={styles.treeImg}
      source={{
       uri: IMAGES.collectiontree,
      }}
     />
    ) : null}
   </View>
   <View style={styles.genusContainer}>
    <Text style={WebStyles.treeCollectionTextBold}>
     {genus ? genus : noInfo}
    </Text>
    <Text style={WebStyles.treeCollectionTextNormal}>
     {species ? species : noInfo}
    </Text>
   </View>
   <View style={styles.dash} />
   <View style={styles.infoContainer}>
    <View style={styles.infoColumn}>
     <Text style={WebStyles.treeCollectionTextNormal}>Common name</Text>
     <Text numberOfLines={1} style={WebStyles.treeCollectionTextBold1}>
      {commonName ? commonName : noInfo}
     </Text>
    </View>
    <View style={styles.infoColumn}>
     <Text style={WebStyles.treeCollectionTextNormal}>Data Uploaded</Text>
     <Text style={WebStyles.treeCollectionTextBold1}>
      {upload ? upload : noInfo}
     </Text>
    </View>
    <View style={styles.infoColumn}>
     <Text style={WebStyles.treeCollectionTextNormal}>Planted</Text>
     <Text style={WebStyles.treeCollectionTextBold1}>
      {datePlanted ? datePlanted : noInfo}
     </Text>
    </View>
   </View>
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  backgroundColor: getColor('NEUTRAL200'),
  borderRadius: 16,
  height: 72,
  width: '100%',
  marginBottom: 12,
  justifyContent: 'center',
  padding: 8,
  flexDirection: 'row',
 },
 imgContainer: {
  width: 60,
  height: 56,
  borderRadius: 12,
  backgroundColor: getColor('WHITE'),
  overflow: 'hidden',
 },
 genusContainer: {
  flex: 1.5,
  justifyContent: 'space-evenly',
  marginLeft: 12,
 },
 infoContainer: {
  flex: 6,
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
 },
 dash: {
  width: 1,
  height: '100%',
  borderWidth: 1,
  borderColor: getColor('NEUTRAL300'),
 },
 infoColumn: {
  flex: 1,
  marginLeft: 20,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: '100%',
 },
 treeDefaultImg: { flex: 1 },
 treeImg: { width: 32, height: 32 },
});

export default CollectionTree;
