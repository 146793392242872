import React, { useState } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';

import Icon from 'reusable/Icon';

import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

const INPUT_HEIGHT = 45;
const ICON_SIZE = 18.5;

const UnderlinedInput = ({
 name,
 error,
 value,
 onClear,
 withIcon,
 placeholder,
 keyboardType,
 onChangeText,
 showClearIcon,
 placeholderTextColor,
}) => {
 const [isShowCloseIcon, setIsShowCloseIcon] = useState(false);

 const onFocus = () => {
  setIsShowCloseIcon(true);
 };

 const onBlur = () => {
  setIsShowCloseIcon(false);
 };

 return (
  <View>
   <TextInput
    name={name}
    value={value}
    onFocus={onFocus}
    onBlur={onBlur}
    placeholder={placeholder}
    onChangeText={onChangeText}
    inputMode={keyboardType}
    placeholderTextColor={placeholderTextColor}
    style={[
     styles.inputStyle,
     {
      paddingRight: withIcon ? 52 : 19,
      color: error ? getColor('error') : getColor('NEUTRAL800'),
      borderColor: error ? getColor('error') : getColor('NEUTRAL300'),
     },
    ]}
   />

   {isShowCloseIcon && (
    <Icon style={styles.iconsStyle} name='clear' onPress={onClear} />
   )}
  </View>
 );
};

export default UnderlinedInput;

UnderlinedInput.defaultProps = {
 value: '',
 withIcon: true,
 secureTextEntry: false,
 showClearIcon: false,
 placeholderTextColor: getColor('NEUTRAL300'),
};

const styles = StyleSheet.create({
 inputStyle: {
  height: INPUT_HEIGHT,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  fontFamily: fonts.MontserratRegular,
 },
 iconsStyle: {
  position: 'absolute',
  top: INPUT_HEIGHT / 2 - ICON_SIZE / 2,
  fontSize: ICON_SIZE,
  right: 0,
  zIndex: 1,
 },
 eyeIconStyle: {
  position: 'absolute',
  top: INPUT_HEIGHT / 2 - ICON_SIZE / 2,
  fontSize: ICON_SIZE,
  right: 19,
 },
});
