import dayjs from 'dayjs';
import { Image } from 'expo-image';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { onOpenOrganization } from 'utils/calls';
import { isWeb } from 'utils/platform';
import { getColor } from 'utils/settings';

const RenderItem = ({ item }) => {
 return (
  <View
   style={[
    item.label === 'User defined 1' ||
    item.label === 'User defined 2' ||
    item.label === 'User defined 3'
     ? { marginBottom: 16 }
     : { flexDirection: 'row' },
    isWeb && { justifyContent: 'space-between' },
   ]}>
   <Text style={[WebStyles.fileSize, { fontSize: 14, marginBottom: 16 }]}>
    {item.label}:{' '}
   </Text>
   <Text
    style={[
     WebStyles.fileSize,
     { fontSize: 14, color: getColor('NEUTRAL000') },
    ]}>
    {item.value}
   </Text>
  </View>
 );
};

const CustomText = ({ text }) => {
 return (
  <Text style={[WebStyles.addGeofenceTitle, { marginBottom: 24 }]}>{text}</Text>
 );
};
const CustomText1 = ({ text }) => {
 return (
  <Text
   style={[
    WebStyles.fileSize,
    { fontSize: 12, color: getColor('NEUTRAL000'), marginBottom: 16 },
   ]}>
   {text}
  </Text>
 );
};

const WebAdditionalInfo = ({ data }) => {
 const properties = data?.properties;
 console.log(data.properties);
 const dataObj = {
  general: [
   { label: 'Common name', value: properties.common || '--' },
   { label: 'Family', value: properties.family || '--' },
   { label: 'Variety', value: properties.variety || '--' },
  ],
  treeMetrics: [
   {
    label: 'Date planted',
    value: properties.planted
     ? dayjs(properties.planted).format('YYYY-MM-DD')
     : '--',
   },
   { label: 'Age', value: properties.age || '--' },
   { label: 'Calculated age', value: properties.calculatedAge || '--' },
   { label: 'Diameter at breast height', value: properties.diameter || '--' },
   { label: 'Crown size', value: properties.crown || '--' },
   { label: 'Height', value: properties.height || '--' },
   { label: 'Current estimated biomass', value: properties.biomass || '--' },
  ],
  treeManagement: [
   { label: 'Description of location', value: properties.location || '--' },
   { label: 'Reference ID', value: properties.refId || '--' },
   { label: 'Date updated', value: properties.updated || '--' },
   { label: 'Health', value: properties.health || '--' },
   { label: 'Structure condition', value: properties.structure || '--' },
   { label: 'Resilience estimation', value: properties.resilience || '--' },
  ],
  definedFields: [
   { label: 'User defined 1', value: properties.customField1 || '--' },
   { label: 'User defined 2', value: properties.customField2 || '--' },
   { label: 'User defined 3', value: properties.customField3 || '--' },
  ],
 };

 return (
  <View style={{ marginTop: 48 }}>
   {data.organizationImg && data.organizationImg[0] && (
    <>
     <CustomText text='Sponsored by' />
     <View
      style={[
       styles.container,
       { justifyContent: 'flex-end', marginBottom: 48 },
      ]}>
      <Pressable onPress={() => onOpenOrganization(data)}>
       <Image
        source={{ uri: data.organizationImg[0] }}
        style={{ width: 80, height: 80, borderRadius: 8 }}
       />
      </Pressable>
     </View>
    </>
   )}
   <CustomText text='Additional info' />

   <CustomText1 text='General' />

   {dataObj.general.map((item, index) => {
    return <RenderItem key={index} item={item} />;
   })}
   <CustomText1 text='Tree metrics' />
   {dataObj.treeMetrics.map((item, index) => {
    return <RenderItem key={index} item={item} />;
   })}
   <CustomText1 text='Tree management' />
   {dataObj.treeManagement.map((item, index) => {
    return <RenderItem key={index} item={item} />;
   })}
   <CustomText text='Defined fields' />
   {dataObj.definedFields.map((item, index) => {
    return <RenderItem key={index} item={item} />;
   })}
  </View>
 );
};

export default WebAdditionalInfo;

const styles = StyleSheet.create({});
