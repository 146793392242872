import React from 'react';
import { useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { changePassword } from 'services/authService';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';
import ScreenContainer from 'components/reusable/ScreenContainer';
import { webChangePasswordSchema } from 'components/validations/schema';

import Text from 'reusable/Text';

import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const NewPassword = ({ navigation, route }) => {
 const resolver = useYupValidationResolver(webChangePasswordSchema);

 const {
  control,
  setError,
  handleSubmit,
  formState: { errors, isSubmitting },
 } = useForm({
  resolver,
  defaultValues: {
   newPassword: '',
  },
 });

 const onSubmit = async (values) => {
  const data = {
   otp: route.params.otp,
   email: route.params.email,
   password: values.newPassword,
  };

  const response = await changePassword(data);

  if (response.type === 'success') {
   navigation.navigate('SuccessResetPassword', { passwordChanged: true });
  } else {
   setError('email', { message: response.message });
  }
 };

 return (
  <KeyboardAwareScrollViewComponent>
   <ScreenContainer>
    <Text
     fontType='MontserratRegular'
     fontSize={lineHeight.subTitle3}
     color='NEUTRAL500'
     style={styles.topText}>
     Your new password must be different from previously used passwords
    </Text>

    <View style={{ marginBottom: 16 }}>
     <ControllerInput
      name='newPassword'
      withEyeIcon={true}
      control={control}
      iconName='lockOn'
      secureTextEntry={true}
      placeholder='New Pasword'
      error={errors.newPassword?.message}
      inputStyles={{ marginBottom: 10 }}
     />
    </View>

    <Button
     title='Confirm'
     disabled={isSubmitting}
     customStyles={{ marginTop: 'auto', marginBottom: 40 }}
     bgColor={getColor('NORMAL_GREEN')}
     onPress={handleSubmit(onSubmit)}
    />
    <KeyboardPaddingView />
   </ScreenContainer>
  </KeyboardAwareScrollViewComponent>
 );
};

export default NewPassword;

const styles = StyleSheet.create({
 topText: {
  textAlign: 'center',
  lineHeight: 22,
  marginBottom: 24,
  marginTop: 25,
 },
});
