import React, { useEffect, useRef, useState } from 'react';
import {
 View,
 Text,
 StyleSheet,
 Animated,
 TouchableOpacity,
} from 'react-native';
import { useSnapshot } from 'valtio';

import treeFilter from 'store/treeFilter';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import Icon from 'components/reusable/Icon';

import { getColor } from 'utils/settings';

const WebNavButton = ({
 title,
 color,
 textColor,
 isFocused: isFocusedProp,
 accessibilityRole,
 accessibilityLabel,
 accessibilityState,
 testID,
 onPress,
 iconName,
 expandedOptions,
 companyRegistration,
 index,
 usersInEnterprise,
 routeName,
 treeCount,
 organisationButton,
}) => {
 const [selectedOption, setSelectedOption] = useState(-1);
 const isFocused = selectedOption < 0 && isFocusedProp;
 const { selectedTrees } = useSnapshot(treeFilter);

 const filterTrees = (index) => {
  if (index === 0) {
   treeFilter.filteredTrees = 'public';
  } else if (index === 1) {
   treeFilter.filteredTrees = 'private';
  }
 };

 return (
  <>
   <TouchableOpacity
    role={accessibilityRole}
    accessibilityState={accessibilityState}
    accessibilityLabel={accessibilityLabel}
    index={index}
    testID={testID}
    onPress={() => {
     if (companyRegistration) {
      companyRegistration();
     } else {
      onPress();
      setSelectedOption(-1);
      treeFilter.filteredTrees = '';
     }
    }}
    style={[
     styles.container,
     {
      backgroundColor: color
       ? color
       : isFocused
       ? getColor('NEUTRAL300')
       : 'white',
     },
    ]}>
    <View style={styles.iconContainer}>
     <Icon
      name={iconName}
      style={[
       styles.iconStyle,
       { color: isFocused ? getColor('NEUTRAL800') : getColor('NEUTRAL500') },
       { color: organisationButton && getColor('NORMAL_GREEN') },
      ]}
     />
     <View style={styles.childCont}>
      <Text
       style={[
        WebStyles.text,
        {
         color: textColor
          ? textColor
          : isFocused
          ? getColor('NEUTRAL800')
          : WebStyles.text.color,
        },
       ]}>
       {title}
      </Text>
      {(index === 0 || routeName === 'Users') && (
       <View
        style={[
         styles.countView,
         { backgroundColor: isFocused ? '#ed7d32' : getColor('NEUTRAL400') },
        ]}>
        <Text style={WebStyles.webButtonText}>
         {index === 0
          ? treeCount
            ? `Global trees:  ${treeCount}`
            : '0 Tree'
          : usersInEnterprise === 1
          ? `${usersInEnterprise} User`
          : `${usersInEnterprise} Users`}
        </Text>
       </View>
      )}
     </View>
    </View>
   </TouchableOpacity>
   {isFocusedProp &&
    expandedOptions?.map((item, index) => (
     <TouchableOpacity
      style={[
       styles.container,
       {
        backgroundColor:
         selectedOption === index ? getColor('NEUTRAL200') : getColor('WHITE'),
       },
      ]}
      key={index.toString()}
      onPress={() => {
       setSelectedOption(index);
       filterTrees(index);
       if (selectedTrees.length > 0) treeFilter.selectedTrees = [];
      }}>
      <View style={styles.iconContainer}>
       <Icon
        name='dot'
        style={[
         styles.iconStyle,
         { fontSize: 8, marginLeft: 18, marginRight: 20 },
         {
          color:
           selectedOption === index
            ? getColor('NEUTRAL800')
            : getColor('NEUTRAL500'),
         },
        ]}
       />
       <View style={styles.childCont}>
        <Text
         style={[
          WebStyles.text,
          {
           color: textColor
            ? textColor
            : selectedOption === index
            ? getColor('NEUTRAL800')
            : WebStyles.text.color,
          },
         ]}>
         {item.title}
        </Text>
        <View style={[styles.countView, { backgroundColor: item.color }]}>
         <Text style={WebStyles.webButtonText}>{item.trees}</Text>
        </View>
       </View>
      </View>
     </TouchableOpacity>
    ))}
  </>
 );
};

const styles = StyleSheet.create({
 container: {
  width: 282,
  height: 44,
  borderRadius: 12,
  flexDirection: 'row',
  outlineStyle: 'none',
 },
 iconContainer: {
  flex: 1,
  alignItems: 'center',
  flexDirection: 'row',
 },
 iconStyle: { fontSize: 21, marginLeft: 12, marginRight: 12 },
 countView: {
  height: 20,
  marginRight: 12,
  borderRadius: 6,
  alignItems: 'center',
  justifyContent: 'center',
 },
 childCont: {
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
 },
});

export default WebNavButton;
