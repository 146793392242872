import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

const usePermission = () => {
 const { permissions } = useSnapshot(authStore);
 return { permissions };
};

export default usePermission;
