// import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import axios from 'axios';
import useProfile from 'hooks/useProfile';
// import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from 'react';
import Map, { Layer, Source } from 'react-map-gl';
import { useSnapshot } from 'valtio';

import {
 clusterCountLayer,
 clusterLayer,
 geofenceLayer,
 geofenceLayerLine,
 unclusteredPointLayer,
} from '../map-style';
import { DrawControl } from '../mapbox-draw';

import geoFence from 'store/geoFence';

import AddPolygonButton from 'components/webComps/webcommon/AddGeoFenceButton';
import MapAlert from 'components/webComps/webcommon/MapAlert';
import MapButton from 'components/webComps/webcommon/MapButton';
import MapZoomButton from 'components/webComps/webcommon/MapZoomButton';

import { getGeofence } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { WebTrash } from 'utils/svgs';
import { API_MAP, MAP_ACCESSTOKEN } from 'utils/uris';

const mapStyle = {
 Satellite: 'mapbox://styles/mapbox/satellite-v9',
 SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
};

const GeofenceMap = ({
 setMapVisible,
 setValue,
 getValues,
 centerCoordinates,
}) => {
 const mapRef = useRef();
 const mapDrawRef = useRef();
 const zoomRef = useRef(2);
 const [geofenceExists, setGeofenceExists] = useState(false);
 const geoFenceValue = getValues('geofence');
 const [clusters, setClusters] = useState([]);
 const { geoFences } = useSnapshot(geoFence);
 const { profile } = useProfile();

 const onUpdate = (e) => {
  setGeofenceExists(true);
  setValue('geofence', [...e.features[0].geometry.coordinates[0]]);
 };

 const zoomOut = () => {
  mapRef.current.zoomOut();
 };

 const zoomIn = () => {
  mapRef.current.zoomIn();
 };

 const drawMode = () => {
  if (zoomRef.current > 14) mapDrawRef.current.changeMode('draw_polygon');
 };

 if (zoomRef.current < 14) {
  mapDrawRef?.current?.changeMode('simple_select');
 }

 const deletePolygon = () => {
  setValue('geofence', []);
  mapDrawRef.current.deleteAll();
  setGeofenceExists(false);
 };

 const finishEditing = () => {
  setMapVisible(false);
 };

 const onLoad = () => {
  if (geoFenceValue.length > 2) {
   mapRef.current.jumpTo({
    center: [geoFenceValue[0][0], geoFenceValue[0][1]],
    essential: true,
    zoom: 15,
   });
   getClusters();
  }
  if (centerCoordinates) {
   mapRef.current.jumpTo({
    center: [centerCoordinates.lng, centerCoordinates.lat],
    essential: true,
    zoom: 15,
   });
   getClusters();
  }
 };

 useEffect(() => {
  if (geoFenceValue.length > 3) {
   const customGeometry = {
    type: 'Polygon',
    coordinates: [[...geoFenceValue]],
   };

   const featureId = mapDrawRef?.current?.add(customGeometry);

   mapDrawRef?.current?.changeMode('direct_select', {
    featureId: featureId[0],
   });
  }

  getGeofence();
 }, [mapDrawRef.current]);

 useEffect(() => {
  if (geoFence.length > 3) {
   setGeofenceExists(true);
  }
 }, []);

 const getColorForTree = (tree) => {
  if (tree.custom === true)
   if (tree.private === true) {
    return '#717993';
   } else if (
    tree.organizationId === profile.enterprise ||
    tree.userId === profile._id
   ) {
    return '#559158';
   }
  return '#ed7d32';
 };

 const getClusters = async () => {
  const map = mapRef?.current?.getMap();
  const bounds = map?.getBounds().toArray();
  const baseUrl = API_MAP;
  const queries = `?zoom=${Math.round(zoomRef.current)}&westLng=${
   bounds[1][0]
  }&southLat=${bounds[0][1]}&eastLng=${bounds[0][0]}&northLat=${
   bounds[1][1]
  }&userId=${Defaults.userId}&private=${null}`;

  axios.get(`${baseUrl}${queries}`).then((res) => {
   const { clusters } = res.data;
   clusters.forEach((c) => {
    if (c.geometry.type === 'Point') {
     if (!c.properties) c.properties = {};
     c.properties.color = getColorForTree(c);
    }
   });
   setClusters(clusters);
  });
 };

 const onMoveEnd = () => {
  getClusters();
 };

 return (
  <>
   <Map
    ref={(ref) => {
     mapRef.current = ref;
    }}
    initialViewState={{
     longitude: -39.498484,
     latitude: 45.334125,
     zoom: 2,
    }}
    onZoomEnd={({ viewState: { zoom } }) => {
     getClusters();
     zoomRef.current = zoom;
    }}
    style={{
     flex: 1,
     borderRadius: 12,
    }}
    onMoveEnd={onMoveEnd}
    attributionControl={false}
    mapboxAccessToken={MAP_ACCESSTOKEN}
    renderWorldCopies={false}
    mapStyle={mapStyle.SatelliteStreet}
    interactiveLayerIds={['data']}
    onLoad={onLoad}>
    <Source
     id='trees'
     type='geojson'
     data={{
      type: 'FeatureCollection',
      crs: {
       type: 'name',
       properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
      },

      features: clusters,
     }}
     clusterMaxZoom={14}
     clusterRadius={50}>
     <Layer {...clusterLayer} />
     <Layer {...clusterCountLayer} />
     <Layer {...unclusteredPointLayer} />
    </Source>
    <Source type='geojson' data={geoFences}>
     <Layer {...geofenceLayerLine} />
     <Layer {...geofenceLayer} />
    </Source>
    <AddPolygonButton text='Finish' onPress={finishEditing} />
    <MapZoomButton zoomIn={zoomIn} zoomOut={zoomOut} />
    {zoomRef.current > 14 ? (
     <>
      <MapButton
       iconName='trash'
       customStyles={{ top: 24, right: 24 }}
       onPress={deletePolygon}
      />
      {geofenceExists ||
       (geoFenceValue.length > 2 ? null : (
        <MapButton
         svg={<WebTrash />}
         customStyles={{ top: 24, right: 92 }}
         onPress={drawMode}
        />
       ))}
     </>
    ) : (
     <>
      <MapAlert text='Zoom in to start drawing' />
     </>
    )}
    <DrawControl
     position='top-left'
     displayControlsDefault={false}
     onCreate={onUpdate}
     onUpdate={onUpdate}
     onMapDrawCreated={(ref) => (mapDrawRef.current = ref)}
    />
   </Map>
  </>
 );
};

export default GeofenceMap;
