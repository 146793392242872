import * as FileSystem from 'expo-file-system';
import { Image } from 'expo-image';
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import React, { useState } from 'react';
import {
 Alert,
 Pressable,
 ActivityIndicator,
 StyleSheet,
 ScrollView,
} from 'react-native';
import uuid from 'react-native-uuid';
import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

import { saveAvatar, uploadImage } from 'services/userService';

import Icon from 'reusable/Icon';
import SquircleContainer from 'reusable/SquircleContainer';
import Text from 'reusable/Text';
import Toast from 'reusable/Toast';

import { Defaults } from 'utils/defaults';
import { storageGetString } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';
import { fonts, fontSize, lineHeight } from 'utils/theme';

const UploadImage = ({
 render,
 images,
 setImages,
 networkSeed,
 onDeletePhoto,
 withAvatar = false,
 uploadedImageWidth = 700,
}) => {
 const store = useSnapshot(authStore);
 const [loading, setLoading] = useState(false);

 const PickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.Images,
  allowsEditing: false,
  quality: 1,
 };

 const pickImage = () => {
  if (!loading) {
   Alert.alert('Choose photo from', '', [
    {
     text: 'Camera',
     onPress: () => pickImageFromCamera(),
    },
    { text: 'Photo Library', onPress: () => pickImageFromLibrary() },
    {
     text: 'Cancel',
     style: 'cancel',
     onPress: () => console.log('Cancel'),
    },
   ]);
  }
 };

 const manipulateImage = async (result) => {
  const image = await ImageManipulator.manipulateAsync(result.assets[0].uri, [
   { resize: { width: uploadedImageWidth } },
  ]);

  return image;
 };

 const updateAvatar = async (uploadedAvatarRes) => {
  const savedAvatarRes = await saveAvatar(
   {
    userId: storageGetString('userId'),
    avatarUrl: uploadedAvatarRes.imageUrl,
   },
   'users',
   true
  );

  if (savedAvatarRes.type === 'success') {
   setLoading(false);
   authStore.profile.avatar = uploadedAvatarRes.imageUrl;
   Defaults.toast.show(<Toast message={savedAvatarRes.message} />);
  }
 };

 const uploadingImage = async (image) => {
  if (image.uri) {
   if (!store.isOffline || networkSeed < 5000) {
    const localUri = image.uri;
    const filename = localUri.split('/').pop();

    // Infer the type of the image
    const match = /\.(\w+)$/.exec(filename);
    const type = match ? `image/${match[1]}` : `image`;

    const uploadedImageRes = await uploadImage(
     { uri: localUri, name: filename, type },
     'users',
     true
    );

    console.log('Uploaded Image...', uploadedImageRes);

    setImages({ id: uuid.v4(), uri: uploadedImageRes.imageUrl });
   } else {
    setImages({ id: uuid.v4(), uri: image.uri });
   }
  }

  setLoading(false);
 };

 const uploadingAvatarImage = async (image) => {
  authStore.profile.avatar = image.uri;

  const localUri = image.uri;
  const filename = localUri.split('/').pop();

  // Infer the type of the image
  const match = /\.(\w+)$/.exec(filename);
  const type = match ? `image/${match[1]}` : `image`;

  const uploadedImageRes = await uploadImage(
   { uri: localUri, name: filename, type },
   'users',
   true
  );

  console.log('Uploaded Image...', uploadedImageRes);

  updateAvatar(uploadedImageRes);
 };

 const onPicked = async (res) => {
  const image = await manipulateImage(res);

  if (withAvatar) {
   uploadingAvatarImage(image);
  } else {
   uploadingImage(image);
  }
 };

 const pickImageFromLibrary = async () => {
  // No permissions request is necessary for launching the image library
  try {
   var result = await ImagePicker.launchImageLibraryAsync(PickerOptions);

   setLoading(true);
   const fileInfo = await FileSystem.getInfoAsync(result.assets[0].uri);

   if (!result.canceled && fileInfo.size <= 5000000) {
    onPicked(result);
   } else if (fileInfo.size > 5000000) {
    setLoading(false);
    Alert.alert('', 'Image size is more that 5MB');
   }

   if (result.canceled === 'Cancel' || result.canceled === true) {
    setLoading(false);
   }
  } catch (error) {
   setLoading(false);
   Alert.alert('Error', JSON.stringify(error.code));
  }
 };

 const checkCamera = async (mediaTypes, allowsEditing) => {
  const { status } = await ImagePicker.requestCameraPermissionsAsync();
  if (status !== 'granted') {
   //TODO: show error
  } else {
   const result = await ImagePicker.launchCameraAsync(PickerOptions);
   if (!result.canceled) {
    setLoading(true);
    onPicked(result);
   }
  }
 };

 const pickImageFromCamera = async () => {
  checkCamera();
 };

 if (!withAvatar) {
  return (
   <>
    <Pressable style={styles.addPhotoButton} onPress={pickImage}>
     <Icon
      name='plus2'
      animated
      style={[
       {
        fontSize: 22,
        marginBottom: 12,
        color: getColor('NORMAL_GREEN'),
       },
      ]}
     />

     <Text
      color='NORMAL_GREEN'
      fontType='MontserratSemiBold'
      fontSize={lineHeight.body3}>
      Add new
     </Text>
    </Pressable>

    {loading && (
     <ActivityIndicator
      style={{ marginLeft: 12 }}
      size='small'
      color='#559158'
     />
    )}

    <ScrollView
     horizontal={true}
     showsHorizontalScrollIndicator={false}
     style={{
      height: 120,
      marginTop: -10,
      marginLeft: 12,
     }}>
     {images?.map((img, index) => (
      <SquircleContainer
       key={uuid.v4()}
       cornerRadius={12}
       bgColor='#fff'
       style={{
        width: 100,
        height: 110,
        paddingTop: 10,
        marginRight: 12,
       }}>
       <Pressable onPress={() => onDeletePhoto(img)} style={{ zIndex: 111 }}>
        <Icon name='clear' style={styles.closeIcon} />
       </Pressable>
       <Image
        contentFit='cover'
        transition={100}
        source={{ uri: img.uri }}
        style={{ flex: 1, borderRadius: 12 }}
       />
      </SquircleContainer>
     ))}
    </ScrollView>
   </>
  );
 }

 return render(pickImage);
};

export default UploadImage;

const styles = StyleSheet.create({
 desc: {
  marginBottom: 16,
 },
 addPhotoButton: {
  width: 100,
  height: 100,
  borderRadius: 12,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: getColor('LIGHT_GREEN_HOVER'),
 },
 buttonStyle: {
  color: getColor('NORMAL_GREEN'),
  fontFamily: fonts.MontserratBold,
  letterSpacing: 0.5,
  marginRight: 12,
 },
 labelStyle: {
  color: getColor('NEUTRAL600'),
 },
 closeIcon: {
  position: 'absolute',
  right: -8,
  top: -9,
  color: getColor('NEUTRAL500'),
  fontSize: fontSize.button1.fontSize,
 },
 fieldContainer: {
  marginBottom: 16,
 },
 infoButton: {
  width: 36,
  height: 36,
  justifyContent: 'center',
  alignItems: 'center',
 },
});
