import { Platform } from 'react-native';

const isIOS = Platform.OS === 'ios';
const isAndroid = Platform.OS === 'android';
const isMobile = isIOS || isAndroid;
const isWeb = Platform.OS === 'web';
const isMac = Platform.OS === 'macos';
const isWindows = Platform.OS === 'windows';
const isDesktop = isWeb || isMac || isWindows;

export { isIOS, isAndroid, isMobile, isWeb, isMac, isWindows, isDesktop };
