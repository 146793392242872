import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';

import Button from 'reusable/Button';
import ScreenContainer from 'reusable/ScreenContainer';
import Text from 'reusable/Text';

import { IMAGES } from 'utils/images';
import { isAndroid } from 'utils/platform';
import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const BeforeChangeEmailModal = ({ navigation }) => {
 const store = useSnapshot(authStore);

 return (
  <ScreenContainer>
   <FocusAwareStatusBar
    barStyle={isAndroid ? 'dark-content' : 'light-content'}
   />
   <View style={styles.imageContainer}>
    <Image source={IMAGES.envelopeImage} />
   </View>

   <Text
    fontType='MontserratSemiBold'
    fontSize={lineHeight.subTitle2}
    style={{ textAlign: 'center' }}>
    {store.profile.email}
   </Text>

   <Text
    fontType='MontserratRegular'
    fontSize={lineHeight.body3}
    style={styles.textStyles}>
    You can change your E-mail address from here
   </Text>

   <Button
    bgColor={getColor('NORMAL_GREEN')}
    title='Change E-mail Address'
    onPress={() => navigation.navigate('ChangeEmailModal')}
   />
  </ScreenContainer>
 );
};

export default BeforeChangeEmailModal;

const styles = StyleSheet.create({
 imageContainer: {
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 24,
  marginTop: 60,
 },
 textStyles: {
  marginTop: 12,
  marginBottom: 48,
  textAlign: 'center',
 },
});
