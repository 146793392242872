export const unclusteredPointLayer = {
 id: 'unclustered-point',
 type: 'circle',
 source: 'trees',
 filter: ['!', ['has', 'point_count']],
 paint: {
  'circle-color': ['get', 'color'],
  'circle-radius': 6,
  'circle-stroke-width': 1,
  'circle-stroke-color': '#fff',
 },
};

export const geofenceLayer = {
 id: 'data',
 type: 'fill',
 paint: {
  'fill-color': {
   property: 'color',
   stops: [[0, '#D96C31']],
  },
  'fill-opacity': 0.4,
  'fill-outline-color': '#D96C31',
 },
};

export const geofenceLayerLine = {
 id: 'dataline',
 type: 'line',
 paint: {
  'line-color': '#D96C31',
  'line-width': 3,
 },
};

export const clusterLayer = {
 id: 'clusters',
 type: 'circle',
 source: 'trees',
 filter: ['has', 'point_count'],
 paint: {
  'circle-color': [
   'step',
   ['get', 'point_count'],
   '#ed7d32',
   100,
   '#ed7d32',
   750,
   '#ed7d32',
  ],
  'circle-radius': [
   'step',
   ['get', 'point_count'],
   15,
   500,
   30,
   100000,
   40,
   300000,
   50,
  ],
  'circle-stroke-width': 1,
  'circle-stroke-color': '#fff',
  'circle-opacity': 0.9,
  'circle-stroke-opacity': 0.3,
 },
};

export const clusterCountLayer = {
 id: 'cluster-count',
 type: 'symbol',
 source: 'trees',
 filter: ['has', 'point_count'],
 layout: {
  'text-field': '{point_count_abbreviated}',
  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
  'text-size': 12,
 },
 paint: {
  'text-color': '#ffffff',
 },
};

export const mapStyle = {
 Satellite: 'mapbox://styles/mapbox/satellite-v9',
 SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
};
