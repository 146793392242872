import React from 'react';
import { StyleSheet } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const KeyboardAwareScrollViewComponent = ({ children }) => {
 return (
  <KeyboardAwareScrollView
   keyboardShouldPersistTaps='handled'
   showsVerticalScrollIndicator={false}
   contentContainerStyle={styles.contentContainerStyle}>
   {children}
  </KeyboardAwareScrollView>
 );
};

export default KeyboardAwareScrollViewComponent;

const styles = StyleSheet.create({
 contentContainerStyle: {
  flex: 1,
 },
});
