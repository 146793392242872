import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect } from 'react';
import { StatusBar } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { useSnapshot } from 'valtio';

import AddPin from './AddPin/AddPin';
import AddTreeInfo from './AddTreeInfo/AddTreeInfo';
import AdditionalModal from './AddTreeInfo/AdditionalModal';
import BeforeChangeEmailModal from './ChangeEmail/BeforeChangeEmailModal';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import ConfirmationEmail from './ChangeEmail/ConfirmationEmail';
import ChangePassword from './ChangePassword/ChangePassword';
import EditProfile from './EditProfile/EditProfile';
import EditTreeInfo from './EditTreeInfo/EditTreeInfo';
import MobileHomeScreen from './HomeScreen/HomeScreen';
import OfflineModal from './OfflineScreen/OfflineModal';
import OnBoarding from './OnBoarding/OnBoarding';
import PoligonsFullData from './PoligonsFullData/PoligonsFullData';
import ConfirmPassword from './ResetPassword/ConfirmPassword';
import NewPassword from './ResetPassword/NewPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import SuccessScreen from './ResetPassword/SuccessScreen';
import SearchScreen from './Search/SearchScreen';
import SelectableModal from './SelectableModal/SelectableModal';
import SettingsScreen from './SettingsScreen/SettingsScreen';
import SigninScreen from './SignInScreen/SignInScreen';
import SignupScreen from './SignUpScreen/SignupScreen';
import Tabs from './Tabs';
import TreeInfo from './TreeInfo/TreeInfo';

import authStore from 'store/authStore';

import ModalProvider from 'components/modals/ModalProvider';
import VerifyModal from 'components/modals/VerifyModal';
import Close from 'components/reusable/Close';

import GoToButton from 'reusable/GoBack';

import { getPermissions } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { storageGetString } from 'utils/mmkvStorage';
import { isAndroid, isWeb } from 'utils/platform';
import { fonts } from 'utils/theme';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

if (isAndroid) {
 StatusBar.setTranslucent(true);
 StatusBar.setBackgroundColor('transparent');
}

const MyTheme = {
 ...DefaultTheme,
 colors: {
  ...DefaultTheme.colors,
  background: '#fff',
 },
};

const userId = storageGetString('userId');

const options = {
 headerShadowVisible: false, // applied here
 headerBackTitleVisible: false,
 headerTintColor: '#0F1118',
 headerTitleStyle: {
  fontFamily: fonts.MontserratSemiBold,
 },
};

const modalsOptions = {
 ...options,
 headerRight: () => <Close />,
};

const screenOptions = {
 ...options,
 headerLeft: () => <GoToButton />,
};

const MobileNavigation = () => {
 const store = useSnapshot(authStore);

 useEffect(() => {
  if (isWeb) {
   if (store.isLoggedIn) {
    Defaults.userId = userId;

    getPermissions(userId);
   }
  }
 }, [store.isLoggedIn]);

 return store.isOnboardingCompleted ? (
  <GestureHandlerRootView style={{ flex: 1 }}>
   <BottomSheetModalProvider>
    <NavigationContainer theme={MyTheme}>
     <Stack.Navigator
      screenOptions={{ headerLeft: () => <></> }}
      screenListeners={{
       state: (e) => {
        Defaults.toast.hide();
       },
      }}>
      <Stack.Screen
       name='Home'
       component={TabsNavigator}
       options={{ headerShown: false }}
      />

      <Stack.Screen
       name='Add'
       options={{ headerShown: false }}
       component={AddTreeStackScreen}
      />
      <Stack.Screen
       name='Settings'
       options={{ headerShown: false }}
       component={SettingsStackScreen}
      />

      <Stack.Screen
       name='Search'
       component={SearchScreen}
       options={{ ...screenOptions, title: '' }}
      />

      <Stack.Group screenOptions={{ presentation: 'modal' }}>
       <Stack.Screen
        name='VerifyModal'
        component={VerifyModal}
        options={{ ...modalsOptions, title: 'Verification code' }}
       />
       <Stack.Screen
        name='ResetPassword'
        component={ResetPassword}
        options={{ ...modalsOptions, title: 'Reset password' }}
       />
       <Stack.Screen
        name='NewPassword'
        component={NewPassword}
        options={{ ...modalsOptions, title: 'New password' }}
       />
       <Stack.Screen
        name='SuccessResetPassword'
        component={SuccessScreen}
        options={{ ...modalsOptions, title: 'Reset password' }}
       />
       <Stack.Screen
        name='ConfirmPassword'
        component={ConfirmPassword}
        options={{ ...modalsOptions, title: 'Verification code' }}
       />
       <Stack.Screen
        name='ChangeEmailModal'
        component={ChangeEmail}
        options={{ ...modalsOptions, title: 'Change e-mail address' }}
       />
       <Stack.Screen
        name='BeforeChangeEmailModal'
        component={BeforeChangeEmailModal}
        options={{ ...modalsOptions, title: 'E-mail address' }}
       />
       <Stack.Screen
        name='ConfirmationEmail'
        component={ConfirmationEmail}
        options={{ ...modalsOptions, title: 'E-mail address' }}
       />

       <Stack.Screen
        name='ChangePasswordModal'
        component={ChangePassword}
        options={{ ...modalsOptions, title: 'Change password' }}
       />

       <Stack.Screen
        name='TreeInfo'
        component={TreeInfo}
        options={{ ...modalsOptions, title: 'Tree info' }}
       />

       <Stack.Screen
        name='EditTreeInfo'
        component={EditTreeInfo}
        options={{ ...modalsOptions, title: 'Edit tree info' }}
       />
       <Stack.Screen
        name='AddTreeInfo'
        component={AddTreeInfo}
        options={{ ...modalsOptions, title: 'Add a tree' }}
       />

       <Stack.Screen
        name='AddPin'
        component={AddPin}
        options={{
         ...screenOptions,
         headerLeft: () => null,
         title: 'Location',
        }}
       />

       <Stack.Screen
        name='SelectableModal'
        component={SelectableModal}
        options={{
         ...modalsOptions,
         title: '',
        }}
       />
       <Stack.Screen
        name='AdditionalInfoModal'
        component={AdditionalModal}
        options={{
         ...modalsOptions,
         title: 'Additional fields',
        }}
       />

       <Stack.Screen
        name='OfflineModal'
        component={OfflineModal}
        options={{
         ...modalsOptions,
         gestureEnabled: false,
         headerRight: () => <></>,
         title: 'Uploading trees',
        }}
       />

       <Stack.Screen
        name='PoligonsFullData'
        component={PoligonsFullData}
        options={{
         ...modalsOptions,
         title: 'Geo-fence Info',
        }}
       />
      </Stack.Group>
     </Stack.Navigator>
    </NavigationContainer>

    <ModalProvider ref={(ref) => (Defaults.BottomSheetModal = ref)} />
    <ModalProvider ref={(ref) => (Defaults.toast = ref)} />
   </BottomSheetModalProvider>
  </GestureHandlerRootView>
 ) : (
  <OnBoarding />
 );
};

const TabsNavigator = () => {
 return (
  <Tab.Navigator tabBar={(props) => <Tabs {...props} />}>
   <Tab.Screen
    name='HomeScreen'
    component={MobileHomeScreen}
    options={{ headerShown: false }}
    initialParams={{ name: 'Home', tabIcon: 'home' }}
   />

   <Tab.Screen
    name='Add'
    component={AddTreeInfo}
    options={{
     headerShown: true,
     title: 'Add a tree',
    }}
    initialParams={{ name: '', tabIcon: 'plus2' }}
   />

   <Tab.Screen
    name='SettingsTab'
    component={SettingsScreen}
    options={{ headerShown: false }}
    initialParams={{ name: 'Settings', tabIcon: 'settings' }}
   />
  </Tab.Navigator>
 );
};

const SettingsStackScreen = () => (
 <Stack.Navigator screenOptions={{ headerLeft: () => <></> }}>
  <Stack.Screen
   name='Setting'
   component={SettingsScreen}
   options={{ headerShown: false }}
  />

  <Stack.Screen
   name='EditProfile'
   component={EditProfile}
   options={{ ...screenOptions, title: 'Profile' }}
  />

  <Stack.Screen
   name='Signin'
   component={SigninScreen}
   options={{ headerShown: false }}
  />
  <Stack.Screen
   name='Signup'
   component={SignupScreen}
   options={{ headerShown: false }}
  />
 </Stack.Navigator>
);

const AddTreeStackScreen = () => (
 <Stack.Navigator>
  <Stack.Screen
   name='AddTreeInfo'
   component={AddTreeInfo}
   options={{
    ...options,
    title: 'Add a Tree',
   }}
  />
 </Stack.Navigator>
);

export default MobileNavigation;
