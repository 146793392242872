import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Icon from 'reusable/Icon';

import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const ResponseMessage = ({ success, error, withIcon }) => {
 return (
  (success || error) && (
   <View style={styles.errorContainer}>
    {withIcon && (
     <Icon
      style={{
       marginLeft: 7,
       color: success ? getColor('NORMAL_GREEN') : getColor('error'),
       ...lineHeight.h3,
      }}
      name={success ? 'tickCircle' : 'forbidden'}
     />
    )}
    <Text
     style={{
      marginLeft: 7,
      color: success ? getColor('NORMAL_GREEN') : getColor('error'),
      ...lineHeight.body3,
     }}>
     {success || error}
    </Text>
   </View>
  )
 );
};

export default ResponseMessage;

ResponseMessage.defaultProps = {
 withIcon: true,
};

const styles = StyleSheet.create({
 errorContainer: {
  flexDirection: 'row',
  alignItems: 'center',
 },
});
