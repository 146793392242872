import React from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';

import LoginForm from 'components/forms/LoginForm';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';

import ScreenContainer from 'reusable/ScreenContainer';

import { IMAGES } from 'utils/images';

const SigninScreen = ({ navigation }) => {
 return (
  <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
   <KeyboardAwareScrollViewComponent>
    <FocusAwareStatusBar barStyle='dark-content' />

    <View style={{ paddingBottom: 30 }}>
     <View style={styles2.containerChild}>
      <Image source={IMAGES.signinImage} style={styles2.ImageStyle} />
     </View>

     <ScreenContainer>
      <LoginForm redirectPage='HomeScreen' navigation={navigation} />
     </ScreenContainer>
    </View>
    <KeyboardPaddingView />
   </KeyboardAwareScrollViewComponent>
  </ScrollView>
 );
};

export default SigninScreen;

const styles2 = StyleSheet.create({
 containerChild: {
  height: 290,
  paddingBottom: 30,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#ECECEE',
 },
 ImageStyle: { width: 252, height: 252, marginTop: 30 },
 contentContainerStyle: {
  justifyContent: 'space-between',
 },
});
