const healthOptions = ['Stump', 'Dead', 'Poor', 'Fair', 'Good', 'Removed'];

const structureOptions = ['Failed', 'Poor', 'Fair', 'Good'];

const ageIndicator = ['Young', 'Semi Mature', 'Mature', 'Over Mature'];

const resilienceIndicator = ['Survivor', 'Threatened', 'Casualty'];

const protectionIndicator = ['TPO', 'ATI', 'Fair', 'Good'];

export {
 ageIndicator,
 healthOptions,
 structureOptions,
 resilienceIndicator,
 protectionIndicator,
};
