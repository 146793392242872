import axios from 'axios';
import useForm from 'hooks/useForm';
import React, { useState } from 'react';
import { ScrollView, Text, View, StyleSheet } from 'react-native';

import { styles as styles1 } from 'screens/MobileNavigation/SignInScreen/styles';

import { enterpriseSignup } from 'services/enterpriseServices';
import { uploadImage } from 'services/userService';

import ModalWrapper from 'components/modals/ModalWrapper';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import DropDown from 'components/reusable/DropDown';
import { organisationRegisterSchema } from 'components/validations/schema';
import ImagePicker from 'components/webComps/webcommon/ImagePicker';
import Loading from 'components/webComps/webcommon/Loading';
import { pickImageResult } from 'components/webComps/webcommon/PickAndUploadImage';

import { getUser } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';

const droppedButtons = [
 { name: 'Botanical garden' },
 { name: 'Arboretum' },
 { name: 'Personal collection' },
 { name: 'Urban green space or park' },
 { name: 'Tree planting' },
 { name: 'Landowner' },
 { name: 'Local or regional authority ' },
 { name: 'Plantation' },
 { name: 'Other' },
];

const WebCompanySignup = () => {
 const [image, setImage] = useState(null);
 const [isLoading, setIsLoading] = useState(false);
 const [size, setSize] = useState();
 const [blob, setBlob] = useState('');

 const pickImage = async () => {
  const result = await pickImageResult();

  const calculateImgSize = () => {
   const stringLength =
    result.assets[0].uri.length - 'data:image/png;base64,'.length;

   const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
   const sizeInKb = sizeInBytes / 1000;
   const sizeInMb = sizeInKb / 1000;
   setSize(sizeInMb.toFixed(2));
  };

  if (!result.cancelled) {
   setImage(result.uri);
   const res = await axios.get(result.uri, { responseType: 'blob' });
   setBlob(res.data);
   calculateImgSize();
  }
 };
 const accountId = Defaults.userId;

 const defaultValues = {
  name: '',
  website: '',
  address: '',
  type: '',
  imageUrl: '',
 };

 const { control, handleSubmit, errors, isSubmitting, setValue } = useForm(
  organisationRegisterSchema,
  defaultValues
 );

 const onSubmit = async (values) => {
  setIsLoading(true);

  if (image) {
   const res = await uploadImage(blob, 'users', true);
   if (res.type === 'success') {
    const response = await enterpriseSignup(
     accountId,
     values.name,
     values.website,
     values.address,
     values.type,
     res.imageUrl
    );
    if (response.type === 'success') {
     Defaults.Modal.hide(<ModalWrapper />);
     getUser();
     setIsLoading(false);
    }
   }
  } else {
   const response = await enterpriseSignup(
    accountId,
    values.name,
    values.website,
    values.address,
    values.type,
    values.imageUrl
   );
   if (response.type === 'success') {
    Defaults.Modal.hide(<ModalWrapper />);
    getUser();
    setIsLoading(false);
   }
  }
 };

 return (
  <ScrollView contentContainerStyle={{ flex: 1 }}>
   {isLoading ? (
    <View style={styles.loadingCont}>
     <Loading />
    </View>
   ) : (
    <View style={styles.container}>
     <Text style={styles1.mainText}>Create an organisation account</Text>
     <View style={{ marginBottom: errors.name ? 6 : 24 }}>
      <ControllerInput
       name='name'
       control={control}
       iconName='people2'
       placeholder='Organisation Name'
       error={errors.name?.message}
      />
      <Text style={styles.requiredSign}>*</Text>
     </View>
     <View style={{ marginBottom: errors.website ? 4 : 28 }}>
      <ControllerInput
       name='website'
       control={control}
       iconName='global'
       placeholder='Website'
       error={errors.website?.message}
      />
     </View>
     <View style={{ marginBottom: errors.address ? 4 : 28 }}>
      <ControllerInput
       name='address'
       control={control}
       iconName='location'
       placeholder="Organisation's physical address"
       error={errors.address?.message}
      />
     </View>
     <View style={{ marginBottom: errors.type ? 4 : 28 }}>
      <DropDown
       name='type'
       placeholder='Type of organisation'
       control={control}
       error={errors.type?.message}
       setValue={(e) => {
        setValue('type', e);
       }}
       droppedButtons={droppedButtons}
      />
      <Text style={styles.requiredSign}>*</Text>
     </View>
     <ImagePicker
      image={image}
      pickImage={pickImage}
      setImage={setImage}
      size={size}
     />
     <Button
      title='Done'
      disabled={isSubmitting}
      bgColor={getColor('NORMAL_GREEN')}
      onPress={handleSubmit(onSubmit)}
      customStyles={{ zIndex: -1 }}
     />
    </View>
   )}
  </ScrollView>
 );
};

const styles = StyleSheet.create({
 container: {
  width: '67%',
  height: '80%',
  alignSelf: 'center',
 },
 loadingCont: { flex: 1, alignItems: 'center', justifyContent: 'center' },
 requiredSign: { position: 'absolute', right: -5, color: 'red' },
});

export default WebCompanySignup;
