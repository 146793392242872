import * as ImagePick from 'expo-image-picker';

export const pickImageResult = async () => {
 const result = await ImagePick.launchImageLibraryAsync({
  mediaTypes: ImagePick.MediaTypeOptions.Images,
  allowsMultipleSelection: false,
  quality: 1,
 });

 return result;
};
