export const treeDefaultValues = (editMode, data) => {
 //  const {coordinates, common: commonName, genus, species, imgUrls,} = data.geometry
 if (editMode) {
  return {
   coordinates: data.geometry.coordinates,
   commonName: data.properties.common,
   userId: '',
   organizationId: '',
   organizationImg:
    data?.organizationImg?.length > 0 ? data.organizationImg : [],
   organizationImgUrl: data.organizationImgUrl,
   genus: data.properties.genus,
   species: data.properties.species,
   imgUrls: data.imgUrls,
   private: data.private,
   family: data.properties.family,
   variety: data.properties.variety,
   datePlanted: data.properties.planted,
   age: data.properties.age,
   calculatedAge: data.properties.calculatedAge,
   diameter: data.properties.diameter,
   crownSize: data.properties.crown,
   height: data.properties.height,
   currentBiomass: data.properties.biomass,
   locationDescription: data.properties.location,
   referenceId: data.properties.refId,
   dateUpdated: data.properties.updated,
   health: data.properties.health,
   structureCondition: data.properties.structure,
   resilienceEstimation: data.properties.resilience,
   userDefined1: data.properties.customField1,
   userDefined2: data.properties.customField2,
   userDefined3: data.properties.customField3,
  };
 } else {
  return {
   coordinates: [],
   commonName: '',
   userId: '',
   organizationId: '',
   organizationImg: [],
   organizationImgUrl: '',
   genus: '',
   species: '',
   imgUrls: [],
   private: false,
   family: '',
   variety: '',
   datePlanted: '',
   age: '',
   calculatedAge: '',
   diameter: '',
   crownSize: '',
   height: '',
   currentBiomass: '',
   locationDescription: '',
   referenceId: '',
   dateUpdated: '',
   health: '',
   structureCondition: '',
   resilienceEstimation: '',
   userDefined1: '',
   userDefined2: '',
   userDefined3: '',
  };
 }
};

export const submittedValues = (editMode, data, values) => {
 if (editMode) {
  return {
   lng: values.coordinates[0],
   lat: values.coordinates[1],
   userId: values.userId,
   organizationId: values.organizationId,
   treeId: data._id,
   imgUrls: values.imgUrls,
   organizationImg: values.organizationImg,
   organizationImgUrl: values.organizationImg.length
    ? values.organizationImgUrl
    : '',
   private: values.private,
   properties: {
    family: values.family,
    age: values.age,
    diameter: values.diameter,
    calculatedAge: values.calculatedAge,
    biomass: values.currentBiomass,
    common: values.commonName,
    crown: values.crownSize,
    genus: values.genus,
    health: values.health,
    height: values.height,
    location: values.locationDescription,
    planted: values.datePlanted,
    refId: values.referenceId,
    resilience: values.resilienceEstimation,
    species: values.species,
    structure: values.structureCondition,
    updated: values.dateUpdated,
    variety: values.variety,
    customField1: values.userDefined1,
    customField2: values.userDefined2,
    customField3: values.userDefined3,
    coordinates: values.coordinates,
   },
  };
 } else {
  return {
   lng: values.coordinates[0],
   lat: values.coordinates[1],
   userId: values.userId,
   organizationId: values.organizationId,
   imgUrls: values.imgUrls,
   organizationImg: values.organizationImg || [],
   organizationImgUrl: values.organizationImgUrl,
   private: values.private,
   properties: {
    family: values.family,
    age: values.age,
    diameter: values.diameter,
    calculatedAge: values.calculatedAge,
    biomass: values.currentBiomass,
    common: values.commonName,
    crown: values.crownSize,
    genus: values.genus,
    health: values.health,
    height: values.height,
    location: values.locationDescription,
    planted: values.datePlanted,
    refId: values.referenceId,
    resilience: values.resilienceEstimation,
    species: values.species,
    structure: values.structureCondition,
    updated: values.dateUpdated,
    variety: values.variety,
    customField1: values.userDefined1,
    customField2: values.userDefined2,
    customField3: values.userDefined3,
    coordinates: values.coordinates,
   },
  };
 }
};
