import { IMAGES } from 'utils/images';

const DATA = [
 {
  id: 1,
  mainTitle: '',
 },
 {
  id: 2,
  pagingVal: 1,
  bgColor: '#EEF4EE',
  buttonText: 'Next',
  mainTitle: 'Welcome to Tremap!',
  description: "The world's first user-friendly global database of trees!",
  source: IMAGES.onBoardingImage1,
 },
 {
  id: 3,
  pagingVal: 2,
  bgColor: '#fff',
  buttonText: 'Next',
  mainTitle: 'Tap on a map marker to get tree info',
  description: 'Search and filter trees by genus, species and common name',
  source: IMAGES.onBoardingImage2,
 },
 {
  id: 4,
  pagingVal: 3,
  bgColor: '#fff',
  buttonText: 'Next',
  mainTitle: 'Want to map trees yourself? ',
  description: 'Just take a moment to sign up for free and then start mapping!',
  source: IMAGES.onBoardingImage3,
 },
 {
  id: 5,
  bgColor: '#fff',
  pagingVal: 4,
  buttonText: 'Start',
  mainTitle: 'To add trees, position the crosshairs:',
  moreData: [
   {
    text: 'Tap the plus sign',
    iconName: 'plus',
   },
   {
    text: 'Add some label info and photos',
    iconName: 'photo',
   },
   {
    text: "Save your tree and you're done!",
    iconName: 'download',
   },
  ],
 },
 {
  id: 6,
  mainTitle: '',
 },
];

export { DATA };
