import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
 useSharedValue,
 useAnimatedStyle,
 interpolate,
 withTiming,
} from 'react-native-reanimated';

import { getColor } from 'utils/settings';

const ProgressBar = () => {
 const line = useSharedValue(0);
 const Click = () => {
  line.value = withTiming(1, { duration: 1500 });
 };
 const animatedStyles = useAnimatedStyle(() => {
  return {
   transform: [{ scaleX: interpolate(line.value, [0, 1], [0, 100]) }],
  };
 });

 useEffect(() => {
  Click();
 }, []);

 return (
  <View style={styles.container}>
   <Animated.View style={[styles.line, animatedStyles]} />
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  overflow: 'hidden',
  width: '100%',
  height: 2,
  backgroundColor: getColor('NEUTRAL300'),
  borderRadius: 20,
 },
 line: { backgroundColor: getColor('NORMAL_GREEN'), height: 2, width: 10 },
});

export default ProgressBar;
