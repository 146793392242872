import { PortalProvider } from '@gorhom/portal';
import { registerRootComponent } from 'expo';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Platform } from 'react-native';

import App from './App';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

if (Platform.OS === 'web') {
 const rootElement = document.getElementById('root');
 const root = createRoot(rootElement);
 root.render(
  <PortalProvider>
   <App />
  </PortalProvider>
 );
} else {
 registerRootComponent(App);
}
