import React from 'react';
import { useForm } from 'react-hook-form';
import { Keyboard, StyleSheet, Text, View } from 'react-native';
import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

import { styles } from 'screens/MobileNavigation/SignInScreen/styles';
import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { changeEmail } from 'services/index';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import ConfirmationCodeField from 'components/reusable/ConfirmationCodeField';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';
import { VerifySchema } from 'components/validations/schema';

import Button from 'reusable/Button';
import ScreenContainer from 'reusable/ScreenContainer';

import { isAndroid, isWeb } from 'utils/platform';
import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const ConfirmationEmail = ({ route, userEmailForWeb, navigation }) => {
 const store = useSnapshot(authStore);

 const userEmail = isWeb ? userEmailForWeb : route.params?.newEmail;

 const resolver = useYupValidationResolver(VerifySchema);

 const {
  setValue,
  setError,
  handleSubmit,
  formState: { errors, isSubmitting },
 } = useForm({
  resolver,
  defaultValues: {
   code: '',
  },
 });

 const onSubmit = async (values) => {
  const response = await changeEmail({
   ...route.params,
   typeOfAction: 'verifyCode',
   verifyOtp: values.code,
  });

  if (response.type === 'success') {
   Keyboard.dismiss();
   authStore.profile = { ...store.profile, email: userEmail };
   navigation.goBack();
  } else {
   setError('code', { message: response.message });
  }
 };

 return (
  <KeyboardAwareScrollViewComponent>
   <FocusAwareStatusBar
    barStyle={isAndroid ? 'dark-content' : 'light-content'}
   />

   <ScreenContainer>
    <View
     style={[{ flex: 1 }, isWeb && { marginTop: 92, marginHorizontal: 44 }]}>
     {isWeb && (
      <View style={{ alignSelf: 'center' }}>
       <Text style={WebStyles.verificationText}>Verification Code</Text>
      </View>
     )}
     <View style={isWeb ? { marginTop: 24 } : { marginTop: 24 }}>
      <Text style={[styles.signUpWithText, styles2.text1]}>
       Enter the code we sent to
      </Text>

      <Text style={[styles.signUpWithText, styles2.text2]}>{userEmail}</Text>
     </View>

     <ConfirmationCodeField
      name='code'
      onChange={(code) => setValue('code', code)}
      error={errors.code?.message}
     />

     <View
      style={
       isWeb ? { marginTop: 32 } : { marginTop: 'auto', marginBottom: 30 }
      }>
      <Button
       title='Done'
       disabled={isSubmitting}
       bgColor={getColor('NORMAL_GREEN')}
       onPress={handleSubmit(onSubmit)}
      />
     </View>
    </View>
    <KeyboardPaddingView />
   </ScreenContainer>
  </KeyboardAwareScrollViewComponent>
 );
};

export default ConfirmationEmail;

const styles2 = StyleSheet.create({
 text1: { textAlign: 'center', ...lineHeight.subTitle3 },
 text2: {
  color: getColor('NEUTRAL800'),
  marginTop: 8,
  marginBottom: 32,
  textAlign: 'center',
  ...lineHeight.subTitle3,
 },
});
