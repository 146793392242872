import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
 ActivityIndicator,
 ScrollView,
 StyleSheet,
 TouchableOpacity,
 View,
} from 'react-native';

import { getCommonNames } from 'services/treeServices';

import Input from 'components/reusable/Input';
import Text from 'components/reusable/Text';

import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

const CommonNames = ({
 commonNames,
 onPressItem,
 onClear,
 onDone,
 onCancelPress,
 inputVal,
 setStateValue,
 isAddScreen,
}) => {
 const [value, setValue] = useState(inputVal);
 const [loading, setloading] = useState(true);
 const [commonNameArray, setCommonNameArray] = useState([]);

 const getCommonNamesData = async (inputVal) => {
  if (!loading) {
   setloading(true);
  }

  const response = await getCommonNames(inputVal);

  if (response.commons.length > 0) {
   setCommonNameArray(response.commons);
  } else if (response.commons.length === 0) {
   setCommonNameArray([]);
  }

  setloading(false);
 };

 const handler = useCallback(
  debounce((value) => getCommonNamesData(value), 500),
  []
 );

 useEffect(() => {
  setCommonNameArray(commonNames);
 }, []);

 useEffect(() => {
  handler(value);
  if (!isAddScreen) {
   setStateValue(value);
  }
 }, [value]);

 return (
  <ScrollView>
   {isAddScreen && (
    <View style={styles.topStyle}>
     <Text
      fontSize={16}
      fontType='MontserratSemiBold'
      style={{ marginLeft: 'auto' }}>
      Add a tree
     </Text>

     <TouchableOpacity
      style={{ marginLeft: 'auto' }}
      hitSlowp={Defaults.hitSlop}
      onPress={() => onDone(value)}>
      <Text style={styles.buttonStyle}>Done</Text>
     </TouchableOpacity>
    </View>
   )}

   <View
    style={{
     paddingHorizontal: 24,
    }}>
    <Text
     fontSize={12}
     color='NEUTRAL600'
     fontType='MontserratSemiBold'
     style={{ marginBottom: 8 }}>
     Common name
    </Text>
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
     <View style={{ flexGrow: 1 }}>
      <Input
       onChangeText={setValue}
       value={value}
       placeholder=''
       withClearIcon={true}
       withIcon={false}
       onClear={() => {
        setValue('');
        onClear();
       }}
       placeholderTextColor={getColor('NEUTRAL300')}
      />
     </View>

     <Text
      fontSize={12}
      fontType='MontserratSemiBold'
      onPress={() => onCancelPress(value)}
      style={{ marginLeft: 16 }}>
      Cancel
     </Text>
    </View>

    {loading && (
     <ActivityIndicator
      style={{ marginTop: 20 }}
      size='small'
      color='#559158'
     />
    )}

    {!loading &&
     commonNameArray.length > 0 &&
     commonNameArray.map((w) => (
      <Text
       onPress={() => onPressItem(w)}
       key={w._id}
       style={{ marginTop: 16 }}
       fontSize={14}
       fontType='MontserratSemiBold'>
       {w.common}
      </Text>
     ))}
   </View>
  </ScrollView>
 );
};

export default CommonNames;

const styles = StyleSheet.create({
 buttonStyle: {
  color: getColor('NORMAL_GREEN'),
  fontFamily: fonts.MontserratBold,
  letterSpacing: 0.5,
 },
 topStyle: {
  flexDirection: 'row',
  paddingRight: 20,
  marginBottom: 37,
  marginTop: 10,
 },
});
