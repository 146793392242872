import { useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Alert, StyleSheet, TouchableOpacity } from 'react-native';
import { useSnapshot } from 'valtio';

import SearchScreen from '../Search/SearchScreen';

import authStore from 'store/authStore';

import { getNetworkSpeed } from 'services/treeServices';

import Map from 'components/map/Map';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import Icon from 'components/reusable/Icon';
import SquircleContainer from 'components/reusable/SquircleContainer';

import { Defaults } from 'utils/defaults';
import { storage, storageGetString } from 'utils/mmkvStorage';

const MobileHomeScreen = ({ navigation }) => {
 const store = useSnapshot(authStore);

 const [networkSpeed, setNetworkSpeed] = useState();

 useFocusEffect(
  React.useCallback(() => {
   getNetworkSpeed().then((speed) => {
    console.log(speed);
    setNetworkSpeed(speed);
   });
  }, [])
 );

 useEffect(() => {
  const OfflineDeletTrees = storageGetString('OfflineDeletTrees');
  const OfflineAddedTrees = storageGetString('OfflineAddedTrees');
  const OfflineEditedTrees = storageGetString('OfflineEditedTrees');
  // const centerCoordinates = storageGetString('centerCoordinates');
  // storageDel('OfflineDeletTrees');
  // storageDel('OfflineAddedTrees');
  // storageDel('OfflineEditedTrees');
  // storage.clearAll();

  // console.log('MMKV Keys...', storage.getAllKeys());

  // console.log(centerCoordinates);
  // console.log('EDIT ---- OfflineEditedTrees....', OfflineEditedTrees);
  // console.log('ADD ---- OfflineAddedTrees....', OfflineAddedTrees);
  // console.log('DELETE ---- OfflineDeletTrees....', OfflineDeletTrees);

  if (store.isOffline === false || networkSpeed < 8000) {
   if (OfflineDeletTrees) {
    Alert.alert('', 'You have Deleted tree. Do you want to delete it?', [
     {
      text: 'Cancel',
      style: 'cancel',
      onPress: () => console.log('Cancel Pressed'),
     },

     {
      text: 'Yes',
      onPress: () =>
       navigation.navigate('OfflineModal', {
        type: 'DELETE_TREE',
        count: JSON.parse(OfflineDeletTrees).length,
       }),
     },
    ]);
   }

   if (OfflineAddedTrees && !OfflineDeletTrees) {
    Alert.alert('', 'You have added tree. Do you want to upload it?', [
     {
      text: 'Cancel',
      style: 'cancel',
      onPress: () => console.log('Cancel Pressed'),
     },

     {
      text: 'Yes',
      onPress: () =>
       navigation.navigate('OfflineModal', {
        type: 'ADD_TREE',
        count: JSON.parse(OfflineAddedTrees).length,
       }),
     },
    ]);
   }

   if (OfflineEditedTrees && !OfflineDeletTrees && !OfflineAddedTrees) {
    Alert.alert('', 'You have Edited tree. Do you want to Edit it?', [
     {
      text: 'Cancel',
      style: 'cancel',
      onPress: () => console.log('Cancel Pressed'),
     },

     {
      text: 'Yes',
      onPress: () =>
       navigation.navigate('OfflineModal', {
        type: 'EDIT_TREE',
        count: JSON.parse(OfflineEditedTrees).length,
       }),
     },
    ]);
   }
  }

  if (!storageGetString('clustersData') && store.isOffline) {
   Alert.alert('', 'No imagery for this location, position the tree anyway?');
  }
 }, [store.isOffline]);

 return (
  <>
   {!store.isOffline && (
    <TouchableOpacity
     style={styles.searchIcon}
     onPress={() => {
      if (storageGetString('centerCoordinates')) {
       Defaults.BottomSheetModal.show(<SearchScreen />);
      }
     }}>
     <SquircleContainer
      style={styles.buttonsStyle}
      bgColor='#fff'
      cornerRadius={12}>
      <Icon
       name={store.isSearchMode ? 'selectedSearch' : 'search'}
       style={{ fontSize: 22 }}
      />
     </SquircleContainer>
    </TouchableOpacity>
   )}

   <Map
    networkSeed={networkSpeed}
    navigation={navigation}
    isOffline={store.isOffline}
    searchedTrees={store.searchedTrees}
   />

   <FocusAwareStatusBar
    barStyle='light-content'
    backgroundColor='transparent'
   />
  </>
 );
};

export default MobileHomeScreen;

const styles = StyleSheet.create({
 buttonsStyle: {
  width: '100%',
  height: '100%',

  justifyContent: 'center',
  alignItems: 'center',
 },
 searchIcon: {
  width: 48,
  height: 48,
  right: 20,
  top: 75,
  position: 'absolute',
  zIndex: 11,
 },
});
