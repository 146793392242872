const IMAGES = {
 signinImage: require('assets/images/login-image.png'),
 signupImage: require('assets/images/authorized-image.png'),
 gmailImage: require('assets/images/gmail.png'),
 facebookImage: require('assets/images/facebook.png'),
 envelopeImage: require('assets/images/envelope-image.png'),
 tremapLogo: require('assets/images/tremaplogo.png'),
 tremapWebRegImg: require('assets/images/web-signup-img.png'),
 tremapWebSigninImg: require('assets/images/web-signin-img.png'),
 rectangle: require('assets/images/rectangle.png'),
 rectangleerror: require('assets/images/rectangleerror.png'),
 onBoardingImage1: require('assets/images/Saly-1.png'),
 onBoardingImage2: require('assets/images/Saly-2.png'),
 onBoardingImage3: require('assets/images/Saly-3.png'),
 TreeImage: require('assets/images/Rectangle2943.png'),
 openLink: require('assets/images/Vector.svg'),
 sponsoredImage: require('assets/images/sponsoredimage.png'),
 userImage: require('assets/images/userimage.png'),
 treepoint: require('assets/images/tree-point.png'),
 selebration: require('assets/images/firework.png'),
 sun: require('assets/images/sun.jpg'),
 currentLocation: require('assets/images/currentlocation.png'),
 profile: require('assets/images/Profile.png'),
 collectiontree: require('assets/images/collectiontree.png'),
 outboxTray: require('assets/images/Outbox-tray.png'),
 profileImage: require('assets/images/profileImage.png'),
 MarkerImage: require('assets/images/TremapGPSmarker.png'),
 SplashImage: require('assets/splash.png'),
};

export { IMAGES };
