import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Icon from 'components/reusable/Icon';
import SquircleContainer from 'components/reusable/SquircleContainer';

import { getColor } from 'utils/settings';
import { PenTool } from 'utils/svgs';

const MapButton = ({ onPress, customStyles, iconName }) => {
 return (
  <TouchableOpacity onPress={onPress}>
   <SquircleContainer
    bgColor={getColor('WHITE')}
    cornerRadius={12}
    style={[styles.squircle, customStyles]}>
    <View>
     {iconName ? (
      <>
       <Icon name={iconName} style={{ fontSize: 20 }} />
      </>
     ) : (
      <PenTool />
     )}
    </View>
   </SquircleContainer>
  </TouchableOpacity>
 );
};

export default MapButton;

const styles = StyleSheet.create({
 squircle: {
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  width: 44,
  height: 44,
 },
});
