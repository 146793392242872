import React, { useEffect } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';
import { Tick, WebClose } from 'utils/svgs';

const Alert = ({ alert }) => {
 useEffect(() => {
  setTimeout(() => {
   Defaults.Modal.hide();
  }, 2000);
 }, []);

 return (
  <View style={styles.container}>
   <View style={styles.alertCont}>
    <View style={styles.iconView}>
     <Tick color={getColor('WHITE')} />
    </View>
    <Text style={[WebStyles.alertText, { marginLeft: 12 }]}>{alert}</Text>
   </View>
   <TouchableOpacity onPress={() => Defaults.Modal.hide()}>
    <WebClose color={getColor('WHITE')} />
   </TouchableOpacity>
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  position: 'absolute',
  width: window.innerWidth,
  height: 76,
  borderBottomRightRadius: 12,
  borderBottomLeftRadius: 12,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingHorizontal: 70,
  top: 0,
  backgroundColor: getColor('NORMAL_GREEN'),
  right: 0,
  left: 0,
  margin: 'auto',
 },
 iconView: {
  width: 44,
  height: 44,
  borderRadius: 40,
  backgroundColor: '#75AE78',
  alignItems: 'center',
  justifyContent: 'center',
 },
 alertCont: { flexDirection: 'row', alignItems: 'center' },
});

export default Alert;
