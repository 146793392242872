import useProfile from 'hooks/useProfile';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
 Pressable,
 StyleSheet,
 Text,
 TouchableOpacity,
 View,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import Loading from './Loading';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { getCommonNames } from 'services/treeServices';

import Input from 'components/reusable/Input';
import SquircleContainer from 'components/reusable/SquircleContainer';

import { getColor } from 'utils/settings';

const WebCommonName = ({ value, setValue, addTree, filter }) => {
 const dropRef = useRef();
 const [visible, setVisible] = useState(false);
 const [commonNameArray, setCommonNameArray] = useState([]);
 const [isSelected, setIsSelected] = useState(false);
 const [loaded, setLoaded] = useState(false);
 const [noResultsFound, setNoResultsFound] = useState(false);
 const { profile } = useProfile();

 const enterpriseId = profile.enterprise;

 const getCommonNamesData = async (value) => {
  const response = await getCommonNames(value, enterpriseId);

  if (response.commons.length > 0) {
   setCommonNameArray(response.commons);
   setLoaded(true);

   if (!noResultsFound) setNoResultsFound(false);
  } else if (response.commons.length === 0 && commonNameArray.length !== 0) {
   setCommonNameArray([]);
   if (loaded) setLoaded(false);
  } else if (response.commons.length === 0 && commonNameArray.length === 0) {
   if (!loaded) setLoaded(true);
   setCommonNameArray([]);
   setNoResultsFound(true);
  }
 };

 useEffect(() => {
  const closeDropDown = () => {
   setVisible(false);
   setNoResultsFound(false);
  };

  window.addEventListener('click', closeDropDown);

  return () => window.removeEventListener('click', closeDropDown);
 }, []);

 const handler = useCallback(
  debounce((value) => getCommonNamesData(value), 100),
  []
 );

 useEffect(() => {
  if (value?.length <= 3) {
   setCommonNameArray([]);
   setLoaded(false);
   setNoResultsFound(false);
  }
  if (value?.length > 2) {
   handler(value);
  }
 }, [value]);

 const renderItem = ({ item }) => {
  return (
   <SquircleContainer
    style={styles.selectContainer}
    strokeColor={getColor('NEUTRAL300')}
    strokeWidth={1}
    bgColor={getColor('WHITE')}>
    <TouchableOpacity
     onPress={() => {
      setValue(item.common);
      setIsSelected(true);
     }}>
     <Text
      style={[WebStyles.genusFilterText, { marginLeft: 20 }]}
      fontType='MontserratSemiBold'>
      {item.common}
     </Text>
    </TouchableOpacity>
   </SquircleContainer>
  );
 };

 return (
  <Pressable
   onPress={() => {
    setIsSelected(false);
    setVisible((prev) => !prev);
   }}>
   <View style={styles.inputContainer} ref={dropRef}>
    <Input
     onChangeText={setValue}
     value={value}
     placeholder='Enter common name'
     withClearIcon
     withIcon={false}
     onClear={() => {
      setValue('');
     }}
     placeholderTextColor={getColor('NEUTRAL300')}
    />
   </View>
   {value?.length >= 3 &&
    commonNameArray?.length > 0 &&
    !isSelected &&
    visible && (
     <View style={styles.droppedContainer}>
      <FlatList
       style={{ width: '100%', paddingHorizontal: 20 }}
       showsHorizontalScrollIndicator={false}
       contentContainerStyle={{ width: '100%' }}
       data={commonNameArray}
       renderItem={renderItem}
       keyExtractor={(item, i) => i}
      />
     </View>
    )}
   {value?.length >= 3 && !loaded && (
    <View style={styles.droppedContainer}>
     <Loading />
    </View>
   )}
   {value?.length >= 3 && noResultsFound && (
    <View
     style={[
      styles.droppedContainer,
      { height: 70, justifyContent: 'center' },
     ]}>
     <Text style={WebStyles.genusFilterText}>No results found</Text>
    </View>
   )}
  </Pressable>
 );
};

const styles = StyleSheet.create({
 inputContainer: {
  position: 'relative',
  width: '100%',
 },
 droppedContainer: {
  width: '100%',
  maxHeight: 400,
  marginTop: 60,
  position: 'absolute',
  borderRadius: 12,
  borderColor: getColor('NEUTRAL300'),
  borderWidth: 1,
  alignItems: 'center',
  paddingVertical: 10,
  zIndex: 10,
  backgroundColor: getColor('WHITE'),
 },
 selectContainer: {
  height: 52,
  width: '100%',
  marginVertical: 10,
  justifyContent: 'center',
 },
});

export default WebCommonName;
