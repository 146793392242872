import { useRef, useState } from 'react';

export default function useReffedState(initialState) {
 const [, setStateRaw] = useState(initialState);
 const stateRef = useRef(initialState);
 const setState = (value) => {
  stateRef.current = value;
  setStateRaw(value);
 };

 return [stateRef, setState];
}
