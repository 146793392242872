import React from 'react';
import { View, Text, Image } from 'react-native';

import { resetStyles } from './resetStyles';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import Button from 'components/reusable/Button';

import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';

const ChangedSuccessfully = ({ next }) => {
 return (
  <View>
   <View style={resetStyles.imgContainer}>
    <Image style={resetStyles.imgSize} source={IMAGES.rectangle} />
   </View>
   <View style={resetStyles.textContainer}>
    <Text style={[WebStyles.WebHeadText, { textAlign: 'center' }]}>
     The password has been changed
    </Text>
    <Text style={[WebStyles.text, resetStyles.text]}>
     Your password has been changed. Press the button to log in
    </Text>
   </View>
   <Button title='Log In' bgColor={getColor('NORMAL_GREEN')} onPress={next} />
  </View>
 );
};

export default ChangedSuccessfully;
