import React from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';

import authStore from 'store/authStore';
import treeFilter from 'store/treeFilter';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { storageDel } from 'utils/mmkvStorage';

const Logout = () => {
 const logOut = () => {
  authStore.isLoggedIn = false;
  treeFilter.filteredTrees = '';
  treeFilter.pin = [];
  treeFilter.selectedTrees = [];
  storageDel('AccessToken');
  storageDel('RefreshToken');
  storageDel('userId');
 };
 return (
  <Pressable onPress={logOut} style={styles.container}>
   <Text style={[WebStyles.webDefaultText, { color: 'red' }]}>Logout</Text>
  </Pressable>
 );
};

const styles = StyleSheet.create({
 container: {
  position: 'absolute',
  top: 35,
  right: 73,
 },
});

export default Logout;
