import useForm from 'hooks/useForm';
import React from 'react';
import { View, Text } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { sendEmailForResetPassword } from 'services/authService';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import { changeEmailSchema } from 'components/validations/schema';

import { getColor } from 'utils/settings';

const StepOne = ({ data, next }) => {
 const { control, handleSubmit, errors, isSubmitting, setError } = useForm(
  changeEmailSchema,
  data
 );

 const onSubmit = async (values) => {
  const response = await sendEmailForResetPassword(values);
  if (response.type === 'success') {
   next(values);
  } else {
   setError('email', { message: response.message });
  }
 };

 return (
  <>
   <Text style={WebStyles.WebHeadText}>Reset Password</Text>
   <Text style={WebStyles.text}>
    Enter the email associated with your account and we'll send an email with
    instructions to reset your password
   </Text>
   <View style={{ marginBottom: errors.email ? 4 : 28, marginTop: 24 }}>
    <ControllerInput
     name='email'
     control={control}
     iconName='envelope'
     placeholder='Email Address'
     error={errors.email?.message}
     inputStyles={{ marginBottom: 10 }}
    />
   </View>

   <Button
    title='Send link to reset'
    disabled={isSubmitting}
    bgColor={getColor('NORMAL_GREEN')}
    onPress={handleSubmit(onSubmit)}
   />

   <Text style={[WebStyles.text, { marginTop: 40 }]}>
    Did not receive the email? Check your spam filter, or{' '}
    <Text style={{ color: getColor('NORMAL_GREEN') }}>
     try another email address
    </Text>
   </Text>
  </>
 );
};

export default StepOne;
