import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import SquircleContainer from 'components/reusable/SquircleContainer';

import { getColor } from 'utils/settings';

const MapAlert = ({ text, customStyles }) => {
 return (
  <SquircleContainer
   bgColor={getColor('WHITE')}
   cornerRadius={12}
   style={[styles.squircle, customStyles]}>
   <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    <Text style={WebStyles.addtTreeText}>{text}</Text>
   </View>
  </SquircleContainer>
 );
};

export default MapAlert;

const styles = StyleSheet.create({
 squircle: {
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  width: 200,
  height: 44,
  bottom: 24,
  right: 0,
  left: 0,
  margin: 'auto',
 },
});
