import dayjs from 'dayjs';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import Text from 'reusable/Text';

import { SCREEN_WIDTH } from 'utils/dimensions';
import { isWeb } from 'utils/platform';
import { lineHeight } from 'utils/theme';

const visibilityData = {
 true: 'Yes',
 false: 'No',
};

const AdditionalInfo = ({ treeInfoData, properties }) => {
 const data = [
  {
   label: 'Family',
   value: properties.family || '',
  },
  {
   label: 'Variety',
   value: properties.variety || '',
  },
  {
   label: 'Date Planted',
   value: properties.planted
    ? dayjs(properties.planted).format('YYYY-MM-DD')
    : '',
  },
  {
   label: 'Age',
   value: properties.age || '',
  },
  {
   label: 'Age indicator',
   value: properties.age || '',
  },
  {
   label: 'Diameter at breast height',
   value: properties.dbh || '',
  },
  {
   label: 'Crown size',
   value: properties.crown || '',
  },
  {
   label: 'Height',
   value: properties.height || '',
  },
  {
   label: 'Estimated biomass',
   value: properties.biomass || '',
  },
  {
   label: 'Location',
   value: properties.location || '',
  },
  {
   label: 'Reference id',
   value: properties.refId || '',
  },

  {
   label: 'Data uploaded',
   value: properties.updated || '',
  },

  {
   label: 'Health indicator',
   value: properties.health || '',
  },

  {
   label: 'Structure indicator',
   value: properties.structure || '',
  },

  {
   label: 'Resilience Estimation',
   value: properties.resilience || '',
  },

  {
   willBigText: true,
   label: 'Custom user field 1',
   value: properties.customField1 || '',
  },
  {
   willBigText: true,
   label: 'Custom user field 2',
   value: properties.customField2 || '',
  },
  {
   willBigText: true,
   label: 'Custom user field 3',
   value: properties.customField3 || '',
  },
  {
   label: 'Private',
   value: visibilityData[treeInfoData.private],
  },
 ];

 return (
  <View>
   {data.map((field, index) => (
    <View
     style={[
      { flexDirection: 'row' },
      isWeb && { justifyContent: 'space-between' },
     ]}
     key={index}>
     <Text
      color='NEUTRAL500'
      fontSize={lineHeight.subTitle3}
      style={{ marginBottom: 8 }}>
      {field.label}:{' '}
     </Text>

     <Text
      style={{
       width: field?.willBigText ? '63%' : SCREEN_WIDTH - 100,
      }}
      color='NEUTRAL000'
      fontType='MontserratSemiBold'
      fontSize={lineHeight.subTitle3}>
      {field.value}
     </Text>
    </View>
   ))}
  </View>
 );
};

export default AdditionalInfo;

const styles = StyleSheet.create({});
