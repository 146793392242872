const fonts = {
 MontserratRegular: 'MontserratRegular',
 MontserratBold: 'MontserratBold',
 MontserratSemiBold: 'MontserratSemiBold',
 MontserratItalic: 'MontserratItalic',
};

const fontSize = {
 h1: {
  fontSize: 32,
 },
 h2: {
  fontSize: 24,
 },
 h3: { fontSize: 18 },
 subTitle1: { fontSize: 18 },
 subTitle2: { fontSize: 16 },
 subTitle3: { fontSize: 14 },
 body1: { fontSize: 16 },
 body2: { fontSize: 14 },
 body3: { fontSize: 12 },
 button1: { fontSize: 16 },
 button2: { fontSize: 14 },
 button3: { fontSize: 12 },
};

const lineHeight = {
 h1: {
  fontSize: fontSize.h1.fontSize,
  lineHeight: 38,
 },
 h2: {
  fontSize: fontSize.h2.fontSize,
  lineHeight: 28,
 },
 h3: { fontSize: fontSize.h3.fontSize, lineHeight: 22 },
 subTitle1: { fontSize: fontSize.subTitle1.fontSize, lineHeight: 22 },
 subTitle2: { fontSize: fontSize.subTitle2.fontSize, lineHeight: 20 },
 subTitle3: { fontSize: fontSize.subTitle3.fontSize, lineHeight: 16 },
 body1: { fontSize: fontSize.body1.fontSize, lineHeight: 20 },
 body2: { fontSize: fontSize.body2.fontSize, lineHeight: 16 },
 body3: { fontSize: fontSize.body3.fontSize, lineHeight: 14 },
 button1: { fontSize: fontSize.button1.fontSize, lineHeight: 20 },
 button2: { fontSize: fontSize.button2.fontSize, lineHeight: 16 },
 button3: { fontSize: fontSize.button3.fontSize, lineHeight: 14 },
};

const colors = {
 LIGHT_GREEN: '#eef4ee',
 LIGHT_GREEN_HOVER: '#e6efe6',
 LIGHT_GREEN_ACTIVE: '#caddcb',
 NORMAL_GREEN: '#559158',
 NORMAL_GREEN_HOVER: '#4d834f',
 NORMAL_GREEN_ACTIVE: '#447446',
 DARK_GREEN: '#406d42',
 DARK_GREEN_HOVER: '#335735',
 DARK_GREEN_ACTIVE: '#264128',
 DARKER_GREEN: '#1e331f',
 LIGHT_ORANGE: '#fbf0ea',
 LIGHT_ORANGE_HOVER: '#f9e8df',
 LIGHT_ORANGE_ACTIVE: '#f3d0bc',
 NORMAL_ORANGE: '#d86628',
 NORMAL_ORANGE_HOVER: '#c24c24',
 NORMAL_ORANGE_ACTIVE: '#ad5220',
 DARK_ORANGE: '#a24d1e',
 DARK_ORANGE_HOVER: '#823d18',
 DARK_ORANGE_ACTIVE: '#612e12',
 DARKER_ORANGE: '#4c240e',
 LIGHT_RED: '#faece9',
 LIGHT_RED_HOVER: '#f8e2de',
 LIGHT_RED_ACTIVE: '#f1c4ba',
 NORMAL_RED: '#d14020',
 NORMAL_RED_HOVER: '#bc3a1d',
 NORMAL_RED_ACTIVE: '#a7331a',
 DARK_RED: '#9d3018',
 DARK_RED_HOVER: '#7d2613',
 DARK_RED_ACTIVE: '#5e1d0e',
 DARKER_RED: '#49160b',
 LIGHT_YELLOW: '#fdf8e9',
 LIGHT_YELLOW_HOVER: '#fcf4de',
 LIGHT_YELLOW_ACTIVE: '#f9e8bb',
 NORMAL_YELLOW: '#ecb423',
 NORMAL_YELLOW_HOVER: '#d4a220',
 NORMAL_YELLOW_ACTIVE: '#bd901c',
 DARK_YELLOW: '#b1871a',
 DARK_YELLOW_HOVER: '#8e6c15',
 DARK_YELLOW_ACTIVE: '#6a5110',
 DARKER_YELLOW: '#533f0c',
 LIGHT_SILVER: '#fdfdfd',
 LIGHT_SILVER_HOVER: '#fcfcfc',
 LIGHT_SILVER_ACTIVE: '#f9f9fa',
 NORMAL_SILVER: '#ececee',
 NORMAL_SILVER_HOVER: '#d4d4d6',
 NORMAL_SILVER_ACTIVE: '#bdbdbe',
 DARK_SILVER: '#b1b1b3',
 DARK_SILVER_HOVER: '#8e8e8f',
 DARK_SILVER_ACTIVE: '#6a6a6b',
 DARKER_SILVER: '#535353',
 WHITE: '#FFF',
 NEUTRAL100: '#F9FAFB',
 NEUTRAL200: '#F4F5F8',
 NEUTRAL300: '#DFE2E8',
 NEUTRAL400: '#C4CBD5',
 NEUTRAL500: '#919AAB',
 NEUTRAL600: '#636C81',
 NEUTRAL700: '#454A5B',
 NEUTRAL800: '#212536',
 NEUTRAL900: '#0F1118',
 NEUTRAL000: '#0C0D13',
 error: '#E45839',
};

export { colors, fonts, fontSize, lineHeight };
