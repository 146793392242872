import DateTimePicker from '@react-native-community/datetimepicker';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';

import SquircleContainer from './SquircleContainer';
import Text from './Text';

import { getColor } from 'utils/settings';

const AndroidDatePicker = ({ value, setDate, squircleContainerStyle }) => {
 const [show, setShow] = useState(false);

 const onChange = (event, selectedDate) => {
  setShow(false);

  if (event.type !== 'dismissed') {
   const currentDate = selectedDate;

   setDate(currentDate);
  }
 };

 const showMode = () => {
  setShow(true);
 };

 return (
  <Pressable onPress={showMode}>
   <SquircleContainer
    strokeWidth={1}
    strokeColor={getColor('NEUTRAL300')}
    bgColor='#fff'
    style={[styles.squircleStyle, squircleContainerStyle]}>
    {show && (
     <DateTimePicker
      testID='dateTimePicker'
      value={!value ? new Date() : new Date(value)}
      mode='date'
      is24Hour={true}
      onChange={onChange}
      style={{ position: 'absolute', right: 0, zIndex: 10 }}
     />
    )}

    {!value ? (
     <Text color='NEUTRAL300' fontType='MontserratSemiBold'>
      Date planted
     </Text>
    ) : (
     <Text color='NEUTRAL600' fontType='MontserratSemiBold'>
      {dayjs(value).format('YYYY-MM-DD')}
     </Text>
    )}
   </SquircleContainer>
  </Pressable>
 );
};

export default AndroidDatePicker;

const styles = StyleSheet.create({
 squircleStyle: {
  height: 48,
  paddingLeft: 16,
  justifyContent: 'center',
 },
});
