import React from 'react';

import WebMap from '../WebMap/index.web';

const EmbedMap = ({ navigation }) => {
 const { routes } = navigation.getState();

 const paramsObj = {
  userId: routes[0].params.userId,
  lat: parseFloat(routes[0].params.lat),
  lng: parseFloat(routes[0].params.lng),
  zoom: parseFloat(routes[0].params.zoom),
 };

 return (
  <WebMap
   isEmbededMap={true}
   navigation={navigation}
   embededMapParams={paramsObj}
  />
 );
};

export default EmbedMap;
