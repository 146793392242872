import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
 ScrollView,
 StyleSheet,
 Text,
 TextInput,
 TouchableOpacity,
 View,
} from 'react-native';
import { useSnapshot } from 'valtio';

import Dash from './Dash';

import treeFilter from 'store/treeFilter';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { searchTree } from 'services/treeServices';

import Icon from 'components/reusable/Icon';

import { getColor } from 'utils/settings';
import { WebClose } from 'utils/svgs';

const ICON_SIZE = 25;

const WebSearch = ({
 centerCoordinates,
 setSearchedTreeValues,
 searchedTreeValues,
 getClusters,
 setTreeHoverInfo,
 geofenceHoverInfo,
 setGeofenceHoverInfo,
}) => {
 const inputContRef = useRef();
 const inputRef = useRef();
 const contRef = useRef();
 const [value, setValue] = useState('');
 const [show, setShow] = useState(false);
 const [data, setData] = useState([]);
 const [error, setError] = useState(false);
 const { pin } = useSnapshot(treeFilter);

 const coords = [centerCoordinates?.lng, centerCoordinates?.lat];
 const onFocus = () => {
  setShow(true);

  if (coords[0]) {
   getSearchData('');
  }
  if (pin) {
   treeFilter.pin = [];
   setTreeHoverInfo(null);
  }
  if (geofenceHoverInfo) {
   setGeofenceHoverInfo(null);
  }
 };

 const addToFilter = (item) => {
  const companyExists = searchedTreeValues.some((i) => {
   if (i.enterpriseName) {
    return i.enterpriseName === item?.enterpriseName;
   } else if (i?.properties?.common) {
    return i?.properties?.common === item?.properties?.common;
   } else if (i?.properties?.genus) {
    return i?.properties?.genus === item?.properties?.genus;
   } else if (i?.properties?.species) {
    return i?.properties?.species === item?.properties?.species;
   } else if (i?.email) {
    return i?._id === item?._id;
   }
  });

  if (companyExists) {
  } else if (searchedTreeValues.length <= 2) {
   setSearchedTreeValues([...searchedTreeValues, item]);
  }
 };

 const removeSearchedTreeValue = (item) => {
  const newArr = searchedTreeValues.filter((i) => i !== item);
  setSearchedTreeValues(newArr);
  if (error) setError(false);
 };

 const getSearchData = async (val) => {
  const response = await searchTree(val, coords);
  if (response.message === 'Tree fetched successfully') {
   setData(response.trees);
  }
 };

 useEffect(() => {
  const closeDropDown = (e) => {
   if (
    contRef.current &&
    !contRef.current.contains(e.target) &&
    !inputRef.current.contains(e.target)
   ) {
    setShow(false);
   }
  };

  window.addEventListener('click', closeDropDown);
  return () => window.removeEventListener('click', closeDropDown);
 }, []);

 useEffect(() => {
  if (value.length >= 4) {
   getSearchData(value);
  }
  if (value.length === 0 && coords[0] > 0) {
   getSearchData('');
  }
 }, [value]);

 const clear = () => {
  setSearchedTreeValues([]);
  setValue('');
  setShow(false);
  setError(false);
 };

 return (
  <>
   <View ref={inputContRef} style={styles.container}>
    <Icon name='search' style={styles.iconsStyle} />
    <TextInput
     ref={inputRef}
     value={value}
     onFocus={onFocus}
     autoCorrect={false}
     spellCheck={false}
     placeholder='Search keyword(s)'
     onChangeText={setValue}
     placeholderTextColor='gray'
     style={styles.inputStyle}
    />
    {(value.length > 0 || searchedTreeValues.length > 0) && (
     <TouchableOpacity onPress={clear}>
      <Icon style={styles.clearIconStyle} name='clear' />
     </TouchableOpacity>
    )}
   </View>
   {show && (
    <View ref={contRef} style={styles.dropDown}>
     {error && (
      <View
       style={{ flexDirection: 'row', alignItems: 'center', marginTop: 8 }}>
       <Icon
        name='warning'
        style={{ color: getColor('error'), fontSize: 23 }}
       />
       <Text
        style={{
         fontFamily: 'MontserratRegular',
         fontSize: 12,
         color: getColor('error'),
         marginLeft: 8,
        }}>
        Maximum 3 keywords
       </Text>
      </View>
     )}
     {searchedTreeValues.length > 0 && (
      <View
       style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
       }}>
       {searchedTreeValues.map((item, index) => {
        return (
         <View
          key={index}
          style={{
           padding: 10,
           backgroundColor: getColor('NEUTRAL300'),
           borderRadius: 12,
           marginRight: 8,
           marginTop: 8,
           flexDirection: 'row',
          }}>
          <Text style={[WebStyles.searchFilterNames, { fontSize: 12 }]}>
           {item?.properties?.common ||
            item?.properties?.genus ||
            item?.properties?.species ||
            item?.enterpriseName ||
            item?.email ||
            ''}
          </Text>
          <TouchableOpacity
           onPress={() => removeSearchedTreeValue(item)}
           style={{ marginLeft: 8 }}>
           <WebClose />
          </TouchableOpacity>
         </View>
        );
       })}
       <Dash customStyle={{ marginVertical: 16 }} />
      </View>
     )}
     {show ? (
      <>
       <Text
        style={{
         fontFamily: 'MontserratSemiBold',
         fontSize: 12,
         color: getColor('NEUTRAL500'),
         marginTop: 6,
        }}>
        Nearby Trees
       </Text>
       <ScrollView style={{ marginTop: 16 }}>
        {data.length > 0 ? (
         data.map((item, index) => {
          if (
           item?.properties?.common ||
           item?.properties?.genus ||
           item?.properties?.species ||
           item?.enterpriseName ||
           item?.email
          )
           return (
            <React.Fragment key={index}>
             <TouchableOpacity
              key={index}
              style={{ marginVertical: 8 }}
              onPress={() => {
               addToFilter(item);
               if (searchedTreeValues.length === 3) {
                setError(true);
               }
              }}>
              {(item?.properties?.common || item?.properties?.genus) && (
               <Text
                style={[
                 WebStyles.searchFilterNames,
                 { color: getColor('NEUTRAL500') },
                ]}>
                Tree:{' '}
                <Text style={WebStyles.searchFilterNames}>
                 {item?.properties?.common || item?.properties?.genus}
                </Text>
               </Text>
              )}
              {item?.enterpriseName && (
               <Text
                style={[
                 WebStyles.searchFilterNames,
                 { color: getColor('NEUTRAL500') },
                ]}>
                Company:{' '}
                <Text style={WebStyles.searchFilterNames}>
                 {item?.enterpriseName}
                </Text>
               </Text>
              )}
              {item?.email && (
               <Text
                style={[
                 WebStyles.searchFilterNames,
                 { color: getColor('NEUTRAL500') },
                ]}>
                User:{' '}
                <Text style={WebStyles.searchFilterNames}>{item?.email}</Text>
               </Text>
              )}
             </TouchableOpacity>
            </React.Fragment>
           );
         })
        ) : (
         <Text style={WebStyles.searchFilterNames}>No trees found</Text>
        )}
       </ScrollView>
      </>
     ) : null}
    </View>
   )}
  </>
 );
};

export default WebSearch;

const styles = StyleSheet.create({
 container: {
  position: 'relative',
  width: 375,
  backgroundColor: getColor('WHITE'),
  height: 44,
  borderRadius: 12,
  flexDirection: 'row',
  zIndex: 10,
 },
 iconsStyle: {
  position: 'absolute',
  top: 44 / 2 - ICON_SIZE / 2,
  fontSize: ICON_SIZE,
  left: 14,
  zIndex: 3,
 },
 inputStyle: {
  height: '100%',
  width: '100%',
  outlineStyle: 'none',
  paddingLeft: 48,
  paddingRight: 48,
  color: getColor('NEUTRAL500'),
  fontFamily: 'MontserratRegular',
  fontWeight: 600,
  fontSize: 12,
 },
 clearIconStyle: {
  position: 'absolute',
  top: 44 / 2 - ICON_SIZE / 2,
  fontSize: ICON_SIZE,
  right: 14,
  zIndex: 3,
 },
 dropDown: {
  width: '100%',
  borderRadius: 12,
  maxHeight: 400,
  backgroundColor: 'white',
  position: 'absolute',
  marginTop: 56,
  paddingVertical: 10,
  paddingHorizontal: 24,
  zIndex: 1,
 },
});
