import React from 'react';

import { SCREEN_HEIGHT, SCREEN_WIDTH } from 'utils/dimensions';

const SecondMap = () => {
 return (
  <iframe
   height={SCREEN_HEIGHT}
   width={SCREEN_WIDTH - 5}
   credentialless='true'
   allow='geolocation'
   src='https://dev.portal.tremap.com/embedmap?zoom=17.95373412237268&lat=41.731124587956145&lng=44.742345476214865&userId=639c40515a1a521c203f5231'></iframe>
 );
};

export default SecondMap;
