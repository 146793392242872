import { usePagination, DOTS } from 'hooks/usePagination';
import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import Icon from 'components/reusable/Icon';

import { getColor } from 'utils/settings';

const Pagination = ({
 onPageChange,
 pageSize,
 totalCount,
 currentPage,
 siblingCount = 1,
}) => {
 const paginationRange = usePagination({
  currentPage,
  totalCount,
  siblingCount,
  pageSize,
 });

 if (currentPage === 0 || paginationRange.length < 2) {
  return null;
 }

 const onNext = () => {
  onPageChange(currentPage + 1);
 };

 const onPrevious = () => {
  onPageChange(currentPage - 1);
 };

 const lastPage = paginationRange[paginationRange.length - 1];

 return (
  <View style={styles.container}>
   <Pressable disabled={currentPage === 1} onPress={onPrevious}>
    <Icon
     style={[
      { fontSize: 8, padding: 8 },
      currentPage === 1 ? { color: getColor('NEUTRAL400') } : null,
     ]}
     name='arrowLeft'
    />
   </Pressable>
   {paginationRange.map((pageNumber, index) => {
    if (pageNumber === DOTS) {
     return (
      <Text
       style={[WebStyles.currentPlan700, { marginHorizontal: 6 }]}
       key={index + DOTS}>
       ...
      </Text>
     );
    }
    return (
     <Pressable
      style={{ alignSelf: 'center', marginHorizontal: 6 }}
      key={index}
      onPress={() => onPageChange(pageNumber)}>
      <Text
       style={[
        WebStyles.currentPlan700,

        {
         color: pageNumber === currentPage ? null : getColor('NEUTRAL500'),
        },
       ]}>
       {pageNumber}
      </Text>
     </Pressable>
    );
   })}
   <Pressable disabled={currentPage === lastPage} onPress={onNext}>
    <Icon
     style={[
      { fontSize: 8, padding: 8, transform: [{ rotate: '180deg' }] },
      currentPage === totalCount ? { color: getColor('NEUTRAL400') } : null,
     ]}
     name='arrowLeft'
    />
   </Pressable>
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'flex-end',
 },
});

export default Pagination;
