import useProfile from 'hooks/useProfile';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, Text, View, Pressable } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import Alert from './Alert';
import Dash from './Dash';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import {
 changeUserPermission,
 removeEnterpriseUser,
} from 'services/enterpriseServices';

import Icon from 'components/reusable/Icon';

import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';
import { CheckMark } from 'utils/svgs';

const InputDropdown = ({
 droppedButtons,
 name,
 control,
 data,
 setUserIdFilter,
 id,
 getEnterpriseUsers,
}) => {
 const btnRef = useRef();
 const [visible, setVisible] = useState(false);
 const [status, setStatus] = useState(data?.userPermission);
 const { profile } = useProfile();
 const enterpriseId = profile?.enterprise;
 const userId = data?.userId;

 const changePermission = async (permission) => {
  const res = await changeUserPermission(enterpriseId, userId, permission);
  if (res.status === 'success') {
   Defaults.Modal.show(<Alert alert={res.message} />);
  }
 };

 const removeUser = async () => {
  const res = await removeEnterpriseUser(enterpriseId, userId);
  if (res.status === 'success') {
   getEnterpriseUsers();
   Defaults.Modal.show(<Alert alert={res.message} />);
  }
 };

 useEffect(() => {
  const closeDropDown = (e) => {
   if (!btnRef.current.contains(e.target)) {
    setVisible(false);
   }
  };

  window.addEventListener('click', closeDropDown);

  return () => window.removeEventListener('click', closeDropDown);
 }, []);

 return (
  <>
   {control ? (
    <Controller
     control={control}
     status
     name={name}
     render={({ field: { onChange, onBlur, value } }) => {
      return (
       <View style={{ width: 120 }}>
        <>
         <Pressable
          ref={btnRef}
          onPress={() => {
           setVisible((prevState) => !prevState);
           setUserIdFilter(id);
          }}
          style={{
           flex: 1,
           justifyContent: 'center',
           alignItems: 'center',
           position: 'relative',
           flexDirection: 'row',
          }}>
          <Text style={[WebStyles.dropDownText]}>
           {value ? value : 'Permissions'}
          </Text>
          <Icon
           style={[
            {
             fontSize: 10,
             color: getColor('NEUTRAL700'),
             marginLeft: 16,
             marginRight: 10,
            },
            visible
             ? { transform: [{ rotate: '90deg' }] }
             : { transform: [{ rotate: '270deg' }] },
           ]}
           name='arrowLeft'
          />
         </Pressable>
        </>
        {visible && (
         <View style={styles.droppedContainer}>
          {droppedButtons.map((item, index) => (
           <Hoverable key={index}>
            {(isHovered) => (
             <Pressable
              style={[
               isHovered && [
                styles.hover,
                index === 0
                 ? { borderTopRightRadius: 12, borderTopLeftRadius: 12 }
                 : null,
                index === 2
                 ? { borderBottomRightRadius: 12, borderBottomLeftRadius: 12 }
                 : null,
               ],
               {
                width: '100%',
                height: 44,
                justifyContent: 'center',
                alignItems: 'center',
               },
              ]}
              onPress={() => {
               onChange(item.name);
               setVisible((prevState) => !prevState);
              }}>
              <Text style={[WebStyles.dropDownText]}>{item.name}</Text>
             </Pressable>
            )}
           </Hoverable>
          ))}
         </View>
        )}
       </View>
      );
     }}
    />
   ) : (
    <View style={{ width: 148, paddingRight: 26 }}>
     <>
      <Pressable
       ref={btnRef}
       onPress={() => {
        setVisible((prevState) => !prevState);
        setUserIdFilter(id);
       }}
       style={{
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
        flexDirection: 'row',
       }}>
       <Text style={[WebStyles.userNameText]}>{status}</Text>
       <Icon
        style={[
         {
          fontSize: 10,
          marginLeft: 16,
          color: getColor('NEUTRAL700'),
          // marginRight: 10,
         },
         visible
          ? { transform: [{ rotate: '90deg' }] }
          : { transform: [{ rotate: '270deg' }] },
        ]}
        name='arrowLeft'
       />
      </Pressable>
     </>
     {visible && (
      <View
       style={[
        styles.droppedContainer,
        {
         marginTop: 40,
         width: 148,
         height: 224,
         paddingVertical: 24,
         paddingHorizontal: 18,
        },
       ]}>
       {droppedButtons.map((item, index) => (
        <React.Fragment key={index}>
         {item.name === 'Remove' ? (
          <Dash
           customStyle={{ marginVertical: 14, alignSelf: 'center' }}
           key={index + 50}
          />
         ) : null}
         <Hoverable key={index}>
          {(isHovered) => (
           <Pressable
            style={[
             {
              width: '100%',
             },
             item.name === 'Admin' || item.name === 'Editor'
              ? { marginBottom: 24 }
              : null,
            ]}
            onPress={() => {
             item.name === 'Remove'
              ? removeUser()
              : (changePermission(item.name), setStatus(item.name));

             setVisible((prevState) => !prevState);
            }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
             <View style={{ width: 10, alignItems: 'center', marginRight: 14 }}>
              {isHovered && <CheckMark />}
             </View>
             <Text
              style={[
               WebStyles.dropDownText,
               item.name === 'Remove' && { color: 'red' },
              ]}>
              {item.name}
             </Text>
            </View>
           </Pressable>
          )}
         </Hoverable>
        </React.Fragment>
       ))}
      </View>
     )}
    </View>
   )}
  </>
 );
};

const styles = StyleSheet.create({
 hover: {
  backgroundColor: getColor('NEUTRAL200'),
 },
 droppedContainer: {
  width: 117,
  backgroundColor: 'white',
  borderWidth: 1,
  borderColor: getColor('NEUTRAL300'),
  position: 'absolute',
  marginTop: 58,
  borderRadius: 12,
  alignItems: 'center',
  zIndex: 5,
 },
});

export default InputDropdown;
