import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import SquircleContainer from './SquircleContainer';

import Icon from 'reusable/Icon';

import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const DeleteComponent = ({ color = '#454A5B', onPress }) => {
 return (
  <TouchableOpacity onPress={onPress}>
   <SquircleContainer style={styles.container} bgColor={getColor('NEUTRAL200')}>
    <View>
     <Icon name='trash' style={{ ...lineHeight.h2, fontSize: 20, color }} />
    </View>
   </SquircleContainer>
  </TouchableOpacity>
 );
};

export default DeleteComponent;

const styles = StyleSheet.create({
 container: {
  width: 52,
  height: 52,
  justifyContent: 'center',
  alignItems: 'center',
 },
});
