import React from 'react';
import { View, Image, StyleSheet } from 'react-native';

import { IMAGES } from 'utils/images';

const UserImageContainer = ({ image, otherStyles }) => {
 return (
  <View
   style={[
    styles.container,
    {
     backgroundColor: 'white',
    },
    !image && { alignItems: 'center', justifyContent: 'center' },
    { ...otherStyles },
   ]}>
   <Image
    style={image ? styles.image : styles.placeHolderImage}
    source={{ uri: image || IMAGES.profileImage }}
   />
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  width: 48,
  height: 44,
  borderColor: '#D1D4DE',
  borderWidth: 2,
  borderRadius: 13,
 },
 image: {
  flex: 1,
  resizeMode: 'cover',
  borderRadius: 11,
 },
 placeHolderImage: {
  width: 36,
  height: 32,
  resizeMode: 'cover',
 },
});

export default UserImageContainer;
