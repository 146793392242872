import React from 'react';
import {
 Linking,
 StyleSheet,
 Text,
 TouchableOpacity,
 View,
} from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { Call, Email, Global, Location } from 'utils/svgs';

const ContactInfo = () => {
 return (
  <View style={styles.container}>
   <Text style={WebStyles.permissionsTitle}>
    Need help? Reach out to us by email, use the contact form or chat window on
    our website or give us a call!
   </Text>
   <View style={styles.infoContainer}>
    <TouchableOpacity
     style={styles.buttonContainer}
     onPress={() =>
      Linking.openURL(
       'mailto:support@example.com?subject=SendMail&body=Description'
      )
     }>
     <Email />
     <Text style={WebStyles.contactText}>info@tremap.com</Text>
    </TouchableOpacity>
    <TouchableOpacity
     style={styles.buttonContainer}
     onPress={() => Linking.openURL('https://www.tremap.com/')}>
     <Global />
     <Text style={WebStyles.contactText}>www.tremap.com</Text>
    </TouchableOpacity>
    <TouchableOpacity
     style={styles.buttonContainer}
     onPress={() => Linking.openURL('tel:+4402039822216')}>
     <Call />
     <Text style={WebStyles.contactText}>+44 (0) 20 3982 2216</Text>
    </TouchableOpacity>
    <View
     style={{
      flexDirection: 'row',
     }}>
     <View>
      <Location />
     </View>
     <Text style={WebStyles.contactText}>
      OurHQ Drinnick House, Cornwall UK PL26 7XR, United Kingdom​
     </Text>
    </View>
   </View>
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  flex: 1,
  paddingHorizontal: 50,
  alignItems: 'center',
 },
 infoContainer: {
  height: 190,
  justifyContent: 'space-evenly',
 },
 buttonContainer: { flexDirection: 'row', alignItems: 'center' },
});

export default ContactInfo;
