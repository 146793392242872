import { debounce } from 'lodash';
import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useSnapshot } from 'valtio';

import { WebStyles } from '../WebStyles';

import authStore from 'store/authStore';

import { deleteUser } from 'services/authService';

import Button from 'components/reusable/Button';
import Alert from 'components/webComps/webcommon/Alert';

import { Defaults } from 'utils/defaults';
import { storageDel, storageGetString } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';

const DeleteAccountScreen = ({ navigation }) => {
 const store = useSnapshot(authStore);

 const [loading, setLoading] = useState(false);

 const onNavigateFunc = debounce(() => navigation.push('Auth'), 200);

 const logOut = () => {
  authStore.profile = null;
  authStore.isLoggedIn = false;
  authStore.permissions = null;

  storageDel('AccessToken');
  storageDel('RefreshToken');
  storageDel('userId');
 };

 const handleDeleteUser = async () => {
  setLoading(true);

  try {
   await deleteUser(storageGetString('AccessToken'));
   logOut();
   onNavigateFunc();
   setLoading(false);
   Defaults.Modal.show(<Alert alert='Account was Deleted' />);
  } catch (error) {
   setLoading(false);
  }
 };

 return (
  <View
   style={{
    flex: 1,
    paddingHorizontal: '20%',
    justifyContent: 'center',
    alignItems: 'center',
   }}>
   {store.isLoggedIn ? (
    <>
     <Text style={[WebStyles.WebHeadText]}>
      If you wish to close your account and don't wish your tree data to remain
      in the Tremap system, you must delete your data before you close your
      account. Whatever data you choose not to delete will remain in the Tremap
      database. If a given tree and its associated attributes and photos was
      marked as private, it will remain as private and not publicly visible to
      any other user. If it was marked as public then those trees will of course
      remain publicly visible.
     </Text>

     <Button
      title='Delete Account'
      disabled={loading}
      bgColor={getColor('NORMAL_RED')}
      onPress={handleDeleteUser}
      customStyles={{ width: 300, marginTop: 20 }}
     />
    </>
   ) : (
    <>
     <Text style={[WebStyles.WebHeadText]}>You are Unauthorized</Text>

     <Button
      title='Log In'
      disabled={loading}
      bgColor={getColor('NORMAL_GREEN')}
      onPress={() => navigation.push('Auth')}
      customStyles={{ width: 300, marginTop: 20 }}
     />
    </>
   )}
  </View>
 );
};

export default DeleteAccountScreen;

const styles = StyleSheet.create({});
