import useProfile from 'hooks/useProfile';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useSnapshot } from 'valtio';

import AddTreeWeb from './AddTreeWeb';
import Alert from './Alert';
import Index from './Carousel';
import Loading from './Loading';
import WebAdditionalInfo from './WebAdditionalInfo';

import authStore from 'store/authStore';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { deleteTree, getTreeInfo } from 'services/treeServices';

import ModalWrapper from 'components/modals/ModalWrapper';

import Button from 'reusable/Button';
// import AddedBy from '/components/Treeinfo/AddedBy';
import DeleteComponent from 'reusable/DeleteComponent';
import Text from 'reusable/Text';

import { getEnterpriseTree, getTrees } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';

const WebTreeInfo = ({ coordinates, getClusters, isEmbededMap }) => {
 const { profile } = useProfile();
 const [tree, setTree] = useState({});
 const [loaded, setLoaded] = useState(false);
 const [editMode, setEditMode] = useState(false);
 const store = useSnapshot(authStore);

 const getTree = async () => {
  const response = await getTreeInfo(coordinates[0], coordinates[1]);
  if (response.message === 'Tree fetched successfully') {
   setTree(response.tree);
   setLoaded(true);
  }
 };

 useEffect(() => {
  getTree();
 }, []);

 const removeTree = async () => {
  const res = await deleteTree(tree._id, Defaults.userId);
  if (res.message === 'Tree deleted successfully.') {
   Defaults.Modal.hide(<ModalWrapper />);
   setTimeout(() => {
    Defaults.Modal.show(<Alert alert={res.message} />);
   }, 500);
   getEnterpriseTree(profile.enterprise, profile._id);
   getTrees();
   getClusters();
  }
 };

 return (
  <ScrollView contentContainerStyle={{ flex: 1 }}>
   {editMode ? (
    <AddTreeWeb editMode data={tree} />
   ) : (
    <>
     {loaded ? null : (
      <View style={styles.loadingCont}>
       <Loading />
      </View>
     )}
     {!loaded ? null : (
      <View style={styles.container}>
       <View style={styles.width100}>
        <View style={styles.treeInfoWrapper}>
         <Text style={WebStyles.currentPlan}>Tree Info</Text>
        </View>

        <View style={styles.width100}>
         <Index
          images={tree.imgUrls ? tree.imgUrls : []}
          isEmbededMap={isEmbededMap}
         />
        </View>
       </View>

       <View
        style={[
         styles.bottomCont,
         { width: window.innerWidth <= 1000 && isEmbededMap ? '70%' : '50%' },
        ]}>
        <View style={{ marginTop: 16 }}></View>
        <Text style={WebStyles.treeGenus}>{tree.properties.genus}</Text>
        <Text style={[WebStyles.treeSpecies, { marginTop: 12 }]}>
         {tree?.properties?.species}
        </Text>
        {!isEmbededMap && store.isLoggedIn && tree.userId === profile?._id && (
         <View style={styles.editContainer}>
          <View style={styles.buttonWidth}>
           <Button
            title='Edit'
            bgColor={getColor('NORMAL_GREEN')}
            onPress={() => {
             setEditMode(true);
            }}
           />
          </View>
          <DeleteComponent onPress={removeTree} />
         </View>
        )}
        <WebAdditionalInfo data={tree} />
       </View>
      </View>
     )}
    </>
   )}
  </ScrollView>
 );
};

export default WebTreeInfo;

WebTreeInfo.defaultProps = {
 isUnRegisteredUser: false,
 isOwner: false,
};

const styles = StyleSheet.create({
 loadingCont: { flex: 1, alignItems: 'center', justifyContent: 'center' },
 container: { flex: 1, alignItems: 'center' },
 width100: { width: '100%' },
 treeInfoWrapper: { alignSelf: 'center', marginBottom: 32 },
 bottomCont: { width: '50%', marginTop: 16 },
 editContainer: { width: '100%', flexDirection: 'row', marginTop: 48 },
 buttonWidth: { flex: 1, marginRight: 12 },
});
