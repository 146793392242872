import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

const SocialIcon = ({ text, children }) => {
 return (
  <View style={styles.container}>
   {children}
   <Text style={styles.text}>{text}</Text>
  </View>
 );
};

export default SocialIcon;

const styles = StyleSheet.create({
 container: {
  backgroundColor: getColor('NEUTRAL200'),
  borderRadius: 12,
  height: 52,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
 },
 text: {
  letterSpacing: 0.5,
  marginLeft: 10,
  fontFamily: fonts.MontserratSemiBold,
 },
});
