import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import OrganisationProfile from './OrganisationProfile';
import UserImageContainer from './UserImageContainer';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import ModalWrapper from 'components/modals/ModalWrapper';

import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';

const UserContainer = ({ company }) => {
 const { profile } = useProfile();
 const { permissions } = usePermission();

 const openModal = () => {
  Defaults.Modal.show(
   <ModalWrapper customStyles={WebStyles.enterpriseInfoWrapperStyles}>
    <OrganisationProfile />
   </ModalWrapper>
  );
 };

 return (
  <View style={styles.container}>
   <UserImageContainer
    image={company ? profile.enterpriseData.avatar : profile.avatar}
   />
   <View style={styles.userInfo}>
    <Text style={[WebStyles.stepText, { lineHeight: 14 }]}>
     {company
      ? profile?.enterpriseData?.enterpriseName
      : profile?.fullName || profile?.email}
    </Text>
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
     <Text style={[WebStyles.text, { lineHeight: 14 }]}>
      {company ? profile?.enterpriseData?.typeOfEnterprise : permissions}
     </Text>
     {company && permissions === 'Admin' ? (
      <TouchableOpacity onPress={openModal}>
       <Text
        style={[
         WebStyles.stepText,
         { lineHeight: 14, color: getColor('error') },
        ]}>
        Edit
       </Text>
      </TouchableOpacity>
     ) : null}
    </View>
   </View>
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  height: 44,
  width: '100%',
  flexDirection: 'row',
 },
 userInfo: {
  flex: 1,
  marginLeft: 10,
  justifyContent: 'space-evenly',
 },
});

export default UserContainer;
