import React from 'react';
import { View, StyleSheet } from 'react-native';

import { getColor } from 'utils/settings';

const Dash = ({ customStyle }) => {
 return <View style={[styles.container, customStyle]} />;
};

const styles = StyleSheet.create({
 container: {
  width: '100%',
  borderWidth: 1,
  borderColor: getColor('NEUTRAL300'),
  marginVertical: 32,
 },
});

export default Dash;
