// import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import axios from 'axios';
import useProfile from 'hooks/useProfile';
// import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useRef, useState } from 'react';
import Map, { Layer, Source } from 'react-map-gl';
import { Image } from 'react-native';
import { useSnapshot } from 'valtio';

import {
 clusterCountLayer,
 clusterLayer,
 geofenceLayer,
 geofenceLayerLine,
 unclusteredPointLayer,
} from '../map-style';

import geoFence from 'store/geoFence';

import Button from 'components/reusable/Button';
import MapAlert from 'components/webComps/webcommon/MapAlert';
import MapZoomButton from 'components/webComps/webcommon/MapZoomButton';

import { Defaults } from 'utils/defaults';
import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';
import { fontSize } from 'utils/theme';
import { MAP_ACCESSTOKEN, API_MAP } from 'utils/uris';

const mapStyle = {
 Satellite: 'mapbox://styles/mapbox/satellite-v9',
 SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
};

const AddTreeMap = ({
 coordinates,
 setMapVisible,
 setValue,
 centerCoordinates,
}) => {
 const mapRef = useRef();
 const zoomRef = useRef(2);
 const [clusters, setClusters] = useState([]);
 const { geoFences } = useSnapshot(geoFence);
 const { profile } = useProfile();

 const styleURL = mapStyle.SatelliteStreet;

 const zoomOut = () => {
  mapRef.current.zoomOut();
 };

 const zoomIn = () => {
  mapRef.current.zoomIn();
 };

 const finishEditing = () => {
  setMapVisible(false);
 };

 const onLoad = () => {
  if (coordinates.length === 2) {
   mapRef.current.jumpTo({
    center: [coordinates[0], coordinates[1]],
    essential: true,
    zoom: 18,
   });
   getClusters();
  }
  if (
   centerCoordinates &&
   centerCoordinates.lat !== 37.79999999999998 &&
   centerCoordinates.lng !== -80.00000000000003
  ) {
   mapRef.current.jumpTo({
    center: [centerCoordinates.lng, centerCoordinates.lat],
    essential: true,
    zoom: 15,
   });
   getClusters();
  }
 };

 const getColorForTree = (tree) => {
  if (tree.custom === true)
   if (tree.private === true) {
    return '#717993';
   } else if (
    tree.organizationId === profile.enterprise ||
    tree.userId === profile._id
   ) {
    return '#559158';
   }
  return '#ed7d32';
 };

 const getClusters = async () => {
  const map = mapRef?.current?.getMap();
  const bounds = map.getBounds().toArray();
  const baseUrl = API_MAP;
  const queries = `?zoom=${Math.round(zoomRef.current)}&westLng=${
   bounds[1][0]
  }&southLat=${bounds[0][1]}&eastLng=${bounds[0][0]}&northLat=${
   bounds[1][1]
  }&userId=${Defaults.userId}&private=${null}`;

  axios.get(`${baseUrl}${queries}`).then((res) => {
   const { clusters } = res.data;
   clusters.forEach((c) => {
    if (c.geometry.type === 'Point') {
     if (!c.properties) c.properties = {};
     c.properties.color = getColorForTree(c);
    }
   });
   setClusters(clusters);
  });
 };

 return (
  <>
   <Map
    ref={(ref) => {
     mapRef.current = ref;
    }}
    initialViewState={{
     longitude: -39.498484,
     latitude: 45.334125,
     zoom: 2,
    }}
    onZoomEnd={({ viewState: { zoom } }) => {
     getClusters();
     zoomRef.current = zoom;
    }}
    style={{
     flex: 1,
     borderRadius: 12,
     zIndex: 2,
    }}
    onMoveEnd={() => {
     const center = mapRef?.current?.getCenter();
     setValue('coordinates', [center.lng, center.lat]);
     getClusters();
    }}
    attributionControl={false}
    mapboxAccessToken={MAP_ACCESSTOKEN}
    renderWorldCopies={false}
    mapStyle={styleURL}
    interactiveLayerIds={['data']}
    onLoad={onLoad}>
    <Source
     id='trees'
     type='geojson'
     data={{
      type: 'FeatureCollection',
      crs: {
       type: 'name',
       properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
      },

      features: clusters,
     }}
     clusterMaxZoom={14}
     clusterRadius={50}>
     <Layer {...clusterLayer} />
     <Layer {...clusterCountLayer} />
     <Layer {...unclusteredPointLayer} />
    </Source>
    <Source type='geojson' data={geoFences}>
     <Layer {...geofenceLayerLine} />
     <Layer {...geofenceLayer} />
    </Source>
    {zoomRef.current > 16 && (
     <Button
      title='Save'
      bgColor={getColor('NORMAL_GREEN')}
      textSize={fontSize.button3.fontSize}
      customStyles={{
       width: 180,
       height: 52,
       position: 'absolute',
       right: 24,
       bottom: 24,
      }}
      onPress={finishEditing}
     />
    )}

    <MapZoomButton zoomIn={zoomIn} zoomOut={zoomOut} />
    {zoomRef.current > 16 ? (
     <>
      <Image
       style={{
        position: 'absolute',
        right: 27,
        left: 0,
        top: 0,
        bottom: 35,
        margin: 'auto',
        width: 60,
        height: 60,
        zIndex: 1,
       }}
       source={{ uri: IMAGES.treepoint }}
      />
     </>
    ) : (
     <>
      <MapAlert text='Zoom in to add tree' />
     </>
    )}
   </Map>
  </>
 );
};

export default AddTreeMap;
