import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { IMAGES } from 'utils/images';

const TremapLogo = () => {
 return <Image style={styles.tremapLogo} source={IMAGES.tremapLogo} />;
};

const styles = StyleSheet.create({
 tremapLogo: {
  width: 137,
  height: 28,
  position: 'absolute',
  left: 74,
  top: 32,
 },
});

export default TremapLogo;
