const gmailConfig = {
 clientId: '1232584373998276',
 expoClientId:
  '998671121868-cq7airrnac9ei9pncotrf037pek0dcbu.apps.googleusercontent.com',
 iosClientId:
  '998671121868-eh1mfkq6jj7fl0gavv2dato3nitllpmp.apps.googleusercontent.com',
 androidClientId:
  '998671121868-o00klqsi56bpiml2hp90duggs51dh5h8.apps.googleusercontent.com',
};

export { gmailConfig };
