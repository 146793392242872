import useProfile from 'hooks/useProfile';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSnapshot } from 'valtio';

import Alert from './Alert';
import Dash from './Dash';

import treeFilter from 'store/treeFilter';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import {
 deleteSelectedTrees,
 updateTreeVisibility,
} from 'services/treeServices';

import Toggle from 'components/reusable/Toggle';

import { getEnterpriseTree, getTrees } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';
import { WebClose, WebTrash } from 'utils/svgs';

const SelectTrees = ({ privateOrPublic, selectedTreeArray, getClusters }) => {
 const [value, setValue] = useState(true);
 const { profile } = useProfile();
 const { selectedTrees } = useSnapshot(treeFilter);

 const isPrivate = privateOrPublic === 'public';

 const enterpriseId = profile.enterprise ? profile.enterprise : null;

 const userId = profile._id;

 const updateTrees = async () => {
  const res = await updateTreeVisibility(
   enterpriseId,
   selectedTreeArray,
   isPrivate,
   userId
  );
  if (res.message === 'Tree privacy updated successfully.') {
   setTimeout(() => {
    treeFilter.selectedTrees = [];
    getTrees();
    getEnterpriseTree(profile.enterprise, profile._id);
    getClusters();
    Defaults.Modal.show(
     <Alert
      alert={`Tree's successfully changed to ${
       privateOrPublic === 'public' ? 'private' : 'public'
      }`}
     />
    );
   }, 500);
  }
 };

 const deleteTrees = async () => {
  const res = await deleteSelectedTrees(
   enterpriseId,
   selectedTreeArray,
   userId
  );

  if (res.message === 'Trees deleted successfully') {
   setTimeout(() => {
    treeFilter.selectedTrees = [];
    getTrees();
    getEnterpriseTree(profile.enterprise, profile._id);
    getClusters();
    Defaults.Modal.show(<Alert alert='Tree`s has been deleted successfully' />);
   }, 500);
  }
 };

 useEffect(() => {
  if (!value) updateTrees();
 }, [value]);

 return (
  <View style={styles.container}>
   <View style={styles.top}>
    <Text style={WebStyles.additionalFeatures}>
     Selected trees ({selectedTrees.length})
    </Text>
    <TouchableOpacity
     style={styles.closeButton}
     onPress={() => {
      treeFilter.selectedTrees = [];
     }}>
     <WebClose />
    </TouchableOpacity>
   </View>
   <View style={styles.bottom}>
    <View style={styles.toggleCont}>
     <Text style={WebStyles.treeSelectText}>
      {privateOrPublic.charAt(0).toUpperCase() + privateOrPublic.slice(1)}
     </Text>
     <Toggle
      toggleSwitch={() => setValue((prev) => !prev)}
      value={value}
      customContainerStyle={{
       width: 34,
       height: 22,
       borderRadius: 25,
       padding: 4,
      }}
     />
    </View>
    <Dash customStyle={{ marginVertical: 16 }} />
    <View style={styles.toggleCont}>
     <Text style={WebStyles.treeSelectText}>Delete</Text>
     <TouchableOpacity onPress={() => deleteTrees()}>
      <WebTrash color={getColor('NEUTRAL500')} />
     </TouchableOpacity>
    </View>
   </View>
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  position: 'absolute',
  width: 227,
  height: 159,
  backgroundColor: getColor('WHITE'),
  left: 24,
  bottom: 24,
  borderRadius: 12,
  padding: 20,
 },
 top: {
  flex: 1,
  justifyContent: 'space-between',
  flexDirection: 'row',
 },
 bottom: {
  flex: 2,
 },
 toggleCont: {
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
 },
});

export default SelectTrees;
