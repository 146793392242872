import { Image } from 'expo-image';
import React, { useEffect } from 'react';
import { Linking, Pressable, StyleSheet, View } from 'react-native';
import Animated, {
 useSharedValue,
 useAnimatedStyle,
 interpolate,
 withTiming,
} from 'react-native-reanimated';

import Icon from 'components/reusable/Icon';
import ImageComponent from 'components/reusable/ImageComponent';
import SquircleContainer from 'components/reusable/SquircleContainer';
import Text from 'components/reusable/Text';

import { Defaults } from 'utils/defaults';
import { IMAGES } from 'utils/images';
import { storageSet } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';

const AnimatedTouchable = Animated.createAnimatedComponent(Pressable);

const PoligonsInfo = ({ data, poligonsData, navigation }) => {
 const position = useSharedValue(0);

 useEffect(() => {
  position.value = withTiming(1, { duration: 500 });

  navigation.addListener('beforeRemove', () => {
   if (Defaults.toast) {
    Defaults.toast.hide();
   }
  });
 }, []);

 const openLink = async (url) => {
  await Linking.openURL(url);
 };

 const openPoligonsInfoModal = () => {
  storageSet('homeScreenMarkerPosition', JSON.stringify(false));
  navigation.navigate('PoligonsFullData', {
   data,
   poligonsData,
  });
 };

 const animatedStyles = useAnimatedStyle(() => {
  return {
   opacity: interpolate(position.value, [0, 1], [0, 1]),
   bottom: interpolate(position.value, [0, 1], [80, 110]),
  };
 });

 return (
  <AnimatedTouchable
   style={[styles.containerStyle, animatedStyles]}
   onPress={openPoligonsInfoModal}>
   <SquircleContainer
    cornerRadius={16}
    bgColor='#fff'
    style={styles.dropdownContainer}>
    {data && (
     <>
      {data?.features[0]?.properties?.avatar?.length > 0 ? (
       <ImageComponent
        width={117}
        height={134}
        rectWidth={120}
        rectHeight={160}
        left={8}
        contentFit='cover'
        transition={100}
        source={data?.features[0]?.properties.avatar[0]}
        style={styles.imageStyle}
       />
      ) : (
       <Image source={IMAGES.tremapWebSigninImg} style={styles.imageStyle} />
      )}

      <View
       style={{
        marginTop: 7,
        flexGrow: 1,
        width: '70%',
        justifyContent: 'space-between',
       }}>
       <View>
        <Text
         fontSize={14}
         numberOfLines={2}
         fontType='MontserratBold'
         style={{ paddingRight: 30 }}>
         {data.features[0].properties.geofenceName}
        </Text>

        {data.features[0].properties?.website && (
         <Text
          onPress={() => openLink(data.features[0].properties?.website)}
          numberOfLines={1}
          fontSize={12}
          fontType='MontserratItalic'
          style={{ paddingRight: 25, marginTop: 4 }}>
          {data.features[0].properties?.website}
         </Text>
        )}
       </View>

       <View style={styles.textStyle}>
        <Text color='NEUTRAL600' fontSize={10} numberOfLines={3}>
         {data.features[0].properties.info}
        </Text>
       </View>
      </View>
      <Pressable
       style={{ position: 'absolute', top: 16, right: 20 }}
       onPress={() => Defaults.toast.hide()}>
       <Icon name='plus2' style={styles.closeIcon} />
      </Pressable>
     </>
    )}
   </SquircleContainer>
  </AnimatedTouchable>
 );
};

export default PoligonsInfo;

const styles = StyleSheet.create({
 containerStyle: {
  position: 'absolute',

  left: 24,
  right: 24,
  height: 142,
  zIndex: 1000,
 },
 dropdownContainer: {
  height: 142,
  padding: 8,
  flexDirection: 'row',
 },
 imageStyle: {
  width: '30%',
  height: '100%',
  marginRight: 10,
  borderRadius: 16,
 },
 desc: {
  marginTop: 4,
 },
 closeIcon: {
  color: getColor('NEUTRAL700'),
  transform: [{ rotate: '45deg' }],
  fontSize: 20,
 },
 textStyle: {
  marginTop: 12,
  paddingRight: 16,
  lineHeight: 16,
 },
});
