import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

const useProfile = () => {
 const { profile } = useSnapshot(authStore);

 return { profile };
};

export default useProfile;
