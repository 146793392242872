import React from 'react';
import { View } from 'react-native';

import WebMap from '../WebMap/index';

import HomeContainer from 'components/webComps/webcommon/HomeContainer';

const WebHomeScreen = () => {
 return (
  <HomeContainer>
   <View style={{ flex: 1 }}>
    <WebMap />
   </View>
  </HomeContainer>
 );
};

export default WebHomeScreen;
