import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import BigList from 'react-native-big-list';

import Close from 'components/reusable/Close';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import Input from 'components/reusable/Input';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';
import SquircleContainer from 'components/reusable/SquircleContainer';
import Text from 'components/reusable/Text';

import ScreenContainer from 'reusable/ScreenContainer';

import { Defaults } from 'utils/defaults';
import { isAndroid } from 'utils/platform';
import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

const INPUT_HEIGHT = 56;

const SelectableModal = ({ route, navigation }) => {
 const [searchValue, setSearchValue] = useState('');
 const [filteredData, setFilteredData] = useState([]);

 useEffect(() => {
  navigation.setOptions({
   title: route.params.title,
  });
 }, [navigation]);

 useEffect(() => {
  if (searchValue) {
   const options = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.0,
    keys: ['genus'],
   };

   const fuse = new Fuse(route.params.array, options);

   const result = fuse.search(searchValue);

   if (result.length === 0) {
    const obj = {
     genus: searchValue,
     species: [],
    };

    navigation.setOptions({
     headerRight: () => (
      <TouchableOpacity
       hitSlop={Defaults.hitSlop}
       onPress={() => {
        if (route.params.name === 'genus') {
         onSelectItem(obj);
        } else {
         onSelectItem(searchValue);
        }
       }}>
       <Text style={styles.buttonStyle}>Create New</Text>
      </TouchableOpacity>
     ),
     headerLeft: () => <Close />,
    });

    setFilteredData([]);
   } else {
    const r = result.map((w) => {
     return w.item;
    });

    setFilteredData(r);

    navigation.setOptions({
     headerLeft: () => <View></View>,
     headerRight: () => <Close />,
    });
   }
  } else {
   if (searchValue === '' && filteredData.length !== 0) {
    setFilteredData([]);
   }
  }
 }, [searchValue]);

 const onSelectItem = (option) => {
  route.params.setValue(option);
  navigation.goBack();
 };

 const renderItem = ({ item }) => (
  <TouchableOpacity onPress={() => onSelectItem(item)}>
   <SquircleContainer
    style={styles.selectContainer}
    strokeColor={getColor('NEUTRAL300')}
    strokeWidth={1}
    bgColor='#fff'>
    <Text fontType='MontserratSemiBold'>
     {item?.item?.genus || item?.genus || item}
    </Text>
   </SquircleContainer>
  </TouchableOpacity>
 );

 const mapedData =
  filteredData.length > 0 || searchValue !== ''
   ? filteredData
   : route.params.array;

 return (
  <KeyboardAwareScrollViewComponent>
   <FocusAwareStatusBar
    barStyle={isAndroid ? 'dark-content' : 'light-content'}
   />

   <ScreenContainer>
    <View style={{ marginBottom: 20 }}>
     {route.params.withSearch && (
      <Input
       onChangeText={setSearchValue}
       value={searchValue}
       placeholder='Search'
       iconName='search'
       isSearchInput={true}
       iconsStyles={{ color: getColor('NEUTRAL800') }}
       searchInputColor={getColor('NEUTRAL200')}
       placeholderTextColor={getColor('NEUTRAL500')}
      />
     )}
    </View>

    <BigList
     data={mapedData}
     showsHorizontalScrollIndicator={false}
     showsVerticalScrollIndicator={false}
     renderItem={renderItem}
     itemHeight={68}
     keyExtractor={(item, i) => i}
    />
    <KeyboardPaddingView />
   </ScreenContainer>
  </KeyboardAwareScrollViewComponent>
 );
};

export default SelectableModal;

const styles = StyleSheet.create({
 selectContainer: {
  height: INPUT_HEIGHT,
  paddingLeft: 16,
  justifyContent: 'center',
 },
 buttonStyle: {
  color: getColor('NORMAL_GREEN'),
  fontFamily: fonts.MontserratBold,
  letterSpacing: 0.5,
 },
});
