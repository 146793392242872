import React, { useEffect, useState } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';

import ChangedSuccessfully from './ChangedSuccessfully';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';
import useReffedState from './useReffedState';

import { styles as styles1 } from 'screens/MobileNavigation/SignInScreen/styles';
import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { resetPassword } from 'services/authService';

import Icon from 'components/reusable/Icon';
import WebSignInContainer from 'components/webComps/webcommon/WebSignInContainer';

import { getColor } from 'utils/settings';
import { fontSize } from 'utils/theme';

const WebPasswordReset = ({ navigation, route }) => {
 const params = route?.params;
 const [currentStep, setCurrentStep] = useState(1);
 const [data, setData] = useReffedState({
  email: '',
  newPassword: '',
  key: '',
 });

 const redirectToSignup = () => {
  navigation.navigate('Signin');
 };

 const successfull = () => {
  setCurrentStep(1);
  navigation.navigate('Signin');
 };

 const makeRequest = async (formData, setError) => {
  const response = await resetPassword(formData);
  if (response.type === 'success') {
   setData({ email: '', newPassword: '', key: '' });
   setCurrentStep(4);
  } else {
   setError('newPassword', { message: response.message });
  }
 };

 const handleNextStep = (newData, final = false, setError) => {
  setData({ ...data.current, ...newData });
  if (final) {
   makeRequest(data.current, setError);
   return;
  }
  setCurrentStep((prev) => prev + 1);
 };

 const handlePrevStep = (newData) => {
  setData((prev) => ({ ...prev, ...newData }));
  setCurrentStep((prev) => prev - 1);
 };

 const steps = [
  0,
  <StepOne navigation={navigation} next={handleNextStep} data={data.current} />,
  <StepTwo navigation={navigation} prev={handlePrevStep} data={data.current} />,
  <StepThree
   navigation={navigation}
   next={handleNextStep}
   data={data.current}
  />,
  <ChangedSuccessfully navigation={navigation} next={successfull} />,
 ];

 const stepUp = () => {
  if (currentStep === 1) {
   navigation.navigate('Signin');
  } else if (currentStep === 2) {
   setCurrentStep((prev) => prev - 1);
  }
 };

 useEffect(() => {
  if (params?.step === 'three') {
   setCurrentStep(3);
   setData({ ...data.current, email: params.email, key: params.key });
  }
 }, []);

 return (
  <WebSignInContainer>
   <View style={styles.container}>
    {currentStep === 4 ? null : (
     <View style={styles.stepUpperContainer}>
      <View style={styles.stepTextContainer}>
       <Text style={WebStyles.stepText}>Step {currentStep}/3</Text>
      </View>
      <View style={styles.pressableContainer}>
       <Pressable
        style={styles.pressable}
        disabled={currentStep === 3}
        onPress={stepUp}>
        <Icon
         style={[
          { fontSize: fontSize.body3, transform: [{ rotate: '90deg' }] },
          currentStep === 3 ? { color: getColor('NEUTRAL400') } : null,
         ]}
         name='arrowLeft'
        />
       </Pressable>
       <Pressable style={styles.pressable} disabled>
        <Icon
         style={{
          color: getColor('NEUTRAL400'),
          fontSize: fontSize.body3,
          transform: [{ rotate: '-90deg' }],
         }}
         name='arrowLeft'
        />
       </Pressable>
      </View>
     </View>
    )}

    <View style={styles.midContainer}>{steps[currentStep]}</View>
    {currentStep === 1 ? (
     <View>
      <Text style={[styles1.bottomText, { marginBottom: 0 }]}>
       First time user?{' '}
       <Text
        style={{ color: getColor('NORMAL_ORANGE') }}
        onPress={redirectToSignup}>
        Create Account
       </Text>
      </Text>
     </View>
    ) : null}
   </View>
  </WebSignInContainer>
 );
};

const styles = StyleSheet.create({
 container: { flex: 1, justifyContent: 'space-evenly' },
 stepUpperContainer: { flexDirection: 'row', justifyContent: 'space-between' },
 stepTextContainer: { justifyContent: 'center' },
 pressableContainer: { flexDirection: 'row', justifyContent: 'center' },
 pressable: {
  padding: 10,
  width: 24,
  height: 24,
  alignItems: 'center',
  justifyContent: 'center',
 },
 midContainer: {
  flex: 1,
  backgroundColor: 'white',
 },
});

export default WebPasswordReset;
