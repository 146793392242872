const formatPrice = (amount, currency, endDate, plan) => {
 const formatted = `${amount} / Month`;
 const getCurrencyPrefix = () => {
  switch (currency) {
   case 'USD':
    return `$`;
   case 'GBP':
    return `£`;
   case 'EUR':
    return `€`;
   default:
    return '';
  }
 };
 if (+amount) {
  return `${getCurrencyPrefix()} ${formatted}`;
 } else {
  return `Free trial until ${endDate}. After: ${plan.toBePaidAmount} / Month`;
 }
};

export { formatPrice };
