import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import Icon from 'reusable/Icon';

import { Defaults } from 'utils/defaults';
import { isAndroid } from 'utils/platform';
import { lineHeight } from 'utils/theme';

function GoToButton() {
 const navigation = useNavigation();

 const style = {
  marginLeft: 3,
  marginRight: isAndroid ? 10 : 0,
 };

 return (
  <>
   <TouchableOpacity
    hitSlop={Defaults.hitSlop}
    onPress={() => navigation.goBack()}
    style={style}>
    <Icon style={{ color: '#0F1118', ...lineHeight.h3 }} name='arrowLeft' />
   </TouchableOpacity>
  </>
 );
}

export default GoToButton;
