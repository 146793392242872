import { getColor } from 'utils/settings';

const resetStyles = {
 imgContainer: {
  marginTop: 28,
  width: 72,
  height: 72,
  backgroundColor: 'rgba(85, 145, 88, 0.12)',
  alignSelf: 'center',
  borderRadius: 16,
  justifyContent: 'center',
 },
 imgSize: { width: 57, height: 47 },
 textContainer: { alignItems: 'center', marginBottom: 24 },
 text: { color: getColor('NEUTRAL800') },
 emailText: { color: getColor('NORMAL_GREEN') },
};

export { resetStyles };
