import MapboxGL, { FillLayer, LineLayer, ShapeSource } from '@rnmapbox/maps';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import authStore from 'store/authStore';

import { getClusters } from 'services';
import { getGeofences } from 'services/mapServices';

import {
 clusterStyle,
 layerStyles,
 singlePointStyle,
} from 'components/map/styles';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import ScreenWithRightButton from 'components/reusable/ScreenWithRightButton';

import { Defaults } from 'utils/defaults';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from 'utils/dimensions';
import { storage } from 'utils/mmkvStorage';
import { PinIcon } from 'utils/svgs';
import { MAP_ACCESSTOKEN } from 'utils/uris';

MapboxGL.setAccessToken(MAP_ACCESSTOKEN);

const mapStyle = {
 Satellite: 'mapbox://styles/mapbox/satellite-v9',
 SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
};

const AddPin = ({ navigation, isOffline, route }) => {
 const mapRef = useRef();
 const bboxData = useRef({ bounds: null, zoom: 2 });
 const coords = route.params.coords;

 const [poligonsData, setPoligonsData] = useState();
 const [clusters, setclusters] = useState([]);
 const [location, setLocation] = useState();
 const [styleURL, setStyleURL] = useState(mapStyle.SatelliteStreet);

 const getRegionChangeDebouncedFunc = debounce(
  (e) => onRegionDidChange(e),
  500
 );

 useEffect(() => {
  setLocation([coords.coords.longitude, coords.coords.latitude]);
 }, []);

 const getClustersData = async () => {
  if (!isOffline) {
   const { bounds, zoom } = bboxData.current;

   if (bounds) {
    const { data } = await getClusters(
     `?zoom=${Math.round(zoom)}&westLng=${bounds[0][0]}&southLat=${
      bounds[1][1]
     }&eastLng=${bounds[1][0]}&northLat=${bounds[0][1]}&private=null${
      Defaults.userId ? '&Defaults.userId' : ''
     }`
    );

    if (data.clusters && data.clusters?.length > 0) {
     const newAr = data.clusters.map((c) => {
      if (c.geometry.type === 'Point') {
       if (!c.properties) {
        return { ...c, properties: { color: getColorForTree(c) } };
       } else {
        return {
         ...c,
         properties: {
          ...c.properties,
          color: getColorForTree(c),
         },
        };
       }
      }
     });

     setclusters(newAr);
    }
   }
  }
 };

 const getColorForTree = (tree) => {
  if (tree.custom === true && authStore.profile)
   if (tree.private === true) {
    return '#717993';
   } else if (
    tree.organizationId === authStore.profile?.enterprise ||
    tree.userId === authStore.profile._id
   ) {
    return '#559158';
   }
  return '#ed7d32';
 };

 const getPoligonsData = async () => {
  if (!isOffline) {
   const res = await getGeofences();

   if (poligonsData) {
    const isEqual = _.isEqual(res.features, poligonsData.features);

    if (!isEqual) {
     setPoligonsData(res);
    }
   } else if (!poligonsData) {
    setPoligonsData(res);
   }
  }
 };

 const onRegionDidChange = async (e) => {
  const bounds = [e.properties.bounds.ne, e.properties.bounds.sw];
  const zoom = Math.floor(e.properties.zoom);

  bboxData.current = { bounds, zoom };

  getClustersData();
  getPoligonsData();

  storage.set('centerCoordinates', JSON.stringify(e.properties.center));
 };

 const onSubmit = async () => {
  const center = await mapRef.current.getCenter();
  route.params.setCoordinates({
   coordinates: center,
  });

  navigation.goBack();
 };

 return (
  <ScreenWithRightButton
   isShow
   text='Done'
   navigation={navigation}
   onPress={onSubmit}>
   <FocusAwareStatusBar barStyle='light-content' />

   <View style={{ flex: 1 }}>
    <MapboxGL.MapView
     style={styles.container}
     ref={mapRef}
     styleURL={styleURL}
     pitchEnabled={false}
     showUserLocation
     logoEnabled={false}
     rotateEnabled={false}
     attributionEnabled={false}
     onCameraChanged={getRegionChangeDebouncedFunc}
     compassEnabled>
     <>
      {location && (
       <MapboxGL.Camera
        zoomLevel={17}
        defaultSettings={{
         zoomLevel: 17,
         centerCoordinate: route.params.coordinates || location,
        }}
       />
      )}
      <MapboxGL.UserLocation />

      <MapboxGL.ShapeSource
       shape={{ type: 'FeatureCollection', features: clusters }}
       id='symbolLocationSource'
       hitbox={{ width: 38, height: 38 }}
       clusterRadius={50}
       cluster={false}>
       <MapboxGL.SymbolLayer
        id='pointCount'
        filter={['has', 'point_count_abbreviated']}
        style={layerStyles.clusterCount}
       />

       <MapboxGL.CircleLayer
        id='clusteredPoints'
        belowLayerID='pointCount'
        filter={['has', 'point_count_abbreviated']}
        style={clusterStyle}
       />

       <MapboxGL.CircleLayer
        id='singlePoint'
        source='point'
        filter={['!', ['has', 'point_count_abbreviated']]}
        style={singlePointStyle}
       />
      </MapboxGL.ShapeSource>

      {/* Polygons */}
      {poligonsData && (
       <ShapeSource id='source' shape={poligonsData}>
        <FillLayer
         id='fill'
         layerIndex={10}
         style={{ fillColor: '#D96C313D' }}
        />
        <LineLayer
         id='line'
         layerIndex={10}
         style={{ lineColor: '#D96C31', lineWidth: 3 }}
        />
       </ShapeSource>
      )}
      {/* Polygons */}
     </>
    </MapboxGL.MapView>
   </View>

   <View style={styles.markerStyle}>
    <PinIcon />
   </View>
  </ScreenWithRightButton>
 );
};

export default AddPin;

const styles = StyleSheet.create({
 container: {
  flex: 1,
 },
 markerStyle: {
  position: 'absolute',
  left: SCREEN_WIDTH / 2 - 17,
  top: SCREEN_HEIGHT / 2 - 95,
  zIndex: 100,
 },
});
