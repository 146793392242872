import { colors } from './theme';

const Themed = {
 LIGHT_GREEN: {
  light: colors.LIGHT_GREEN,
  dark: '#eef4ee',
 },
 LIGHT_GREEN_HOVER: {
  light: colors.LIGHT_GREEN_HOVER,
  dark: '#e6efe6',
 },
 LIGHT_GREEN_ACTIVE: {
  light: colors.LIGHT_GREEN_ACTIVE,
  dark: '#caddcb',
 },
 NORMAL_GREEN: {
  light: colors.NORMAL_GREEN,
  dark: 'black',
 },
 NORMAL_GREEN_HOVER: {
  light: colors.NORMAL_GREEN_HOVER,
  dark: '#4d834f',
 },
 NORMAL_GREEN_ACTIVE: {
  light: colors.NORMAL_GREEN_ACTIVE,
  dark: '#447446',
 },
 DARK_GREEN: {
  light: colors.DARK_GREEN,
  dark: '#406d42',
 },
 DARK_GREEN_HOVER: {
  light: colors.DARK_GREEN_HOVER,
  dark: '#335735',
 },
 DARK_GREEN_ACTIVE: {
  light: colors.DARK_GREEN_ACTIVE,
  dark: '#264128',
 },
 DARKER_GREEN: {
  light: colors.DARKER_GREEN,
  dark: '#1e331f',
 },
 LIGHT_ORANGE: {
  light: colors.LIGHT_ORANGE,
  dark: '#fbf0ea',
 },
 LIGHT_ORANGE_HOVER: {
  light: colors.LIGHT_ORANGE_HOVER,
  dark: '#f9e8df',
 },
 LIGHT_ORANGE_ACTIVE: {
  light: colors.LIGHT_ORANGE_ACTIVE,
  dark: '#f3d0bc',
 },
 NORMAL_ORANGE: {
  light: colors.NORMAL_ORANGE,
  dark: '#d86628',
 },
 NORMAL_ORANGE_HOVER: {
  light: colors.NORMAL_ORANGE_HOVER,
  dark: '#c24c24',
 },
 NORMAL_ORANGE_ACTIVE: {
  light: colors.NORMAL_ORANGE_ACTIVE,
  dark: '#ad5220',
 },
 DARK_ORANGE: {
  light: colors.DARK_ORANGE,
  dark: '#a24d1e',
 },
 DARK_ORANGE_HOVER: {
  light: colors.DARK_ORANGE_HOVER,
  dark: '#823d18',
 },
 DARK_ORANGE_ACTIVE: {
  light: colors.DARK_ORANGE_ACTIVE,
  dark: '#612e12',
 },
 DARKER_ORANGE: {
  light: colors.DARKER_ORANGE,
  dark: '#4c240e',
 },
 LIGHT_RED: {
  light: colors.LIGHT_RED,
  dark: '#faece9',
 },
 LIGHT_RED_HOVER: {
  light: colors.LIGHT_RED_HOVER,
  dark: '#f8e2de',
 },
 LIGHT_RED_ACTIVE: {
  light: colors.LIGHT_RED_ACTIVE,
  dark: '#f1c4ba',
 },
 NORMAL_RED: {
  light: colors.NORMAL_RED,
  dark: '#d14020',
 },
 NORMAL_RED_HOVER: {
  light: colors.NORMAL_RED_HOVER,
  dark: '#bc3a1d',
 },
 NORMAL_RED_ACTIVE: {
  light: colors.NORMAL_RED_ACTIVE,
  dark: '#a7331a',
 },
 DARK_RED: {
  light: colors.DARK_RED,
  dark: '#9d3018',
 },
 DARK_RED_HOVER: {
  light: colors.DARK_RED_HOVER,
  dark: '#7d2613',
 },
 DARK_RED_ACTIVE: {
  light: colors.DARK_RED_ACTIVE,
  dark: '#5e1d0e',
 },
 DARKER_RED: {
  light: colors.DARKER_RED,
  dark: '#49160b',
 },
 LIGHT_YELLOW: {
  light: colors.LIGHT_YELLOW,
  dark: '#fdf8e9',
 },
 LIGHT_YELLOW_HOVER: {
  light: colors.LIGHT_YELLOW_HOVER,
  dark: '#fcf4de',
 },
 LIGHT_YELLOW_ACTIVE: {
  light: colors.LIGHT_YELLOW_ACTIVE,
  dark: '#f9e8bb',
 },
 NORMAL_YELLOW: {
  light: colors.NORMAL_YELLOW,
  dark: '#ecb423',
 },
 NORMAL_YELLOW_HOVER: {
  light: colors.NORMAL_YELLOW_HOVER,
  dark: '#d4a220',
 },
 NORMAL_YELLOW_ACTIVE: {
  light: colors.NORMAL_YELLOW_ACTIVE,
  dark: '#bd901c',
 },
 DARK_YELLOW: {
  light: colors.DARK_YELLOW,
  dark: '#b1871a',
 },
 DARK_YELLOW_HOVER: {
  light: colors.DARK_YELLOW_HOVER,
  dark: '#8e6c15',
 },
 DARK_YELLOW_ACTIVE: {
  light: colors.DARK_YELLOW_ACTIVE,
  dark: '#6a5110',
 },
 DARKER_YELLOW: {
  light: colors.DARKER_YELLOW,
  dark: '#533f0c',
 },
 LIGHT_SILVER: {
  light: colors.LIGHT_SILVER,
  dark: '#fdfdfd',
 },
 LIGHT_SILVER_HOVER: {
  light: colors.LIGHT_SILVER_HOVER,
  dark: '#fcfcfc',
 },
 LIGHT_SILVER_ACTIVE: {
  light: colors.LIGHT_SILVER_ACTIVE,
  dark: '#f9f9fa',
 },
 NORMAL_SILVER: {
  light: colors.NORMAL_SILVER,
  dark: '#ececee',
 },
 NORMAL_SILVER_HOVER: {
  light: colors.NORMAL_SILVER_HOVER,
  dark: '#d4d4d6',
 },
 NORMAL_SILVER_ACTIVE: {
  light: colors.NORMAL_SILVER_ACTIVE,
  dark: '#bdbdbe',
 },
 DARK_SILVER: {
  light: colors.DARK_SILVER,
  dark: '#b1b1b3',
 },
 DARK_SILVER_HOVER: {
  light: colors.DARK_SILVER_HOVER,
  dark: '#8e8e8f',
 },
 DARK_SILVER_ACTIVE: {
  light: colors.DARK_SILVER_ACTIVE,
  dark: '#6a6a6b',
 },
 DARKER_SILVER: {
  light: colors.DARKER_SILVER,
  dark: '#535353',
 },
 NEUTRAL100: {
  light: colors.NEUTRAL100,
  dark: colors.NEUTRAL100,
 },
 WHITE: { light: colors.WHITE, dark: colors.WHITE },
 NEUTRAL200: { light: colors.NEUTRAL200, dark: colors.NEUTRAL200 },
 NEUTRAL300: { light: colors.NEUTRAL300, dark: colors.NEUTRAL300 },
 NEUTRAL400: { light: colors.NEUTRAL400, dark: colors.NEUTRAL400 },
 NEUTRAL500: { light: colors.NEUTRAL500, dark: colors.NEUTRAL500 },
 NEUTRAL600: { light: colors.NEUTRAL600, dark: colors.NEUTRAL600 },
 NEUTRAL700: { light: colors.NEUTRAL700, dark: colors.NEUTRAL700 },
 NEUTRAL800: { light: colors.NEUTRAL800, dark: colors.NEUTRAL800 },
 NEUTRAL900: { light: colors.NEUTRAL900, dark: colors.NEUTRAL900 },
 NEUTRAL000: { light: colors.NEUTRAL000, dark: colors.NEUTRAL000 },
 error: { light: colors.error, dark: colors.error },
};

export default Themed;
