import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Text from './Text';

import { getColor } from 'utils/settings';

export default function Radio({ onPress, selected, children }) {
 return (
  <View style={styles.radioButtonContainer}>
   <TouchableOpacity
    onPress={onPress}
    style={[
     styles.radioButton,
     selected
      ? {
         backgroundColor: getColor('NORMAL_GREEN'),
         borderColor: getColor('NORMAL_GREEN'),
        }
      : { backgroundColor: '#fff' },
    ]}>
    {selected ? <View style={styles.radioButtonIcon} /> : null}
   </TouchableOpacity>

   <TouchableOpacity onPress={onPress}>
    <Text style={styles.radioButtonText}>{children}</Text>
   </TouchableOpacity>
  </View>
 );
}

const styles = StyleSheet.create({
 radioButtonContainer: {
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 5,
 },
 radioButton: {
  height: 20,
  width: 20,
  borderRadius: 10,
  borderWidth: 1,
  borderColor: '#200E32',
  alignItems: 'center',
  justifyContent: 'center',
 },
 radioButtonIcon: {
  height: 7,
  width: 7,
  borderRadius: 7,
  backgroundColor: '#fff',
 },
 radioButtonText: {
  fontSize: 14,
  marginLeft: 12,
  color: getColor('NEUTRAL700'),
 },
});
