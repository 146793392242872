import React from 'react';
import { StyleSheet, View } from 'react-native';

import { getColor } from 'utils/settings';

const Hr = ({ customStyles = {} }) => {
 return <View style={[styles.HrStyle, customStyles]} />;
};

export default Hr;

const styles = StyleSheet.create({
 HrStyle: {
  backgroundColor: getColor('NEUTRAL300'),
  height: 1,
  borderRadius: 5,
  width: '100%',
 },
});
