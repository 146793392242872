export const geoFenceDefaultValues = (
 editMode,
 geometry,
 properties,
 avatar
) => {
 if (editMode) {
  return {
   geofence: geometry[0],
   geofenceName: properties.geofenceName,
   info: properties.info,
   website: properties.website,
   avatar,
  };
 } else {
  return {
   geofence: [],
   geofenceName: '',
   info: '',
   website: '',
   avatar: [],
  };
 }
};
