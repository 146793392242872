import axios from 'axios';

import Ajax from '../helpers/ajax';

import {
 SIGNIN,
 SIGNUP,
 VERIFY,
 GET_USER_ID,
 SIGNIN_WITH_FB,
 SIGNIN_WITH_GOOGLE,
 SEND_EMAIL_FOR_RESET_PASSWORD,
 RESET_PASSWORD,
 VERIFY_PASSWORD_CODE,
 RESET_PASSWORD2,
 CHANGEPASSWORD,
 RESEND_OTP,
 UNREGISTEREDUSERINENTERPRISE,
 SIGNUPBYGMAIL,
 SIGNINBYGMAIL,
 SIGNINBYFB,
 SIGNUPBYFB,
 SIGNINBYAPPLE,
 SIGNUPBYAPPLE,
 DELETEUSER,
} from 'utils/uris';

const deleteUser = async (token) => {
 try {
  const res = await axios.delete(DELETEUSER, {
   headers: {
    Authorization: 'Bearer ' + token,
   },
  });

  return res;
 } catch (error) {
  return error;
 }
};
const signInUser = async (email, password) => {
 try {
  return await Ajax.post(SIGNIN, {
   email,
   password,
  });
 } catch (error) {
  return error;
 }
};

const signInUserByGmail = async (email) => {
 try {
  return await Ajax.post(SIGNINBYGMAIL, {
   email,
  });
 } catch (error) {
  return error;
 }
};

const signUpUserByGmail = async (firstName = '', lastName = '', email = '') => {
 try {
  return await Ajax.post(SIGNUPBYGMAIL, {
   firstName,
   lastName,
   email,
  });
 } catch (error) {
  return error;
 }
};

const signInUserByFb = async (email = '') => {
 try {
  return await Ajax.post(SIGNINBYFB, {
   email,
  });
 } catch (error) {
  return error;
 }
};

const signUpUserByFb = async (firstName = '', lastName = '', email = '') => {
 try {
  const res = await Ajax.post(SIGNUPBYFB, {
   firstName,
   lastName,
   email,
  });

  return res;
 } catch (error) {
  return error;
 }
};

const signInUserByApple = async (email = '') => {
 try {
  return await Ajax.post(SIGNINBYAPPLE, {
   email,
  });
 } catch (error) {
  return error;
 }
};

const signUpUserByApple = async (firstName = '', lastName = '', email = '') => {
 try {
  return await Ajax.post(SIGNUPBYAPPLE, {
   firstName,
   lastName,
   email,
  });
 } catch (error) {
  return error;
 }
};

const signUpUser = async (value) => {
 try {
  return await Ajax.post(SIGNUP, value);
 } catch (error) {
  return error;
 }
};

const verifyUser = async (values) => {
 try {
  return await Ajax.post(VERIFY, values);
 } catch (error) {
  return error;
 }
};

const getUserId = async (email) => {
 try {
  return await Ajax.get(`${GET_USER_ID}?email=${email}`);
 } catch (error) {
  return error;
 }
};

const signWithFacebook = async () => {
 try {
  return await Ajax.get(SIGNIN_WITH_FB);
 } catch (error) {
  return error;
 }
};

const signWithGoogle = async () => {
 try {
  return await Ajax.get(SIGNIN_WITH_GOOGLE);
 } catch (error) {
  return error;
 }
};

const sendEmailForResetPassword = async (values) => {
 try {
  return await Ajax.post(SEND_EMAIL_FOR_RESET_PASSWORD, {
   email: values.email,
  });
 } catch (error) {
  return error;
 }
};

const resetPassword = async (values) => {
 try {
  return await Ajax.put(RESET_PASSWORD, {
   email: values.email,
   key: values.key,
   password: values.newPassword,
  });
 } catch (error) {
  return error;
 }
};

const resetPasswordForMobile = async (email) => {
 try {
  return await Ajax.post(RESET_PASSWORD2, { email });
 } catch (error) {
  return error;
 }
};

const verifyResetPasswordCode = async (data) => {
 try {
  return await Ajax.post(VERIFY_PASSWORD_CODE, data);
 } catch (error) {
  return error;
 }
};

const changePassword = async (data) => {
 try {
  return await Ajax.put(CHANGEPASSWORD, data);
 } catch (error) {
  return error;
 }
};

const sendOtp = async (email) => {
 try {
  return await Ajax.put(RESEND_OTP, { email });
 } catch (error) {
  return error;
 }
};

const registerNewUserInEnterprise = async (enterpriseId, email, password) => {
 try {
  return await Ajax.post(UNREGISTEREDUSERINENTERPRISE, {
   enterpriseId,
   email,
   password,
  });
 } catch (error) {
  return error;
 }
};

export {
 sendEmailForResetPassword,
 getUserId,
 deleteUser,
 signInUser,
 signInUserByFb,
 signUpUserByFb,
 signInUserByApple,
 signUpUserByApple,
 signInUserByGmail,
 signUpUserByGmail,
 signUpUser,
 verifyUser,
 signWithGoogle,
 signWithFacebook,
 resetPassword,
 resetPasswordForMobile,
 verifyResetPasswordCode,
 changePassword,
 sendOtp,
 registerNewUserInEnterprise,
};
