import dayjs from 'dayjs';
import useProfile from 'hooks/useProfile';
import React, { useEffect, useState } from 'react';
import { Linking, Pressable, StyleSheet, Text, View } from 'react-native';

import Dash from './Dash';
import Pagination from './Pagination';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { getTransactionHistory } from 'services/enterpriseServices';

import Icon from 'components/reusable/Icon';

import { getColor } from 'utils/settings';
import { WebDownload } from 'utils/svgs';

const PlanHistory = () => {
 const [transactionHistory, setTransactionHistory] = useState({});
 const [currentPage, setCurrentPage] = useState(1);
 const [loaded, setLoaded] = useState(false);

 const { profile } = useProfile();

 const userId = profile?._id;

 const postsPerPage = 3;
 const totalCount = transactionHistory?.recordCount;

 const totalPageCount = Math.ceil(totalCount / postsPerPage);

 const getHistory = async () => {
  const response = await getTransactionHistory(
   userId,
   currentPage,
   postsPerPage
  );
  if (response.status === 'success') {
   setTransactionHistory(response);
   setLoaded(true);
  }
 };

 useEffect(() => {
  getHistory();
 }, [currentPage]);

 return (
  <>
   <Text style={[WebStyles.currentPlan, { marginBottom: 24 }]}>History</Text>
   <View style={styles.container}>
    {loaded ? (
     <>
      <View style={styles.firstColumn}>
       <Text style={[WebStyles.currentPlan700]}>Plan Details</Text>
      </View>
      <Dash />
      {transactionHistory.transactions.map((item, index) => (
       <View key={index}>
        <View style={[styles.secondColumn]}>
         <View style={styles.secondRow}>
          <Text style={WebStyles.currentPlanTitle}>Plan Name</Text>
          <Text style={WebStyles.currentPlan700}>{item.plan}</Text>
         </View>
         <View style={styles.secondRow}>
          <Text style={WebStyles.currentPlanTitle}>Cost</Text>
          <Text style={WebStyles.currentPlan700}>
           {item.amount?.slice(-3) === 'USD'
            ? '$'
            : item.amount?.slice(-3) === 'GBP'
            ? '£'
            : item.amount?.slice(-3) === 'EUR'
            ? '€'
            : null}{' '}
           {item.amount?.slice(0, -4)} / Month
          </Text>
         </View>
         <View style={styles.secondRow}>
          <Text style={WebStyles.currentPlanTitle}>Start Date</Text>
          <Text style={WebStyles.currentPlan700}>
           {dayjs(item.subscribedAt).format('MMM DD. YYYY')}
          </Text>
         </View>
         <View style={styles.secondRow}>
          <Text style={WebStyles.currentPlanTitle}>Renewal Date</Text>
          <Text style={WebStyles.currentPlan700}>
           {dayjs(item.subscriptionEndsAt).format('MMM DD. YYYY')}
          </Text>
         </View>
         <View style={styles.secondRow}>
          <Text style={WebStyles.currentPlanTitle}>Download</Text>
          <Pressable
           style={{ flexDirection: 'row' }}
           onPress={() => {
            Linking.openURL(item.invoice);
           }}>
           <Text
            style={[
             WebStyles.currentPlan700,
             { color: getColor('NORMAL_GREEN') },
             { marginRight: 8, textAlign: 'center' },
            ]}>
            PDF
           </Text>
           <WebDownload />
          </Pressable>
         </View>
        </View>
        <Dash />
       </View>
      ))}
      <Pagination
       data={transactionHistory}
       currentPage={currentPage}
       pageSize={postsPerPage}
       totalCount={totalPageCount}
       onPageChange={(page) => setCurrentPage(page)}
      />
     </>
    ) : null}
   </View>
  </>
 );
};

const styles = StyleSheet.create({
 container: {
  backgroundColor: getColor('NEUTRAL200'),
  marginRight: 24,
  marginBottom: 24,
  borderRadius: 16,
  padding: 32,
 },
 secondColumn: {
  height: 52,
  flexDirection: 'row',
  justifyContent: 'space-between',
 },
 secondRow: {
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
 },
});

export default PlanHistory;
