import React from 'react';
import { useForm } from 'react-hook-form';
import { View, Text } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import { webChangePasswordSchema } from 'components/validations/schema';

import { getColor } from 'utils/settings';

const StepThree = ({ data, next }) => {
 const { control, handleSubmit, errors, isSubmitting, setError } = useForm(
  webChangePasswordSchema,
  data
 );

 const onSubmit = (values) => {
  next(values, true, setError);
 };

 return (
  <>
   <Text style={WebStyles.WebHeadText}>Reset Password</Text>
   <Text style={WebStyles.text}>
    Your new password must be different from previously used passwords.
   </Text>

   <View
    style={{
     marginBottom: errors?.newPassword ? 4 : 28,
     marginTop: 24,
    }}>
    <ControllerInput
     withEyeIcon
     secureTextEntry
     name='newPassword'
     control={control}
     iconName='lockOn'
     placeholder='New Password'
     error={errors?.newPassword?.message}
     inputStyles={{ marginBottom: 10 }}
    />
   </View>

   <Button
    title='Reset Password'
    disabled={isSubmitting}
    bgColor={getColor('NORMAL_GREEN')}
    onPress={handleSubmit(onSubmit)}
   />
  </>
 );
};

export default StepThree;
