import * as React from 'react';
import {
 Dimensions,
 Image,
 StyleSheet,
 TouchableOpacity,
 View,
} from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import Carousel from 'react-native-reanimated-carousel';

import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';
import { ArrowLeft, ArrowRight } from 'utils/svgs';

function Index({ images, isEmbededMap }) {
 const width = Dimensions.get('window').width;
 const carRef = React.useRef();
 const [currentIndex, setCurrentIndex] = React.useState(0);

 const defaultImage = [
  {
   customImage: true,
   uri: IMAGES.tremapWebSigninImg,
  },
 ];

 let imgWidth = isEmbededMap && window.innerWidth <= 1000 ? 400 : 856;
 let imgHeight = isEmbededMap && window.innerWidth <= 1000 ? 400 : 500;

 return (
  <>
   <GestureHandlerRootView style={{ flex: 1 }}>
    <Carousel
     ref={carRef}
     mode='parallax'
     vertical={false}
     loop={false}
     width={isEmbededMap && window.innerWidth <= 1000 ? width : width - 200}
     height={isEmbededMap && window.innerHeight <= 700 ? 400 : 500}
     autoPlay={false}
     modeConfig={{
      parallaxScrollingScale: 1,
      parallaxAdjacentItemScale: 1,
     }}
     data={images && images.length > 0 ? images : defaultImage}
     scrollAnimationDuration={1000}
     onSnapToItem={(index) => setCurrentIndex(index)}
     renderItem={({ item }) => {
      return (
       <Image
        style={[
         styles.imgContainer,
         item.customImage
          ? { resizeMode: 'contain', width: imgWidth, height: imgHeight }
          : { width: imgWidth, height: imgHeight, resizeMode: 'cover' },
        ]}
        source={{ uri: item.uri || item }}
       />
      );
     }}
    />
    {images.length > 0 ? (
     <View style={styles.paginationCont}>
      {currentIndex > 0 && (
       <TouchableOpacity
        style={{ marginRight: 4 }}
        onPress={() => carRef?.current?.prev({ index: currentIndex - 1 })}>
        <ArrowLeft />
       </TouchableOpacity>
      )}
      {images.map((image, index) => {
       return (
        <React.Fragment key={index}>
         <View style={{ transform: [{ rotateX: '45deg' }] }}>
          <TouchableOpacity
           style={{
            width: currentIndex === index ? 24 : 6,
            height: 6,
            backgroundColor:
             currentIndex === index
              ? getColor('NORMAL_GREEN_HOVER')
              : getColor('NEUTRAL300'),
            borderRadius: 12,
            marginRight: 4,
           }}
           onPress={() => carRef.current.scrollTo({ index, animated: true })}
          />
         </View>
        </React.Fragment>
       );
      })}
      {currentIndex !== images.length - 1 && (
       <TouchableOpacity
        onPress={() => carRef?.current?.next({ index: currentIndex + 1 })}>
        <ArrowRight />
       </TouchableOpacity>
      )}
     </View>
    ) : null}
   </GestureHandlerRootView>
  </>
 );
}

const styles = StyleSheet.create({
 imgContainer: {
  alignSelf: 'center',
  borderRadius: 12,
 },
 paginationCont: {
  alignSelf: 'center',
  flexDirection: 'row',
  marginTop: 20,
  alignItems: 'center',
  justifyContent: 'center',
 },
});

export default Index;
