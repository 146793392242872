import React, { useEffect } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

const ScreenWithRightButton = ({
 text,
 children,
 isShow,
 disabled,
 onPress,
 userData,
 navigation,
}) => {
 useEffect(() => {
  if (isShow) {
   navigation.setOptions({
    headerRight: () => (
     <TouchableOpacity
      hitSlop={Defaults.hitSlop}
      disabled={disabled}
      onPress={onPress}>
      <Text style={styles.buttonStyle} disabled={disabled}>
       {text}
      </Text>
     </TouchableOpacity>
    ),
   });
  }
 }, [navigation, userData]);

 return children;
};

export default ScreenWithRightButton;

ScreenWithRightButton.defaultProps = {
 isShow: false,
 disabled: false,
};

const styles = StyleSheet.create({
 buttonStyle: {
  color: getColor('NORMAL_GREEN'),
  fontFamily: fonts.MontserratBold,
  letterSpacing: 0.5,
 },
});
