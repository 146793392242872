import { debounce } from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

import { changePassword } from 'services/index';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import ControllerInput from 'components/reusable/ControllerInput';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';
import Toast from 'components/reusable/Toast';
import { changePasswordSchema } from 'components/validations/schema';

import Button from 'reusable/Button';
import ScreenContainer from 'reusable/ScreenContainer';
import Text from 'reusable/Text';

import { Defaults } from 'utils/defaults';
import { isAndroid } from 'utils/platform';
import { getColor } from 'utils/settings';

const ChangePassword = ({ navigation }) => {
 const store = useSnapshot(authStore);

 const resolver = useYupValidationResolver(changePasswordSchema);
 const func = debounce(
  (res) => Defaults.toast.show(<Toast message={res} />),
  100
 );

 const {
  control,
  setError,
  handleSubmit,
  formState: { errors, isSubmitting },
 } = useForm({
  resolver,
  defaultValues: {
   oldPassword: '',
   newPassword: '',
  },
 });

 const onSubmit = async (values) => {
  const obj = {
   userId: store.profile._id,
   oldPassword: values.oldPassword,
   newPassword: values.newPassword,
  };

  const response = await changePassword(obj);

  if (response.type === 'success') {
   func(response.message);
   navigation.goBack();
  } else {
   setError('oldPassword', { message: response.message });
   setError('newPassword', { message: response.message });
  }
 };

 return (
  <KeyboardAwareScrollViewComponent>
   <FocusAwareStatusBar
    barStyle={isAndroid ? 'dark-content' : 'light-content'}
   />
   <ScreenContainer>
    <Text style={styles2.centerText}>Enter new and old Passwords</Text>

    <View style={{ marginBottom: 24 }}>
     <ControllerInput
      withEyeIcon
      secureTextEntry
      name='oldPassword'
      control={control}
      withIcon={false}
      placeholder='Old Password'
      error={errors.oldPassword?.message}
      inputStyles={{ marginBottom: 10 }}
     />
    </View>

    <View style={{ marginBottom: 32 }}>
     <ControllerInput
      withEyeIcon
      secureTextEntry
      name='newPassword'
      control={control}
      withIcon={false}
      placeholder='New Password'
      error={errors.newPassword?.message}
      inputStyles={{ marginBottom: 10 }}
     />
    </View>

    <View
     style={{
      marginTop: 'auto',
      marginBottom: 30,
     }}>
     <Button
      title='Confirm'
      disabled={isSubmitting}
      bgColor={getColor('NORMAL_GREEN')}
      onPress={handleSubmit(onSubmit)}
     />
    </View>
    <KeyboardPaddingView />
   </ScreenContainer>
  </KeyboardAwareScrollViewComponent>
 );
};

export default ChangePassword;

const styles2 = StyleSheet.create({
 centerText: {
  marginBottom: 24,
  marginTop: 40,
  textAlign: 'center',
 },
 container: {
  flex: 1,
 },
 contentContainerStyle: {
  height: '100%',
 },
});
