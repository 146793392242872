import React from 'react';
import { SquircleView } from 'react-native-figma-squircle';

import { getColor } from 'utils/settings';

const SquircleContainer = ({
 children,
 cornerRadius,
 disabled,
 bgColor,
 strokeWidth,
 strokeColor,
 style,
}) => {
 const squircleParams = {
  cornerRadius,
  cornerSmoothing: 0.7,
  strokeColor,
  strokeWidth,
  fillColor: disabled ? getColor('NEUTRAL300') : bgColor,
 };

 return (
  <SquircleView style={style} squircleParams={squircleParams}>
   {children}
  </SquircleView>
 );
};

SquircleContainer.defaultProps = {
 cornerRadius: 12,
 strokeWidth: 0,
 strokeColor: 'transparent',
 disabled: false,
 style: {},
};

export default SquircleContainer;
