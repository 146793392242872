import axios from 'axios';
import React, { useState } from 'react';
import { Image, Pressable, StyleSheet } from 'react-native';
import { ActivityIndicator, View } from 'react-native-web';

import { pickImageResult } from './PickAndUploadImage';

import { uploadImage } from 'services/userService';

import Icon from 'reusable/Icon';
import Text from 'reusable/Text';

import { getColor } from 'utils/settings';
import { fonts, fontSize, lineHeight } from 'utils/theme';

const UploadImageWeb = ({
 limit,
 render,
 images,
 setImages,
 onDeletePhoto,
 withAvatar = false,
 type,
 imgType = 'OTHER',
}) => {
 const [loading, setLoading] = useState(false);
 const [isError, setIsError] = useState(false);

 const uploadingImage = async (blob) => {
  const response = await uploadImage(blob, type, true);

  if (response.type === 'success') {
   setImages(response.imageUrl);
   setLoading(false);
  }
 };

 function bytesToMB(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2);
 }

 const pickImage = async () => {
  const result = await pickImageResult();

  if (!result.canceled) {
   const res = await axios.get(result.assets[0].uri, { responseType: 'blob' });

   const { width, height } = result.assets[0];

   const megabytes = bytesToMB(res.data.size);

   if (megabytes > 5) {
    setIsError('Image file size must be less than 5MB');
   } else if (
    imgType === 'PROFILE' &&
    parseFloat(width) !== 400 &&
    parseFloat(height) !== 400
   ) {
    setIsError(
     `Maximum image dimensions: 400 x 400 pixels. Current dimensions: ${parseFloat(
      width
     )} x ${parseFloat(height)} pixels`
    );
   } else if (megabytes <= 5) {
    if (isError) {
     setIsError('');
    }

    setLoading(true);
    uploadingImage(res.data);
   }
  }
 };

 if (!withAvatar) {
  return (
   <>
    {images?.map((img, index) => {
     return (
      <View key={index} style={styles.container}>
       <Pressable
        onPress={() => onDeletePhoto(img.uri ? img.uri : img)}
        style={{ zIndex: 111 }}>
        <Icon name='clear' style={styles.closeIcon} />
       </Pressable>
       <Image
        source={{ uri: img.uri ? img.uri : img }}
        style={styles.imgStyle}
       />
      </View>
     );
    })}

    {loading ? (
     <View style={styles.loadingCont}>
      <ActivityIndicator size='large' color='#559158' />
     </View>
    ) : null}

    {images.length < limit ? (
     <View style={{ flexDirection: 'column' }}>
      <Pressable
       disabled={loading === true}
       style={[styles.addPhotoButton]}
       onPress={pickImage}>
       <Icon name='plus2' animated style={styles.iconStyle} />

       <Text
        color='NORMAL_GREEN'
        fontType='MontserratSemiBold'
        fontSize={lineHeight.body3}>
        Add new
       </Text>
      </Pressable>
      {isError && (
       <Text style={{ marginTop: 6, color: '#E45839', width: 400 }}>
        {isError}
       </Text>
      )}
     </View>
    ) : null}
   </>
  );
 }

 return render(pickImage);
};

export default UploadImageWeb;

const styles = StyleSheet.create({
 loadingCont: {
  width: 100,
  height: 100,
  marginRight: 12,
  alignItems: 'center',
  justifyContent: 'center',
 },
 iconStyle: {
  fontSize: 22,
  marginBottom: 12,
  color: getColor('NORMAL_GREEN'),
 },
 container: { width: 100, height: 100, marginRight: 12, borderRadius: 12 },
 desc: {
  marginBottom: 16,
 },
 imgStyle: {
  width: 100,
  height: 100,
  borderRadius: 12,
  resizeMode: 'cover',
 },
 addPhotoButton: {
  width: 100,
  height: 100,
  borderRadius: 12,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: getColor('LIGHT_GREEN_HOVER'),
 },
 buttonStyle: {
  color: getColor('NORMAL_GREEN'),
  fontFamily: fonts.MontserratBold,
  letterSpacing: 0.5,
  marginRight: 12,
 },
 labelStyle: {
  color: getColor('NEUTRAL600'),
 },
 closeIcon: {
  position: 'absolute',
  right: -8,
  top: -9,
  color: getColor('NEUTRAL500'),
  fontSize: fontSize.button1.fontSize,
 },
 fieldContainer: {
  marginBottom: 16,
 },
 infoButton: {
  width: 36,
  height: 36,
  justifyContent: 'center',
  alignItems: 'center',
 },
});
