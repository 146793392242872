import * as Yup from 'yup';

import { isWeb } from 'utils/platform';

const SignupSchema = Yup.object().shape({
 email: Yup.string()
  .email('Enter the email address in the correct form')
  .required('Fill out the field'),
 password: Yup.string().required('Fill out the field'),
});

const SigninSchema = Yup.object().shape({
 email: Yup.string()
  .email('Enter the email address in the correct form')
  .required('Fill out the field'),
 password: Yup.string()
  .required('Fill out the field')
  .min(8, 'Minimum 8 digits.'),
});

const VerifySchema = Yup.object().shape({
 code: Yup.string()
  .required('')
  .min(1, '')
  .min(4, 'Invalid Code. Enter the Code Again'),
});

const changeEmailSchema = Yup.object().shape({
 email: Yup.string()
  .email('Enter the email address in the correct form')
  .required('Fill out the field'),
});

const changePasswordSchema = Yup.object().shape({
 oldPassword: Yup.string()
  .required('Fill out the field')
  .min(8, 'Minimum 8 digits.'),
 newPassword: Yup.string()
  .required('Fill out the field')
  .min(8, 'Minimum 8 digits.'),
});

const webChangePasswordSchema = Yup.object().shape({
 newPassword: Yup.string()
  .required('Fill out the field')
  .min(8, 'Minimum 8 digits.'),
});

const organisationRegisterSchema = Yup.object().shape({
 name: Yup.string()
  .required('Fill out the field')
  .min(2, 'At least 2 characters')
  .max(30, 'Maximum 30 characters'),
 type: Yup.string().required('Fill out the field'),
});

const userPermissionSchema = Yup.object().shape({
 email: Yup.string()
  .email('Enter the email address in the correct form')
  .required('Email required'),
 type: Yup.string().required('Type required'),
});

const EditTreeInfoSchema = Yup.object().shape({
 genus: Yup.string().required('Fill out the field'),
 height: Yup.string().required('Fill out the field'),
 Species: Yup.string().required('Fill out the field'),
 CommonName: Yup.string().required('Fill out the field'),
});

const AddTreeInfoSchema = Yup.object().shape({
 commonName: Yup.string()
  .required('Fill out the field')
  .min(2, 'At least 2 characters')
  .max(45, 'Maximum 45 characters'),
 genus: isWeb
  ? Yup.string().required('Fill out the field')
  : Yup.object()
     .shape({ genus: Yup.string().required('Fill out the field') })
     .required('Fill out the field'),
 species: Yup.string().required('Fill out the field'),
 coordinates: Yup.array().min(1, 'Fill out the field'),
});

const AddGeofenceSchema = Yup.object().shape({
 geofence: Yup.array().min(4, 'Fill out the field'),
 geofenceName: Yup.string().required('Fill out the field'),
 info: Yup.string().required('Fill out the field'),
 website: Yup.string().url(),
});

const EnterpriseName = Yup.object().shape({
 enterpriseName: Yup.string()
  .required('Fill out the field')
  .min(2, 'At least 2 characters')
  .max(30, 'Maximum 30 characters'),
});

export {
 SignupSchema,
 SigninSchema,
 VerifySchema,
 changeEmailSchema,
 AddTreeInfoSchema,
 EditTreeInfoSchema,
 changePasswordSchema,
 webChangePasswordSchema,
 organisationRegisterSchema,
 userPermissionSchema,
 AddGeofenceSchema,
 EnterpriseName,
};
