import { proxy } from 'valtio';

const treeFilter = proxy({
 filteredTrees: '',
 treeCount: '',
 enterpriseTreeCount: {},
 selectedTrees: [],
 pin: [],
});

export default treeFilter;
