import dayjs from 'dayjs';
import useProfile from 'hooks/useProfile';
import React, { useEffect, useState } from 'react';
import { Linking, Pressable, StyleSheet, Text, View } from 'react-native';

import Dash from './Dash';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { getCurrentPlan } from 'services/enterpriseServices';

import Icon from 'components/reusable/Icon';

import { formatPrice } from 'utils/formatPrice';
import { getColor } from 'utils/settings';

const TickIcon = () => {
 return (
  <Icon
   name='tickCircle'
   style={{ color: getColor('NORMAL_GREEN'), fontSize: 21.5, marginRight: 13 }}
  />
 );
};

const CurrentPlan = () => {
 const { profile } = useProfile();

 const [plan, setPlan] = useState({});
 const [loaded, setLoaded] = useState(false);

 const currency = plan?.amount?.slice(-3);
 const amount = plan?.amount?.slice(0, -4);

 const userId = profile._id;

 const getPlan = async () => {
  const response = await getCurrentPlan(userId);
  if (response.type === 'success') {
   setPlan(response.subscription);
   setLoaded(true);
  }
 };

 const startDate = dayjs(plan.subscribedAt).format('MMM DD. YYYY');
 const endDate = dayjs(plan.subscriptionEndsAt).format('MMM DD. YYYY');

 useEffect(() => {
  getPlan();
 }, []);

 return (
  <>
   <Text style={[WebStyles.currentPlan, { marginBottom: 24 }]}>
    Current Plan
   </Text>
   <View style={styles.container}>
    {loaded ? (
     <>
      <View style={styles.firstColumn}>
       <Text style={WebStyles.currentPlan700}>Plan Details</Text>
       <Pressable
        onPress={() => {
         Linking.openURL(plan?.billingPortalUrl);
        }}>
        <Text
         style={[
          WebStyles.currentPlan700,
          { color: getColor('NORMAL_GREEN') },
         ]}>
         Billing Options
        </Text>
       </Pressable>
      </View>
      <Dash />
      <View style={styles.secondColumn}>
       <View style={styles.secondRow}>
        <Text style={WebStyles.currentPlanTitle}>Payment Plan Tier</Text>
        <Text style={WebStyles.currentPlan700}>{plan.plan}</Text>
       </View>
       <View style={styles.secondRow}>
        <Text style={WebStyles.currentPlanTitle}>Cost</Text>
        <Text style={WebStyles.currentPlan700}>
         {formatPrice(amount, currency, endDate, plan)}
        </Text>
       </View>
       <View style={styles.secondRow}>
        <Text style={WebStyles.currentPlanTitle}>Start Date</Text>
        <Text style={WebStyles.currentPlan700}>{startDate}</Text>
       </View>
       <View style={styles.secondRow}>
        <Text style={WebStyles.currentPlanTitle}>
         {plan?.canceled === true && 'Cancel Date'}
         {plan?.canceled === false && 'Renewal Date'}
        </Text>
        <Text style={WebStyles.currentPlan700}>{endDate}</Text>
       </View>
      </View>
      <Dash />
      <View style={styles.thirdColumn}>
       <Text style={WebStyles.currentPlan700}>Included in plan</Text>
       <View style={styles.thirdRow}>
        <View style={styles.thirdColumnView}>
         <TickIcon />
         <Text style={WebStyles.currentPlanInfo}>
          {plan?.featureList?.feature1}
         </Text>
        </View>
        <View style={styles.thirdColumnView}>
         <TickIcon />
         <Text style={WebStyles.currentPlanInfo}>
          {plan?.featureList?.feature2}
         </Text>
        </View>
        <View style={styles.thirdColumnView}>
         <TickIcon />
         <Text style={WebStyles.currentPlanInfo}>
          {plan?.featureList?.feature3}
         </Text>
        </View>
        <View style={styles.thirdColumnView}>
         <TickIcon />
         <Text style={WebStyles.currentPlanInfo}>
          {plan?.featureList?.feature4}
         </Text>
        </View>
       </View>
      </View>
     </>
    ) : null}
   </View>
  </>
 );
};

export default CurrentPlan;

const styles = StyleSheet.create({
 container: {
  backgroundColor: '#F4F5F8',
  marginRight: 24,
  marginBottom: 24,
  borderRadius: 16,
  padding: 32,
 },
 firstColumn: {
  height: 22,
  justifyContent: 'space-between',
  flexDirection: 'row',
 },
 secondColumn: {
  height: 52,
  flexDirection: 'row',
  justifyContent: 'space-between',
 },
 secondRow: {
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
 },
 thirdColumn: {
  height: 58,
  justifyContent: 'space-between',
  flexDirection: 'column',
 },
 thirdRow: {
  flexDirection: 'row',
  flexWrap: 'wrap',
 },
 thirdColumnView: {
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: 24,
 },
});
