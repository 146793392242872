import { useMemo } from 'react';

export const DOTS = '...';

const range = (start, end) => {
 const length = end - start + 1;
 return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
 totalCount,
 pageSize,
 siblingCount = 1,
 currentPage,
}) => {
 const paginationRange = useMemo(() => {
  // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
  const totalPageNumbers = siblingCount + 5;

  /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
  if (totalPageNumbers >= totalCount) {
   return range(1, totalCount);
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);

  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalCount);

  /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalCount - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalCount;

  if (!shouldShowLeftDots && shouldShowRightDots) {
   const leftItemCount = 3 + 2 * siblingCount;
   const leftRange = range(1, leftItemCount);

   return [...leftRange, DOTS, totalCount];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
   const rightItemCount = 3 + 2 * siblingCount;
   const rightRange = range(totalCount - rightItemCount + 1, totalCount);
   return [firstPageIndex, DOTS, ...rightRange];
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
   const middleRange = range(leftSiblingIndex, rightSiblingIndex);
   return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
  }
 }, [totalCount, pageSize, siblingCount, currentPage]);

 return paginationRange;
};
