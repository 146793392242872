import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

const data = [
 {
  title: 'Users with Viewer permissions can:',
  text1: "See your organisation's trees (both private and public) on the map",
  text2:
   "See tree info screens for any of the organisation's trees (both private and public)",
  text3: 'Download reports in CSV file in the Collection Management tab',
 },
 {
  title: 'Users with Editor permissions can:',
  text1: 'Do everything a Viewer can do',
  text2:
   "+ Edit information in the tree info screens for all of your organisation's trees",
  text3: '+ Add, edit and delete trees for your organisation',
 },
 {
  title: 'Users with Admin permissions can:',
  text1: 'Do everything a Viewer and Editor can do',
  text2: '+ Add, edit and delete geo-fences',
  text3: '+ Edit geo-fence pop-up info',
  text4: '+ Manage users',
  text5: '+ Manage billing',
 },
];

const PermissionsInfo = () => {
 return (
  <View style={styles.container}>
   {data.map((item, index) => (
    <View key={index}>
     <Text style={WebStyles.permissionsTitle}>{item.title}</Text>
     <View>
      <Text style={WebStyles.permissionsText}>{item.text1}</Text>
      <Text style={WebStyles.permissionsText}>{item.text2}</Text>
      <Text style={WebStyles.permissionsText}>{item.text3}</Text>
      <Text style={WebStyles.permissionsText}>{item.text4}</Text>
      <Text style={WebStyles.permissionsText}>{item.text5}</Text>
     </View>
    </View>
   ))}
  </View>
 );
};

const styles = StyleSheet.create({
 container: { flex: 1, paddingHorizontal: 40, justifyContent: 'flex-start' },
});

export default PermissionsInfo;
