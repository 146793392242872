import { Link } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import Button from 'components/reusable/Button';

import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';

const MaximumUsersExceeded = () => {
 return (
  <View style={styles.container}>
   <View style={styles.imgcontainer}>
    <Image style={styles.imgSize} source={IMAGES.rectangleerror} />
   </View>

   <Text style={[WebStyles.currentPlan, { marginTop: 35 }]}>
    Oops! You've reached your maximum number of users!
   </Text>
   <Text style={[WebStyles.currentPlanInfo, { marginTop: 24 }]}>
    Want to give access to more users? Upgrade your subscription plan and start
    adding users!
   </Text>
   <View style={styles.button}>
    <Link to='billing'>
     <Button
      title='Upgrade your subscription plan'
      bgColor={getColor('NORMAL_GREEN')}
     />
    </Link>
   </View>
  </View>
 );
};

export default MaximumUsersExceeded;

const styles = StyleSheet.create({
 container: {
  flex: 1,
  alignSelf: 'center',
  alignItems: 'center',
  paddingHorizontal: 72,
  paddingVertical: 80,
 },
 imgcontainer: {
  width: 72,
  height: 72,
  borderRadius: 12,
  backgroundColor: 'rgba(226, 115, 54, 0.1)',
 },
 imgSize: { width: 68, height: 66 },
 button: { marginTop: 32, width: 327 },
});
