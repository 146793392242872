import React from 'react';
import { View, Image, StyleSheet, ScrollView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import SignupForm from 'components/forms/SignupForm';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';

import ScreenContainer from 'reusable/ScreenContainer';

import { SCREEN_HEIGHT, WINDOW_HEIGHT } from 'utils/dimensions';
import { IMAGES } from 'utils/images';

const SignupScreen = ({ navigation }) => {
 return (
  <ScrollView showsVerticalScrollIndicator={false}>
   <KeyboardAwareScrollView
    extraScrollHeight={68}
    showsVerticalScrollIndicator={false}
    contentContainerStyle={{
     justifyContent: 'space-between',
     flex: 1,
    }}>
    <FocusAwareStatusBar barStyle='dark-content' />

    <View style={styles2.containerChild}>
     <Image
      style={{ width: 284, height: 284, marginTop: 20 }}
      source={IMAGES.signupImage}
     />
    </View>

    <ScreenContainer style={{ paddingBottom: 28 }}>
     <SignupForm navigation={navigation} />
    </ScreenContainer>
   </KeyboardAwareScrollView>
  </ScrollView>
 );
};

export default SignupScreen;

const styles2 = StyleSheet.create({
 containerChild: {
  justifyContent: 'center',
  alignItems: 'center',
  height: 290,
  backgroundColor: '#ECECEE',
 },
});
