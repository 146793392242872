import useForm from 'hooks/useForm';
import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
 ScrollView,
 StyleSheet,
 Text,
 TouchableOpacity,
 View,
} from 'react-native';
import MaskInput from 'react-native-mask-input';
import uuid from 'react-native-uuid';

import { submittedValues, treeDefaultValues } from './AddTreeValues';
import Alert from './Alert';
import MultiLineTextInput from './MultiLineTextInput';
import Selectable from './Selectable';
import UploadImageWeb from './UploadImageWeb';
import WebCommonName from './WebCommonName';
import {
 ageData,
 healthData,
 structureData,
 resilienceData,
} from './addTreeDropDownData';

import treeFilter from 'store/treeFilter';

import AddTreeMap from 'screens/WebNavigation/WebMap/AddTreeMap/index.web';
import MiniMap from 'screens/WebNavigation/WebMap/MiniMap/index.web';
import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { addTree } from 'services';
import { deleteTreeImage, editTreeInfo } from 'services/treeServices';

import ModalWrapper from 'components/modals/ModalWrapper';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import DropDown from 'components/reusable/DropDown';
import InputError from 'components/reusable/InputError';
import Radio from 'components/reusable/Radio';
import { AddTreeInfoSchema } from 'components/validations/schema';

import { getEnterpriseTree, getTrees } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { storageGetString } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

const AddTreeWeb = ({ editMode, data, getClusters, centerCoordinates }) => {
 const [mapVisible, setMapVisible] = useState(false);
 const [species, setSpecies] = useState([]);

 const scrollViewRef = React.useRef();

 const defaultValues = treeDefaultValues(editMode, data);

 const { control, handleSubmit, errors, isSubmitting, getValues, setValue } =
  useForm(AddTreeInfoSchema, defaultValues);

 const { permissions } = usePermission();
 const { profile } = useProfile();

 const userImageLimit = profile.enterpriseData
  ? profile.enterpriseData.boughtImages
  : profile.boughtImages;

 const genusArr = storageGetString('genusData');

 const genusData = JSON.parse(genusArr);

 useEffect(() => {
  const userId = getValues('userId');
  const organizationId = getValues('organizationId');
  if (organizationId === '') {
   setValue('organizationId', profile.enterprise);
  }
  if (userId === '') {
   setValue('userId', profile._id);
  }
 }, []);

 useEffect(() => {
  if (
   errors.commonName?.message ||
   errors.genus?.message ||
   errors.species?.message ||
   errors.coordinates?.message
  ) {
   scrollViewRef.current?.scrollTo({
    y: 0,
    animated: true,
   });
  }
 }, [errors]);

 const getSpecies = (item) => {
  setSpecies(item?.item?.species || item?.species);
 };

 const deletePhoto = async (img) => {
  const fileId = img.split('fileId=')[1];

  const imgUrlsValue = getValues('imgUrls');

  const newArr = imgUrlsValue.filter((e) => e.uri !== img);

  const res = await deleteTreeImage(fileId);

  if (res.type === 'success') {
   setValue('imgUrls', newArr);
  }
 };

 const onSubmit = async (values) => {
  const obj = submittedValues(editMode, data, values);

  let response;
  if (editMode) {
   response = await editTreeInfo(obj);
  } else {
   response = await addTree(obj);
  }
  if (response.type === 'success') {
   getEnterpriseTree(profile.enterprise, profile._id);
   if (editMode) getTrees();
   getClusters();
   treeFilter.pin = [];
   Defaults.Modal.hide(<ModalWrapper />);
   setTimeout(() => {
    Defaults.Modal.show(
     <Alert
      alert={editMode ? 'Tree changed successfully' : 'Tree added successfully'}
     />
    );
   }, 500);
  }
 };

 const treeCoordinates = getValues('coordinates');
 const isPrivate = getValues('private');

 return (
  <ScrollView
   ref={scrollViewRef}
   contentContainerStyle={{ flex: 1 }}
   style={styles.container}>
   {mapVisible ? (
    <View style={styles.mapContainer}>
     <Text style={[WebStyles.currentPlan, { marginBottom: 32 }]}>
      Position tree on the map
     </Text>
     <AddTreeMap
      centerCoordinates={centerCoordinates}
      setMapVisible={setMapVisible}
      coordinates={getValues('coordinates')}
      setValue={setValue}
     />
    </View>
   ) : (
    <View style={styles.secContainer}>
     <Text style={WebStyles.currentPlan}>
      {editMode ? 'Edit tree' : 'Add a tree'}
     </Text>
     <View
      style={{
       marginTop: 24,
       width: '100%',
       alignItems: 'flex-end',
      }}>
      <View style={{ width: 200 }}>
       <Button
        title='Save'
        disabled={isSubmitting}
        bgColor={getColor('NORMAL_GREEN')}
        onPress={handleSubmit(onSubmit)}
        customStyles={{ zIndex: -1 }}
       />
      </View>
     </View>
     <View style={{ marginTop: 24 }}>
      <View style={styles.miniMapTextContainer}>
       <Field bold text='Location' />
       {treeCoordinates?.length > 0 && (
        <TouchableOpacity onPress={() => setMapVisible(true)}>
         <Text style={WebStyles.editGeofence}>
          Update tree position on the map
         </Text>
        </TouchableOpacity>
       )}
      </View>
      {treeCoordinates?.length > 0 && (
       <MiniMap isPrivate={isPrivate} treeCoordinates={treeCoordinates} />
      )}
      {treeCoordinates?.length === 0 && (
       <>
        <Button
         title='Place on the map'
         bgColor={getColor('NORMAL_GREEN')}
         textColor={getColor('WHITE')}
         onPress={() => setMapVisible(true)}
        />
        <InputError error={errors.coordinates?.message} name='coordinates' />
       </>
      )}
     </View>
     <View style={{ marginTop: 48 }}>
      <Field bold text='Label Info' />
      <View style={styles.mb24}>
       <View style={{ zIndex: 3 }}>
        <Field text='Common name' />
        <Controller
         control={control}
         name='commonName'
         render={({ field: { value, onChange } }) => {
          return (
           <View style={styles.mb24}>
            <WebCommonName setValue={onChange} addTree value={value} />
            <InputError error={errors.commonName?.message} name='commonName' />
           </View>
          );
         }}
        />
       </View>
       <View style={{ zIndex: 2 }}>
        <Field text='Genus' />
        <Controller
         control={control}
         name='genus'
         render={({ field: { value, onChange } }) => {
          return (
           <View style={styles.mb24}>
            <Selectable
             onChange={onChange}
             placeholder='Choose genus'
             data={genusData}
             addTree
             setSpecies={setSpecies}
             genus={value}
             setGenusValue={setValue}
             getSpecies={getSpecies}
            />
            <InputError error={errors.genus?.message} name='genus' />
           </View>
          );
         }}
        />
       </View>
       <View style={{ zIndex: 1 }}>
        <Field text='Species' />
        <Controller
         control={control}
         name='species'
         render={({ field: { value } }) => {
          return (
           <View style={styles.mb24}>
            <Selectable
             placeholder='Choose species'
             addTree
             data={species}
             speciesValue={value}
             setSpeciesValue1={setValue}
            />
            <InputError error={errors.species?.message} name='species' />
           </View>
          );
         }}
        />
       </View>

       <Field bold text='Tree images' />
       <View style={styles.imgContainer}>
        <Controller
         control={control}
         name='imgUrls'
         render={({ field: { value } }) => {
          return (
           <UploadImageWeb
            type='trees'
            limit={userImageLimit}
            images={value}
            onDeletePhoto={(img) => deletePhoto(img)}
            setImages={(img) =>
             setValue('imgUrls', [...value, { id: uuid.v4(), uri: img }])
            }
            withAvatar={false}
           />
          );
         }}
        />
       </View>

       {permissions === 'Admin' || permissions === 'Editor' ? (
        <>
         <Field bold text='Embedded Logo' />
         <View style={styles.imgContainer}>
          <Controller
           control={control}
           name='organizationImg'
           render={({ field: { value } }) => {
            return (
             <UploadImageWeb
              type='trees'
              limit={1}
              images={value}
              imgType='PROFILE'
              onDeletePhoto={() => setValue('organizationImg', [])}
              setImages={(img) => setValue('organizationImg', [img])}
              withAvatar={false}
             />
            );
           }}
          />
         </View>

         <View style={{ marginVertical: 14 }}>
          <Field text='When logo is clicked/tapped, link to URL' />
          <ControllerInput
           name='organizationImgUrl'
           control={control}
           withIcon={false}
           placeholder='Enter Url'
           error={errors.organizationImg?.message}
          />
         </View>
        </>
       ) : null}

       <View style={styles.privateCont}>
        <Field bold text='Private' />
        <View style={{ flexDirection: 'row' }}>
         <View style={{ flexDirection: 'row' }}>
          <Controller
           control={control}
           name='private'
           render={({ field: { value } }) => {
            return (
             <View style={{ marginRight: 36 }}>
              <Radio
               onPress={() => setValue('private', true)}
               selected={value || false}>
               Yes
              </Radio>
             </View>
            );
           }}
          />

          <Controller
           control={control}
           name='private'
           render={({ field: { value } }) => {
            return (
             <View style={{ marginRight: 36 }}>
              <Radio
               onPress={() => setValue('private', false)}
               selected={value === false}>
               No
              </Radio>
             </View>
            );
           }}
          />
         </View>
        </View>
       </View>
      </View>
      <Field bold text='Additional info' />
      <Field subText text='General' />
      <View style={styles.generalContainer}>
       <View style={styles.flexMr}>
        <Field text='Family' />
        <ControllerInput
         name='family'
         control={control}
         withIcon={false}
         placeholder='Enter family'
         error={errors.family?.message}
        />
       </View>
       <View style={styles.flex}>
        <Field text='Variety' />
        <ControllerInput
         name='variety'
         control={control}
         withIcon={false}
         placeholder='Enter variety'
         error={errors.variety?.message}
        />
       </View>
      </View>
      <Field subText text='Tree metrics' />
      <View style={[styles.generalContainer, { zIndex: -1 }]}>
       <View style={styles.flexMr}>
        <Field text='Date planted' />
        {/* <ControllerInput
         name='datePlanted'
         control={control}
         withIcon={false}
         placeholder='Enter date'
         error={errors.datePlanted?.message}
        /> */}
        <Controller
         control={control}
         name='datePlanted'
         render={({ field: { onChange, value } }) => {
          return (
           <MaskInput
            placeholder='YEAR-MM-DD'
            style={styles.maskedStyle}
            value={value}
            onChangeText={(masked) => {
             onChange(masked);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
           />
          );
         }}
        />
       </View>
       <View style={styles.flex}>
        <Field text='Age' />
        <DropDown
         name='age'
         withoutIcon
         placeholder='Choose age'
         control={control}
         error={errors.age?.message}
         setValue={(e) => {
          setValue('age', e);
         }}
         droppedButtons={ageData}
        />
       </View>
      </View>
      <View style={[styles.generalContainer, { zIndex: -2 }]}>
       <View style={styles.flexMr}>
        <Field text='Calculated age' />
        <ControllerInput
         name='calculatedAge'
         control={control}
         withIcon={false}
         placeholder='Enter calculated age'
         error={errors.calculatedAge?.message}
        />
       </View>
       <View style={styles.flex}>
        <Field text='Diameter at breast height (metres)' />
        <ControllerInput
         name='diameter'
         control={control}
         withIcon={false}
         placeholder='Enter diameter at breast height'
         error={errors.diameter?.message}
        />
       </View>
      </View>
      <View style={[styles.generalContainer, { zIndex: -3 }]}>
       <View style={styles.flexMr}>
        <Field text='Crown size (metres)' />
        <ControllerInput
         name='crownSize'
         control={control}
         withIcon={false}
         placeholder='Enter crown size'
         error={errors.crownSize?.message}
        />
       </View>
       <View style={styles.flex}>
        <Field text='Height (metres)' />
        <ControllerInput
         name='height'
         control={control}
         withIcon={false}
         placeholder='Enter height'
         error={errors.height?.message}
        />
       </View>
      </View>
      <View style={{ marginBottom: 30 }}>
       <Field text='Current estimated biomass' />
       <ControllerInput
        name='currentBiomass'
        control={control}
        withIcon={false}
        placeholder='Enter current estimated biomass'
        error={errors.currentBiomass?.message}
       />
      </View>
      <Field subText text='Tree management' />
      <View style={[styles.generalContainer, { zIndex: -4 }]}>
       <View style={styles.flexMr}>
        <Field text='Description of location' />
        <ControllerInput
         name='locationDescription'
         control={control}
         withIcon={false}
         placeholder='Enter description of location'
         error={errors.locationDescription?.message}
        />
       </View>
       <View style={styles.flex}>
        <Field text='Reference ID' />
        <ControllerInput
         name='referenceId'
         control={control}
         withIcon={false}
         placeholder='Enter reference ID'
         error={errors.referenceId?.message}
        />
       </View>
      </View>
      <View style={[styles.generalContainer, { zIndex: 1 }]}>
       <View style={styles.flexMr}>
        <Field text='Enter date updated' />
        <ControllerInput
         name='dateUpdated'
         control={control}
         withIcon={false}
         placeholder='Enter description of location'
         error={errors.dateUpdated?.message}
        />
       </View>
       <View style={styles.flex}>
        <Field text='Health' />
        <DropDown
         withoutIcon
         name='health'
         control={control}
         placeholder='Choose health'
         error={errors.type?.message}
         setValue={(e) => {
          setValue('health', e);
         }}
         droppedButtons={healthData}
        />
       </View>
      </View>
      <View style={[styles.generalContainer, { zIndex: -1 }]}>
       <View style={styles.flexMr}>
        <Field text='Structure condition' />
        <DropDown
         withoutIcon
         name='structureCondition'
         control={control}
         placeholder='Choose structure condition'
         error={errors.type?.message}
         setValue={(e) => {
          setValue('structureCondition', e);
         }}
         droppedButtons={structureData}
        />
       </View>
       <View style={styles.flex}>
        <Field text='Resilience estimation' />
        <DropDown
         withoutIcon
         name='resilienceEstimation'
         placeholder='Choose resilience estimation'
         control={control}
         error={errors.type?.message}
         setValue={(e) => {
          setValue('resilienceEstimation', e);
         }}
         droppedButtons={resilienceData}
        />
       </View>
      </View>
      <Field bold text='Defined fields' />
      <View style={styles.multiLineContainer}>
       <Field text='User defined 1' />
       <MultiLineTextInput
        name='userDefined1'
        error={errors.info?.message}
        control={control}
        placeholder='Enter field values/text'
       />
      </View>
      <View style={styles.multiLineContainer}>
       <Field text='User defined 2' />
       <MultiLineTextInput
        name='userDefined2'
        error={errors.info?.message}
        control={control}
        placeholder='Enter field values/text'
       />
      </View>
      <View style={styles.multiLineContainer}>
       <Field text='User defined 3' />
       <MultiLineTextInput
        name='userDefined3'
        error={errors.info?.message}
        control={control}
        placeholder='Enter field values/text'
       />
      </View>
      <Button
       title='Save'
       disabled={isSubmitting}
       bgColor={getColor('NORMAL_GREEN')}
       onPress={handleSubmit(onSubmit)}
       customStyles={{ zIndex: -1 }}
      />
     </View>
    </View>
   )}
  </ScrollView>
 );
};

const styles = StyleSheet.create({
 container: {
  flex: 1,
  borderRadius: 12,
 },
 mapContainer: {
  flex: 1,
  paddingHorizontal: 20,
 },
 secContainer: { flex: 1, paddingHorizontal: '25%' },
 miniMapTextContainer: {
  justifyContent: 'space-between',
  flexDirection: 'row',
 },
 imgContainer: {
  flexDirection: 'row',
  marginBottom: 48,
  zIndex: -2,
  flexWrap: 'wrap',
 },
 generalContainer: {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 30,
  zIndex: -1,
 },
 treeMetricsContainer: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 16,
  zIndex: -1,
 },
 multiLineContainer: { marginBottom: 24, zIndex: -2 },
 flex: {
  flex: 1,
 },
 flexMr: {
  flex: 1,
  marginRight: 25,
 },
 mb24: {
  marginBottom: 24,
 },
 privateCont: {
  marginBottom: 48,
  zIndex: -2,
 },
 maskedStyle: {
  borderRadius: 12,
  height: 52,
  borderColor: getColor('NEUTRAL300'),
  outlineStyle: 'none',
  borderWidth: 1,
  paddingLeft: 18,
  fontFamily: fonts.MontserratRegular,
  fontWeight: 600,
  letterSpacing: 0.5,
  color: getColor('NEUTRAL700'),
  lineHeight: 26,
 },
});

export default AddTreeWeb;

const Field = ({ text, marginBottom = 8, bold, subText }) => (
 <View
  style={[
   { zIndex: -2 },
   bold || subText ? { marginBottom: 16 } : { marginBottom },
  ]}>
  <Text
   style={[
    bold
     ? [WebStyles.addGeofenceTitle]
     : subText
     ? [
        WebStyles.fileSize,
        {
         fontSize: 14,
         color: getColor('NEUTRAL800'),
         fontFamily: fonts.MontserratBold,
        },
       ]
     : [WebStyles.fileSize, { fontSize: 12 }],
   ]}>
   {text}
  </Text>
 </View>
);
