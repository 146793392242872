import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import ModalWrapper from 'components/modals/ModalWrapper';
import Button from 'components/reusable/Button';

import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';

const RemoveModal = ({ remove }) => {
 return (
  <View style={styles.container}>
   <Text
    style={[WebStyles.currentPlan, { alignSelf: 'center', marginBottom: 32 }]}>
    Sure you want to delete?
   </Text>

   <Button
    title='Delete'
    bgColor={getColor('error')}
    onPress={() => remove()}
   />
   <View style={{ marginTop: 12 }}>
    <Button
     title='Cancel'
     bgColor={getColor('NORMAL_GREEN')}
     onPress={() => Defaults.Modal.hide(<ModalWrapper />)}
    />
   </View>
  </View>
 );
};

export default RemoveModal;

const styles = StyleSheet.create({
 container: { flex: 1, paddingHorizontal: 50, paddingTop: 70 },
});
