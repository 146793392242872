import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Animated, {
 interpolate,
 useAnimatedStyle,
 useSharedValue,
 withTiming,
} from 'react-native-reanimated';

import Hr from 'components/reusable/Hr';
import Icon from 'components/reusable/Icon';
import SquircleContainer from 'components/reusable/SquircleContainer';
import Text from 'components/reusable/Text';
import Toggle from 'components/reusable/Toggle';

// import SponsoredBy from 'components/Treeinfo/SponsoredBy';
import { Defaults } from 'utils/defaults';
import { storageGetBoolean, storageSet } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';

const MapAdditionalFeatures = ({
 onClose,
 download,
 storePacks,
 togglePoligons,
 toggleMap,
 navigation,
}) => {
 let geofence = storageGetBoolean('isCheckedGeofence');
 let streets = storageGetBoolean('isCheckStreets');

 const position = useSharedValue(0);

 const [isCheckedGeofence, setGeoFence] = useState(geofence);
 const [isCheckStreets, setIsCheckStreets] = useState(streets);

 useEffect(() => {
  position.value = withTiming(1, { duration: 500 });

  navigation.addListener('beforeRemove', () => {
   if (Defaults.toast) {
    Defaults.toast.hide();
   }
  });
 }, []);

 const animatedStyles = useAnimatedStyle(() => {
  return {
   opacity: interpolate(position.value, [0, 1], [0, 1]),
   bottom: interpolate(position.value, [0, 1], [80, 110]),
  };
 });

 const toggleGeoFence = () => {
  togglePoligons();
  storageSet('isCheckedGeofence', !isCheckedGeofence);
  setGeoFence((prev) => !prev);
 };

 const toggleStreets = () => {
  toggleMap();
  storageSet('isCheckStreets', !isCheckStreets);

  setIsCheckStreets((prev) => !prev);
 };

 return (
  <Animated.View style={[styles.dropdownContainer, animatedStyles]}>
   <SquircleContainer cornerRadius={16} bgColor='#fff' style={{ padding: 20 }}>
    <View
     style={{
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
     }}>
     <Text fontSize={14} fontType='MontserratBold'>
      Additional features
     </Text>

     <Pressable onPress={onClose}>
      <Icon name='plus2' style={styles.closeIcon} />
     </Pressable>
    </View>

    <View style={{ marginTop: 24 }}>
     <Item text='Show geofence'>
      <Toggle value={isCheckedGeofence} toggleSwitch={toggleGeoFence} />
     </Item>
     <Hr customStyles={styles.hrStyles} />

     <Item text='Show streets'>
      <Toggle value={isCheckStreets} toggleSwitch={toggleStreets} />
     </Item>
     <Hr customStyles={styles.hrStyles} />

     <Item text='Offline map'>
      <Text
       fontSize={14}
       color='NORMAL_GREEN_HOVER'
       fontType='MontserratSemiBold'
       onPress={storePacks}>
       {download ? `${Math.round(download)}%` : 'Download current'}
      </Text>
     </Item>
    </View>
   </SquircleContainer>
  </Animated.View>
 );
};

export default MapAdditionalFeatures;

const Item = ({ text, children }) => {
 return (
  <View
   style={{
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
   }}>
   <Text fontSize={14}>{text}</Text>
   {children}
  </View>
 );
};

const styles = StyleSheet.create({
 dropdownContainer: {
  position: 'absolute',
  left: 20,
  right: 20,
  bottom: 110,
  overflow: 'hidden',
 },
 imageStyle: {
  width: 120,
  height: '100%',
  marginRight: 10,
  borderRadius: 16,
 },
 desc: {
  marginTop: 4,
 },
 closeIcon: {
  color: getColor('NEUTRAL700'),
  transform: [{ rotate: '45deg' }],
  fontSize: 20,
 },
 hrStyles: {
  marginTop: 16,
  marginBottom: 16,
 },
});
