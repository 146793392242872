import React from 'react';
import { SafeAreaView } from 'react-native';

import RegisterUserSettings from './RegisterUserSettings';
import UnregisterUserSettings from './UnregisterUserSettings';

import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import ScreenContainer from 'components/reusable/ScreenContainer';

import { storageGetString } from 'utils/mmkvStorage';

const SettingsScreen = ({ navigation }) => {
 return (
  <SafeAreaView style={{ flex: 1 }}>
   <FocusAwareStatusBar barStyle='dark-content' />

   <ScreenContainer>
    {storageGetString('AccessToken') ? (
     <RegisterUserSettings navigation={navigation} />
    ) : (
     <UnregisterUserSettings navigation={navigation} />
    )}
   </ScreenContainer>
  </SafeAreaView>
 );
};

export default SettingsScreen;
