import axios from 'axios';
import Ajax from 'helpers/ajax';

import {
 GETGEOFENCES,
 ADDGEOFENCE,
 ADDTREE,
 GETENTERPRISEGEOFENCES,
 DELETEGEOFENCE,
 EDITGEOFENCE,
 API_MAP,
} from 'utils/uris';

const getClusters = async (bounds) => {
 try {
  const response = await axios.get(`${API_MAP}${bounds}`);

  return response;
 } catch (error) {
  return error;
 }
};

const getGeofences = async () => {
 try {
  return await Ajax.get(GETGEOFENCES);
 } catch (error) {
  return error;
 }
};

const getEnterpriseGeofences = async (enterpriseId) => {
 try {
  return await Ajax.get(`${GETENTERPRISEGEOFENCES}${enterpriseId}`);
 } catch (error) {
  return error;
 }
};

const addTree = async (data) => {
 try {
  const response = await axios.post(`${API_MAP}${ADDTREE}`, data);

  return response;
 } catch (error) {
  return error;
 }
};

const addGeofence = async (enterpriseId, values) => {
 try {
  return await Ajax.post(ADDGEOFENCE, { enterpriseId, ...values });
 } catch (error) {
  return error;
 }
};

const deleteGeofence = async (geofenceId) => {
 try {
  return await Ajax.delete(DELETEGEOFENCE, { geofenceId });
 } catch (error) {
  return error;
 }
};

const editGeofence = async (geofenceId, values) => {
 try {
  return await Ajax.put(EDITGEOFENCE, { geofenceId, ...values });
 } catch (error) {
  return error;
 }
};

export {
 getClusters,
 getGeofences,
 addGeofence,
 addTree,
 getEnterpriseGeofences,
 deleteGeofence,
 editGeofence,
};
