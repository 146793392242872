import React from 'react';

import SignupForm from 'components/forms/SignupForm';
import WebSignInContainer from 'components/webComps/webcommon/WebSignInContainer';

const WebPPSignup = ({ navigation, route }) => {
 const token = route?.params?.token;
 const action = route?.params;

 return (
  <WebSignInContainer regImg>
   <SignupForm
    navigation={navigation}
    webStyle={{ justifyContent: 'space-between' }}
    signUpToken={token}
    action={action}
   />
  </WebSignInContainer>
 );
};

export default WebPPSignup;
