// import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
// import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useRef } from 'react';
import Map, { Source, Layer } from 'react-map-gl';
import { Image } from 'react-native';

import { unclusteredPointLayer } from '../map-style';

import { IMAGES } from 'utils/images';
import { MAP_ACCESSTOKEN } from 'utils/uris';

const mapStyle = {
 Satellite: 'mapbox://styles/mapbox/satellite-v9',
 SatelliteStreet: 'mapbox://styles/mapbox/satellite-streets-v11',
};

const MiniMap = ({ coordinates, treeCoordinates, isPrivate }) => {
 const styleURL = mapStyle.SatelliteStreet;
 const mapRef = useRef();

 let data;

 if (coordinates) {
  data = {
   type: 'FeatureCollection',
   features: [
    {
     type: 'Feature',
     properties: {},
     geometry: {
      coordinates: [[...coordinates]],
      type: 'Polygon',
     },
    },
   ],
  };
 }

 return (
  <>
   <Map
    ref={(ref) => (mapRef.current = ref)}
    initialViewState={{
     longitude: treeCoordinates ? treeCoordinates[0] : coordinates[0][0],
     latitude: treeCoordinates ? treeCoordinates[1] : coordinates[0][1],
     zoom: 15,
    }}
    scrollZoom={false}
    dragRotate={false}
    keyboard={false}
    touchZoomRotate={false}
    boxZoom={false}
    doubleClickZoom={false}
    dragPan={false}
    logoEnabled={false}
    attributionControl={false}
    interactive={false}
    style={{
     maxWidth: 856,
     height: 120,
     borderRadius: 12,
    }}
    mapboxAccessToken={MAP_ACCESSTOKEN}
    renderWorldCopies={false}
    mapStyle={styleURL}>
    {coordinates && (
     <Source type='geojson' data={data}>
      <Layer {...dataLayerLine} />
      <Layer {...dataLayer} />
     </Source>
    )}

    {treeCoordinates && (
     <Source
      id='trees'
      type='geojson'
      data={{
       type: 'FeatureCollection',
       crs: {
        type: 'name',
        properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
       },

       features: [
        {
         properties: {
          color: '#559158',
         },
         type: 'Feature',
         geometry: {
          coordinates: [treeCoordinates[0], treeCoordinates[1]],
          type: 'Point',
         },
        },
       ],
      }}
      clusterMaxZoom={14}
      clusterRadius={50}>
      <Layer {...unclusteredPointLayer} />
     </Source>
    )}
   </Map>
  </>
 );
};

export default MiniMap;

const dataLayer = {
 id: 'data',
 type: 'fill',
 paint: {
  'fill-color': {
   property: 'color',
   stops: [[0, '#D96C31']],
  },
  'fill-opacity': 0.4,
  'fill-outline-color': '#D96C31',
 },
};

const dataLayerLine = {
 id: 'dataline',
 type: 'line',
 paint: {
  'line-color': '#D96C31',
  'line-width': 3,
 },
};
