import React from 'react';
import { StyleSheet, Image, View } from 'react-native';

import Text from 'reusable/Text';

import { lineHeight } from 'utils/theme';

const SponsoredBy = ({ image, status, text }) => {
 return (
  <View style={styles.container}>
   <Image
    source={!image ? require('assets/images/profileImage.png') : { uri: image }}
    style={{ width: 40, height: 40, borderRadius: 8 }}
   />

   <View style={{ marginLeft: 10 }}>
    <Text
     fontType='MontserratSemiBold'
     color='NEUTRAL800'
     fontSize={lineHeight.subTitle3}>
     {text}
    </Text>

    <Text
     color='NEUTRAL700'
     fontType='MontserratRegular'
     fontSize={lineHeight.subTitle3}
     style={{ marginTop: 3 }}>
     {status}
    </Text>
   </View>
  </View>
 );
};

export default SponsoredBy;

SponsoredBy.defaultProps = {
 withOpenLinkIcon: false,
 image: '',
};

const styles = StyleSheet.create({
 container: {
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
 },
});
