import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import SquircleContainer from 'components/reusable/SquircleContainer';

import { getColor } from 'utils/settings';

const AddGeoFenceButton = ({ onPress, text }) => {
 return (
  <SquircleContainer
   bgColor={getColor('LIGHT_ORANGE')}
   cornerRadius={12}
   style={styles.squircle}>
   <TouchableOpacity onPress={onPress} style={styles.button}>
    <Text style={[WebStyles.addtTreeText, { color: '#D96C31' }]}>{text}</Text>
   </TouchableOpacity>
  </SquircleContainer>
 );
};

const styles = StyleSheet.create({
 squircle: {
  position: 'absolute',
  width: 142,
  height: 44,
  bottom: 24,
  right: 24,
 },
 button: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});

export default AddGeoFenceButton;
