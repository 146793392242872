import React from 'react';
import { View, Image, StyleSheet } from 'react-native';

import PrivacyPolicy from './PrivacyPolicy';

import TermsAndConditions from 'components/webComps/webcommon/TermsAndConditions';
import TremapC from 'components/webComps/webcommon/TremapC';
import TremapLogo from 'components/webComps/webcommon/TremapLogo';

import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';

const WebSignInContainer = ({ children, regImg }) => {
 return (
  <View style={styles.container}>
   <View style={styles.mainContainer}>
    <View style={styles.signInContainer}>{children}</View>
   </View>
   <View style={styles.tremapImgContainer}>
    <Image
     style={styles.tremapImg}
     source={regImg ? IMAGES.tremapWebRegImg : IMAGES.tremapWebSigninImg}
    />
   </View>
   <TremapLogo />
   <TermsAndConditions />
   <PrivacyPolicy />
   <TremapC />
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  flex: 1,
  flexDirection: 'row',
  backgroundColor: getColor('WHITE'),
 },
 mainContainer: {
  width: '43%',
  justifyContent: 'center',
 },
 signInContainer: {
  marginLeft: '29%',
  width: '52%',
  height: '67%',
 },

 tremapImgContainer: {
  width: '57%',
  backgroundColor: getColor('NORMAL_SILVER'),
  alignItems: 'center',
  justifyContent: 'center',
 },
 tremapImg: {
  width: 524,
  height: 524,
 },
});

export default WebSignInContainer;
