import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
 Easing,
 useAnimatedStyle,
 useSharedValue,
 withDelay,
 withTiming,
} from 'react-native-reanimated';

import Icon from './Icon';
import SquircleContainer from './SquircleContainer';

import Text from 'reusable/Text';

import { Defaults } from 'utils/defaults';
import { SCREEN_WIDTH } from 'utils/dimensions';
import { getColor } from 'utils/settings';

const Toast = ({ message, withError }) => {
 const animatedValue = useSharedValue(0);

 useEffect(() => {
  animatedValue.value = 30;
 }, []);

 const onCloseToast = () => {
  Defaults.toast.hide();
 };

 const style = useAnimatedStyle(() => {
  return {
   bottom: withTiming(
    animatedValue.value,
    {
     duration: 500,
     easing: Easing.bezier(0.25, 0.1, 0.25, 1),
    },
    () => {
     animatedValue.value = withDelay(
      1000,
      withTiming(-80, {
       duration: 500,
       easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      })
     );
    }
   ),
  };
 });

 return (
  <Animated.View style={[styles.animatedViewStyle, style]}>
   <SquircleContainer
    strokeColor={withError ? getColor('error') : getColor('NORMAL_GREEN')}
    strokeWidth={1}
    cornerRadius={16}
    bgColor={withError ? '#FBE6E1' : getColor('LIGHT_GREEN_HOVER')}
    style={styles.notification}>
    <SquircleContainer
     style={styles.checkmarkContainer}
     cornerRadius={8}
     bgColor={withError ? '#E45839' : getColor('NORMAL_GREEN')}>
     {withError ? (
      <Icon
       name='plus2'
       style={{ color: '#fff', transform: [{ rotate: '45deg' }], fontSize: 20 }}
      />
     ) : (
      <Icon name='checkmark' style={{ color: '#fff', fontSize: 11 }} />
     )}
    </SquircleContainer>

    <View style={styles.textStyle}>
     <Text style={{ fontSize: 12, lineHeight: 16 }}>{message}</Text>
    </View>
   </SquircleContainer>

   <Icon name='plus2' style={styles.iconStyle} onPress={onCloseToast} />
  </Animated.View>
 );
};

export default Toast;

const styles = StyleSheet.create({
 animatedViewStyle: {
  position: 'absolute',
  bottom: -100,
  left: 20,
  right: 20,
  zIndex: 11,
  width: SCREEN_WIDTH - 40,
 },
 iconStyle: {
  transform: [{ rotate: '45deg' }],
  fontSize: 22,
  right: 20,
  top: 27,
  position: 'absolute',
 },
 notification: {
  height: 73,
  padding: 20,
  flexDirection: 'row',
  alignItems: 'center',
 },
 checkmarkContainer: {
  width: 32,
  height: 32,
  marginRight: 12,
  justifyContent: 'center',
  alignItems: 'center',
 },
 textStyle: {
  width: '70%',
  height: '100%',
  marginTop: 2,
  justifyContent: 'center',
 },
});
