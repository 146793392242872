import React, { useEffect, useRef } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

import Dash from './Dash';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import Toggle from 'components/reusable/Toggle';

import { getColor } from 'utils/settings';

const MapFeatures = ({
 toggleMap,
 profile,
 permissions,
 isEmbededMap,
 streetsValue,
 geofenceValue,
 toggleGeofence,
 setShowFeatures,
 addGeoFence,
 handleEmbedLink,
}) => {
 const ref = useRef();

 useEffect(() => {
  const closeDropDown = (e) => {
   if (ref.current && !ref.current.contains(e.target)) {
    setShowFeatures(false);
   }
  };

  window.addEventListener('click', closeDropDown);

  return () => window.removeEventListener('click', closeDropDown);
 }, [ref.current]);

 return (
  <Pressable
   style={[styles.container, { left: isEmbededMap ? 79 : 321 }]}
   ref={ref}>
   <Text style={[WebStyles.additionalFeatures, { marginBottom: 24 }]}>
    Additional Features
   </Text>
   <View
    style={[
     styles.showContainer,
     {
      marginBottom: 16,
     },
    ]}>
    <Text style={[WebStyles.genusFilterText, { fontWeight: 500 }]}>
     Show geofence
    </Text>
    <Toggle
     toggleSwitch={toggleGeofence}
     value={geofenceValue}
     customContainerStyle={styles.toggleCont}
     customCircleStyle={styles.toggleCircleStyle}
    />
   </View>
   <Dash customStyle={styles.dashStyle} />
   <View style={styles.showContainer}>
    <Text style={[WebStyles.genusFilterText, { fontWeight: 500 }]}>
     Show streets
    </Text>
    <Toggle
     value={streetsValue}
     toggleSwitch={toggleMap}
     customContainerStyle={styles.toggleCont}
     customCircleStyle={styles.toggleCircleStyle}
    />
   </View>

   {!isEmbededMap && profile.enterpriseData && permissions === 'Admin' && (
    <>
     <Dash customStyle={[styles.dashStyle, { marginTop: 16 }]} />
     <View style={styles.showContainer}>
      <Text
       onPress={addGeoFence}
       style={[
        WebStyles.genusFilterText,
        { fontWeight: 500, color: getColor('NORMAL_GREEN') },
       ]}>
       Add geo-fence
      </Text>
     </View>
    </>
   )}

   {!isEmbededMap && (
    <>
     <Dash customStyle={[styles.dashStyle, { marginTop: 16 }]} />
     <View style={styles.showContainer}>
      <Text
       onPress={handleEmbedLink}
       style={[
        WebStyles.genusFilterText,
        { fontWeight: 500, color: getColor('NORMAL_GREEN') },
       ]}>
       Generate embed code
      </Text>
     </View>
    </>
   )}
  </Pressable>
 );
};

export default MapFeatures;

const styles = StyleSheet.create({
 container: {
  position: 'absolute',
  width: 335,
  borderRadius: 12,
  top: 72,
  backgroundColor: '#ffffff',
  padding: 20,
  cursor: 'default',
 },
 showContainer: {
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
 },
 toggleCont: {
  width: 34,
  height: 22,
  borderRadius: 25,
  padding: 4,
 },
 toggleCircleStyle: { width: 16, height: 16, borderRadius: 20 },
 dashStyle: { marginVertical: 0, marginBottom: 16 },
});
