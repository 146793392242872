const Defaults = {
 BottomSheetModal: null,
 Modal: null,
 themeMode: 'light',
 toast: null,
 userId: '',
 userPermissions: ['Admin', 'Editor', 'Viewer'],
 hitSlop: { top: 15, bottom: 15, left: 15, right: 15 },
 maxWaitTime: 10000,
};

export { Defaults };
