import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, Image, View } from 'react-native';

import Button from 'reusable/Button';
import Text from 'reusable/Text';

import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';
import { lineHeight, fontSize } from 'utils/theme';

const MoreInfoAboutTree = () => {
 const navigation = useNavigation();

 const onCloseModal = async () => {
  navigation.goBack();
  navigation.navigate('Settings', { screen: 'Signup' });
 };

 return (
  <View style={styles.container}>
   <Image source={IMAGES.signinImage} style={styles.imageStyle} />

   <View>
    <Text
     fontType='MontserratSemiBold'
     fontSize={lineHeight.body3}
     style={{ marginBottom: 16 }}>
     Want more info about this tree?
    </Text>

    <Button
     title='Sign up for free!'
     bgColor={getColor('NORMAL_GREEN')}
     textSize={11}
     customStyles={{ width: 140, height: 40 }}
     onPress={onCloseModal}
    />
   </View>
  </View>
 );
};

export default MoreInfoAboutTree;

const styles = StyleSheet.create({
 container: {
  flexDirection: 'row',
  alignItems: 'center',
  borderWidth: 0.6,
  padding: 8,
  marginTop: 16,
  borderColor: '#559158CC',
  backgroundColor: '#E6EFE6',
  borderRadius: 16,
 },
 imageStyle: {
  width: 95,
  height: 95,
 },
});
