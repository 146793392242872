import useProfile from 'hooks/useProfile';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { getColor } from 'utils/settings';

const Trial = () => {
 const { profile } = useProfile();
 const subscriptionEndsAt =
  profile?.subscription?.subscriptionEndsAt ||
  profile?.enterpriseData?.subscription?.subscriptionEndsAt;
 const date = Date.now();
 const diff = subscriptionEndsAt - date;
 const daysLeftUntilSubExpires = diff / (1000 * 60 * 60 * 24);

 return (
  <View style={styles.tremapTrialContainer}>
   <Text style={WebStyles.webDefaultText}>
    Your trial will expire after{' '}
    <Text style={{ color: getColor('NORMAL_ORANGE') }}>
     {daysLeftUntilSubExpires.toFixed(0)} days
    </Text>
   </Text>
  </View>
 );
};

const styles = StyleSheet.create({
 tremapTrialContainer: {
  position: 'absolute',
  top: 35,
  right: 210,
 },
});

export default Trial;
