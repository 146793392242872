import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ScrollView, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import {
 ageIndicator,
 healthOptions,
 resilienceIndicator,
 structureOptions,
} from '../EditTreeInfo/data';

import AndroidDatePicker from 'components/reusable/AndroidDatePicker';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import ScreenContainer from 'components/reusable/ScreenContainer';
import Select from 'components/reusable/Select';

import Text from 'reusable/Text';

import { colors, fontSize, lineHeight } from 'utils/theme';

const AdditionalModal = ({ navigation, route }) => {
 const {
  control,
  getValues,
  setValue,
  handleSubmit,
  formState: {},
 } = useForm({
  defaultValues: {
   properties: {
    family: '',
    variety: '',
    planted: '',
    age: '',
    CalculatedAge: '',
    dbh: '',
    crown: '',
    height: '',
    biomass: '',
    location: '',
    refId: '',
    updated: '',
    health: '',
    structure: '',
    resilience: '',
    customField1: '',
    customField2: '',
    customField3: '',
   },
  },
 });

 useEffect(() => {
  if (Object.keys(route.params.values).length > 0) {
   setValue('properties', route.params.values);
  }
 }, []);

 const onselect = (label, name, options, id) => {
  navigation.navigate('SelectableModal', {
   name,
   genusData: id || '',
   title: label,
   selectedGenus: getValues().genus,
   array: options,
   setValue: (val) =>
    setValue('properties', { ...getValues().properties, [name]: val }),
  });
 };

 const onSubmit = (values) => {
  let properties = {
   family: values.properties.family || '',
   age: values.properties.age || '',
   biomass: values.properties.biomass || '',
   carbon: values.properties.carbon || '',
   credit_retired_by: values.properties.credit_retired_by || '',
   crown: values.properties.crown || '',
   dbh: values.properties.dbh || '',
   dbhMax: values.properties.dbhMax || '',
   dbhMin: values.properties.dbhMin || '',
   health: values.properties.health || '',
   height: values.properties.height || '',
   location: values.properties.location || '',
   origin: values.properties.origin || '',
   photoes: values.properties.photoes || '',
   planted: values.properties.planted || '',
   protect: values.properties.protect || '',
   refId: values.properties.refId || '',
   resilience: values.properties.resilience || '',
   structure: values.properties.structure || '',
   updated: values.properties.updated || '',
   variety: values.properties.variety || '',
   customField1: values.properties.customField1,
   customField2: values.properties.customField2,
   customField3: values.properties.customField3,
  };

  route.params.setValue(properties);
  navigation.goBack();
 };

 return (
  <>
   <KeyboardAwareScrollView
    contentContainerStyle={{ flexGrow: 1 }}
    extraScrollHeight={150}
    enableOnAndroid={true}
    keyboardShouldPersistTaps='handled'>
    <ScrollView style={{ flex: 1 }}>
     <ScreenContainer>
      <Text
       fontType='MontserratBold'
       fontSize={lineHeight.body2}
       style={styles.desc}>
       General
      </Text>

      <Field label='Family'>
       <ControllerInput
        withIcon={false}
        name='properties.family'
        control={control}
        placeholder='Enter family'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Field label='Variety'>
       <ControllerInput
        withIcon={false}
        name='properties.variety'
        control={control}
        placeholder='Variety'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Text
       fontType='MontserratBold'
       fontSize={lineHeight.body2}
       style={styles.desc}>
       Tree Metrics
      </Text>

      <Field label='Date planted'>
       <Controller
        control={control}
        name='properties.planted'
        render={({ field: { onChange, value } }) => (
         <AndroidDatePicker setDate={(e) => onChange(e)} value={value} />
        )}
       />
      </Field>

      <Field label=' Age'>
       <Controller
        control={control}
        render={({ field: { value } }) => {
         return (
          <Select
           value={value}
           placeholder='Choose  age'
           onPress={() => onselect('Age', 'age', ageIndicator)}
          />
         );
        }}
        name='properties.age'
       />
      </Field>

      <Field label='Calculated age'>
       <ControllerInput
        withIcon={false}
        name='properties.calculatedAge'
        control={control}
        placeholder='Calculated age'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Field label='Diameter at breast height'>
       <ControllerInput
        withIcon={false}
        name='properties.dbh'
        control={control}
        placeholder='Diameter at breast height'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Field label=' Crown Size'>
       <ControllerInput
        withIcon={false}
        name='properties.crown'
        control={control}
        placeholder=' Crown Size'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Field label='Height'>
       <ControllerInput
        withIcon={false}
        name='properties.height'
        control={control}
        placeholder='Enter height'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Field label='Current estimated biomass'>
       <ControllerInput
        withIcon={false}
        name='properties.biomass'
        control={control}
        placeholder='Estimated biomass'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Text
       fontType='MontserratBold'
       fontSize={lineHeight.body2}
       style={styles.desc}>
       Tree Management
      </Text>

      <Field label='Description of location'>
       <ControllerInput
        withIcon={false}
        name='properties.location'
        control={control}
        placeholder='Location'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Field label='Reference ID'>
       <ControllerInput
        withIcon={false}
        name='properties.refId'
        control={control}
        placeholder='Reference ID'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Field label='Data updated'>
       <ControllerInput
        withIcon={false}
        name='properties.updated'
        control={control}
        placeholder='YYYY/DD/MM'
        inputStyles={styles.inputStyle}
       />
      </Field>

      <Field label='Health'>
       <Controller
        control={control}
        render={({ field: { value } }) => {
         return (
          <Select
           value={value}
           placeholder='Choose health'
           onPress={() => onselect('Health', 'health', healthOptions)}
          />
         );
        }}
        name='properties.health'
       />
      </Field>

      <Field label='Structure Condition'>
       <Controller
        control={control}
        render={({ field: { value } }) => {
         return (
          <Select
           value={value}
           placeholder='Choose structure condition'
           onPress={() =>
            onselect('Structure condition', 'structure', structureOptions)
           }
          />
         );
        }}
        name='properties.structure'
       />
      </Field>

      <Field label='Resilience Estimation'>
       <Controller
        control={control}
        render={({ field: { value } }) => {
         return (
          <Select
           value={value}
           placeholder='Choose resilience estimation'
           onPress={() =>
            onselect('Resilience estimation', 'resilience', resilienceIndicator)
           }
          />
         );
        }}
        name='properties.resilience'
       />
      </Field>

      <Field label='User defined 1'>
       <ControllerInput
        withIcon={false}
        name='properties.customField1'
        control={control}
        placeholder='Max. symbols 50'
        inputStyles={{ marginBottom: 10 }}
       />
      </Field>

      <Field label='User defined 2'>
       <ControllerInput
        withIcon={false}
        name='properties.customField2'
        control={control}
        placeholder='Max. symbols 50'
        inputStyles={{ marginBottom: 10 }}
       />
      </Field>

      <Field label='User defined 3'>
       <ControllerInput
        withIcon={false}
        name='properties.customField3'
        control={control}
        placeholder='Max. symbols 50'
        inputStyles={{ marginBottom: 10 }}
       />
      </Field>
     </ScreenContainer>
    </ScrollView>
   </KeyboardAwareScrollView>

   <ScreenContainer style={styles.submitButtonStyle}>
    <Button
     title='Confirm'
     bgColor={colors.NORMAL_GREEN}
     onPress={handleSubmit(onSubmit)}
    />
   </ScreenContainer>
  </>
 );
};

export default AdditionalModal;

const Field = ({ label, marginBottom = 16, children }) => (
 <View style={{ marginBottom: marginBottom }}>
  <Text
   color='NEUTRAL600'
   fontSize={fontSize.body3}
   fontType='MontserratSemiBold'
   style={{ marginBottom: 8 }}>
   {label}
  </Text>
  {children}
 </View>
);

const styles = StyleSheet.create({
 desc: {
  marginBottom: 16,
  marginTop: 10,
 },
 inputStyle: { marginBottom: 10 },

 screenContainer: { marginBottom: 0, paddingTop: 10, backgroundColor: '#fff' },
 submitButtonStyle: {
  marginBottom: 89,
  paddingTop: 10,
  backgroundColor: '#fff',
 },
});
