import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import SquircleContainer from 'components/reusable/SquircleContainer';

import { getColor } from 'utils/settings';

const AddTreeButton = ({ onPress }) => {
 return (
  <SquircleContainer
   bgColor={getColor('LIGHT_GREEN_HOVER')}
   cornerRadius={12}
   style={styles.squircle}>
   <TouchableOpacity onPress={onPress} style={styles.button}>
    <Text style={WebStyles.addtTreeText}>+ Add Tree</Text>
   </TouchableOpacity>
  </SquircleContainer>
 );
};

const styles = StyleSheet.create({
 squircle: {
  position: 'absolute',
  width: 107,
  height: 44,
  right: 24,
  top: 24,
 },
 button: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});

export default AddTreeButton;
