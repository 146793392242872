import React from 'react';
import { StyleSheet, View, Image } from 'react-native';

import SquircleContainer from './SquircleContainer';

import Icon from 'reusable/Icon';

import { lineHeight } from 'utils/theme';

const AvatarComponent = ({ image, children }) => (
 <SquircleContainer
  bgColor='#fff'
  cornerRadius={20}
  style={styles.profileImageContainer}>
  {image ? (
   <Image
    style={{ flex: 1, width: '100%', borderRadius: 16 }}
    source={{ uri: image }}
   />
  ) : (
   <Icon name='userBg' style={{ color: '#D96C31', fontSize: 35 }} />
  )}

  {children}
 </SquircleContainer>
);

const Avatar = ({ mode, image = '' }) => {
 if (mode === 'view') {
  return <AvatarComponent image={image} />;
 } else if (mode === 'edit') {
  return (
   <AvatarComponent image={image}>
    <Icon name='camera' style={styles.icon} />
    <View style={styles.layerStyle}></View>
   </AvatarComponent>
  );
 }
};

export default Avatar;

const styles = StyleSheet.create({
 profileImageContainer: {
  width: 76,
  height: 76,
  alignItems: 'center',
  justifyContent: 'center',
 },

 icon: {
  color: '#fff',
  position: 'absolute',
  zIndex: 1,
  ...lineHeight.h2,
 },
 layerStyle: {
  backgroundColor: '#0F1118',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  opacity: 0.3,
  borderRadius: 16,
 },
});
