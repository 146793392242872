import React from 'react';
import {
 View,
 Text,
 TouchableOpacity,
 StyleSheet,
 Linking,
} from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

const TermsAndConditions = () => {
 return (
  <View style={styles.container}>
   <TouchableOpacity
    onPress={() =>
     Linking.openURL('https://app.websitepolicies.com/policies/view/q43ixxbe')
    }>
    <Text style={WebStyles.webDefaultText}>Terms & Conditions</Text>
   </TouchableOpacity>
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  position: 'absolute',
  left: 72,
  bottom: 24,
 },
});

export default TermsAndConditions;
