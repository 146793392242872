import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import Icon from 'reusable/Icon';

const Close = () => {
 const navigation = useNavigation();

 return (
  <TouchableOpacity
   onPress={() => navigation.goBack()}
   style={{ marginLeft: 3 }}>
   <Icon style={styles.iconStyle} name='plus2' />
  </TouchableOpacity>
 );
};

export default Close;

const styles = StyleSheet.create({
 iconStyle: {
  color: '#0F1118',
  fontSize: 24,
  transform: [{ rotate: '45deg' }],
 },
});
