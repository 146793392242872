const AUTH_URI = 'https://auth.tremap.com/api';
const API_MAP = 'https://clusters.tremap.com/api';
const API_CLUSTERS = 'https://3.8.194.128/';
const MAP_ACCESSTOKEN =
 'pk.eyJ1IjoidHJlbWFwIiwiYSI6ImNsODJzcTU4ZjBzczgzdXIzZW91OGVkenYifQ.B8bPtJAsYVkJSkv1MqrmDg';
const GENERATE_SOURCEMAP = false;

const DELETEUSER = `${AUTH_URI}/delete/user`;
const SIGNIN = `${AUTH_URI}/signIn`;
const SIGNUP = `${AUTH_URI}/signup`;
const SIGNINBYGMAIL = `${AUTH_URI}/signIn/google`;
const SIGNUPBYGMAIL = `${AUTH_URI}/signup/google`;
const SIGNINBYFB = `${AUTH_URI}/signIn/facebook`;
const SIGNUPBYFB = `${AUTH_URI}/signup/facebook`;

const SIGNINBYAPPLE = `${AUTH_URI}/signIn/apple`;
const SIGNUPBYAPPLE = `${AUTH_URI}/signup/apple`;

const VERIFY = `${AUTH_URI}/verify`;
const GET_USER_ID = `${AUTH_URI}/getUserId`;
const SIGNIN_WITH_FB = `${AUTH_URI}/user/facebook`;
const SIGNIN_WITH_GOOGLE = `${AUTH_URI}/user/google`;
const SIGNIN_WITH_APPLE = `${AUTH_URI}/user/apple`;
const SEND_EMAIL_FOR_RESET_PASSWORD = `${AUTH_URI}/reset/password/link`;
const RESET_PASSWORD = `${AUTH_URI}/reset/password`;
const RESET_PASSWORD2 = `${AUTH_URI}/reset/password/send/otp`;
const VERIFY_PASSWORD_CODE = `${AUTH_URI}/verify/reset/password/code`;
const CHANGEPASSWORD = `${AUTH_URI}/reset/password/otp`;
const PROFILE = `${AUTH_URI}/profile`;
const EDITPASSWORD = `${AUTH_URI}/edit/password`;
const EDITEMAIL = `${AUTH_URI}/edit/email`;
const UPLOADAVATAR = `${AUTH_URI}/add-image`;
const ENTERPRISESIGNUP = `${AUTH_URI}/enterprise/signUp`;
const UPDATENAME = `${AUTH_URI}/edit/name`;
const ADDTREE = `${API_MAP}/addTree`;
const EDITTREE = `${API_MAP}/updateTree`;
const GETIMAGE = `${AUTH_URI}/get-image`;
const GETPAYMENT = `${AUTH_URI}/get/payment`;
const TRANSACTIONHISTORY = `${AUTH_URI}/transaction/history`;
const GETGEOFENCES = `${API_MAP}/get/allGeoFences`;
const GETENTERPRISEGEOFENCES = `${API_MAP}/get/geofences?enterpriseId=`;
const ADDGEOFENCE = `${API_MAP}/add/geofence`;
const EDITGEOFENCE = `${API_MAP}/edit/geofence`;
const DELETEGEOFENCE = `${API_MAP}/delete/geofence`;
const SAVEAVATAR = `${AUTH_URI}/edit/avatar`;
const GETUSERSINENTERPRISE = `${AUTH_URI}/get/enterprise/users?enterpriseId=`;
const SENDINVITETOUSER = `${AUTH_URI}/enterprise/invite/user`;
const CHANGE_PERMISSION = `${AUTH_URI}/enterprise/change/permission`;
const REMOVEUSERFROMENTERPRISE = `${AUTH_URI}/enterprise/user/delete`;
const PERMISSIONS = `${AUTH_URI}/get/permission`;
const SUGGESTIONS = `${API_MAP}/suggestions`;
const COMMENTS = `${API_MAP}/commons`;
const DELETETREE = `${API_MAP}/deleteTree`;
const TREEINFO = `${API_MAP}/treeInfo`;
const TREECOUNT = `${API_MAP}/treeCount`;
const GETENTERPRISETREES = `${API_MAP}/get/organizationTreeCount?`;
const RESEND_OTP = `${AUTH_URI}/otp`;
const GETFULLTREEINFO = `${API_MAP}/getTree`;
const DELETETREEIMAGE = `${API_MAP}/deleteTreeImg?fileId=`;
const SEARCHTREE = `${API_MAP}/search`;
const UNREGISTEREDUSERINENTERPRISE = `${AUTH_URI}/enterprise/verify/unregistered/user`;
const GETCOLLECTION = `${API_MAP}/dashboard?`;
const GETCSV = `${API_MAP}/getTreesCsv?`;
const FILTER_SEARCHED_TREES = `${API_MAP}/filterSearchedTrees`;
const UPDATE_TREE_VISIBILITY = `${API_MAP}/updatePrivate`;
const DELETE_SELECTED_TREES = `${API_MAP}/deleteManyTrees`;
const CHANGE_ENTERPRISE_NAME = `${AUTH_URI}/enterprise/edit/name`;
const CHANGE_ENTERPRISE_AVATAR = `${AUTH_URI}/enterprise/edit/avatar`;

export {
 SIGNIN,
 SIGNUP,
 VERIFY,
 DELETEUSER,
 SIGNINBYFB,
 SIGNUPBYFB,
 GET_USER_ID,
 SIGNIN_WITH_FB,
 SIGNINBYAPPLE,
 SIGNUPBYAPPLE,
 SIGNINBYGMAIL,
 SIGNUPBYGMAIL,
 SIGNIN_WITH_GOOGLE,
 SEND_EMAIL_FOR_RESET_PASSWORD,
 RESET_PASSWORD,
 PROFILE,
 EDITPASSWORD,
 EDITEMAIL,
 UPDATENAME,
 UPLOADAVATAR,
 ENTERPRISESIGNUP,
 ADDTREE,
 GETIMAGE,
 GETPAYMENT,
 TRANSACTIONHISTORY,
 GETGEOFENCES,
 ADDGEOFENCE,
 SAVEAVATAR,
 RESET_PASSWORD2,
 VERIFY_PASSWORD_CODE,
 CHANGEPASSWORD,
 GETENTERPRISEGEOFENCES,
 GETUSERSINENTERPRISE,
 SENDINVITETOUSER,
 CHANGE_PERMISSION,
 REMOVEUSERFROMENTERPRISE,
 PERMISSIONS,
 SUGGESTIONS,
 COMMENTS,
 DELETETREE,
 TREEINFO,
 EDITTREE,
 DELETETREEIMAGE,
 GETFULLTREEINFO,
 SEARCHTREE,
 EDITGEOFENCE,
 DELETEGEOFENCE,
 TREECOUNT,
 GETENTERPRISETREES,
 RESEND_OTP,
 UNREGISTEREDUSERINENTERPRISE,
 GETCOLLECTION,
 MAP_ACCESSTOKEN,
 GENERATE_SOURCEMAP,
 API_MAP,
 AUTH_URI,
 API_CLUSTERS,
 GETCSV,
 FILTER_SEARCHED_TREES,
 UPDATE_TREE_VISIBILITY,
 DELETE_SELECTED_TREES,
 CHANGE_ENTERPRISE_NAME,
 SIGNIN_WITH_APPLE,
 CHANGE_ENTERPRISE_AVATAR,
};
