import useProfile from 'hooks/useProfile';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
 ScrollView,
 StyleSheet,
 Text,
 TouchableOpacity,
 View,
} from 'react-native';

import CollectionTree from './CollectionTree';
import HomeContainer from './HomeContainer';
import Loading from './Loading';
import Pagination from './Pagination';
import Selectable from './Selectable';
import WebCommonName from './WebCommonName';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { downloadCsv, getCollection } from 'services/treeServices';

import { storageGetString } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';
import { WebSettings } from 'utils/svgs';

const headers = [
 { label: 'createdAt', key: 'createdAt' },
 { label: 'custom', key: 'custom' },
 { label: 'geometry', key: 'geometry.coordinates' },
 { label: 'organizationId', key: 'organizationId' },
 { label: 'organizationName', key: 'organizationName' },
 { label: 'private', key: 'private' },
 { label: 'age', key: 'properties.age' },
 { label: 'biomass', key: 'properties.biomass' },
 { label: 'calculatedAge', key: 'properties.calculatedAge' },
 { label: 'common', key: 'properties.common' },
 { label: 'coordinates', key: 'coordinates' },
 { label: 'crown', key: 'properties.crown' },
 { label: 'customField1', key: 'properties.customField1' },
 { label: 'customField2', key: 'properties.customField2' },
 { label: 'customField3', key: 'properties.customField3' },
 { label: 'diameter', key: 'properties.diameter' },
 { label: 'family', key: 'properties.family' },
 { label: 'genus', key: 'properties.genus' },
 { label: 'health', key: 'properties.health' },
 { label: 'height', key: 'properties.height' },
 { label: 'location', key: 'properties.location' },
 { label: 'planted', key: 'properties.planted' },
 { label: 'refId', key: 'properties.refId' },
 { label: 'resilience', key: 'properties.resilience' },
 { label: 'species', key: 'properties.species' },
 { label: 'structure', key: 'properties.structure' },
 { label: 'updated', key: 'properties.updated' },
 { label: 'type', key: 'type' },
 { label: 'userId', key: 'userId' },
 { label: '_id', key: '_id' },
];

const CollectionManagement = () => {
 const csvRef = useRef();
 const [showFilter, setShowFilter] = useState(false);
 const [genus, setGenus] = useState('');
 const [species, setSpecies] = useState([]);
 const [speciesValue, setSpeciesValue] = useState('');
 const [common, setCommon] = useState('');
 const [collection, setCollection] = useState({});
 const [currentPage, setCurrentPage] = useState(1);
 const [loaded, setLoaded] = useState(false);
 const [csv, setCsv] = useState([]);

 const postsPerPage = 10;
 const totalCount = collection?.count?.totalCount || 0;

 const totalPageCount = Math.ceil(totalCount / postsPerPage);

 const clear = () => {
  setCommon('');
  setGenus('');
  setSpecies([]);
  setSpeciesValue('');
 };

 const genusArr = storageGetString('genusData');

 const data = JSON.parse(genusArr);

 const { profile } = useProfile();
 const enterpriseId = profile.enterprise;
 const userId = profile._id;

 const getSpecies = (item) => {
  setGenus(item?.item?.genus || item?.genus);
  setSpecies(item?.item?.species || item?.species);
 };

 const getSpeciesValue = (item) => {
  setSpeciesValue(item.item || item);
 };

 const fetchCollection = async () => {
  const response = await getCollection(
   currentPage,
   postsPerPage,
   enterpriseId,
   userId,
   speciesValue,
   genus,
   common
  );
  setCollection(response);
  setLoaded(true);
 };

 useEffect(() => {
  fetchCollection();
 }, [common, genus, speciesValue, currentPage]);

 useEffect(() => {
  fetchCsv();
 }, []);

 const fetchCsv = async () => {
  const res = await downloadCsv(enterpriseId, userId);

  if (res?.trees?.length > 0) {
   setCsv(res.trees);
  }
 };

 const csvDownload = () => {
  if (common === '' && genus === '' && species.length === 0) {
   return csv;
  } else {
   return collection.trees;
  }
 };

 return (
  <HomeContainer>
   {loaded ? (
    <>
     <View style={styles.container}>
      <Text style={WebStyles.currentPlan}>Collection management</Text>
      <View style={styles.flexRow}>
       <View style={[styles.flexRow, { alignItems: 'center' }]}>
        {csv.length > 0 ? (
         <TouchableOpacity
          onPress={() => {
           csvRef.current.link.click();
          }}
          style={{ marginRight: 24 }}>
          <Text style={WebStyles.editGeofence}>Download CSV file</Text>
          <CSVLink
           ref={csvRef}
           filename='Tremap_data-export.csv'
           className='hidden'
           data={csvDownload()}
           headers={headers}
           target='_blank'
          />
         </TouchableOpacity>
        ) : null}
        <TouchableOpacity
         style={styles.filterButton}
         onPress={() => setShowFilter((prev) => !prev)}>
         <WebSettings color={showFilter ? getColor('error') : '#292D32'} />
         <Text
          style={[
           WebStyles.filterText,
           showFilter ? { color: getColor('error') } : null,
          ]}>
          Filters
         </Text>
        </TouchableOpacity>
       </View>
      </View>
     </View>
     {showFilter && (
      <View style={styles.filterCont}>
       <View style={styles.inputWrapperCont}>
        <Text style={[WebStyles.genusFilterText, { marginBottom: 8 }]}>
         Common name
        </Text>
        <WebCommonName filter value={common} setValue={setCommon} />
       </View>
       <View style={styles.inputWrapperCont}>
        <View style={styles.genusCont}>
         <Text style={WebStyles.genusFilterText}>Genus</Text>
         <TouchableOpacity onPress={clear}>
          <Text
           style={[
            WebStyles.genusFilterText,
            { color: getColor('NORMAL_RED') },
           ]}>
           Clear
          </Text>
         </TouchableOpacity>
        </View>

        <Selectable
         placeholder='Select'
         genus={genus}
         setGenus={setGenus}
         setSpecies={setSpecies}
         data={data}
         getSpecies={getSpecies}
        />
       </View>
       <View style={[styles.inputWrapperCont, { marginRight: 0 }]}>
        <Text style={[WebStyles.genusFilterText, { marginBottom: 8 }]}>
         Species
        </Text>

        <Selectable
         placeholder='Select'
         speciesValue={speciesValue}
         setSpeciesValue={setSpeciesValue}
         getSpeciesValue={getSpeciesValue}
         data={species}
        />
       </View>
      </View>
     )}
     <ScrollView style={styles.scrollCont}>
      {collection?.trees?.map((item, index) => {
       return (
        <View key={index} style={{ marginRight: 20 }}>
         <CollectionTree data={item} />
        </View>
       );
      })}
     </ScrollView>
     <Pagination
      data={collection}
      currentPage={currentPage}
      pageSize={postsPerPage}
      totalCount={totalPageCount}
      onPageChange={(page) => setCurrentPage(page)}
     />
    </>
   ) : (
    <Loading />
   )}
  </HomeContainer>
 );
};

const styles = StyleSheet.create({
 container: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 24,
 },
 filterButton: {
  width: 88,
  height: 44,
  borderRadius: 12,
  backgroundColor: getColor('NEUTRAL200'),
  alignItems: 'center',
  justifyContent: 'space-evenly',
  flexDirection: 'row',
 },
 filterCont: { flexDirection: 'row' },
 inputWrapperCont: { marginRight: 24, width: '20%' },
 genusCont: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 8,
 },
 scrollCont: { flex: 1, marginTop: 24, zIndex: -1 },
 flexRow: { flexDirection: 'row' },
});

export default CollectionManagement;
