import usePermission from 'hooks/usePermission';
import React, { useEffect } from 'react';
import { View, Pressable, StyleSheet, Platform, Alert } from 'react-native';
import Animated, {
 useAnimatedStyle,
 useSharedValue,
 withTiming,
} from 'react-native-reanimated';

import authStore from 'store/authStore';

import Icon from 'components/reusable/Icon';
import SquircleContainer from 'components/reusable/SquircleContainer';

import { WINDOW_WIDTH } from 'utils/dimensions';
import { storageGetString } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';
import { colors, fonts, fontSize } from 'utils/theme';

const TAB_HEIGHT = 48;
const TAB_BAR_MARGIN = 20;
const TAB_BAR_PADDING = 8;
const TAB_BAR_INNER_WIDTH =
 WINDOW_WIDTH - TAB_BAR_MARGIN * 2 - TAB_BAR_PADDING * 2 - 16;

const Indicator = ({ width, offset }) => (
 <Animated.View style={[styles.indicator, offset, { width: width }]} />
);

const Tab = ({ isFocused, IconName, index, title, onPress }) => {
 const colorValue = !isFocused
  ? getColor('NEUTRAL700')
  : getColor('NORMAL_GREEN_HOVER');

 const opacityValue = !isFocused ? 7 : 0;
 const displayValue = !isFocused ? 0 : 1;

 const textColor = useAnimatedStyle(() => {
  const color = withTiming(colorValue, { duration: 100 });

  return { color };
 });

 const addTreeIconStyle = useAnimatedStyle(() => {
  const color = withTiming(colorValue, { duration: 100 });

  return { color };
 });

 const otherStyle = useAnimatedStyle(() => {
  const color = withTiming(colorValue, { duration: 100 });
  const transform = withTiming(opacityValue, { duration: 100 });
  const scale = withTiming(displayValue, { duration: 100 });

  return { color, transform: [{ translateX: transform }, { scale }] };
 });

 const reversedFlexStyle =
  index === 0 ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' };
 const reversedMarginStyle =
  index === 0 ? { marginLeft: 5 } : { marginRight: 5 };

 return (
  <Pressable onPress={onPress} style={styles.tabWrapper}>
   <Animated.View style={[styles.tabLabel, reversedFlexStyle]}>
    {title && isFocused && (
     <Animated.Text
      style={[{ ...styles.textStyle, ...reversedMarginStyle }, otherStyle]}>
      {title}
     </Animated.Text>
    )}

    {IconName === 'plus2' ? (
     <Icon
      name='plus2'
      animated={true}
      style={[{ fontSize: 25 }, addTreeIconStyle]}
     />
    ) : (
     <Icon
      name={isFocused ? `${IconName}Bg` : IconName}
      animated={true}
      style={[{ fontSize: 25 }, textColor]}
     />
    )}
   </Animated.View>
  </Pressable>
 );
};

const Tabs = ({ state, navigation }) => {
 const { permissions } = usePermission();

 const focusedTab = useSharedValue(0);
 const tabWidth = TAB_BAR_INNER_WIDTH / state.routes.length;

 const indicatorOffset = useAnimatedStyle(() => {
  return {
   transform: [
    {
     translateX: withTiming(focusedTab.value * tabWidth, {
      duration: 200,
     }),
    },
   ],
  };
 }, [focusedTab.value]);

 useEffect(() => {
  if (state.routes[0].params.haveToRenderTabs) {
   focusedTab.value = 0;
   navigation.setParams({
    haveToRenderTabs: false,
   });
  }

  if (state.index === 0) {
   focusedTab.value = 0;
  }
 }, [state]);

 if (navigation.getState().index !== 1) {
  return (
   <SquircleContainer style={styles.container} cornerRadius={16} bgColor='#fff'>
    <View style={styles.tabsWrapper}>
     <Indicator width={tabWidth + 15} offset={indicatorOffset} />

     {state.routes.map((route, index) => {
      const label = route.params.name;
      const isFocused = state.index === index;

      const onPress = () => {
       const token = storageGetString('AccessToken');

       if (index === 1) {
        if (token && !storageGetString('clustersData') && authStore.isOffline) {
         Alert.alert(
          '',
          'No imagery for this location, position the tree anyway?'
         );
        } else if (!token) {
         navigation.navigate('Settings', { screen: 'Signin' });
        } else {
         focusedTab.value = index;
         navigation.navigate({ name: route.name });
        }
       } else {
        if ((!isFocused && token) || (!token && index !== 1)) {
         if (permissions !== 'Viewer') {
          focusedTab.value = index;
          navigation.navigate({ name: route.name });
         } else {
          Alert.alert('You do not have a permission');
         }
        }
       }
      };

      return (
       <Tab
        key={index}
        index={index}
        title={label}
        IconName={route.params.tabIcon}
        isFocused={isFocused}
        onPress={onPress}
       />
      );
     })}
    </View>
   </SquircleContainer>
  );
 }

 return null;
};

const styles = StyleSheet.create({
 container: {
  position: 'absolute',
  bottom: 30,
  height: 64,
  left: TAB_BAR_MARGIN,
  right: TAB_BAR_MARGIN,
  flexDirection: 'row',
  alignItems: 'center',
  padding: TAB_BAR_PADDING,
  ...Platform.select({
   ios: {
    shadowColor: colors.NORMAL_GREEN,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.08,
   },
   android: {
    elevation: 3,
   },
  }),
  shadowColor: '#00000014',
  shadowOffset: {
   width: 5,
   height: 5,
  },
  shadowOpacity: 1,
  shadowRadius: 12,
 },
 indicator: {
  position: 'absolute',
  height: TAB_HEIGHT,
  borderRadius: 12,
  backgroundColor: colors.LIGHT_GREEN_HOVER,
 },
 tabsWrapper: {
  flexGrow: 1,
  flexDirection: 'row',
  alignItems: 'center',
  height: TAB_HEIGHT,
  paddingHorizontal: 9,
 },
 tabWrapper: {
  flex: 1,
  height: TAB_HEIGHT,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 1,
  width: TAB_BAR_INNER_WIDTH / 3,
 },
 tabLabel: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: 0.3,
  fontFamily: fonts.MontserratSemiBold,
  ...fontSize.button2,
 },
 textStyle: {
  letterSpacing: 0.3,
  fontFamily: fonts.MontserratSemiBold,
  ...fontSize.button2,
 },
});

export default Tabs;
