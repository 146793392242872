import useForm from 'hooks/useForm';
import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Alert from './Alert';
import UploadImageWeb from './UploadImageWeb';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import {
 changeEnterpriseAvatar,
 changeEnterpriseName,
} from 'services/enterpriseServices';
import { deleteTreeImage } from 'services/treeServices';

import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import Input from 'components/reusable/Input';
import { EnterpriseName } from 'components/validations/schema';

import { getUser } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';

const OrganisationProfile = () => {
 const { profile } = useProfile();
 const perrmissions = usePermission();
 const [urlValue, setUrlValue] = useState(
  profile.enterpriseData.sponsorRedirect || ''
 );
 const [enterpriseAvatar, setEnterpriseAvatar] = useState(
  profile.enterpriseData.avatar ? [profile.enterpriseData.avatar] : []
 );
 const enterpriseName = profile.enterpriseData.enterpriseName;
 const enterpriseId = profile.enterprise;

 const defaultValues = {
  enterpriseName,
 };

 const { control, handleSubmit, errors, isSubmitting } = useForm(
  EnterpriseName,
  defaultValues
 );

 const deletePhoto = async (img) => {
  const fileId = img.split('fileId=')[1];
  const res = await changeEnterpriseAvatar('', enterpriseId);
  if (res.type === 'success') {
   const deleteImage = await deleteTreeImage(fileId);
   if (deleteImage.type === 'success') {
    setEnterpriseAvatar([]);
    getUser();
   }
  }
 };

 const uploadImage = async (img) => {
  console.log(img);

  setEnterpriseAvatar([img]);
  const res = await changeEnterpriseAvatar(img, enterpriseId);

  console.log('res...', res);
  if (res.type === 'success') {
   getUser();
  }
 };

 const onSubmit = async (values) => {
  const res = await changeEnterpriseName(
   values.enterpriseName,
   urlValue,
   enterpriseId
  );
  if (res.type === 'success') {
   getUser();
   Defaults.Modal.hide();
   setTimeout(() => {
    Defaults.Modal.show(<Alert alert={res.message} />);
   }, 500);
  }
 };

 return (
  <View style={styles.container}>
   <Text style={[WebStyles.WebHeadText]}>Change organisation info</Text>
   <View style={{ marginBottom: 4 }}>
    <Text style={[WebStyles.addGeofenceTitle]}>Organisation logo</Text>

    <View
     style={{
      flexDirection: 'row',
      marginTop: 10,
     }}>
     <UploadImageWeb
      limit={1}
      type='users'
      imgType='PROFILE'
      images={enterpriseAvatar}
      onDeletePhoto={(img) => deletePhoto(img)}
      setImages={(img) => uploadImage(img)}
      withAvatar={false}
     />
    </View>

    <View style={{ marginTop: 10 }}>
     <Input
      onChangeText={setUrlValue}
      value={urlValue}
      placeholder='Url'
      withIcon={false}
      disabled={perrmissions.permissions !== 'Admin' || false}
      placeholderTextColor={getColor('NEUTRAL300')}
     />
    </View>

    <Text
     style={[WebStyles.imagePickerBotText, { marginBottom: 0, marginTop: 10 }]}>
     Maximum image dimensions: 400 x 400 pixels
    </Text>
   </View>
   <View style={{ marginTop: 10 }}>
    <Text style={[WebStyles.addGeofenceTitle, { marginBottom: 8 }]}>
     Organisation Name
    </Text>
    <ControllerInput
     name='enterpriseName'
     withIcon={false}
     control={control}
     placeholder='Enter name'
     error={errors.enterpriseName?.message}
    />
    <View style={{ height: 20 }}></View>
   </View>
   <View style={styles.buttonStyle}>
    <Button
     title='Save'
     disabled={isSubmitting}
     bgColor={getColor('NORMAL_GREEN')}
     onPress={handleSubmit(onSubmit)}
    />
   </View>
  </View>
 );
};

export default OrganisationProfile;

const styles = StyleSheet.create({
 container: { flex: 1, paddingHorizontal: 100 },
 buttonStyle: { width: '100%', alignSelf: 'center' },
});
