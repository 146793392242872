import React, { useRef } from 'react';
import { FlatList, Image, SafeAreaView, StyleSheet, View } from 'react-native';
import Animated, {
 useAnimatedScrollHandler,
 useSharedValue,
} from 'react-native-reanimated';

import Paginator from './Paginator';
import { DATA } from './data';

import authStore from 'store/authStore';

import Button from 'components/reusable/Button';
import Icon from 'components/reusable/Icon';
import SquircleContainer from 'components/reusable/SquircleContainer';

import Text from 'reusable/Text';

import { SCREEN_WIDTH } from 'utils/dimensions';
import { storageSet } from 'utils/mmkvStorage';
import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const ITEMS_LENGTH = 4;
const GAP_WIDTH = 6;
const ITEM_WIDTH = SCREEN_WIDTH * 0.846; // it's a parcent bro
const ITEM_WIDTH_WITH_GAPS = ITEM_WIDTH + GAP_WIDTH * 2;
const DUMMY_ITEM_WIDTH = (SCREEN_WIDTH - ITEM_WIDTH) / 2 - GAP_WIDTH;
const FULL_ITEM_WIDTH = ITEM_WIDTH + DUMMY_ITEM_WIDTH + GAP_WIDTH;

const itemWidth = Math.ceil(ITEM_WIDTH_WITH_GAPS);

const arr = [0, itemWidth, itemWidth * 2, itemWidth * 3];

const OnBoarding = () => {
 const flatListRef = useRef();
 const currentIndex = useRef(0);
 const onScrollCurrentIndex = useRef(0);
 const scrollWith = useRef();

 const scrollIndex = useSharedValue(0);
 const scrollOffset = useSharedValue(0);

 const onStart = () => {
  const isLastItem = scrollOffset.value > arr[ITEMS_LENGTH - 2];

  if (isLastItem) {
   authStore.isOnboardingCompleted = true;
   storageSet('isOnboardingCompleted', true);
  } else {
   flatListRef.current.scrollToOffset({
    animated: true,
    offset: arr[scrollIndex.value + 1],
   });
  }
 };

 const renderItem = ({ item }) => {
  const isDummy = item.mainTitle === '';

  if (isDummy) {
   return <View style={styles.dummyItem} />;
  } else {
   return (
    <SquircleContainer
     cornerRadius={24}
     bgColor={getColor('LIGHT_GREEN')}
     style={{
      ...styles.onBoardingItem,
      paddingTop: item.id === DATA[DATA.length - 2].id ? 45 : 0,
     }}>
     {item.source && (
      <View style={styles.imageContainer}>
       <Image style={{ width: 300, height: 300 }} source={item.source} />
      </View>
     )}

     <Text
      fontType='MontserratBold'
      fontSize={lineHeight.h2}
      style={{ textAlign: 'center', paddingHorizontal: 22, fontSize: 23 }}>
      {item.mainTitle}
     </Text>

     {item.description && (
      <Text
       fontType='MontserratSemiBold'
       fontSize={lineHeight.body2}
       color='NEUTRAL500'
       style={styles.descriptionStyle}>
       {item.description}
      </Text>
     )}

     {item.moreData?.map((item, index) => (
      <View key={index} style={styles.moredataStyle}>
       <View style={styles.iconContainer}>
        <Icon
         name={item.iconName}
         style={{
          color: getColor('NORMAL_ORANGE'),
          fontSize: 28,
         }}
        />
       </View>

       <Text fontType='MontserratSemiBold' fontSize={lineHeight.subTitle3}>
        {item.text}
       </Text>
      </View>
     ))}

     <Button
      height={52}
      onPress={onStart}
      cornerRadius={40}
      title={item.buttonText}
      bgColor={getColor('NORMAL_GREEN')}
      customStyles={styles.ButtonStyle}
     />
    </SquircleContainer>
   );
  }
 };

 const getItemLayout = (_, index) => ({
  length: FULL_ITEM_WIDTH,
  offset: FULL_ITEM_WIDTH * index,
  index,
 });

 const onScroll = useAnimatedScrollHandler((event) => {
  scrollIndex.value = Math.floor(
   Math.floor(event.contentOffset.x) / Math.round(ITEM_WIDTH_WITH_GAPS)
  );

  scrollOffset.value = Math.floor(event.contentOffset.x);
 });

 const onMomentumScrollEnd = () => {
  if (scrollWith.current === 'CLICK') {
   scrollWith.current = null;
  } else {
   const findIndex = (element) => element === scrollOffset.value;

   const ind = arr.findIndex(findIndex);

   onScrollCurrentIndex.current = Math.abs(ind);
   currentIndex.current = 0;
  }
 };

 return (
  <View style={styles.bgColor}>
   <SafeAreaView style={{ flex: 1, marginTop: 30, marginBottom: 20 }}>
    <Paginator
     currentIndex={currentIndex}
     pageCount={4}
     pages={DATA}
     itemWidth={Math.round(ITEM_WIDTH_WITH_GAPS)}
     scrollOffset={scrollOffset}
     pointerEvents='none'
    />

    <AnimatedFlatList
     data={DATA}
     horizontal
     ref={flatListRef}
     onMomentumScrollEnd={onMomentumScrollEnd}
     pagingEnabled
     onScroll={onScroll}
     renderItem={renderItem}
     decelerationRate='fast'
     disableIntervalMomentum
     scrollEventThrottle={16}
     getItemLayout={getItemLayout}
     showsHorizontalScrollIndicator={false}
     keyExtractor={(item) => item.id.toString()}
     contentContainerStyle={styles.contentContainerStyle}
     snapToOffsets={DATA.map((_, index) => index * ITEM_WIDTH_WITH_GAPS)}
    />
   </SafeAreaView>
  </View>
 );
};

export default OnBoarding;

const styles = StyleSheet.create({
 bgColor: {
  flex: 1,
  paddingTop: 40,
  alignItems: 'center',
  backgroundColor: getColor('NORMAL_GREEN'),
 },
 onBoardingItem: {
  height: '100%',
  marginTop: 'auto',
  alignItems: 'center',
  marginHorizontal: GAP_WIDTH,
  width: ITEM_WIDTH,
 },
 contentContainerStyle: {
  height: '100%',
  paddingTop: 24,
 },
 ButtonStyle: {
  width: 144,
  marginTop: 'auto',
  marginBottom: 48,
 },
 imageContainer: {
  height: 300,
  margin: 20,
 },
 iconContainer: {
  width: 58,
  height: 58,
  backgroundColor: getColor('WHITE'),
  borderRadius: 12,
  padding: 15,
  marginBottom: 16,
  justifyContent: 'center',
  alignItems: 'center',
 },
 moredataStyle: {
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 50,
 },
 dummyItem: {
  width: DUMMY_ITEM_WIDTH,
 },
 descriptionStyle: {
  textAlign: 'center',
  marginTop: 20,
  paddingHorizontal: 24,
 },
});
