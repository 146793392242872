import useForm from 'hooks/useForm';
import useProfile from 'hooks/useProfile';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
 ScrollView,
 StyleSheet,
 Text,
 TouchableOpacity,
 View,
} from 'react-native';

import { geoFenceDefaultValues } from './AddGeoFenceValues';
import Alert from './Alert';
import MultiLineTextInput from './MultiLineTextInput';
import UploadImageWeb from './UploadImageWeb';

import GeofenceMap from 'screens/WebNavigation/WebMap/AddGeofenceMap/index.web';
import MiniMap from 'screens/WebNavigation/WebMap/MiniMap/index.web';
import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { addGeofence, editGeofence } from 'services/mapServices';
import { deleteTreeImage } from 'services/treeServices';

import ModalWrapper from 'components/modals/ModalWrapper';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import InputError from 'components/reusable/InputError';
import { AddGeofenceSchema } from 'components/validations/schema';

import { getGeofence } from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';

const AddGeoFence = ({ editMode, data, centerCoordinates }) => {
 const [mapVisible, setMapVisible] = useState(false);
 const { profile } = useProfile();

 const enterpriseId = profile?.enterprise;
 const properties = data?.feature?.properties;
 const geofenceId = data?.feature?.properties?.id;
 const geometry = data?.feature?.geometry?.coordinates;
 const avatar = data?.feature?.properties?.avatar
  ? JSON.parse(data?.feature?.properties?.avatar)
  : [];

 const defaultValues = geoFenceDefaultValues(
  editMode,
  geometry,
  properties,
  avatar
 );

 const { control, handleSubmit, errors, isSubmitting, getValues, setValue } =
  useForm(AddGeofenceSchema, defaultValues);

 const geoFenceValue = getValues('geofence');

 const onSubmit = async (values) => {
  let response;
  if (editMode) {
   response = await editGeofence(geofenceId, values);
  } else {
   response = await addGeofence(enterpriseId, values);
  }

  if (response.status === 'success') {
   getGeofence();
   Defaults.Modal.hide(<ModalWrapper />);
   setTimeout(() => {
    Defaults.Modal.show(
     <Alert
      alert={
       editMode
        ? 'Geofence changed successfully'
        : 'Geofence added successfully'
      }
     />
    );
   }, 500);
  }
 };

 const deletePhoto = async (img) => {
  const fileId = img.split('fileId=')[1];

  const res = await deleteTreeImage(fileId);

  if (res.type === 'success') {
   setValue('avatar', []);
  }
 };

 return (
  <ScrollView contentContainerStyle={{ flex: 1 }} style={styles.container}>
   {mapVisible ? (
    <View style={styles.mapContainer}>
     <Text style={[WebStyles.currentPlan, { marginBottom: 32 }]}>
      Position geofence on the map
     </Text>
     <GeofenceMap
      centerCoordinates={centerCoordinates}
      getValues={getValues}
      geometry={data?.feature}
      editMode={editMode}
      setValue={setValue}
      setMapVisible={setMapVisible}
     />
    </View>
   ) : (
    <View style={styles.secContainer}>
     <Text style={WebStyles.currentPlan}>
      {editMode ? 'Edit geo-fence' : 'Add geo-fence'}
     </Text>
     <View style={styles.mt24}>
      <View style={styles.miniMapTextContainer}>
       <Text style={[WebStyles.addGeofenceTitle, { marginBottom: 8 }]}>
        Location
       </Text>
       {geoFenceValue.length > 2 && (
        <TouchableOpacity onPress={() => setMapVisible(true)}>
         <Text style={WebStyles.editGeofence}>Edit geo-fence on the map</Text>
        </TouchableOpacity>
       )}
      </View>
      {geoFenceValue.length > 2 && <MiniMap coordinates={geoFenceValue} />}
      {geoFenceValue.length < 2 && (
       <Button
        title='Position on the map'
        bgColor={getColor('NORMAL_GREEN')}
        textColor='#ffffff'
        onPress={() => setMapVisible(true)}
       />
      )}
      <InputError
       error={geoFenceValue.length < 4 ? errors.geofence?.message : null}
      />
     </View>
     <View style={styles.mt24}>
      <Text style={[WebStyles.addGeofenceTitle, { marginBottom: 8 }]}>
       Logo
      </Text>
      <View style={styles.imgContainer}>
       <Controller
        control={control}
        name='avatar'
        render={({ field: { value } }) => {
         return (
          <UploadImageWeb
           limit={1}
           type='polygons'
           images={value}
           onDeletePhoto={(img) => deletePhoto(img)}
           setImages={(img) => setValue('avatar', [...value, img])}
           withAvatar={false}
          />
         );
        }}
       />
      </View>
     </View>
     <View style={{ marginTop: 48 }}>
      <Text style={[WebStyles.addGeofenceTitle, { marginBottom: 16 }]}>
       Label Info
      </Text>
      <View style={styles.mb24}>
       <Text style={[WebStyles.fileSize, { marginBottom: 8, fontSize: 12 }]}>
        Name
       </Text>
       <ControllerInput
        name='geofenceName'
        withIcon={false}
        control={control}
        placeholder='Enter name'
        error={errors.geofenceName?.message}
        inputStyles={{ marginBottom: 10 }}
       />
      </View>
      <View style={styles.mb24}>
       <Text style={[WebStyles.fileSize, { marginBottom: 8, fontSize: 12 }]}>
        Description
       </Text>
       <MultiLineTextInput
        name='info'
        error={errors.info?.message}
        control={control}
        placeholder='Enter description'
       />
      </View>
      <View style={styles.mb24}>
       <Text style={[WebStyles.fileSize, { marginBottom: 8, fontSize: 12 }]}>
        URL link
       </Text>
       <ControllerInput
        name='website'
        withIcon={false}
        control={control}
        placeholder='Enter URL link'
        error={errors.website?.message}
        inputStyles={{ marginBottom: 10 }}
       />
      </View>
      <Button
       title='Save'
       disabled={isSubmitting}
       bgColor={getColor('NORMAL_GREEN')}
       onPress={handleSubmit(onSubmit)}
      />
     </View>
    </View>
   )}
  </ScrollView>
 );
};

const styles = StyleSheet.create({
 container: {
  flex: 1,
  borderRadius: 12,
 },
 mapContainer: {
  flex: 1,
  paddingHorizontal: 20,
 },
 secContainer: { flex: 1, paddingHorizontal: '25%' },
 miniMapTextContainer: {
  justifyContent: 'space-between',
  flexDirection: 'row',
 },
 imgContainer: {
  flexDirection: 'row',
 },
 mb24: {
  marginBottom: 24,
 },
 mt24: {
  marginTop: 24,
 },
});

export default AddGeoFence;
