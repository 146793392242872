import React from 'react';
import { View, Image, StyleSheet } from 'react-native';

import { getColor } from 'utils/settings';

const WithoutImage = ({ image, otherStyles }) => {
 return (
  <View
   style={[
    styles.container,
    { backgroundColor: getColor('WHITE') },
    { ...otherStyles },
   ]}>
   {image ? <Image style={styles.image} source={{ uri: image }} /> : null}
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  width: 48,
  height: 44,
  borderColor: getColor('NEUTRAL300'),
  borderWidth: 2,
  borderRadius: 13,
  alignItems: 'center',
  justifyContent: 'center',
 },
 image: {
  width: 24,
  height: 24,
  resizeMode: 'cover',
  borderRadius: 11,
 },
});

export default WithoutImage;
