import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

import ContactInfo from './ContactInfo';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import ModalWrapper from 'components/modals/ModalWrapper';

import { Defaults } from 'utils/defaults';

const Contact = () => {
 const openModal = () => {
  Defaults.Modal.show(
   <ModalWrapper customStyles={WebStyles.contactModalWrapperStyles}>
    <ContactInfo />
   </ModalWrapper>
  );
 };
 return (
  <View style={styles.container}>
   <TouchableOpacity onPress={() => openModal()}>
    <Text style={WebStyles.webDefaultText}>Contact</Text>
   </TouchableOpacity>
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  position: 'absolute',
  top: 35,
  right: 140,
 },
});

export default Contact;
