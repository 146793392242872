import { useFonts } from 'expo-font';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSnapshot } from 'valtio';

import authStore from 'store/authStore';

import AnimatedSplashScreen from 'components/reusable/AnimatedSplashScreen';

import { Defaults } from 'utils/defaults';
import { storageGetString } from 'utils/mmkvStorage';
import { isWeb } from 'utils/platform';

export default function App() {
 const store = useSnapshot(authStore);
 const [height, setHeight] = useState(undefined);

 const containerStyle = height ? { height, width: '100%' } : { flex: 1 };
 const getAccessToken = storageGetString('AccessToken');

 const [fontsLoaded] = useFonts({
  MyCustomIconSet: require('./assets/fonts/icomoon.ttf'),
  MontserratBold: require('./assets/fonts/Montserrat-Bold.ttf'),
  MontserratRegular: require('./assets/fonts/Montserrat-Regular.ttf'),
  MontserratSemiBold: require('./assets/fonts/Montserrat-SemiBold.ttf'),
  MontserratItalic: require('./assets/fonts/Montserrat-LightItalic.ttf'),
 });

 useEffect(() => {
  if (isWeb && store.isLoggedIn) {
   const userId = storageGetString('userId');
   Defaults.userId = userId;
  }

  if (getAccessToken) {
   authStore.isLoggedIn = true;
  }
 }, [store.isLoggedIn]);

 if (fontsLoaded) {
  if (isWeb) {
   const WebNavigation = require('./src/screens/WebNavigation').default;
   return <WebNavigation />;
  } else {
   if (!store.isAppReady) {
    return <AnimatedSplashScreen />;
   } else {
    const MobileNavigation = require('./src/screens/MobileNavigation').default;
    return (
     <View
      style={containerStyle}
      onLayout={({
       nativeEvent: {
        layout: { height },
       },
      }) => {
       setHeight(height);
      }}>
      {height && <MobileNavigation />}
     </View>
    );
   }
  }
 }

 return null;
}
