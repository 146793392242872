import debounce from 'lodash/debounce';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import Barge from 'components/reusable/Barge';
import Icon from 'components/reusable/Icon';
import Input from 'components/reusable/Input';
import Text from 'components/reusable/Text';

const SearchParent = ({
 getSearchData,
 barges,
 error,
 onDeleteBarge,
 onClose,
}) => {
 const func = debounce(getSearchData, 500);

 return (
  <View style={{ marginTop: 40, alignItems: 'flex-start' }}>
   <View style={styles.searchInput}>
    <View style={{ flexGrow: 1 }}>
     <Input
      placeholder='Search keyword(s)'
      iconName='search'
      onChangeText={func}
      isSearchInput={true}
      searchInputColor='#F4F5F8'
     />
    </View>

    <Text
     fontSize={14}
     fontType='MontserratRegular'
     color='NEUTRAL900'
     style={{ marginLeft: 12 }}
     onPress={onClose}>
     Cancel
    </Text>
   </View>

   {error && (
    <View style={styles.keywoordsParent}>
     <Icon name='warning' style={{ color: '#E45839', fontSize: 23 }} />
     <Text
      fontSize={12}
      fontType='MontserratRegular'
      color='error'
      style={{ marginLeft: 8 }}>
      Maximum amount of keywords is 3
     </Text>
    </View>
   )}

   <View style={styles.bargesView}>
    {barges.length > 0 &&
     barges.map((w) => (
      <View style={styles.bargeContainer} key={w._id}>
       <Barge
        title={
         w?.properties?.common || w?.properties?.common || w?.enterpriseName
        }
        onDelete={() => onDeleteBarge(w)}
       />
      </View>
     ))}
   </View>
  </View>
 );
};

export default SearchParent;

const styles = StyleSheet.create({
 searchInput: {
  alignItems: 'center',
  flexDirection: 'row',
 },
 bargesView: {
  marginTop: 12,
  flexWrap: 'wrap',
  flexDirection: 'row',
 },
 bargeContainer: {
  marginRight: 10,
  marginBottom: 10,
 },
 keywoordsParent: {
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 8,
 },
});
