import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import InputDropdown from './InputDropdown';
import UserImageContainer from './UserImageContainer';
import WithoutImage from './WithoutImage';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import SquircleContainer from 'components/reusable/SquircleContainer';

import { IMAGES } from 'utils/images';
import { getColor } from 'utils/settings';

const droppedButtons = [
 { name: 'Admin' },
 { name: 'Editor' },
 { name: 'Viewer' },
 { name: 'Remove' },
];

const EnterpriseUsersContainer = ({
 data,
 userIdFilter,
 setUserIdFilter,
 getEnterpriseUsers,
}) => {
 const { profile } = useProfile();
 const { permissions } = usePermission();

 const isSameUser = profile.email === data.email;
 const isTrue = data.userId === userIdFilter;

 return (
  <SquircleContainer
   strokeWidth={0}
   bgColor={getColor('NEUTRAL200')}
   style={[styles.squircle, { zIndex: -1 }, isTrue && { zIndex: 1 }]}>
   <View style={styles.container}>
    <View style={styles.imageWrapper}>
     {data.avatar ? <UserImageContainer image={data.avatar} /> : null}
     {!data.avatar ? <WithoutImage image={IMAGES.profile} /> : null}
     <View style={styles.txtWrapper}>
      <Text
       style={[
        WebStyles.userNameText,
        data.status === 'Pending' ? { color: getColor('NEUTRAL500') } : null,
       ]}>
       {data.fullName || data.email}
      </Text>
      <Text style={[WebStyles.userEmail]}>{data.status || data.email}</Text>
     </View>
    </View>
    <View style={{ flexDirection: 'row' }}>
     {isSameUser || permissions === 'Viewer' ? (
      <Text style={[WebStyles.userNameText, { marginRight: 24 }]}>
       {permissions}
      </Text>
     ) : (
      <InputDropdown
       setUserIdFilter={setUserIdFilter}
       droppedButtons={droppedButtons}
       data={data}
       id={data.userId}
       isTrue={isTrue}
       getEnterpriseUsers={getEnterpriseUsers}
      />
     )}
    </View>
   </View>
  </SquircleContainer>
 );
};

const styles = StyleSheet.create({
 squircle: {
  width: '100%',
  height: 56,
  marginBottom: 12,
 },
 container: {
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
 },
 imageWrapper: { flexDirection: 'row', marginLeft: 8, alignItems: 'center' },
 txtWrapper: {
  marginLeft: 16,
  justifyContent: 'space-evenly',
  height: 44,
 },
});

export default EnterpriseUsersContainer;
