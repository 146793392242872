import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Icon from 'reusable/Icon';

import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const InputError = ({ error, withIcon }) => {
 return error ? (
  <View style={styles.errorContainer}>
   {withIcon && <Icon style={styles.errorIconStyle} name='forbidden' />}
   <Text style={styles.errorTextStyle}>{error}</Text>
  </View>
 ) : null;
};

export default InputError;

InputError.defaultProps = {
 withIcon: true,
};

const styles = StyleSheet.create({
 errorContainer: {
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: -1,
 },
 errorTextStyle: {
  marginLeft: 7,
  color: getColor('error'),
  ...lineHeight.body3,
 },
 errorIconStyle: {
  marginLeft: 7,
  color: getColor('error'),
  ...lineHeight.h3,
 },
});
