import useForm from 'hooks/useForm';
import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import React, { useEffect, useState } from 'react';
import {
 ScrollView,
 StyleSheet,
 Text,
 View,
 TouchableOpacity,
} from 'react-native';

import EnterpriseUsersContainer from './EnterpriseUsersContainer';
import HomeContainer from './HomeContainer';
import InputDropdown from './InputDropdown';
import Loading from './Loading';
import MaximumUsersExceeded from './MaximumUsersExceeded';
import Pagination from './Pagination';
import PermissionsInfo from './PermissionsInfo';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { getUsersInEnterprise, inviteUser } from 'services/enterpriseServices';

import ModalWrapper from 'components/modals/ModalWrapper';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import InputError from 'components/reusable/InputError';
import ResponseMessage from 'components/reusable/InputSuccess';
import SquircleContainer from 'components/reusable/SquircleContainer';
import { userPermissionSchema } from 'components/validations/schema';

import { Defaults } from 'utils/defaults';
import { getColor } from 'utils/settings';
import { OrangeQuestionMark } from 'utils/svgs';

const droppedButtons = [
 { name: 'Admin' },
 { name: 'Editor' },
 { name: 'Viewer' },
];

const Users = () => {
 const [enterpriseUsers, setEnterpriseUsers] = useState([]);
 const [userIdFilter, setUserIdFilter] = useState('');
 const [successMessage, setSuccessMessage] = useState('');
 const [errorMessage, setErrorMessage] = useState('');
 const [currentPage, setCurrentPage] = useState(1);
 const [count, setCount] = useState('');
 const [permCount, setPermCount] = useState({});
 const [loaded, setLoaded] = useState(false);
 const { profile } = useProfile();
 const { permissions } = usePermission();

 const enterpriseId = profile?.enterprise;
 const maximumUserAmount = profile?.enterpriseData?.boughtUsers;
 const defaultValues = { email: '', type: '' };
 const usersPerPage = 10;
 const totalPageCount = Math.ceil(count / usersPerPage);

 const { control, handleSubmit, errors, isSubmitting, setValue } = useForm(
  userPermissionSchema,
  defaultValues
 );

 const getEnterpriseUsers = async () => {
  const res = await getUsersInEnterprise(
   enterpriseId,
   currentPage,
   usersPerPage
  );

  if (res.type === 'success') {
   setEnterpriseUsers(res.usersArr);
   setCount(res.totalCount);
   setPermCount(res.permissionCount);
   setLoaded(true);
  }
 };

 const onSubmit = async (values) => {
  const res = await inviteUser(values.email, enterpriseId, values.type);

  if (res.type === 'success') {
   setSuccessMessage(res.message);
   setTimeout(() => {
    setSuccessMessage('');
   }, '5000');
   getEnterpriseUsers();
  }
  if (res.status === 'Fail') {
   setErrorMessage(res.message);
   setTimeout(() => {
    setErrorMessage('');
   }, '5000');
  }
  if (
   res.message ===
   'You have exceeded the amount of users bought for this enterprise!'
  ) {
   Defaults.Modal.show(
    <ModalWrapper customStyles={WebStyles.userErrorModalWrapperStyles}>
     <MaximumUsersExceeded />
    </ModalWrapper>
   );
  }
 };

 const openModal = () => {
  Defaults.Modal.show(
   <ModalWrapper customStyles={WebStyles.userErrorModalWrapperStyles}>
    <PermissionsInfo />
   </ModalWrapper>
  );
 };

 useEffect(() => {
  getEnterpriseUsers();
 }, [currentPage]);

 return (
  <HomeContainer>
   {loaded ? (
    <>
     <View style={styles.topContainer}>
      <View>
       <View style={styles.inputContainer}>
        <Text style={[WebStyles.currentPlan]}>
         Users{' '}
         <Text style={WebStyles.usersCount}>
          <Text style={{ color: getColor('NEUTRAL800') }}>({count}</Text> of{' '}
          {maximumUserAmount ? (
           maximumUserAmount === 2147483647 ? (
            <Text
             style={{
              color: getColor('NEUTRAL800'),
             }}>
             ∞
            </Text>
           ) : (
            maximumUserAmount
           )
          ) : (
           count
          )}
          <Text style={{ color: getColor('NEUTRAL800') }}>)</Text>
         </Text>
        </Text>
       </View>
       <View style={{ flexDirection: 'row' }}>
        <Text style={WebStyles.userNameText}>
         Admin({permCount.adminCount})
        </Text>
        <Text style={[WebStyles.userNameText, { marginLeft: 24 }]}>
         Viewer({permCount.viewerCount})
        </Text>
        <Text style={[WebStyles.userNameText, { marginLeft: 24 }]}>
         Editor({permCount.editorCount})
        </Text>
       </View>
      </View>

      {count < maximumUserAmount && permissions === 'Admin' && (
       <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity
         onPress={() => openModal()}
         style={styles.questionMarkStyle}>
         <OrangeQuestionMark />
        </TouchableOpacity>

        <View style={{ flexDirection: 'column' }}>
         <SquircleContainer
          bgColor={getColor('WHITE')}
          strokeWidth={1}
          strokeColor={
           errors.email || errors.type
            ? getColor('error')
            : getColor('NEUTRAL300')
          }
          style={styles.squircle}>
          <View style={{ flexDirection: 'row', zIndex: 1 }}>
           <ControllerInput
            name='email'
            control={control}
            withIcon={false}
            placeholder="New user's e-mail address"
            //    error={errors.email?.message}
            inputStyles={{ marginBottom: 10 }}
            otherStyles={{ width: 280 }}
            hasDropDown
           />

           <InputDropdown
            name='type'
            control={control}
            setValue={(e) => {
             setValue('type', e);
            }}
            droppedButtons={droppedButtons}
            enterpriseId={enterpriseId}
            setUserIdFilter={setUserIdFilter}
           />
          </View>
         </SquircleContainer>
         <View style={{ flexDirection: 'row' }}>
          <InputError error={errors.email?.message} />
          <ResponseMessage success={successMessage} error={errorMessage} />
         </View>
        </View>

        <View style={{ marginLeft: 12, width: 130 }}>
         <Button
          title='Add user'
          disabled={isSubmitting}
          bgColor={getColor('NORMAL_GREEN')}
          onPress={handleSubmit(onSubmit)}
         />
        </View>
       </View>
      )}
     </View>
     <ScrollView style={{ flex: 1, zIndex: -1 }}>
      {enterpriseUsers.map((item, index) => (
       <View key={index} style={{ marginRight: 20, zIndex: -index }}>
        <EnterpriseUsersContainer
         getEnterpriseUsers={getEnterpriseUsers}
         userIdFilter={userIdFilter}
         setUserIdFilter={setUserIdFilter}
         key={index}
         data={item}
        />
       </View>
      ))}
     </ScrollView>

     <Pagination
      data={enterpriseUsers}
      currentPage={currentPage}
      pageSize={usersPerPage}
      totalCount={totalPageCount}
      onPageChange={(page) => setCurrentPage(page)}
     />
    </>
   ) : (
    <Loading />
   )}
  </HomeContainer>
 );
};

const styles = StyleSheet.create({
 topContainer: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: 76,
  marginBottom: 24,
 },
 questionMarkStyle: {
  alignItems: 'center',
  justifyContent: 'center',
  height: 52,
  marginRight: 12,
 },
 inputContainer: { flexDirection: 'row', height: 52, alignItems: 'center' },
 squircle: { height: 52, justifyContent: 'center', width: 417 },
});

export default Users;
