import useProfile from 'hooks/useProfile';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import Contact from './Contact';
import Logout from './Logout';
import TremapC from './TremapC';
import Trial from './Trial';

import { getColor } from 'utils/settings';

const HomeContainer = ({ children }) => {
 const { profile } = useProfile();

 const amount =
  profile?.subscription?.amount?.slice(0, -4) ||
  profile?.enterpriseData?.subscription?.amount?.slice(0, -4);

 return (
  <>
   <View style={styles.container}>{children}</View>
   <Contact />
   <TremapC />
   <Logout />
   {amount === 'NaN' && <Trial />}
  </>
 );
};

const styles = StyleSheet.create({
 container: {
  backgroundColor: getColor('WHITE'),
  marginLeft: 354,
  flex: 1,
  paddingLeft: 24,
  paddingTop: 84,
  paddingRight: 73,
  paddingBottom: 63,
 },
});

export default HomeContainer;
