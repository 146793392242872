import React from 'react';
import { StyleSheet, View, Pressable } from 'react-native';

import SquircleContainer from './SquircleContainer';
import Text from './Text';

import Icon from 'reusable/Icon';
import InputError from 'reusable/InputError';

import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';
import { colors, lineHeight } from 'utils/theme';

const INPUT_HEIGHT = 52;
const ICON_SIZE = 22;

const Select = ({ name, error, value, withIcon, onPress, placeholder }) => {
 return (
  <Pressable onPress={onPress}>
   <SquircleContainer
    bgColor='#fff'
    strokeWidth={1}
    strokeColor={error ? getColor('error') : '#DFE2E8'}
    style={styles.inputStyle}>
    {withIcon && <Icon style={styles.iconsStyle} name='arrowLeft' />}

    <Text fontType='MontserratSemiBold' style={styles.placeholderStyle}>
     {(value && (
      <Text
       fontType='MontserratSemiBold'
       fontSize={lineHeight.subTitle3}
       color='NEUTRAL900'>
       {value}
      </Text>
     )) ||
      placeholder}
    </Text>
   </SquircleContainer>

   <InputError error={error} name={name} />
  </Pressable>
 );
};

export default Select;

Select.defaultProps = {
 withIcon: true,
};

const styles = StyleSheet.create({
 inputStyle: {
  height: INPUT_HEIGHT,
  paddingLeft: 16,
  justifyContent: 'center',
  fontFamily: fonts.MontserratRegular,
 },
 iconsStyle: {
  position: 'absolute',
  top: INPUT_HEIGHT / 2 - 15 / 2,
  fontSize: 15,
  right: 22,
  color: colors.NEUTRAL800,
  transform: [{ rotate: '-180deg' }],
 },
 eyeIconStyle: {
  position: 'absolute',
  top: INPUT_HEIGHT / 2 - ICON_SIZE / 2,
  fontSize: ICON_SIZE,
  right: 19,
 },
 placeholderStyle: {
  color: colors.NEUTRAL300,
  ...lineHeight.button2,
 },
});
