import Fuse from 'fuse.js';
import React, { useEffect, useRef, useState } from 'react';
import {
 FlatList,
 StyleSheet,
 Text,
 TouchableOpacity,
 View,
} from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import Icon from 'components/reusable/Icon';
import Input from 'components/reusable/Input';
import SquircleContainer from 'components/reusable/SquircleContainer';

import { getColor } from 'utils/settings';

const Selectable = ({
 getSpeciesValue,
 speciesValue,
 setSpeciesValue,
 genus,
 setGenus,
 getSpecies,
 data,
 addTree,
 setGenusValue,
 setSpeciesValue1,
 placeholder,
}) => {
 const btnRef = useRef();
 const viewRef = useRef();
 const [visible, setVisible] = useState(false);
 const [searchValue, setSearchValue] = useState('');
 const [filteredData, setFilteredData] = useState([]);

 const onSelectItem = (option, item) => {
  if (getSpecies) getSpecies(item);
  if (addTree) {
   if (setGenusValue)
    setGenusValue('genus', option || item, { shouldValidate: true });
   if (setSpeciesValue1)
    setSpeciesValue1('species', option || item, { shouldValidate: true });
  } else {
   if (genus) setGenus(option || item);
   if (getSpeciesValue) getSpeciesValue(item);
   if (speciesValue) setSpeciesValue(option || item);
  }
 };

 useEffect(() => {
  const closeDropDown = (e) => {
   if (
    btnRef.current &&
    viewRef.current &&
    !btnRef.current.contains(e.target) &&
    !viewRef.current.contains(e.target)
   )
    setVisible(false);
  };

  window.addEventListener('click', closeDropDown);

  return () => window.removeEventListener('click', closeDropDown);
 }, []);

 useEffect(() => {
  if (searchValue) {
   const options = {
    shouldSort: true,
    includeMatches: true,
    threshold: 0.0,
    keys: ['genus'],
   };

   const fuse = new Fuse(data, options);

   const result = fuse.search(searchValue);

   const r = result.map((w) => {
    return { ...w };
   });
   setFilteredData(r);
  } else {
   if (searchValue === '' && filteredData.length !== 0) {
    setFilteredData([]);
   }
  }
 }, [searchValue]);

 const renderItem = ({ item }) => {
  return (
   <TouchableOpacity
    onPress={() => {
     onSelectItem(item?.item?.genus || item?.genus || item?.item, item);
     setVisible((prev) => !prev);
    }}>
    <SquircleContainer
     style={styles.selectCont}
     strokeColor={getColor('NEUTRAL300')}
     strokeWidth={1}
     bgColor={getColor('WHITE')}>
     <View>
      <Text
       style={[WebStyles.genusFilterText, { marginLeft: 20 }]}
       fontType='MontserratSemiBold'>
       {item?.item?.genus || item?.genus || item?.item || item}
      </Text>
     </View>
    </SquircleContainer>
   </TouchableOpacity>
  );
 };

 const mapedData =
  filteredData.length > 0 || searchValue !== '' ? filteredData : data;

 return (
  <View>
   <SquircleContainer
    style={styles.squircle}
    strokeColor={getColor('NEUTRAL300')}
    strokeWidth={1}
    bgColor={getColor('WHITE')}>
    <TouchableOpacity
     ref={btnRef}
     onPress={() => {
      setVisible((prev) => !prev);
     }}
     style={styles.touchableStyle}>
     <Text style={WebStyles.genusFilterText}>
      {speciesValue || genus || placeholder}
     </Text>
     <Icon
      name='arrowLeft'
      style={[
       {
        fontSize: 10,
        color: getColor('NEUTRAL700'),
       },
       visible
        ? { transform: [{ rotate: '90deg' }] }
        : { transform: [{ rotate: '270deg' }] },
      ]}
     />
    </TouchableOpacity>
   </SquircleContainer>
   {visible ? (
    <View ref={viewRef} style={styles.droppedContainer}>
     <View style={{ marginBottom: 10, width: '100%' }}>
      <Input
       onChangeText={setSearchValue}
       value={searchValue}
       placeholder='Search'
       iconName='search'
       isSearchInput
       iconsStyles={{ color: getColor('NEUTRAL800') }}
       searchInputColor={getColor('NEUTRAL200')}
       placeholderTextColor={getColor('NEUTRAL500')}
      />
      {mapedData.length === 0 && addTree ? (
       <TouchableOpacity
        onPress={() => {
         setGenusValue
          ? setGenusValue('genus', searchValue)
          : setSpeciesValue1('species', searchValue);
         setVisible(false);
        }}
        style={{ position: 'absolute', right: 10, top: 16 }}>
        <Text
         style={[
          WebStyles.genusFilterText,
          { color: getColor('NORMAL_GREEN') },
         ]}>
         Create New
        </Text>
       </TouchableOpacity>
      ) : null}
     </View>
     <FlatList
      style={styles.width100}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles.width100}
      data={mapedData}
      renderItem={renderItem}
      keyExtractor={(item, i) => i}
     />
    </View>
   ) : null}
  </View>
 );
};

export default Selectable;

const styles = StyleSheet.create({
 squircle: {
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: 52,
 },
 touchableStyle: {
  height: '100%',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingHorizontal: 18,
 },
 droppedContainer: {
  position: 'absolute',
  width: '100%',
  maxHeight: 400,
  marginTop: 60,
  borderRadius: 12,
  borderColor: getColor('NEUTRAL300'),
  borderWidth: 1,
  alignItems: 'center',
  paddingVertical: 10,
  zIndex: 1,
  backgroundColor: getColor('WHITE'),
  paddingHorizontal: 20,
 },
 selectCont: {
  width: '100%',
  height: 52,
  marginVertical: 10,
  justifyContent: 'center',
 },
 width100: { width: '100%' },
});
