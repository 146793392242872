import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { StyleSheet, Text, TextInput, View } from 'react-native';

import { styles } from 'screens/MobileNavigation/SignInScreen/styles';
import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { verifyResetPasswordCode } from 'services/authService';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import ConfirmationCodeField from 'components/reusable/ConfirmationCodeField';
import KeyboardAwareScrollViewComponent from 'components/reusable/KeyboardAwareScrollViewComponent';
import KeyboardPaddingView from 'components/reusable/KeyboardPaddingView';
import { VerifySchema } from 'components/validations/schema';

import Button from 'reusable/Button';
import ScreenContainer from 'reusable/ScreenContainer';

import { isWeb } from 'utils/platform';
import { getColor } from 'utils/settings';
import { lineHeight } from 'utils/theme';

const ConfirmPassword = ({ route, navigation }) => {
 const inputRef = useRef();

 const resolver = useYupValidationResolver(VerifySchema);

 const {
  setValue,
  setError,
  handleSubmit,
  formState: { errors, isSubmitting },
 } = useForm({
  resolver,
  defaultValues: {
   code: '',
  },
 });

 const onSubmit = async (values) => {
  const response = await verifyResetPasswordCode({
   otp: values.code,
   email: route.params.email,
  });

  if (response.type === 'success') {
   navigation.navigate('NewPassword', {
    otp: values.code,
    email: route.params.email,
   });
  } else {
   setError('code', { message: response.message });
  }
 };

 return (
  <KeyboardAwareScrollViewComponent>
   <ScreenContainer>
    <View
     style={[{ flex: 1 }, isWeb && { marginTop: 92, marginHorizontal: 44 }]}>
     {isWeb && (
      <View style={{ alignSelf: 'center' }}>
       <Text style={WebStyles.verificationText}>Verification Code</Text>
      </View>
     )}
     <View style={isWeb ? { marginTop: 24 } : { marginTop: 24 }}>
      <Text style={[styles.signUpWithText, styles2.text1]}>
       Enter the code we sent to
      </Text>

      <Text style={[styles.signUpWithText, styles2.text2]}>
       {route?.params?.email}
      </Text>
     </View>

     <ConfirmationCodeField
      name='code'
      onChange={(code) => setValue('code', code)}
      error={errors.code?.message}
     />

     <TextInput
      ref={inputRef}
      onLayout={() => inputRef.current.focus()}
      style={{ opacity: 0, visibility: 'hidden' }}
     />

     <View
      style={
       isWeb ? { marginTop: 32 } : { marginTop: 'auto', marginBottom: 30 }
      }>
      <Button
       title='Confirm'
       disabled={isSubmitting}
       bgColor={getColor('NORMAL_GREEN')}
       onPress={handleSubmit(onSubmit)}
      />
     </View>
    </View>
   </ScreenContainer>
   <KeyboardPaddingView />
  </KeyboardAwareScrollViewComponent>
 );
};

export default ConfirmPassword;

const styles2 = StyleSheet.create({
 text1: { textAlign: 'center', ...lineHeight.subTitle3 },
 text2: {
  color: getColor('NEUTRAL800'),
  marginTop: 8,
  marginBottom: 32,
  textAlign: 'center',
  ...lineHeight.subTitle3,
 },
});
