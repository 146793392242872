import React, { useState, useRef, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import InputError from './InputError';
import SquircleContainer from './SquircleContainer';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import Icon from 'reusable/Icon';

import { getColor } from 'utils/settings';
import { WebCase } from 'utils/svgs';

const INPUT_HEIGHT = 52;
const ICON_SIZE = 10;

const DropDown = ({
 droppedButtons,
 error,
 name,
 control,
 placeholder,
 withoutIcon,
}) => {
 const btnRef = useRef();
 const [visible, setVisible] = useState(false);

 useEffect(() => {
  const closeDropDown = (e) => {
   if (btnRef.current && !btnRef.current.contains(e.target)) setVisible(false);
  };

  window.addEventListener('click', closeDropDown);

  return () => window.removeEventListener('click', closeDropDown);
 }, []);

 return (
  <>
   {control ? (
    <Controller
     control={control}
     name={name}
     render={({ field: { onChange, onBlur, value } }) => {
      return (
       <>
        <SquircleContainer
         strokeWidth={1}
         strokeColor={
          visible
           ? getColor('NORMAL_GREEN')
           : visible === false && error
           ? getColor('error')
           : visible === false
           ? getColor('NEUTRAL300')
           : null
         }
         bgColor='white'
         style={styles.container}>
         <Pressable
          ref={btnRef}
          onPress={() => setVisible((prevState) => !prevState)}
          style={{ flex: 1, justifyContent: 'center' }}>
          {withoutIcon ? null : (
           <View
            style={{
             position: 'absolute',
             top: INPUT_HEIGHT / 2 - 20 / 2,
             fontSize: 20,
             left: 19,
            }}>
            <WebCase />
           </View>
          )}

          <View style={styles.dropIconStyle}>
           <Icon
            style={[
             {
              fontSize: ICON_SIZE,
              color: getColor('NEUTRAL700'),
             },
             visible
              ? { transform: [{ rotate: '90deg' }] }
              : { transform: [{ rotate: '270deg' }] },
            ]}
            name='arrowLeft'
           />
          </View>
          <Text
           style={[
            WebStyles.dropDownText,
            withoutIcon ? { paddingLeft: 16 } : { paddingLeft: 52 },
           ]}>
           {value ? value : placeholder}
          </Text>
         </Pressable>
        </SquircleContainer>
        {visible && (
         <View style={[styles.droppedContainer, { marginTop: 78 }]}>
          {droppedButtons.map((item, index) => (
           <Hoverable key={index}>
            {(isHovered) => (
             <Pressable
              style={[
               isHovered && [
                styles.hover,
                index === 0
                 ? { borderTopRightRadius: 12, borderTopLeftRadius: 12 }
                 : null,
                index === droppedButtons.length - 1
                 ? { borderBottomRightRadius: 12, borderBottomLeftRadius: 12 }
                 : null,
               ],
               {
                width: '100%',
                height: 44,
                justifyContent: 'center',
               },
              ]}
              onPress={() => {
               onChange(item.name);
               setVisible((prevState) => !prevState);
              }}>
              <Text style={[WebStyles.dropDownText, { paddingLeft: 20 }]}>
               {item.name}
              </Text>
             </Pressable>
            )}
           </Hoverable>
          ))}
         </View>
        )}
       </>
      );
     }}
    />
   ) : (
    <>
     <SquircleContainer
      strokeWidth={1}
      strokeColor={
       visible
        ? getColor('NORMAL_GREEN')
        : visible === false && error
        ? getColor('error')
        : visible === false
        ? getColor('NEUTRAL300')
        : null
      }
      bgColor='white'
      style={styles.container}>
      <Pressable
       onPress={() => setVisible((prevState) => !prevState)}
       style={{ flex: 1, justifyContent: 'center' }}>
       <Icon style={styles.iconsStyle} name='envelope' />
       <View style={styles.dropIconStyle}>
        <WebCase />
       </View>
       <Text style={[WebStyles.dropDownText, { paddingLeft: 52 }]}>
        Type of organisation
       </Text>
      </Pressable>
     </SquircleContainer>
     {visible && (
      <View style={styles.droppedContainer}>
       {droppedButtons.map((item, index) => (
        <Hoverable key={index}>
         {(isHovered) => (
          <Pressable
           style={[
            isHovered && [
             styles.hover,
             index === 0
              ? { borderTopRightRadius: 12, borderTopLeftRadius: 12 }
              : null,
             index === droppedButtons.length - 1
              ? { borderBottomRightRadius: 12, borderBottomLeftRadius: 12 }
              : null,
            ],
            {
             width: '100%',
             height: 44,
             justifyContent: 'center',
            },
           ]}
           onPress={() => {
            setVisible((prevState) => !prevState);
           }}>
           <Text style={[WebStyles.dropDownText, { paddingLeft: 20 }]}>
            {item.name}
           </Text>
          </Pressable>
         )}
        </Hoverable>
       ))}
      </View>
     )}
    </>
   )}

   <InputError error={error} />
  </>
 );
};

const styles = StyleSheet.create({
 container: {
  position: 'relative',
  height: INPUT_HEIGHT,
  justifyContent: 'center',
 },
 iconsStyle: {
  position: 'absolute',
  top: INPUT_HEIGHT / 2 - ICON_SIZE / 2,
  fontSize: ICON_SIZE,
  left: 19,
 },
 dropIconStyle: {
  position: 'absolute',
  top: INPUT_HEIGHT / 2 - ICON_SIZE / 2,
  right: 19,
 },
 hover: {
  backgroundColor: getColor('NEUTRAL300'),
 },
 droppedContainer: {
  width: '100%',
  backgroundColor: 'white',
  borderWidth: 1,
  borderColor: getColor('NEUTRAL300'),
  position: 'absolute',
  marginTop: 55,
  zIndex: 1,
  borderRadius: 12,
 },
});

export default DropDown;
