import usePermission from 'hooks/usePermission';
import useProfile from 'hooks/useProfile';
import React from 'react';
import { ScrollView } from 'react-native';

import CurrentPlan from './CurrentPlan';
import HomeContainer from './HomeContainer';
import PlanHistory from './PlanHistory';

const Billing = () => {
 const { profile } = useProfile();
 const { permissions } = usePermission();
 const userId = profile._id;

 return (
  <HomeContainer>
   <ScrollView>
    {profile?.enterpriseData?.subscription || profile?.subscription ? (
     <>
      <CurrentPlan />
      <PlanHistory />
     </>
    ) : null}
    {(profile?.enterpriseData?.subscription || profile?.subscription) &&
    permissions === 'Admin' ? null : (
     <stripe-pricing-table
      pricing-table-id='prctbl_1MdUIdESIWAwPY9rX7mJV9og'
      publishable-key='pk_live_51Lsk6RESIWAwPY9r7uBSqWq5KeCeHo3ZpeeAuAZsLFGZhJAkjjfxuPGGTmfSU7EYAgHI7XSsF78TC1kdGGtkc31l00Kb503pHU'
      client-reference-id={userId}
      customer-email={profile.email}></stripe-pricing-table>
    )}
   </ScrollView>
  </HomeContainer>
 );
};

export default Billing;
