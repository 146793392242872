import React from 'react';
import { Image, View, Text, Pressable, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import ProgressBar from '../../reusable/ProgressBar';
import SquircleContainer from '../../reusable/SquircleContainer';

import { styles as styles1 } from 'screens/MobileNavigation/SignInScreen/styles';
import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { getColor } from 'utils/settings';
import { WebTrash } from 'utils/svgs';

export default function ImagePickerExample({
 image,
 setImage,
 pickImage,
 size,
}) {
 return (
  <>
   <SquircleContainer
    strokeWidth={1}
    strokeColor={getColor('NEUTRAL300')}
    bgColor={image ? 'white' : 'rgba(85, 145, 88, 0.1)'}
    style={styles.squircleContainer}>
    <View style={styles.container}>
     {image ? (
      <View style={styles.withImageContainer}>
       <View style={styles.withImageTopContainer}>
        <View style={{ flexDirection: 'row' }}>
         <View>
          <Image source={{ uri: image }} style={styles.image} />
         </View>
         <View style={styles.textView}>
          <Text style={WebStyles.stepText}>Organisation's Logo</Text>
          <Text style={WebStyles.imagePickerBotText}>{size} Mb</Text>
         </View>
        </View>
        <Pressable
         onPress={() => setImage(null)}
         style={{ alignSelf: 'center' }}>
         <WebTrash color='#CE4C2F' />
        </Pressable>
       </View>
       <View>
        <ProgressBar />
       </View>
      </View>
     ) : (
      <Pressable
       onPress={() => {
        pickImage();
       }}
       style={styles.pickImageContainer}>
       <Text style={[styles1.bottomText, { marginBottom: 0, marginTop: 0 }]}>
        Browse your organisation's{' '}
        <Text style={{ color: getColor('NORMAL_GREEN') }}>Logo</Text>
       </Text>
       <Text
        style={[
         WebStyles.imagePickerBotText,
         { marginBottom: 0, marginTop: 0 },
        ]}>
        Maximum image dimensions: 400 x 400 pixels
       </Text>
      </Pressable>
     )}
    </View>
   </SquircleContainer>
  </>
 );
}

const styles = StyleSheet.create({
 squircleContainer: {
  width: '100%',
  height: 78,

  marginBottom: 24,
  alignItems: 'center',
  zIndex: -1,
 },
 container: {
  width: '100%',
  height: '100%',
 },
 withImageContainer: {
  width: '100%',
  height: '100%',
  padding: 16,
  justifyContent: 'space-between',
 },
 withImageTopContainer: {
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
 },
 image: { width: 32, height: 32, borderRadius: 12 },
 textView: {
  marginLeft: 16,
  height: '100%',
  justifyContent: 'space-between',
 },
 pickImageContainer: {
  flex: 1,
  justifyContent: 'space-evenly',
  alignItems: 'center',
  borderStyle: 'dashed',
  borderColor: getColor('NORMAL_GREEN'),
  borderRadius: 12,
  borderWidth: 1,
 },
});
