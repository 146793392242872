import Ajax from '../helpers/ajax';

import {
 CHANGE_ENTERPRISE_AVATAR,
 CHANGE_ENTERPRISE_NAME,
 CHANGE_PERMISSION,
 ENTERPRISESIGNUP,
 GETPAYMENT,
 GETUSERSINENTERPRISE,
 REMOVEUSERFROMENTERPRISE,
 SENDINVITETOUSER,
 TRANSACTIONHISTORY,
} from 'utils/uris';

const enterpriseSignup = async (
 accountId,
 enterpriseName,
 website,
 address,
 typeOfEnterprise,
 avatar
) => {
 try {
  return await Ajax.post(ENTERPRISESIGNUP, {
   accountId,
   enterpriseName,
   website,
   address,
   typeOfEnterprise,
   avatar,
  });
 } catch (error) {
  return error;
 }
};

const getCurrentPlan = async (userId) => {
 try {
  return await Ajax.get(`${GETPAYMENT}?userId=${userId}`);
 } catch (error) {
  return error;
 }
};

const getTransactionHistory = async (userId, page, limit) => {
 try {
  return await Ajax.get(
   `${TRANSACTIONHISTORY}?userId=${userId}&page=${page}&limit=${limit}`
  );
 } catch (error) {
  return error;
 }
};

const getUsersInEnterprise = async (enterpriseId, page, limit) => {
 try {
  return await Ajax.get(
   `${GETUSERSINENTERPRISE}${enterpriseId}&page=${page}&limit=${limit}`
  );
 } catch (error) {
  return error;
 }
};

const inviteUser = async (email, enterpriseId, userPermission) => {
 try {
  return await Ajax.post(SENDINVITETOUSER, {
   email,
   enterpriseId,
   userPermission,
  });
 } catch (error) {
  return error;
 }
};

const changeUserPermission = async (enterpriseId, userId, userPermission) => {
 try {
  return await Ajax.put(CHANGE_PERMISSION, {
   enterpriseId,
   userId,
   userPermission,
  });
 } catch (error) {
  return error;
 }
};

const removeEnterpriseUser = async (enterpriseId, userId) => {
 try {
  return await Ajax.delete(REMOVEUSERFROMENTERPRISE, {
   enterpriseId,
   userId,
  });
 } catch (error) {
  return error;
 }
};

const changeEnterpriseName = async (
 name,
 sponsorRedirect = '',
 enterpriseId
) => {
 try {
  return await Ajax.put(CHANGE_ENTERPRISE_NAME, {
   name,
   sponsorRedirect,
   enterpriseId,
  });
 } catch (error) {
  return error;
 }
};

const changeEnterpriseAvatar = async (avatarUrl, enterpriseId) => {
 try {
  return await Ajax.put(CHANGE_ENTERPRISE_AVATAR, {
   avatarUrl,
   enterpriseId,
  });
 } catch (error) {
  return error;
 }
};

export {
 enterpriseSignup,
 getTransactionHistory,
 getCurrentPlan,
 getUsersInEnterprise,
 inviteUser,
 changeUserPermission,
 removeEnterpriseUser,
 changeEnterpriseName,
 changeEnterpriseAvatar,
};
