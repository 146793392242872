import { useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { useSnapshot } from 'valtio';

import CommonNames from '../AddTreeInfo/CommonNames';

import authStore from 'store/authStore';

import {
 deleteTreeImage,
 editTreeInfo,
 getNetworkSpeed,
 getTreeFullInfo,
} from 'services/treeServices';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';
import SmallMap from 'components/map/SmallMap';
import Button from 'components/reusable/Button';
import ControllerInput from 'components/reusable/ControllerInput';
import FocusAwareStatusBar from 'components/reusable/FocusAwareStatusBar';
import Hr from 'components/reusable/Hr';
import Icon from 'components/reusable/Icon';
import Radio from 'components/reusable/Radio';
import ScreenWithRightButton from 'components/reusable/ScreenWithRightButton';
import Select from 'components/reusable/Select';
import SquircleContainer from 'components/reusable/SquircleContainer';
import Toast from 'components/reusable/Toast';
import UploadImage from 'components/reusable/UploadImage';
import { AddTreeInfoSchema } from 'components/validations/schema';

import ScreenContainer from 'reusable/ScreenContainer';
import Text from 'reusable/Text';

import { Defaults } from 'utils/defaults';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from 'utils/dimensions';
import { storageDel, storageGetString, storageSet } from 'utils/mmkvStorage';
import { isAndroid } from 'utils/platform';
import { getColor } from 'utils/settings';
import { colors, fonts, fontSize, lineHeight } from 'utils/theme';

const EditTreeInfo = ({ navigation, route }) => {
 const genusArr = storageGetString('genusData');

 const [networkSpeed, setNetworkSpeed] = useState();

 const currentCommonNameValue = useRef(
  route.params.treeInfoData.properties.common
 );

 const store = useSnapshot(authStore);

 const [isOpenCommonNames, setIsOpenCommonNames] = useState(false);
 const [commonNamesArray, setCommonNamesArray] = useState([]);

 const resolver = useYupValidationResolver(AddTreeInfoSchema);

 const {
  control,
  reset,
  setValue,
  getValues,
  handleSubmit,
  formState: { errors },
 } = useForm({
  resolver,
  defaultValues: {
   commonName: '',
   genus: '',
   species: '',
   imageUrl: [],
   privateValue: false,
   coordinates: '',
   properties: {},
   customFields: {},
  },
 });
 //  const watchFields = watch('commonName');

 //  const getCommonNamesData = async () => {
 //   const response = await getCommonNames(watchFields);

 //   if (response.commons.length > 0) {
 //    setCommonNamesArray(response.commons);
 //    setIsOpenCommonNames(true);
 //   }
 //  };

 //  const handler = useCallback(
 //   debounce(() => getCommonNamesData(), 1000),
 //   []
 //  );

 useFocusEffect(
  React.useCallback(() => {
   getNetworkSpeed().then((speed) => {
    console.log(speed);
    setNetworkSpeed(speed);
   });
  }, [])
 );
 const onSubmit = async () => {
  const {
   coordinates,
   customFields,
   commonName,
   imageUrl,
   genus,
   privateValue,
   species,
   properties,
  } = getValues();

  let obj = {
   lng: coordinates[0],
   lat: coordinates[1],
   userId: storageGetString('userId'),
   treeId: route.params.treeInfoData._id,
   imgUrls: imageUrl,
   organizationId: route.params.treeInfoData.organizationId,
   private: privateValue,
   properties: {
    family: properties.family,
    age: properties.age,
    biomass: properties.biomass,
    common: commonName,
    crown: properties.crown,
    dbh: properties.dbh,
    genus: genus.genus,
    health: properties.health,
    height: properties.height,
    location: properties.location,
    planted: properties.planted,
    refId: properties.refId,
    resilience: properties.resilience,
    species: species,
    structure: properties.structure,
    updated: properties.updated,
    variety: properties.variety,
    ...customFields,
   },
  };

  if (store.isOffline || networkSpeed > Defaults.maxWaitTime) {
   if (properties.offlineTreeId) {
    const OfflineAddedTrees = storageGetString('OfflineAddedTrees');

    const newArr = JSON.parse(OfflineAddedTrees).map((w) => {
     if (
      w.properties.offlineTreeId ===
      route.params.treeInfoData.properties.offlineTreeId
     ) {
      return {
       _id: '',
       createdAt: new Date().toISOString(),
       custom: true,
       geometry: {
        coordinates: [coordinates[0], coordinates[1]],
        type: 'Point',
       },
       imgUrls: imageUrl,
       organizationId: store.profile?.enterpriseData?._id,
       private: privateValue,
       properties: {
        ...obj.properties,
        offlineTreeId: w.properties.offlineTreeId,
        color: privateValue ? '#717993' : '#559158',
       },
       type: 'Feature',
       userId: store.profile._id,
      };
     }

     return w;
    });

    const newStore = authStore.trees.map((w) => {
     if (
      w.properties.offlineTreeId &&
      w.properties.offlineTreeId ===
       route.params.treeInfoData.properties.offlineTreeId
     ) {
      return {
       _id: '',
       createdAt: new Date().toISOString(),
       custom: true,
       geometry: {
        coordinates: [coordinates[0], coordinates[1]],
        type: 'Point',
       },
       imgUrls: imageUrl,
       organizationId: store.profile?.enterpriseData?._id,
       private: privateValue,
       properties: {
        ...obj.properties,
        offlineTreeId: w.properties.offlineTreeId,
        imgUrls: imageUrl,
        color: privateValue ? '#717993' : '#559158',
       },
       type: 'Feature',
       userId: store.profile._id,
      };
     }
     return w;
    });

    storageSet('OfflineAddedTrees', JSON.stringify(newArr));
    route.params.updateTreeData(obj);
    authStore.trees = newStore;

    navigation.goBack();
   } else {
    const OfflineEditedTrees = storageGetString('OfflineEditedTrees');

    if (OfflineEditedTrees) {
     let isSame = false;

     const newArr = JSON.parse(OfflineEditedTrees).map((w) => {
      if (w.treeId === route.params.treeInfoData._id) {
       isSame = true;
       return obj;
      }

      return w;
     });

     if (isSame) {
      storageSet('OfflineEditedTrees', JSON.stringify(newArr));
     } else {
      storageSet('OfflineEditedTrees', JSON.stringify([...newArr, obj]));
     }
    } else {
     storageSet('OfflineEditedTrees', JSON.stringify([obj]));
    }

    navigation.goBack();
   }
  } else {
   const response = await editTreeInfo(obj);

   if (response.type === 'success') {
    route.params.updateTreeData(obj);

    const newArr = authStore.trees.map((t) => {
     if (route.params.treeInfoData._id === t._id) {
      return {
       _id: t._id,
       createdAt: route.params.treeInfoData.createdAt,
       custom: route.params.treeInfoData.custom,
       geometry: {
        coordinates: [coordinates[0], coordinates[1]],
        type: 'Point',
       },
       imgUrls: imageUrl,
       organizationId: route.params.treeInfoData.organizationId,
       private: privateValue,
       properties: {
        color: privateValue ? '#717993' : '#559158',
       },
       type: 'Feature',
       userId: store.profile._id,
      };
     }

     return t;
    });

    storageSet('homeScreenMarkerPosition', JSON.stringify(false));
    authStore.trees = newArr;
    navigation.goBack();
   } else {
    Defaults.toast.show(<Toast message={response.message} />);
    Alert.alert('', response.message);
   }
  }
 };

 const deletePhoto = async (img) => {
  let fileId = img.uri.split('fileId=')[1];

  const filterImages = getValues().imageUrl.filter((g) => g.id !== img.id);

  setValue('imageUrl', filterImages);

  navigation.setParams({
   treeInfoData: {
    ...route.params.treeInfoData,
    imgUrls: filterImages,
   },
  });

  await deleteTreeImage(fileId);
 };

 const onDeletePhoto = (id) => {
  Alert.alert(
   'This action will delete this photo from the gallery. Would you like to continue?',
   '',
   [
    {
     text: 'Cancel',
     style: 'cancel',
     onPress: () => console.log('Cancel Pressed'),
    },

    { text: 'Continue', onPress: () => deletePhoto(id) },
   ]
  );
 };

 useEffect(() => {
  if (route.params.treeInfoData) {
   const { properties, geometry } = route.params.treeInfoData;

   const genusObj = JSON.parse(genusArr).find(
    (g) => g.genus === properties.genus
   );

   setValue('coordinates', geometry.coordinates);
   setValue('genus', genusObj || { genus: properties.genus });
   setValue('species', properties.species);
   setValue('commonName', properties.common);
   setValue('privateValue', route.params.treeInfoData.private);
   setValue('imageUrl', route.params.treeInfoData.imgUrls || []);

   setValue('customFields', {
    customField1: properties.customField1 || '',
    customField2: properties.customField2 || '',
    customField3: properties.customField3 || '',
   });

   let propertiesData = {
    family: properties.family || '',
    variety: properties.variety || '',
    planted: properties.planted || '',
    age: properties.age || '',
    CalculatedAge: properties.CalculatedAge || '',
    dbh: properties.dbh || '',
    crown: properties.crown || '',
    mapScreenshoot: properties.mapScreenshoot || '',
    height: properties.height || '',
    biomass: properties.biomass || '',
    location: properties.location || '',
    refId: properties.refId || '',
    updated: properties.updated || '',
    health: properties.health || '',
    structure: properties.structure || '',
    resilience: properties.resilience || '',
   };

   if (properties.offlineTreeId) {
    propertiesData.offlineTreeId = properties.offlineTreeId;
   }

   setValue('properties', propertiesData);
  }
 }, []);

 useEffect(() => {
  navigation.setOptions({
   headerLeft: () => (
    <Pressable onPress={() => navigation.goBack()}>
     <Icon
      style={{ color: '#0F1118', ...lineHeight.h3, marginRight: 10 }}
      name='arrowLeft'
     />
    </Pressable>
   ),
   headerRight: () => (
    <Text style={styles.buttonStyle} onPress={onSubmit}>
     Done
    </Text>
   ),
  });
 }, [navigation]);

 //  useEffect(() => {
 //   if (
 //    watchFields &&
 //    currentCommonNameValue.current !== watchFields &&
 //    !store.isOffline
 //   ) {
 //    handler();
 //   }
 //  }, [watchFields]);

 const onselect = (title, name, withSearch = false) => {
  navigation.navigate('SelectableModal', {
   name,
   title,
   array:
    Object.keys(getValues().genus).length > 0 && name === 'species'
     ? getValues().genus.species
     : Object.keys(getValues().genus).length === 0 && name === 'species'
     ? []
     : JSON.parse(genusArr),
   withSearch,
   setValue: (val) => {
    if (val.genus && getValues().species) {
     setValue(name, val);
     setValue('species', '');
    } else {
     setValue(name, val);
    }
   },
  });
 };

 const setData = async (data) => {
  setIsOpenCommonNames(false);
  setCommonNamesArray([]);

  reset();
  const response = await getTreeFullInfo(data.treeId);

  reset();
  const { properties } = response.tree;

  const genusObj = JSON.parse(genusArr).find(
   (g) => g.genus === properties.genus
  );

  currentCommonNameValue.current = properties.common;
  setValue('commonName', properties.common);
  setValue('genus', genusObj);
  setValue('species', properties.species);
 };

 const setAdditionalInfo = (val) => {
  setValue('properties', val);

  let customFieldsObj = {
   customField1: val.customField1,
   customField2: val.customField2,
   customField3: val.customField3,
  };
  setValue('customFields', customFieldsObj);
 };

 const navigateAddPinScreen = (coords) => {
  let obj;

  if (coords) {
   obj = {
    coords: {
     longitude: coords[0],
     latitude: coords[1],
    },
   };
  }

  navigation.navigate('AddPin', {
   coords: obj,
   setCoordinates: (data) => {
    setValue('coordinates', data.coordinates);
   },
  });
 };

 return (
  <ScrollView>
   <FocusAwareStatusBar
    barStyle={isAndroid ? 'dark-content' : 'light-content'}
   />
   <ScreenWithRightButton
    isShow
    text='Done'
    navigation={navigation}
    onPress={handleSubmit(onSubmit)}>
    <ScreenContainer style={{ marginBottom: 30 }}>
     <View
      style={{
       flexDirection: 'row',
       justifyContent: 'space-between',
       marginTop: 20,
      }}>
      <Text
       fontType='MontserratBold'
       fontSize={lineHeight.body2}
       style={styles.desc}>
       Location
      </Text>

      <Controller
       control={control}
       name='coordinates'
       render={({ field: { value } }) => {
        if (value) {
         return (
          <Text
           fontType='MontserratBold'
           fontSize={lineHeight.body2}
           style={styles.desc}
           onPress={() => navigateAddPinScreen(value)}>
           Edit
          </Text>
         );
        }

        return null;
       }}
      />
     </View>

     <Controller
      control={control}
      name='coordinates'
      render={({ field: { value } }) => {
       if (value) {
        return <SmallMap coords={value} />;
       } else {
        return (
         <Button
          title='Position on the map'
          bgColor={colors.NORMAL_GREEN}
          onPress={() => navigateAddPinScreen()}
         />
        );
       }
      }}
     />

     <View style={{ marginTop: 16 }}>
      <Hr />
     </View>

     <View>
      <Text
       fontType='MontserratBold'
       fontSize={lineHeight.body2}
       style={[styles.desc, { marginTop: 30 }]}>
       Label Info
      </Text>

      <Field label='Common Name'>
       <ControllerInput
        withClearIcon
        withIcon={false}
        name='commonName'
        control={control}
        placeholder='Enter common name'
        error={errors.commonName?.message}
        inputStyles={{ marginBottom: 10 }}
       />
      </Field>

      <Field label='Genus'>
       <Controller
        control={control}
        name='genus'
        render={({ field: { value } }) => {
         return (
          <Select
           error={errors.genus?.message}
           value={value.genus}
           placeholder='Select genus'
           onPress={() => onselect('Genus', 'genus', true)}
          />
         );
        }}
       />
      </Field>

      <Field label='Species'>
       <Controller
        control={control}
        name='species'
        render={({ field: { value } }) => {
         return (
          <Select
           error={errors.species?.message}
           value={value}
           placeholder='Select species'
           onPress={() => onselect('Species', 'species', true)}
          />
         );
        }}
       />
      </Field>
     </View>

     <View style={{ marginTop: 18, marginBottom: 20 }}>
      <Hr />
     </View>

     <Text
      fontType='MontserratBold'
      fontSize={lineHeight.body1}
      style={styles.desc}>
      Photo(s)
     </Text>

     <View
      style={{
       flexDirection: 'row',
      }}>
      <Controller
       control={control}
       name='imageUrl'
       render={({ field: { value } }) => {
        return (
         <UploadImage
          images={value}
          networkSeed={networkSpeed}
          onDeletePhoto={onDeletePhoto}
          setImages={(img) => setValue('imageUrl', [img, ...value])}
          withAvatar={false}
         />
        );
       }}
      />
     </View>

     <View style={{ marginTop: 16 }}>
      <Hr />
     </View>

     <Text
      fontType='MontserratBold'
      fontSize={lineHeight.body2}
      style={[styles.desc, { marginTop: 16 }]}>
      Visibility
     </Text>
     <View style={{ flexDirection: 'row' }}>
      <Controller
       control={control}
       name='privateValue'
       render={({ field: { value } }) => {
        return (
         <View style={{ marginRight: 36 }}>
          <Radio
           onPress={() => setValue('privateValue', true)}
           selected={value || false}>
           Yes
          </Radio>
         </View>
        );
       }}
      />

      <Controller
       control={control}
       name='privateValue'
       render={({ field: { value } }) => {
        return (
         <View style={{ marginRight: 36 }}>
          <Radio
           onPress={() => setValue('privateValue', false)}
           selected={value === false ? true : false}>
           No
          </Radio>
         </View>
        );
       }}
      />
     </View>

     <View style={{ marginBottom: 16, marginTop: 20 }}>
      <Hr />
     </View>

     <Pressable
      onPress={() => {
       navigation.navigate('AdditionalInfoModal', {
        setValue: (val) => setAdditionalInfo(val),
        values: { ...getValues().properties, ...getValues().customFields },
       });
      }}
      style={{ flexDirection: 'row', alignItems: 'center' }}>
      <SquircleContainer
       bgColor={getColor('LIGHT_GREEN_HOVER')}
       style={styles.infoButton}
       cornerRadius={12}>
       <Icon
        name='plus2'
        style={{ fontSize: 15, color: getColor('NORMAL_GREEN') }}
       />
      </SquircleContainer>
      <Text
       fontType='MontserratSemiBold'
       fontSize={fontSize.body2}
       color='NEUTRAL800'
       style={{ marginLeft: 12 }}>
       Additional Info
      </Text>

      <Icon
       name='arrowLeft'
       style={{ marginLeft: 'auto', transform: [{ rotate: '180deg' }] }}
      />
     </Pressable>
    </ScreenContainer>
   </ScreenWithRightButton>

   {isOpenCommonNames && (
    <View
     style={{
      position: 'absolute',
      top: 0,
      paddingBottom: 30,
      bottom: 250,
      width: SCREEN_WIDTH,
      height: SCREEN_HEIGHT - 80,
      backgroundColor: '#fff',
     }}>
     <CommonNames
      isAddScreen={false}
      onClear={() => setValue('commonName', '')}
      inputVal={getValues().commonName}
      commonNames={commonNamesArray}
      onPressItem={(data) => setData(data)}
      setStateValue={(val) => setValue('commonName', val)}
      onDone={(val) => {
       navigation.setOptions({ headerShown: true });
       setValue('commonName', val);
       setIsOpenCommonNames(false);
      }}
      onCancelPress={() => {
       setCommonNamesArray([]);
       setIsOpenCommonNames(false);
      }}
     />
    </View>
   )}
  </ScrollView>
 );
};

export default EditTreeInfo;

const Field = ({ label, marginBottom = 16, children }) => (
 <View style={{ marginBottom: marginBottom }}>
  <Text
   color='NEUTRAL600'
   fontSize={fontSize.body3}
   fontType='MontserratSemiBold'
   style={{ marginBottom: 8 }}>
   {label}
  </Text>
  {children}
 </View>
);

const styles = StyleSheet.create({
 desc: {
  marginBottom: 16,
 },
 addPhotoButton: {
  width: 100,
  height: 100,
  borderRadius: 12,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: getColor('LIGHT_GREEN_HOVER'),
 },
 buttonStyle: {
  color: getColor('NORMAL_GREEN'),
  fontFamily: fonts.MontserratBold,
  letterSpacing: 0.5,
  marginRight: 12,
 },
 labelStyle: {
  color: getColor('NEUTRAL600'),
 },
 closeIcon: {
  position: 'absolute',
  right: -8,
  top: -9,
  color: getColor('NEUTRAL500'),
  fontSize: 18,
 },
 fieldContainer: {
  marginBottom: 16,
 },
 infoButton: {
  width: 36,
  height: 36,
  justifyContent: 'center',
  alignItems: 'center',
 },
});
