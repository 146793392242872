import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

const TremapC = () => {
 return (
  <View style={styles.tremapTextContainer}>
   <Text style={WebStyles.webDefaultText}>© 2023 tremap.com</Text>
  </View>
 );
};

const styles = StyleSheet.create({
 tremapTextContainer: {
  position: 'absolute',
  bottom: 24,
  right: 72,
 },
});

export default TremapC;
