import * as React from 'react';
import Svg, { Path, Rect, G, Circle, Defs } from 'react-native-svg';

const AddIcon = (props) => (
 <Svg width={88} height={56} xmlns='http://www.w3.org/2000/svg' {...props}>
  <G filter='url(#a)'>
   <Circle cx={44} cy={28} r={20} fill='#559158' />
  </G>
  <Path
   d='M50 28.75H38c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h12c.41 0 .75.34.75.75s-.34.75-.75.75Z'
   fill='#fff'
  />
  <Path
   d='M44 34.75c-.41 0-.75-.34-.75-.75V22c0-.41.34-.75.75-.75s.75.34.75.75v12c0 .41-.34.75-.75.75Z'
   fill='#fff'
  />
  <Defs></Defs>
 </Svg>
);

const SettingsIcon = (props) => (
 <Svg
  width={24}
  height={24}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M12 16c-2.208 0-4-1.792-4-4s1.792-4 4-4 4 1.792 4 4-1.792 4-4 4Zm0-6.4A2.404 2.404 0 0 0 9.6 12c0 1.323 1.077 2.4 2.4 2.4s2.4-1.077 2.4-2.4-1.077-2.4-2.4-2.4Z'
   fill='#919AAB'
  />
  <Path
   d='M14.974 20.996a2.65 2.65 0 0 1-.585-.07 2.273 2.273 0 0 1-1.367-.98l-.111-.177c-.548-.901-1.301-.901-1.85 0l-.102.168c-.307.494-.79.847-1.366.989-.586.15-1.19.07-1.7-.221l-1.6-.874a2.36 2.36 0 0 1-1.152-1.422 2.243 2.243 0 0 1 .242-1.775c.27-.45.344-.857.186-1.113-.158-.256-.558-.406-1.106-.406-1.357 0-2.463-1.051-2.463-2.34V11.22c0-1.29 1.106-2.34 2.463-2.34.548 0 .948-.15 1.106-.407.158-.256.093-.662-.186-1.112a2.248 2.248 0 0 1-.242-1.775 2.298 2.298 0 0 1 1.153-1.422L7.9 3.289c1.05-.591 2.435-.247 3.067.769l.112.176c.548.901 1.301.901 1.85 0l.102-.167c.632-1.025 2.016-1.37 3.076-.769l1.598.875c.567.309.976.812 1.153 1.421.167.61.083 1.237-.242 1.776-.27.45-.344.856-.186 1.112.158.256.558.407 1.106.407 1.357 0 2.463 1.05 2.463 2.34v1.554c0 1.29-1.106 2.34-2.463 2.34-.548 0-.948.15-1.106.407-.158.256-.093.662.186 1.112a2.21 2.21 0 0 1 .242 1.776 2.298 2.298 0 0 1-1.153 1.422l-1.607.874a2.407 2.407 0 0 1-1.125.282Zm-2.983-3.267c.827 0 1.598.494 2.128 1.369l.102.167a.82.82 0 0 0 .52.371.863.863 0 0 0 .633-.08l1.607-.882a1.03 1.03 0 0 0 .502-.619.966.966 0 0 0-.102-.768c-.53-.865-.595-1.757-.186-2.437.41-.68 1.255-1.069 2.314-1.069.595 0 1.07-.45 1.07-1.015V11.21c0-.556-.475-1.015-1.07-1.015-1.06 0-1.905-.389-2.314-1.069-.409-.68-.344-1.572.186-2.437a.966.966 0 0 0 .102-.769 1.041 1.041 0 0 0-.492-.618l-1.608-.874a.88.88 0 0 0-1.171.291l-.102.168c-.53.874-1.301 1.369-2.129 1.369-.827 0-1.598-.495-2.128-1.369l-.102-.177c-.232-.37-.753-.503-1.152-.282l-1.608.883a1.03 1.03 0 0 0-.502.618.965.965 0 0 0 .102.768c.53.866.595 1.758.186 2.438-.409.68-1.255 1.069-2.314 1.069-.595 0-1.069.45-1.069 1.015v1.554c0 .557.474 1.016 1.069 1.016 1.06 0 1.905.389 2.314 1.069.409.68.344 1.572-.186 2.437a.965.965 0 0 0-.102.768c.074.265.25.477.492.619l1.608.874a.878.878 0 0 0 1.171-.291l.102-.168c.53-.866 1.302-1.37 2.129-1.37Z'
   fill='#919AAB'
  />
 </Svg>
);

const HomeIcon = (props) => (
 <Svg
  width={20}
  height={20}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M18.86 6.304 11.931.66c-1.07-.877-2.8-.877-3.859-.01L1.143 6.303c-.78.632-1.28 1.967-1.11 2.965l1.33 8.112c.24 1.447 1.6 2.619 3.04 2.619H15.6c1.43 0 2.8-1.182 3.04-2.619l1.33-8.112c.16-.998-.34-2.333-1.11-2.965Zm-8.858 7.266c-1.38 0-2.5-1.142-2.5-2.548 0-1.406 1.12-2.547 2.5-2.547s2.5 1.14 2.5 2.547c0 1.406-1.12 2.548-2.5 2.548Z'
   fill='#4D834F'
  />
 </Svg>
);

const PinIcon = (props) => (
 <Svg
  width={36}
  height={41}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   fillRule='evenodd'
   clipRule='evenodd'
   d='M2.343 2.343C0 4.686 0 8.458 0 16v4c0 7.543 0 11.314 2.343 13.657 2.186 2.186 5.616 2.333 12.193 2.342l2.598 4.501a1 1 0 0 0 1.732 0l2.599-4.5c6.576-.01 10.006-.157 12.192-2.343C36 31.314 36 27.543 36 20v-4c0-7.542 0-11.314-2.343-13.657S27.543 0 20 0h-4C8.458 0 4.686 0 2.343 2.343Z'
   fill='#D96C31'
  />
  <Rect x={6} y={6} width={24} height={24} rx={6} fill='#fff' />
  <Path
   d='M20.78 16.707h-5.56c-.787 0-1.06-.527-.6-1.167l2.78-3.893c.326-.467.873-.467 1.2 0l2.78 3.893c.46.64.186 1.167-.6 1.167Z'
   fill='#D96C31'
  />
  <Path
   d='M21.726 22h-7.453c-1.053 0-1.413-.7-.793-1.554l2.66-3.74h3.72l2.66 3.74c.62.853.26 1.553-.794 1.553ZM18.5 22v2.667c0 .273-.227.5-.5.5a.504.504 0 0 1-.5-.5V22h1Z'
   fill='#D96C31'
  />
 </Svg>
);

const WebTrash = (props) => (
 <Svg
  width={19}
  height={20}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M16.325 7.468s-.543 6.735-.858 9.572c-.15 1.355-.987 2.15-2.358 2.174-2.61.047-5.221.05-7.83-.005-1.318-.027-2.141-.83-2.288-2.162-.317-2.862-.857-9.579-.857-9.579M17.708 4.24H.75M14.44 4.24a1.648 1.648 0 0 1-1.614-1.324L12.583 1.7a1.28 1.28 0 0 0-1.237-.95H7.113a1.28 1.28 0 0 0-1.237.95l-.243 1.216A1.648 1.648 0 0 1 4.018 4.24'
   stroke={props.color}
   strokeWidth={1.5}
   strokeLinecap='round'
   strokeLinejoin='round'
  />
 </Svg>
);

const WebCase = (props) => (
 <Svg
  width={20}
  height={20}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M9.996 14.677V12.14'
   stroke='#454A5B'
   strokeWidth={1.5}
   strokeLinecap='round'
   strokeLinejoin='round'
  />
  <Path
   clipRule='evenodd'
   d='M16.19 3.33c1.69 0 3.05 1.37 3.05 3.06v3.44c-2.46 1.44-5.71 2.31-9.25 2.31S3.21 11.27.75 9.83V6.38c0-1.69 1.37-3.05 3.06-3.05h12.38Z'
   stroke='#454A5B'
   strokeWidth={1.5}
   strokeLinecap='round'
   strokeLinejoin='round'
  />
  <Path
   d='M13.496 3.326V2.96c0-1.22-.99-2.21-2.21-2.21h-2.58c-1.22 0-2.21.99-2.21 2.21v.366M.775 13.483l.19 2.509a3.242 3.242 0 0 0 3.231 2.998h11.6a3.242 3.242 0 0 0 3.231-2.998l.19-2.51'
   stroke='#454A5B'
   strokeWidth={1.5}
   strokeLinecap='round'
   strokeLinejoin='round'
  />
 </Svg>
);

const WebSettings = (props) => (
 <Svg
  width={24}
  height={24}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M22 6.5h-6M6 6.5H2M10 10a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM22 17.5h-4M8 17.5H2M14 21a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z'
   stroke={props.color}
   strokeWidth={1.5}
   strokeMiterlimit={10}
   strokeLinecap='round'
   strokeLinejoin='round'
  />
 </Svg>
);

const WebDownload = (props) => (
 <Svg
  width={14}
  height={12}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M7.072 8.004V.98M8.773 6.296l-1.7 1.708L5.37 6.296'
   stroke='#559158'
   strokeWidth={1.5}
   strokeLinecap='round'
   strokeLinejoin='round'
  />
  <Path
   d='M9.774 3.741h.544c1.187 0 2.149.962 2.149 2.15V8.74c0 1.184-.96 2.143-2.144 2.143H3.825a2.15 2.15 0 0 1-2.15-2.149v-2.85c0-1.183.96-2.143 2.144-2.143h.55'
   stroke='#559158'
   strokeWidth={1.5}
   strokeLinecap='round'
   strokeLinejoin='round'
  />
 </Svg>
);

const WebClose = (props) => (
 <Svg
  width={18}
  height={18}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='m10.058 9 3.225-3.217a.753.753 0 1 0-1.065-1.065L9 7.943 5.783 4.718a.753.753 0 1 0-1.065 1.065L7.943 9l-3.225 3.218a.751.751 0 0 0 .244 1.229.75.75 0 0 0 .82-.164L9 10.058l3.218 3.225a.751.751 0 0 0 1.287-.533.75.75 0 0 0-.222-.532L10.058 9Z'
   fill={props.color ? props.color : '#454A5B'}
  />
 </Svg>
);

const Tick = (props) => (
 <Svg
  width={16}
  height={13}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   fillRule='evenodd'
   clipRule='evenodd'
   d='M15.508.424c.01.01.021.02.031.032.6.618.6 1.601 0 2.22l-8.622 8.88c-.253.26-.577.41-.912.446l-.144.01a1.497 1.497 0 0 1-1.1-.456l-4.31-4.44c-.601-.619-.601-1.602 0-2.22a1.502 1.502 0 0 1 2.124-.032l.03.032L5.84 8.225l7.545-7.77a1.502 1.502 0 0 1 2.124-.03Z'
   fill={props.color}
  />
 </Svg>
);

const CheckMark = (props) => (
 <Svg
  width={12}
  height={9}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   fillRule='evenodd'
   clipRule='evenodd'
   d='M11.384.366a1.25 1.25 0 0 1 0 1.768l-6 6a1.25 1.25 0 0 1-1.768 0l-3-3a1.25 1.25 0 1 1 1.768-1.768L4.5 5.482 9.616.366a1.25 1.25 0 0 1 1.768 0Z'
   fill='#212536'
  />
 </Svg>
);

const InfoPolygon = (props) => (
 <Svg
  width={13}
  height={9}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M9.854 4.84C8.342 7.167 7.585 8.33 6.5 8.33S4.658 7.167 3.146 4.84L0 0h13L9.854 4.84Z'
   fill='#fff'
  />
 </Svg>
);

const WebTreePin = (props) => (
 <Svg
  width={84}
  height={89}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <G filter='url(#a)'>
   <Path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M26.343 10.343C24 12.686 24 16.457 24 24v4c0 7.542 0 11.314 2.343 13.657 2.186 2.186 5.616 2.333 12.192 2.342l2.599 4.501a1 1 0 0 0 1.732 0l2.599-4.5c6.576-.01 10.005-.157 12.192-2.343C60 39.314 60 35.543 60 28v-4c0-7.543 0-11.314-2.343-13.657S51.543 8 44 8h-4c-7.542 0-11.314 0-13.657 2.343Z'
    fill='#D96C31'
   />
  </G>
  <Rect x={30} y={14} width={24} height={24} rx={6} fill='#fff' />
  <Path
   d='M44.78 24.707h-5.56c-.787 0-1.06-.527-.6-1.167l2.78-3.893c.326-.467.873-.467 1.2 0l2.78 3.893c.46.64.186 1.167-.6 1.167Z'
   fill='#D96C31'
  />
  <Path
   d='M45.726 30h-7.453c-1.053 0-1.413-.7-.793-1.554l2.66-3.74h3.72l2.66 3.74c.62.853.26 1.553-.794 1.553ZM42.5 30v2.667c0 .273-.227.5-.5.5a.504.504 0 0 1-.5-.5V30h1Z'
   fill='#D96C31'
  />
  <Defs></Defs>
 </Svg>
);

const PenTool = (props) => (
 <Svg
  width={34}
  height={34}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='m25.561 22.522-3.054 3.054c-1.09 1.09-2.78 1.011-3.508-.141l-1.272-2.008a.996.996 0 0 1 .141-1.245l4.306-4.306a1.005 1.005 0 0 1 1.245-.142L25.42 19c1.237.785 1.301 2.362.141 3.522ZM22.988 13.343l-.092 1.421c-.035.488-.24.948-.58 1.287l-6.25 6.251a1.973 1.973 0 0 1-1.301.58l-1.408.078c-2.213.22-2.983-.538-3.224-2.645l-.976-8.372a2.909 2.909 0 0 1-.02-.18c-.057-.68.745-.849 1.228-.366l2.455 2.455a.733.733 0 0 0 1.068.007c.304-.29.297-.763 0-1.074l-2.456-2.456c-.482-.483-.313-1.27.368-1.229.056.004.114.008.171.015l8.366.997c2.093.254 2.786 1.018 2.651 3.231Z'
   fill='#fff'
   stroke='#212536'
  />
 </Svg>
);

const ArrowRight = (props) => (
 <Svg
  width={24}
  height={25}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M19.75 12.72h-15M13.701 6.695l6.05 6.024-6.05 6.025'
   stroke='#292B41'
   strokeWidth={1.5}
   strokeLinecap='round'
   strokeLinejoin='round'
  />
 </Svg>
);

const ArrowLeft = (props) => (
 <Svg
  width={26}
  height={26}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='m5.252 13.31 15-.081M11.334 19.302 5.25 13.31l6.018-6.057'
   stroke='#292B41'
   strokeWidth={1.5}
   strokeLinecap='round'
   strokeLinejoin='round'
  />
 </Svg>
);

const OrangeQuestionMark = (props) => (
 <Svg
  width={24}
  height={24}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   fillRule='evenodd'
   clipRule='evenodd'
   d='M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm9.303 1.394c-.071.32.2.606.545.606h2.193a.613.613 0 0 0 .565-.394c.091-.227.406-.639.648-.854.229-.24.467-.467.702-.69.926-.879 1.798-1.706 1.798-3.152 0-2.507-2.172-3.91-4.483-3.91-1.767 0-4.207.806-4.687 3.504-.097.543.364.996.916.996h1.412c.527 0 .92-.424 1.1-.918.174-.476.526-.827 1.259-.827 1.336 0 1.335 1.469.564 2.43-.292.362-.633.683-.97 1-.685.645-1.355 1.275-1.562 2.21ZM14 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z'
   fill='#D96C31'
  />
 </Svg>
);

const Email = (props) => (
 <Svg
  width={24}
  height={24}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M17 3.5H7c-3 0-5 1.5-5 5v7c0 3.5 2 5 5 5h10c3 0 5-1.5 5-5v-7c0-3.5-2-5-5-5Zm.47 6.09-3.13 2.5c-.66.53-1.5.79-2.34.79-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.77.77 0 0 1-.12-1.06c.26-.32.73-.38 1.05-.12l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.11 1.06Z'
   fill='#292D32'
  />
 </Svg>
);

const Location = (props) => (
 <Svg
  width={24}
  height={24}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M20.62 8.45c-1.05-4.62-5.08-6.7-8.62-6.7h-.01c-3.53 0-7.57 2.07-8.62 6.69-1.17 5.16 1.99 9.53 4.85 12.28A5.436 5.436 0 0 0 12 22.25c1.36 0 2.72-.51 3.77-1.53 2.86-2.75 6.02-7.11 4.85-12.27ZM12 13.46a3.15 3.15 0 1 1 0-6.3 3.15 3.15 0 0 1 0 6.3Z'
   fill='#292D32'
  />
 </Svg>
);

const Call = (props) => (
 <Svg
  width={24}
  height={24}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M17.62 10.75a.77.77 0 0 1-.77-.77c0-.37-.37-1.14-.99-1.81-.61-.65-1.28-1.03-1.84-1.03a.77.77 0 0 1-.77-.77c0-.42.35-.77.77-.77 1 0 2.05.54 2.97 1.51.86.91 1.41 2.04 1.41 2.86 0 .43-.35.78-.78.78ZM21.23 10.75a.77.77 0 0 1-.77-.77c0-3.55-2.89-6.43-6.43-6.43a.77.77 0 0 1-.77-.77c0-.42.34-.78.76-.78C18.42 2 22 5.58 22 9.98c0 .42-.35.77-.77.77ZM11.05 14.95 9.2 16.8c-.39.39-1.01.39-1.41.01-.11-.11-.22-.21-.33-.32a28.414 28.414 0 0 1-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 8.67 2 7.58 2 6.54c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67C4.15 2.31 4.85 2 5.59 2c.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.1.1.21.2.31.3.4.39.41 1.03.01 1.43ZM21.97 18.33c0 .28-.05.57-.15.85-.03.08-.06.16-.1.24-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.01 0-.02.01-.03.01-.59.24-1.23.37-1.92.37-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98c-.39-.29-.78-.58-1.15-.89l3.27-3.27c.28.21.53.37.74.48.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78Z'
   fill='#292D32'
  />
 </Svg>
);

const Global = (props) => (
 <Svg
  width={24}
  height={24}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M7.65 20.91c-.03 0-.07.02-.1.02-1.94-.96-3.52-2.55-4.49-4.49 0-.03.02-.07.02-.1 1.22.36 2.48.63 3.73.84.22 1.26.48 2.51.84 3.73ZM20.94 16.45c-.99 1.99-2.64 3.6-4.65 4.57.38-1.27.7-2.55.91-3.84 1.26-.21 2.5-.48 3.72-.84-.01.04.02.08.02.11ZM21.02 7.71c-1.26-.38-2.53-.69-3.82-.91-.21-1.29-.52-2.57-.91-3.82 2.07.99 3.74 2.66 4.73 4.73ZM7.65 3.09c-.36 1.22-.62 2.46-.83 3.72-1.29.2-2.57.52-3.84.9.97-2.01 2.58-3.66 4.57-4.65.03 0 .07.03.1.03ZM15.49 6.59c-2.32-.26-4.66-.26-6.98 0 .25-1.37.57-2.74 1.02-4.06.02-.08.01-.14.02-.22.79-.19 1.6-.31 2.45-.31.84 0 1.66.12 2.44.31.01.08.01.14.03.22.45 1.33.77 2.69 1.02 4.06ZM6.59 15.49c-1.38-.25-2.74-.57-4.06-1.02-.08-.02-.14-.01-.22-.02C2.12 13.66 2 12.85 2 12c0-.84.12-1.66.31-2.44.08-.01.14-.01.22-.03 1.33-.44 2.68-.77 4.06-1.02-.25 2.32-.25 4.66 0 6.98ZM22 12c0 .85-.12 1.66-.31 2.45-.08.01-.14 0-.22.02-1.33.44-2.69.77-4.06 1.02.26-2.32.26-4.66 0-6.98 1.37.25 2.74.57 4.06 1.02.08.02.14.03.22.03.19.79.31 1.6.31 2.44ZM15.49 17.41c-.25 1.38-.57 2.74-1.02 4.06-.02.08-.02.14-.03.22-.78.19-1.6.31-2.44.31-.85 0-1.66-.12-2.45-.31-.01-.08 0-.14-.02-.22a29.77 29.77 0 0 1-1.02-4.06c1.16.13 2.32.22 3.49.22 1.17 0 2.34-.09 3.49-.22ZM15.763 15.763a30.035 30.035 0 0 1-7.526 0 30.039 30.039 0 0 1 0-7.526 30.039 30.039 0 0 1 7.526 0 30.035 30.035 0 0 1 0 7.526Z'
   fill='#292D32'
  />
 </Svg>
);

const Apple = (props) => (
 <Svg
  width={26}
  height={28}
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
  {...props}>
  <Path
   d='M17.242 6.526c-.786.923-2.042 1.652-3.298 1.548-.157-1.25.458-2.576 1.178-3.396.785-.95 2.159-1.626 3.271-1.678.131 1.3-.38 2.576-1.151 3.526ZM18.38 8.32c-1.819-.104-3.376 1.028-4.24 1.028-.877 0-2.199-.976-3.638-.95-1.872.026-3.612 1.08-4.567 2.758-1.963 3.356-.51 8.326 1.387 11.058.929 1.353 2.041 2.836 3.507 2.784 1.387-.052 1.937-.898 3.612-.898 1.688 0 2.173.898 3.638.872 1.518-.026 2.474-1.353 3.403-2.706 1.06-1.535 1.492-3.031 1.518-3.11-.026-.026-2.931-1.131-2.958-4.462-.026-2.784 2.29-4.11 2.395-4.189-1.308-1.925-3.35-2.133-4.057-2.185Z'
   fill='#000'
  />
 </Svg>
);

const CopyIcon = (props) => (
 <Svg
  xmlns='http://www.w3.org/2000/svg'
  className='ionicon'
  viewBox='0 0 512 512'
  {...props}>
  <Rect
   width={336}
   height={336}
   x={128}
   y={128}
   fill='none'
   stroke='currentColor'
   strokeLinejoin='round'
   strokeWidth={32}
   rx={57}
   ry={57}
  />
  <Path
   fill='none'
   stroke='currentColor'
   strokeLinecap='round'
   strokeLinejoin='round'
   strokeWidth={32}
   d='m383.5 128 .5-24a56.16 56.16 0 0 0-56-56H112a64.19 64.19 0 0 0-64 64v216a56.16 56.16 0 0 0 56 56h24'
  />
 </Svg>
);

export {
 Email,
 Call,
 Location,
 Global,
 AddIcon,
 PinIcon,
 SettingsIcon,
 HomeIcon,
 WebTrash,
 WebCase,
 WebDownload,
 WebSettings,
 WebClose,
 Tick,
 CheckMark,
 InfoPolygon,
 WebTreePin,
 PenTool,
 ArrowRight,
 ArrowLeft,
 OrangeQuestionMark,
 Apple,
 CopyIcon,
};
