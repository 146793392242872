import React from 'react';
import { View, Text, Image } from 'react-native';

import { resetStyles } from './resetStyles';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import { IMAGES } from 'utils/images';

const StepTwo = ({ data }) => {
 return (
  <>
   <View>
    <View style={resetStyles.imgContainer}>
     <Image style={resetStyles.imgSize} source={IMAGES.rectangle} />
    </View>
    <View style={resetStyles.textContainer}>
     <Text style={WebStyles.WebHeadText}>Check your email</Text>
     <Text style={[WebStyles.text, resetStyles.text, { textAlign: 'center' }]}>
      We've sent an email with password reset instructions to:{' '}
      <Text style={resetStyles.emailText}>{data.email}</Text>
     </Text>
    </View>
   </View>
  </>
 );
};

export default StepTwo;
