import * as Google from 'expo-auth-session/providers/google';
import useForm from 'hooks/useForm';
import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import {
 Text,
 View,
 Image,
 StyleSheet,
 Pressable,
 Platform,
 Alert,
} from 'react-native';

import { gmailConfig } from './configs';

import authStore from 'store/authStore';

import { styles } from 'screens/MobileNavigation/SignInScreen/styles';
import { WebStyles } from 'screens/WebNavigation/WebStyles';

import {
 registerNewUserInEnterprise,
 signUpUser,
 signUpUserByApple,
 signUpUserByFb,
 signUpUserByGmail,
} from 'services/authService';

import ModalWrapper from 'components/modals/ModalWrapper';
import VerifyModal from 'components/modals/VerifyModal';
import ControllerInput from 'components/reusable/ControllerInput';
import Toast from 'components/reusable/Toast';
import { SignupSchema } from 'components/validations/schema';

import Button from 'reusable/Button';
import SocialIcon from 'reusable/SocialIcon';

import {
 getAppleCredentials,
 getFacebookUserData,
 getPermissions,
 getUser,
} from 'utils/calls';
import { Defaults } from 'utils/defaults';
import { IMAGES } from 'utils/images';
import { storageSet } from 'utils/mmkvStorage';
import { isIOS, isMobile, isWeb } from 'utils/platform';
import { getColor } from 'utils/settings';
import { Apple } from 'utils/svgs';
import {
 SIGNIN_WITH_FB,
 SIGNIN_WITH_GOOGLE,
 AUTH_URI,
 SIGNIN_WITH_APPLE,
} from 'utils/uris';

const SignupForm = ({ navigation, onCompanySubmit, signUpToken, action }) => {
 const [request, response, promptAsync] = Google.useAuthRequest(
  gmailConfig,
  {}
 );

 const defaultValues = {
  email: '',
  password: '',
 };

 const { control, handleSubmit, clearErrors, errors, isSubmitting, setError } =
  useForm(SignupSchema, defaultValues);

 useEffect(() => {
  if (isMobile) {
   navigation.addListener('beforeRemove', () => {
    clearErrors();
   });
  }
 }, []);

 const getFbData = (profile) => {
  signUpUserByFb(profile.firstName || '', profile.lastName || '', profile.email)
   .then((response) => {
    if (response.type === 'success') {
     navigation.replace('Signin');
    } else {
     Defaults.toast.show(<Toast withError={true} message={response.message} />);
     setError('email', { type: 'custom', message: response.message });
    }
   })
   .catch((err) => console.log('errr', err));
 };

 const onSigninWithFb = async () => {
  if (isWeb) {
   window.open(SIGNIN_WITH_FB, '_self');
  } else {
   try {
    const data = await getFacebookUserData();

    getFbData(data);
   } catch (error) {
    console.log(error);
   }
  }
 };

 const onSigninWithGoogle = () => {
  if (isWeb) {
   window.open(`${AUTH_URI + SIGNIN_WITH_GOOGLE}`, '_self');
  } else {
   promptAsync(request.url);
  }
 };

 const getGmailUser = async (token) => {
  var decoded = jwt_decode(token);

  const obj = {
   firstName: decoded.given_name,
   lastName: decoded.family_name,
   email: decoded.email,
  };

  const response = await signUpUserByGmail(
   obj.firstName,
   obj.lastName,
   obj.email
  );

  if (response.type === 'success') {
   navigation.navigate('Signin');
  } else {
   setError('email', { type: 'custom', message: response.message });
  }
 };

 useEffect(() => {
  if (response?.type === 'success') {
   getGmailUser(response.authentication.idToken);
  }
 }, [response]);

 const onSubmit = async (values) => {
  let response;

  if (action?.action) {
   response = await registerNewUserInEnterprise(
    action.enterpriseId,
    values.email,
    values.password
   );
  } else {
   response = await signUpUser(values);
  }

  if (response.type === 'success') {
   const { token, refreshToken } = response;

   if (isWeb) {
    Defaults.Modal.show(
     <ModalWrapper customStyles={WebStyles.modalWrapperStyles}>
      <VerifyModal
       navigation={navigation}
       tokenForWeb={token}
       refreshTokenForWeb={refreshToken}
       userEmailForWeb={values.email}
      />
     </ModalWrapper>
    );
   } else {
    navigation.navigate('VerifyModal', {
     newEmail: values.email,
     tokenForMobile: token,
     refreshTokenForMobile: refreshToken,
    });
   }
  } else {
   setError('email', { type: 'custom', message: response.message });
  }
 };

 const getUSerDataFromApple = async (userData) => {
  let email = userData.email || '';
  let givenName = userData.email || '';
  let familyName = userData.email || '';

  if (userData.email) {
   email = userData.email;
   givenName = userData.fullName.givenName;
   familyName = userData.fullName.familyName;
  } else {
   var user = jwt_decode(userData.identityToken);
   email = user.email;
  }

  var res = await signUpUserByApple(givenName, familyName, email);

  if (res.type === 'success') {
   navigation.navigate('Signin');
  } else {
   setError('email', { type: 'custom', message: res.message });
  }
 };

 const onApple = async () => {
  if (isWeb) {
   window.open(SIGNIN_WITH_APPLE, '_self');
  } else {
   const appleRes = await getAppleCredentials();

   getUSerDataFromApple(appleRes);
  }
 };

 if (signUpToken) {
  authStore.profile = jwt_decode(signUpToken);
  storageSet('userId', jwt_decode(signUpToken).userId);
  storageSet('AccessToken', signUpToken);
  storageSet('RefreshToken', signUpToken);
  Defaults.userId = jwt_decode(signUpToken).userId;
  authStore.isLoggedIn = true;
  getPermissions(Defaults.userId);
  getUser();
 }

 return (
  <View style={{ flex: 1 }}>
   <View>
    <Text style={isWeb ? WebStyles.WebHeadText : styles.mainText}>
     Create Account
    </Text>
    <View style={{ marginBottom: errors.email ? 6 : 24 }}>
     <ControllerInput
      name='email'
      control={control}
      iconName='envelope'
      keyboardType='email'
      placeholder='Email Address'
      error={errors.email?.message}
      inputStyles={{ marginBottom: 10 }}
     />
    </View>

    <View style={{ marginBottom: errors.password ? 4 : 28 }}>
     <ControllerInput
      name='password'
      control={control}
      iconName='lockOn'
      secureTextEntry
      withEyeIcon
      placeholder='Password'
      error={errors.password?.message}
      inputStyles={{ marginBottom: 10 }}
     />
    </View>

    <Text style={styles.TermsText}>
     By signing up, you confirm that you have read and agree to our{' '}
     <Text style={styles.greenColorStyle}>Terms & Conditions</Text> and{' '}
     <Text style={styles.greenColorStyle}>Privacy Policy</Text>
    </Text>

    <Button
     title='Sign Up'
     disabled={isSubmitting}
     bgColor={getColor('NORMAL_GREEN')}
     onPress={handleSubmit(onSubmit)}
    />

    <View style={styles.signUpWith}>
     <View style={styles.hr} />
     <Text style={styles.signUpWithText}>OR sign up with</Text>
     <View style={styles.hr} />
    </View>

    <View>
     <View
      style={
       isWeb ? { flexDirection: 'row', justifyContent: 'space-between' } : {}
      }>
      <View style={[{ marginBottom: 12 }, isWeb && { width: '48%' }]}>
       <Pressable onPress={onSigninWithGoogle}>
        <SocialIcon text='Google'>
         <Image style={{ width: 25, height: 25 }} source={IMAGES.gmailImage} />
        </SocialIcon>
       </Pressable>
      </View>

      <View style={[{ marginBottom: 12 }, isWeb && { width: '48%' }]}>
       <Pressable onPress={onSigninWithFb}>
        <SocialIcon text='Facebook'>
         <Image
          style={{ width: 25, height: 25 }}
          source={IMAGES.facebookImage}
         />
        </SocialIcon>
       </Pressable>
      </View>
     </View>

     {(isIOS || isWeb) && (
      <View style={{ marginBottom: 12 }}>
       <Pressable onPress={onApple}>
        <SocialIcon text='Sign up with Apple'>
         <Text style={{ fontSize: 22 }}></Text>
        </SocialIcon>
       </Pressable>
      </View>
     )}
    </View>
   </View>

   <View style={isWeb ? {} : { flex: 1, justifyContent: 'center' }}>
    <Text
     style={StyleSheet.flatten([
      styles.bottomText,
      isWeb ? { marginTop: 0, marginBottom: 0 } : {},
     ])}>
     {isWeb ? 'Already have an account?' : 'Already a registered user? '}
     <Text
      style={{ color: getColor('NORMAL_ORANGE') }}
      onPress={() => navigation.navigate('Signin')}>
      Log In
     </Text>
    </Text>
   </View>
  </View>
 );
};

export default SignupForm;
