import React from 'react';
import { View } from 'react-native';

import { isWeb } from 'utils/platform';

const ScreenContainer = ({ children, style = {} }) => {
 return (
  <View
   style={
    isWeb
     ? {}
     : {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 24,
        ...style,
       }
   }>
   {children}
  </View>
 );
};

export default ScreenContainer;
