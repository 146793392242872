import React from 'react';
import { StyleSheet, Pressable } from 'react-native';

import SquircleContainer from './SquircleContainer';

import { getColor } from 'utils/settings';

const CustomButton = ({
 height,
 withOutBorder,
 customStyles,
 onPress,
 children,
}) => {
 const _styles = {
  height,
  borderRadius: withOutBorder ? 0 : 16,
  ...customStyles,
 };

 return (
  <Pressable onPress={onPress}>
   <SquircleContainer
    cornerRadius={16}
    bgColor={getColor('NEUTRAL200')}
    style={{ ...styles.container, ..._styles }}>
    {children}
   </SquircleContainer>
  </Pressable>
 );
};

export default CustomButton;

CustomButton.defaultProps = {
 height: 76,
 withSponsor: false,
 iconColor: getColor('NORMAL_ORANGE'),
};

const styles = StyleSheet.create({
 container: {
  width: '100%',
  paddingLeft: 16,
  paddingRight: 26,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: getColor('NEUTRAL200'),
 },
});
