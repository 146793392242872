export const icons = {
 envelope: '',
 lockOn: '',
 lock: '',
 lock3: '',
 forbidden: '',
 eyeHide: '',
 eyeShow: '',
 arrowLeft: '',
 user: '',
 people: '',
 checkmark: '',
 clear: '',
 camera: '',
 download: '',
 add: '',
 photo: '',
 trash: '',
 plus: '',
 plus2: '',
 home: '',
 homeBg: '',
 settings: '',
 settingsBg: '',
 search: '',
 document: '',
 logOut: '',
 currentLocation: '',
 warning: '',
 arrowDown: '',
 circle: '',
 more: '',
 gps: '',
 tickCircle: '',
 people2: '',
 map: '',
 location: '',
 category: '',
 profileAdd: '',
 walletmoney: '',
 edit: '',
 fullscreen: '',
 global: '',
 dot: '',
 userBg: '',
 selectedSearch: '',
};
