import BottomSheet, {
 BottomSheetBackdrop,
 BottomSheetView,
} from '@gorhom/bottom-sheet';
import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';

const BottomSheetModal = ({
 sheetRef,
 onClose,
 snapPoints = ['50%', '70%'],
 children,
}) => {
 const renderBackdrop = useCallback(
  (props) => (
   <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />
  ),
  []
 );

 return (
  <BottomSheet
   ref={sheetRef}
   snapPoints={snapPoints}
   overDragResistanceFactor={5}
   enableOverDrag={false}
   backdropComponent={renderBackdrop}
   enablePanDownToClose
   onClose={onClose}>
   <BottomSheetView style={styles.contentContainer}>{children}</BottomSheetView>
  </BottomSheet>
 );
};

export default BottomSheetModal;

const styles = StyleSheet.create({
 container: {
  flex: 1,
  padding: 24,
  backgroundColor: 'grey',
 },
 contentContainer: {
  flex: 1,
  alignItems: 'center',
 },
});
