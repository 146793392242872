import { proxy } from 'valtio';

const authStore = proxy({
 isLoggedIn: false,
 isOnboardingCompleted: false,
 profile: null,
 isOffline: null,
 permissions: null,
 searchedTrees: null,
 poligons: null,
 mapIsLoaded: false,
 isAppReady: false,
 trees: [],
 isSearchMode: false,
});

export default authStore;
