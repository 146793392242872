import React from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
 Extrapolate,
 interpolate,
 useAnimatedStyle,
} from 'react-native-reanimated';

import { getColor } from 'utils/settings';

const Paginator = ({ pages, itemWidth, scrollOffset }) => {
 const slides = [0, itemWidth, itemWidth * 2, itemWidth * 3];

 const newPages = pages.filter((w) => w.mainTitle);

 const arrForSlides = [
  {
   input: [slides[0], slides[1]],
   output: [24, 6],
  },
  {
   input: [slides[0], slides[1], slides[2]],
   output: [6, 24, 6],
  },
  {
   input: [slides[1], slides[2], slides[3]],
   output: [6, 24, 6],
  },
  {
   input: [slides[2], slides[3]],
   output: [6, 24],
  },
 ];

 const items = newPages.map((page, i) => {
  const indicatorOffset = useAnimatedStyle(() => {
   const interpolateFunc = interpolate(
    scrollOffset.value,
    arrForSlides[i].input,
    arrForSlides[i].output,
    Extrapolate.CLAMP
   );

   return {
    backgroundColor: Math.floor(interpolateFunc) > 6 ? '#fff' : '#447446',
    width: Math.floor(interpolateFunc),
   };
  });

  if (page.mainTitle) {
   return (
    <Animated.View key={page.id} style={[styles.indicator, indicatorOffset]} />
   );
  }

  return null;
 });

 return <View style={styles.itemsParent}>{items || null}</View>;
};

const styles = StyleSheet.create({
 container: {
  backgroundColor: getColor('NEUTRAL200'),
  borderRadius: 12,
  height: 6,
 },
 itemsParent: {
  height: 6,
  flexDirection: 'row',
  justifyContent: 'center',
 },
 indicator: {
  height: 6,
  width: 6,
  borderRadius: 50,
  marginHorizontal: 2,
 },
});

export default Paginator;
