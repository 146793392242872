import React, { useState, useEffect } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import {
 CodeField,
 Cursor,
 useBlurOnFulfill,
 useClearByFocusCell,
} from 'react-native-confirmation-code-field';

import InputError from 'reusable/InputError';

import { getColor } from 'utils/settings';
import { fonts, lineHeight } from 'utils/theme';

const CELL_COUNT = 4;

const ConfirmationCodeField = ({ name, error, onChange }) => {
 const [value, setValue] = useState('');
 const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
 const [props, getCellOnLayoutHandler] = useClearByFocusCell({
  value,
  setValue,
 });

 useEffect(() => {
  onChange(value);
 }, [value]);

 return (
  <>
   <CodeField
    ref={ref}
    {...props}
    value={value}
    onChangeText={setValue}
    cellCount={CELL_COUNT}
    keyboardType='numeric'
    textContentType='oneTimeCode'
    rootStyle={styles.codeFiledRoot}
    renderCell={({ index, symbol, isFocused }) => (
     <Text
      key={index}
      style={[styles.cell, error && styles.errorState]}
      onLayout={getCellOnLayoutHandler(index)}>
      {symbol || (isFocused ? <Cursor /> : null)}
     </Text>
    )}
   />

   <View style={{ alignItems: 'center' }}>
    <InputError error={error} name={name} withIcon={false} />
   </View>
  </>
 );
};

export default ConfirmationCodeField;

const styles = StyleSheet.create({
 codeFiledRoot: {
  marginBottom: 6,
  justifyContent: 'center',
 },
 errorState: {
  color: getColor('error'),
  borderColor: getColor('error'),
 },
 cell: {
  width: 52,
  height: 52,
  borderWidth: 1,
  borderColor: '#00000030',
  textAlign: 'center',
  borderRadius: 12,
  marginHorizontal: 8,
  fontFamily: fonts.MontserratBold,
  ...lineHeight.h2,
  lineHeight: 51,
 },
});
