import { useForm as RHFuseForm } from 'react-hook-form';

import useYupValidationResolver from 'components/forms/useYupValidationResolver';

export default function useForm(schema, defaultValues = {}) {
 const resolver = useYupValidationResolver(schema);

 const {
  control,
  handleSubmit,
  getValues,
  setValue,
  setError,
  clearErrors,
  formState: { errors, isSubmitting },
 } = RHFuseForm({
  resolver,
  defaultValues,
 });

 return {
  control,
  clearErrors,
  handleSubmit,
  errors,
  isSubmitting,
  getValues,
  setValue,
  setError,
 };
}
