const ageData = [
 { name: 'Young' },
 { name: 'Semi Mature' },
 { name: 'Mature' },
 { name: 'Over Mature' },
];

const healthData = [
 { name: 'Stump' },
 { name: 'Dead' },
 { name: 'Poor' },
 { name: 'Fair' },
 { name: 'Good' },
 { name: 'Removed' },
];

const structureData = [
 { name: 'Failed' },
 { name: 'Poor' },
 { name: 'Fair' },
 { name: 'Good' },
];

const resilienceData = [
 { name: 'Survivor' },
 { name: 'Threatened' },
 { name: 'Casualty' },
];

export { ageData, healthData, structureData, resilienceData };
