import React from 'react';

import LoginForm from 'components/forms/LoginForm';
import WebSignInContainer from 'components/webComps/webcommon/WebSignInContainer';

const WebSigninScreen = ({ navigation, route }) => {
 const token = route?.params?.token;

 return (
  <WebSignInContainer>
   <LoginForm navigation={navigation} signInToken={token} />
  </WebSignInContainer>
 );
};

export default WebSigninScreen;
