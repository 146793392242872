import { Portal } from '@gorhom/portal';
import React, {
 useState,
 useRef,
 useImperativeHandle,
 forwardRef,
} from 'react';

const ModalProvider = forwardRef((_, ref) => {
 const modalComponentRef = useRef();
 const paramsRef = useRef();

 const [visible, setVisible] = useState(false);

 const show = (modalComponent, props) => {
  paramsRef.current = props;
  modalComponentRef.current = modalComponent;
  setVisible(true);
 };

 const hide = () => {
  setVisible(false);
 };

 useImperativeHandle(ref, () => ({ show, hide }));

 if (visible) {
  return <Portal name='Modal'>{modalComponentRef.current}</Portal>;
 } else return null;
});

export default ModalProvider;
