import Clipboard from '@react-native-clipboard/clipboard';
import React from 'react';
import {
 Text,
 View,
 StyleSheet,
 Pressable,
 TouchableOpacity,
} from 'react-native';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import Input from 'components/reusable/Input';

import { CopyIcon } from 'utils/svgs';

const GenerateMapEmbedLink = ({ inputValue }) => {
 const onCopy = () => {
  Clipboard.setString(inputValue);
 };

 return (
  <View style={styles.container}>
   <Text style={[WebStyles.currentPlan]}>Embed code</Text>
   <View style={{ marginTop: 48 }}>
    <Input
     readOnly={true}
     withIcon={false}
     value={inputValue}
     otherStyles={{ paddingRight: 55 }}
    />

    <TouchableOpacity onPress={onCopy} style={styles.iconsStyle}>
     <CopyIcon style={{ width: 22 }} />
    </TouchableOpacity>
   </View>
  </View>
 );
};

const styles = StyleSheet.create({
 container: {
  paddingHorizontal: 24,
 },
 embedLinkStyle: {
  padding: 24,
 },
 iconsStyle: { position: 'absolute', right: 19, top: 15 },
});

export default GenerateMapEmbedLink;
