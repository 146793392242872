import React, { useState } from 'react';
import { StyleSheet, View, TextInput, Pressable } from 'react-native';

import SquircleContainer from './SquircleContainer';

import { WebStyles } from 'screens/WebNavigation/WebStyles';

import Icon from 'reusable/Icon';
import InputError from 'reusable/InputError';

import { isWeb } from 'utils/platform';
import { getColor } from 'utils/settings';
import { fonts } from 'utils/theme';

const INPUT_HEIGHT = 52;
const ICON_SIZE = 22;

let outlineStyle = isWeb ? { outlineStyle: 'none' } : {};

const Input = ({
 name,
 error,
 value,
 onClear,
 withIcon,
 iconName,
 disabled,
 iconsStyles,
 withEyeIcon,
 placeholder,
 keyboardType,
 onChangeText,
 isSearchInput,
 withClearIcon,
 secureTextEntry,
 searchInputColor,
 placeholderTextColor,
 otherStyles,
 hasDropDown,
 multiline,
 editable,
 onPressIn,
 squircleContainerStyle,
}) => {
 const [isHide, setIsHide] = useState(secureTextEntry);
 const [isShowCloseIcon, setIsShowCloseIcon] = useState(false);
 const [isFocused, setIsFocused] = useState(false);

 const onEyePress = () => {
  setIsHide((prev) => !prev);
 };

 const onFocus = () => {
  if (withClearIcon) {
   setIsShowCloseIcon(true);
  }
  if (isWeb) {
   setIsFocused(true);
  }
 };

 const onBlur = () => {
  if (withClearIcon) {
   setIsShowCloseIcon(false);
  }
  if (isWeb) {
   setIsFocused(false);
  }
 };

 return (
  <View>
   <SquircleContainer
    strokeWidth={hasDropDown ? 0 : 1}
    strokeColor={
     isWeb && isFocused
      ? getColor('NORMAL_GREEN')
      : error
      ? getColor('error')
      : getColor('NEUTRAL300')
    }
    bgColor={isSearchInput ? searchInputColor : '#fff'}
    style={[
     {
      ...styles.inputStyle,
      ...squircleContainerStyle,
     },
     hasDropDown && isWeb
      ? { alignSelf: 'center', height: 48, marginLeft: 3 }
      : null,
    ]}>
    {withIcon && (
     <Icon style={[styles.iconsStyle, iconsStyles]} name={iconName} />
    )}

    {withEyeIcon && (
     <Pressable activeOpacity={1} onPress={onEyePress} style={{ zIndex: 1111 }}>
      <Icon
       style={[styles.eyeIconStyle]}
       name={isHide ? 'eyeHide' : 'eyeShow'}
      />
     </Pressable>
    )}

    {withClearIcon && value !== '' && (
     <Pressable onPress={onClear} style={{ zIndex: 10 }}>
      <Icon style={styles.eyeIconStyle} name='clear' />
     </Pressable>
    )}

    <TextInput
     name={name}
     multiline={multiline}
     autoCorrect={false}
     autoCapitalize='none'
     value={value}
     disabled={disabled}
     spellCheck={false}
     onFocus={onFocus}
     onPressIn={onPressIn}
     readOnly={editable}
     placeholder={placeholder}
     onChangeText={onChangeText}
     inputMode={keyboardType}
     secureTextEntry={isHide}
     placeholderTextColor={placeholderTextColor}
     onBlur={onBlur}
     style={[
      {
       ...styles.textInputStyle,
       ...outlineStyle,
       paddingLeft: withIcon ? 52 : 19,
       paddingRight: withEyeIcon ? 40 : 10,
       color: error ? getColor('error') : getColor('NEUTRAL800'),
      },
      otherStyles,
      isWeb && WebStyles.webInputTextStyle,
     ]}
    />
   </SquircleContainer>

   <InputError error={error} name={name} />
  </View>
 );
};

export default Input;

Input.defaultProps = {
 withIcon: true,
 iconsStyles: {},
 multiline: false,
 editable: false,
 disabled: false,
 withEyeIcon: false,
 keyboardType: 'text',
 withClearIcon: false,
 placeholderTextColor: '#DFE2E8',
};

const styles = StyleSheet.create({
 inputStyle: {
  height: INPUT_HEIGHT,
  fontFamily: fonts.MontserratRegular,
 },
 textInputStyle: {
  height: '100%',
  width: '100%',
  zIndex: 1,
  fontFamily: 'MontserratSemiBold',
 },
 iconsStyle: {
  position: 'absolute',
  top: INPUT_HEIGHT / 2 - ICON_SIZE / 2,
  fontSize: ICON_SIZE,
  left: 19,
  zIndex: 3,
 },
 eyeIconStyle: {
  position: 'absolute',
  top: INPUT_HEIGHT / 2 - ICON_SIZE / 2,
  fontSize: ICON_SIZE,
  right: 19,
 },
});
