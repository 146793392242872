import { Image } from 'expo-image';
import React, { useState } from 'react';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { StyleSheet } from 'react-native';

const ImageComponent = (props) => {
 const [loading, setLoading] = useState(true);

 return (
  <>
   <Image
    onLoad={() => setLoading(false)}
    onLoadStart={() => setLoading(true)}
    {...props}
    left={0}
   />

   {loading && (
    <ContentLoader
     style={{ ...styles.loaderStyle, left: props.left }}
     speed={1}
     width={props.width}
     height={props.height}
     viewBox='0 0 150 150'
     backgroundColor='#f3f3f3'
     foregroundColor='#ecebeb'>
     <Rect
      x='0'
      y='0'
      rx='10'
      ry='10'
      width={props.rectWidth}
      height={props.rectHeight}
     />
    </ContentLoader>
   )}
  </>
 );
};

ImageComponent.defaultProps = {
 rectWidth: '135',
 rectHeight: '143',
 left: 8,
};

export default ImageComponent;

const styles = StyleSheet.create({
 loaderStyle: {
  marginRight: 5,
  position: 'absolute',
  top: 0,
  zIndex: 3,
 },
});
