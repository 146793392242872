import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import SquircleContainer from 'components/reusable/SquircleContainer';

import { getColor } from 'utils/settings';

const MapZoomButton = ({ zoomIn, zoomOut }) => {
 return (
  <SquircleContainer
   bgColor={getColor('WHITE')}
   cornerRadius={12}
   style={styles.squircle}>
   <TouchableOpacity style={styles.pressable} onPress={zoomIn}>
    <Text style={{ fontSize: 20 }}>+</Text>
   </TouchableOpacity>
   <TouchableOpacity style={styles.pressable} onPress={zoomOut}>
    <Text style={{ fontSize: 28 }}>-</Text>
   </TouchableOpacity>
  </SquircleContainer>
 );
};

const styles = StyleSheet.create({
 squircle: {
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  width: 42,
  height: 113,
  right: 24,
  top: 0,
  bottom: 0,
  margin: 'auto',
 },
 pressable: {
  width: '100%',
  height: '50%',
  alignItems: 'center',
  justifyContent: 'center',
 },
});

export default MapZoomButton;
