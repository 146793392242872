import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import SquircleContainer from './SquircleContainer';

import { getColor } from 'utils/settings';
import { fonts, fontSize } from 'utils/theme';

const Button = ({
 title,
 bgColor,
 textColor,
 disabledColor,
 cornerRadius,
 disabled,
 textSize,
 customStyles,
 onPress,
}) => {
 return (
  <TouchableOpacity
   style={[styles.buttonStyles, { ...customStyles }]}
   disabled={disabled}
   onPress={onPress}>
   <SquircleContainer
    cornerRadius={cornerRadius}
    style={styles.SquircleViewStyle}
    bgColor={disabled ? disabledColor : bgColor}>
    <Text
     style={[
      styles.TextColor,
      {
       fontSize: textSize,
       color: disabled ? getColor('NEUTRAL400') : textColor,
      },
     ]}>
     {title}
    </Text>
   </SquircleContainer>
  </TouchableOpacity>
 );
};

export default Button;

Button.defaultProps = {
 disabled: false,
 textColor: '#fff',
 textSize: 14,
 cornerRadius: 12,
 disabledColor: getColor('NEUTRAL300'),
};

const styles = StyleSheet.create({
 buttonStyles: {
  height: 52,
  width: '100%',
 },
 TextColor: {
  letterSpacing: 0.5,
  zIndex: 2,
  fontFamily: fonts.MontserratBold,
  ...fontSize.button2,
 },
 SquircleViewStyle: {
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
 },
});
