import React from 'react';
import { StyleSheet } from 'react-native';
import SwitchToggle from 'react-native-switch-toggle';

import { getColor } from 'utils/settings';

const Toggle = ({
 value,
 toggleSwitch,
 customContainerStyle,
 customCircleStyle,
}) => {
 return (
  <SwitchToggle
   switchOn={value}
   onPress={toggleSwitch}
   circleColorOff={getColor('WHITE')}
   circleColorOn={getColor('WHITE')}
   backgroundColorOn={getColor('NORMAL_GREEN')}
   backgroundColorOff={getColor('NEUTRAL300')}
   containerStyle={customContainerStyle || styles.containerStyle}
   circleStyle={customCircleStyle || styles.circleStyle}
  />
 );
};

export default Toggle;

const styles = StyleSheet.create({
 containerStyle: {
  width: 28,
  height: 22,
  borderRadius: 25,
  padding: 4,
 },
 circleStyle: {
  width: 13,
  height: 13,
  borderRadius: 20,
 },
});
