import themeStore from 'store/useTheme';

import { storageGetString } from 'utils/mmkvStorage';
import Themed from 'utils/themed';

const THEME_MODE = 'THEME_MODE';

const getThemeMode = () => {
 return storageGetString(THEME_MODE);
};

const getColor = (key) => Themed[key][themeStore.mode];

export { getThemeMode, getColor, THEME_MODE };
